/* @flow */
import * as React from 'react';
import type { SectionDropdownProps } from 'shared/types';
import Select from 'react-select'



/**
 * Component to handle search field.
 */
class StandardSectionDropdownField extends React.Component<SectionDropdownProps> {
  
  render() {
    return (
      <Select id={this.props.id} options={this.props.options} onChange={this.props.onChange}  placeholder={this.props.placeholder} defaultValue={this.props.default}  />
    );
  }
}

export default StandardSectionDropdownField;