/* @flow */
/* eslint-disable no-unused-vars */
import * as React from 'react';
import { Alert,
  Badge, 
  Button, 
  Form, 
  FormGroup, 
  FormFeedback, 
  Label, 
  Input,  
  Card,
  CardTitle,
  CardBody
} from 'reactstrap';
import Select from 'react-select';

import { userDeleteMutation, userInfoQuery, userNameQuery } from 'user/queries';
import { emailQuery, updateEmailMutation, insertEmailMutation, copyEmailsMutation, bulkRemoveEmailMutation , bulkAddEmailMutation} from 'emails/queries';
import { Query, Mutation } from 'react-apollo';
import ModalSection, { modalClose, modalHide } from 'elements/ModalSection';
import { client } from 'functions/Connect';
import { SystemAlert } from 'elements/SystemAlert';
import ReactDOM from 'react-dom';
import type { AddUserProps, DeleteUserProps, EditUserProps, EditUserState } from 'email/types';
import { doUsernamesMatch } from 'functions/AuthFunctions';
import AssociationForm from 'elements/AssociationForm';
import { emailListQuery } from './queries';
import ReactPaginate from "react-paginate";
import SectionTable from 'elements/SectionTable';
import SectionError from 'elements/SectionError';
import { getPermissions } from 'functions/AuthFunctions';
import qs from "qs";
import { createBrowserHistory } from "history";
import type { SharedSectionCallbacks } from 'shared/types';

const EmailSectionContext = React.createContext();
const history = createBrowserHistory();

const isValidUsername = (username) =>{
  return /^[a-zA-Z0-9]+$/.test(username);
}

export class NewUserModal extends React.Component<AddUserProps> {
  componentDidMount() {
    setTimeout(() => {
      ModalSection.openModal();
    }, 100);
  }

  render() {
    const emptyUser = {
      id: ''
    };
    return (
      <ModalSection
        heading="Add New Practice"
        content={
          <UserEditForm
            history={this.props.history}
            match={this.props.match}
            user={emptyUser}
            search={this.props.search}
            newUser
          />
        }
        actions={false}
        history={this.props.history}
      />
    );
  }
}

export class EditUserModal extends React.Component<EditUserProps> {


  componentDidMount() {
    ModalSection.openModal();
  }

  render() {
    return (
      <ModalSection
        heading={`Edit Practice`}
        content={
          <React.Fragment>
            <UserEditForm
              history={this.props.history}
              match={this.props.match}
              user={this.props.user}
              search={this.props.search}
              newUser={false}
            />
          </React.Fragment>
        }
        actions={false}
        history={this.props.history}
      />
    );
  }
  componentWillUnmount() {
    //console.log('test3')
  }
}

class UserEditForm extends React.Component<EditUserProps, EditUserState> {
  waitInterval: number;
  enterKey: number;
  timer: any;

  constructor(props: {}) {
    super(props);
    this.state = {
      id: this.props.user && this.props.user.id ? this.props.user.id : false,
      name: '',
      validName: true,
      password: '',
      passwordMatch: '',
      passwordStrength: '',
      passwordBadge: null,
      submitAvailable: false,
      formChanged: false,
      formSubmitted: false,
      deleteRequest: false,
      deleteGroupSlug: false,
      newUser: typeof this.props.newUser !== 'undefined' && this.props.newUser === true,
      custom: false,
      error: false
    };

    this.handleFormChange = this.handleFormChange.bind(this);
    this.handleFormSaved = this.handleFormSaved.bind(this);
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.handleFormSubmitCreate = this.handleFormSubmitCreate.bind(this);
    this.handleDeleteSubmit = this.handleDeleteSubmit.bind(this);
    this.changePassword = this.changePassword.bind(this);
    this.changeConfirmPassword = this.changeConfirmPassword.bind(this);
    this.passwordsMatch = this.passwordsMatch.bind(this);
    this.changeUsername = this.changeUsername.bind(this);
    this.handleUsernameChange = this.handleUsernameChange.bind(this);
    this.readySubmit = this.readySubmit.bind(this);
    this.passwordStrengthTest = this.passwordStrengthTest.bind(this);

    this.waitInterval = 500;
    this.enterKey = 13;
  }

  readySubmit = () => {

    //setTimeout(async () => {
    // console.groupCollapsed(`Running readySubmit()...`);
    // Start out as a failure.
    // let status = false;

    // // Means we are on an edit screen.
    // if (!!this.state.id) {
    //   // console.log(`This is an existing user. We are on the edit user screen.`);
    //   const doPasswordsMatch = this.passwordsMatch();
    //   const isPasswordFilled = this.state.password.length >= 3;
    //   // console.log(isPasswordFilled);
    //   // console.log(doPasswordsMatch);

    //   // This means we are updating the password.
    //   if (isPasswordFilled && doPasswordsMatch) {
    //     status = true;
    //   }

    //   // This means an Admin user (since standard users can't) edited either the
    //   // is_admin or status flags on the user.
    //   if (!isPasswordFilled && this.state.formChanged) {
    //     status = true;
    //   }
    // }
    // // Means we are on a create screen.
    // else {
    //   // console.log(`This is a new user. We are on the create user screen.`);
    //   const isUsernameValid = await this.usernameCheck(this.state.name);
    //   const isUsernameFilled = this.state.name.length >= 3;
    //   const doPasswordsMatch = this.passwordsMatch();
    //   const isPasswordFilled = this.state.password.length >= 3;
    //   if (doPasswordsMatch && isPasswordFilled && isUsernameValid && isUsernameFilled) {
    //     status = true;
    //   }
    // }

    // console.log(`status: ${status.toString()}`);
    // console.groupEnd()
    this.setState(() => ({
      submitAvailable: true,

    }));
    //}, 500);
  };

  changeUsername = val => {
    this.setState(() => ({
      name: val
    }));
  };

  handleUsernameChange = ({ target }: { target: HTMLInputElement }) => {
    clearTimeout(this.timer);
    this.timer = setTimeout(
      async function doChange() {
        const suppliedName = target.value;
        const validatedName = suppliedName.replace(/[^\w\s-]/gi, '');
        if (validatedName !== suppliedName) {
          // Since the user submitted a name with disallowed chars, we reset the field value.
          target.value = validatedName;
        }
        if (validatedName.length >= 1) {
          const validUsername = await this.usernameCheck(validatedName);
          this.setState(() => ({
            name: validatedName,
            validName: validUsername
          }));
        } else {
          this.setState(() => ({
            name: '',
            validName: true
          }));
        }
        this.readySubmit();
      }.bind(this),
      this.waitInterval
    );
  };

  usernameCheck = val => {
    const match: boolean = client
      .query({
        query: userNameQuery,
        // Ensure we ALWAYS reach out immediately and don't rely on cache for this query.
        fetchPolicy: 'no-cache',
        variables: {
          username: val
        }
      })
      .then(response => {
        const data = response.data.user;
        if (data !== null && data.id) {
          return false;
        }
        return true;
      })
      .catch(error => console.log(error));

    return match;
  };

  /**
   * @see https://www.thepolyglotdeveloper.com/2015/05/use-regex-to-test-password-strength-in-javascript/
   */
  passwordStrengthTest = val => {
    const strongRegex = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})');
    const mediumRegex = new RegExp(
      '^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,})'
    );

    if (strongRegex.test(val)) {
      this.setState(() => ({
        passwordStrength: 'strong',
        passwordBadge: <Badge color="success">strong</Badge>
      }));
    } else if (mediumRegex.test(val)) {
      this.setState(() => ({
        passwordStrength: 'average',
        passwordBadge: <Badge color="warning">average</Badge>
      }));
    } else {
      if (val.length >= 1) {
        this.setState(() => ({
          passwordStrength: 'weak',
          passwordBadge: <Badge color="danger">weak</Badge>
        }));
      } else {
        this.setState(() => ({
          passwordStrength: null
        }));
      }
    }
  };

  changePassword = val => {
    this.setState(() => ({
      password: val
    }));
    this.readySubmit();
  };

  changeConfirmPassword = val => {
    this.setState(() => ({
      passwordMatch: val
    }));
    this.readySubmit();
  };

  passwordsMatch = () => {
    return this.state.password === this.state.passwordMatch;
  };

  handleFormChange = async () => {
    this.readySubmit();
    this.setState(() => ({
      formChanged: true,
      submitAvailable: true,

    }));
  };

  handleFormSaved = () => {
    this.setState(() => ({
      formChanged: false
    }));
  };

  handleDeleteSubmit = id => {
    modalHide();

    // Wait a tick and change the state, which will open the delete modal.
    setTimeout(() => {
      this.setState(() => ({
        deleteRequest: true,
        deleteUserId: id
      }));
    }, 500);
  };

  /**
   * @todo: Handle sanitizing bogus input. #TRUSTNOONE
   */
  handleFormSubmit = () => {
    const status = document && document.querySelector('input[name="userActiveStatus"]:checked');
    const admin = document && document.querySelector('input[name="userAdminStatus"]:checked');
    const username = document && document.getElementById('userName');
    const soarian_resource_mne = document && document.getElementById('userPassword');
    const soarian_location_mne = document && document.getElementById('soarianLocation');
    const match = document && document.getElementById('match');
    const department_mne = document && document.getElementById('departmentMne');
    let soarian_resource_mn, soarian_location_mn, matche, department_mn;
    soarian_location_mn = soarian_location_mne.value;
    soarian_resource_mn = soarian_resource_mne.value;
    matche = match.value;
    department_mn = department_mne.value

    // if (typeof password !== 'undefined' && password !== null && password instanceof HTMLInputElement) {
    //   userPassword = password.value;
    // }


    const submittedData = {

      soarian_resource_mne: soarian_resource_mn,
      soarian_location_mne: soarian_location_mn,
      match: matche,
      department_mne: department_mn
    };




    // If we don't have a user id, then we are creating a new user and must send
    // the username of the new user. Otherwise, on edit screens, it is omitted.


    // If the password is NOT an empty string (edit user without updating password).
    // if (userPassword && userPassword.length !== 0) {
    //   userPayload.password = userPassword;
    // }

    return submittedData;
  };

  handleFormSubmitCreate = () => {
    const status = document && document.querySelector('input[name="userActiveStatus"]:checked');
    const admin = document && document.querySelector('input[name="userAdminStatus"]:checked');
    const providerId = document && document.getElementById('providerId');
    const soarian_resource_mne = document && document.getElementById('userPassword');
    const soarian_location_mne = document && document.getElementById('soarianLocation');
    const match = document && document.getElementById('match');
    const department_mne = document && document.getElementById('departmentMne');
    let soarian_resource_mn, soarian_location_mn, matche, department_mn;
    soarian_location_mn = soarian_location_mne.value;
    soarian_resource_mn = soarian_resource_mne.value;
    matche = match.value;
    department_mn = department_mne.value

    // if (typeof password !== 'undefined' && password !== null && password instanceof HTMLInputElement) {
    //   userPassword = password.value;
    // }


    const submittedData = {
      provider_id: providerId.value === "" ? null : providerId.value,
      soarian_resource_mne: soarian_resource_mn,
      soarian_location_mne: soarian_location_mn,
      match: matche,
      department_mne: department_mn
    };




    // If we don't have a user id, then we are creating a new user and must send
    // the username of the new user. Otherwise, on edit screens, it is omitted.


    // If the password is NOT an empty string (edit user without updating password).
    // if (userPassword && userPassword.length !== 0) {
    //   userPayload.password = userPassword;
    // }

    return submittedData;
  };

  render() {

    if (this.state.id === false) {

      return (
        <React.Fragment>
          <Mutation mutation={insertEmailMutation}>
            {(upsertGroup, { loading, error }) => {
              if (loading) {
                return null;
              }
              if (error) {
                console.log(`ERROR UPSERTING GROUP...`);
              }

              return (

                <Form
                  onSubmit={(e: Event) => {
                    e.preventDefault();
                    const submittedData = this.handleFormSubmitCreate();

                    upsertGroup({
                      variables: {
                        input: submittedData
                      }

                    });


                    // Send a message to the screen.
                    const NotifyUpserted = () => {
                      return (
                        <SystemAlert
                          autoClose
                          color="success"
                          icon="alert-icon fal fa-check-circle"
                          messageData={{
                            action: 'Created',
                            item: submittedData.provider_id !== null ? submittedData.provider_id : submittedData.soarian_resource_mne,
                            id: '',
                            tail: '...'
                          }}
                        />
                      );
                    };

                    const alert = document && document.getElementById('system-alert-wrapper');
                    if (alert) {
                      ReactDOM.render(<NotifyUpserted />, alert);
                      modalClose(this.props.history);
                    }
                  }}

                >
                  <FormGroup>
                    <React.Fragment>
                      <Input type="hidden" name="id" id="userId" defaultValue={''} />

                      <Label for="userName">Provider Id</Label>
                      <Input
                        onChange={e => {
                          this.handleFormChange();
                          this.handleUsernameChange(e);


                        }}
                        // isUniqueCallback={(slug: string) => {
                        //   return uniqueSlugCheck(slug, groupSlugQuery, 'group', 'saveGroupButton');
                        // }}
                        type="text"
                        autoComplete="off"
                        name="name"
                        id="providerId"

                        placeholder={`Enter a valid Provider Id...`}
                        defaultValue={''}

                      />
                      <FormFeedback valid>
                        <p className="mb-0">
                          <i className="fal fa-info-circle" /> The <strong>username</strong> you have selected is a
                          good one. This is good news!
                        </p>
                      </FormFeedback>
                      <FormFeedback>
                        <p className="mb-0">
                          <i className="fal fa-info-circle" /> The <strong>username</strong> you have selected is
                          already taken, please choose another.
                        </p>
                      </FormFeedback>
                    </React.Fragment>
                    <hr />
                  </FormGroup>

                  <FormGroup>
                    <React.Fragment>
                      <Label for="userPassword" className={`d-flex justify-content-between`}>
                        <span>Soarian Resource Mne</span>
                        <span className={'d-block'}>{this.state.passwordBadge}</span>
                      </Label>
                      <Input
                        onChange={e => {
                          this.handleFormChange();
                          //this.handleUsernameChange(e);
                        }}
                        // onChange={e => {
                        //   this.handleFormChange();
                        //   this.passwordStrengthTest(e.target.value);
                        //   this.changePassword(e.target.value);
                        // }}
                        type="text"
                        autoComplete="off"
                        name="password"
                        id="userPassword"
                        placeholder={`Enter a valid Soarian Resource Mne...`}
                        //placeholder={`Enter a password...`}

                        defaultValue={''}
                      // valid={passwordMatch && passwordsFilled}
                      // invalid={!passwordMatch && passwordsFilled}
                      />





                    </React.Fragment>
                    <hr />
                  </FormGroup>

                  <FormGroup>
                    <React.Fragment>
                      <Label for="soarianLocation" className={`d-flex justify-content-between`}>
                        <span>Soarian location Mne</span>
                        <span className={'d-block'}>{this.state.passwordBadge}</span>
                      </Label>
                      <Input
                        onChange={e => {
                          this.handleFormChange();
                          // this.handleUsernameChange(e);
                        }}
                        // onChange={e => {
                        //   this.handleFormChange();
                        //   this.passwordStrengthTest(e.target.value);
                        //   this.changePassword(e.target.value);
                        // }}
                        type="text"
                        autoComplete="off"
                        name="soarianLocation"
                        id="soarianLocation"
                        placeholder={`Enter a valid Soarian location Mne...`}
                        //placeholder={`Enter a password...`}

                        defaultValue={''}
                      // valid={passwordMatch && passwordsFilled}
                      // invalid={!passwordMatch && passwordsFilled}
                      />





                    </React.Fragment>
                    <hr />
                  </FormGroup>
                  <FormGroup>
                    <React.Fragment>
                      <Label for="match" className={`d-flex justify-content-between`}>
                        <span>Match</span>
                        <span className={'d-block'}>{this.state.passwordBadge}</span>
                      </Label>
                      <Input
                        onChange={e => {
                          this.handleFormChange();
                          //this.handleUsernameChange(e);
                        }}
                        // onChange={e => {
                        //   this.handleFormChange();
                        //   this.passwordStrengthTest(e.target.value);
                        //   this.changePassword(e.target.value);
                        // }}
                        type="text"
                        autoComplete="off"
                        name="match"
                        id="match"
                        placeholder={`Enter Soarian Resource Mne + Soarian location Mne ...`}

                        defaultValue={''}
                      // valid={passwordMatch && passwordsFilled}
                      // invalid={!passwordMatch && passwordsFilled}
                      />





                    </React.Fragment>
                    <hr />
                  </FormGroup>
                  <FormGroup>
                    <React.Fragment>
                      <Label for="match" className={`d-flex justify-content-between`}>
                        <span>Department Mne</span>
                        <span className={'d-block'}>{this.state.passwordBadge}</span>
                      </Label>
                      <Input
                        onChange={e => {
                          this.handleFormChange();
                          //this.handleUsernameChange(e);
                        }}
                        // onChange={e => {
                        //   this.handleFormChange();
                        //   this.passwordStrengthTest(e.target.value);
                        //   this.changePassword(e.target.value);
                        // }}
                        type="text"
                        autoComplete="off"
                        name="department_mne"
                        id="departmentMne"
                        placeholder={`Enter a valid Department Mne...`}

                        defaultValue={''}
                      // valid={passwordMatch && passwordsFilled}
                      // invalid={!passwordMatch && passwordsFilled}
                      />





                    </React.Fragment>
                    <hr />
                  </FormGroup>
                  <FormGroup className="mt-2 form-actions d-flex justify-content-between align-items-end">
                    <Button
                      id="saveGroupButton"
                      type="submit"
                      size="md"
                      color="primary"
                      title="Save"
                      disabled={!this.state.submitAvailable}
                      onClick={() => {
                        this.handleFormChange();
                        this.handleFormSaved();
                      }}
                    >
                      <i className="fal fa-save" />
                      <span>Save Practice</span>
                    </Button>

                  </FormGroup>
                </Form>
              )
            }}
          </Mutation>
        </React.Fragment>
      );
    }

    return (
      <Query
        query={emailQuery}
        pollInterval={0}
        notifyOnNetworkStatusChange={true}
        variables={{
          id: this.props.user && this.props.user.id ? this.props.user.id : ''
        }}
        fetchPolicy="cache-and-network"
      >
        {({ data, loading }) => {
          if (loading) return null;
          const user =
            data && data.practiceEmail
              ? data.practiceEmail
              : {
                // Set the default form values.
                id: false,
                username: '',
                status: false,
                is_admin: false
              };
          const passwordMatch = this.passwordsMatch();
          const passwordsFilled = this.state.password.length >= 1 && this.state.passwordMatch.length >= 1;
          const usernameFilled = this.state.name.length >= 1;
          const upsertMutation = user.id ? updateEmailMutation : insertEmailMutation;
          const noPass = this.state.passwordStrength === '' || this.state.password.length === 0;
          const notAverage = this.state.passwordStrength !== 'strong' && this.state.passwordStrength !== 'average';
          const notStrong = this.state.passwordStrength !== 'strong';

          return (
            <React.Fragment>
              <Alert color="warning" className={`mb-3 ${this.state.formChanged ? 'd-block' : 'd-none'}`}>
                <div className="alert--with-icon">
                  <i className="alert-icon fal fa-info-circle" />
                  <span>
                    The form has been updated. Use the <strong>Save User</strong> button to avoid losing any changes.
                  </span>
                </div>
              </Alert>

              <Alert color="warning" className={`mb-3 ${this.state.error ? 'd-block' : 'd-none'}`}>
                <div className="alert--with-icon">
                  <i className="alert-icon fal fa-info-circle" />
                  <span>
                    The form has been updated. Use the <strong>Save User</strong> button to avoid losing any changes.
                  </span>
                </div>
              </Alert>

              <Mutation mutation={upsertMutation}>
                {(upsertGroup, { loading, error }) => {
                  if (loading) {
                    return null;
                  }
                  if (error) {
                    console.log(`ERROR UPSERTING GROUP...`);
                  }

                  return (
                    <Form
                      onSubmit={(e: Event) => {
                        e.preventDefault();
                        const submittedData = this.handleFormSubmit();
                        if (user && user.id) {
                          upsertGroup({
                            variables: {
                              input: {
                                id: user.id,
                                //  log: this.getLogValue(),

                                update: submittedData


                              }
                            }

                          });
                        } else {
                          upsertGroup({
                            variables: {
                              input: submittedData
                            }
                          });
                        }

                        // Send a message to the screen.
                        const NotifyUpserted = () => {
                          return (
                            <SystemAlert
                              autoClose
                              color="success"
                              icon="alert-icon fal fa-check-circle"
                              messageData={{
                                action: user.id ? 'Updated' : 'Created',
                                item: submittedData.username ? submittedData.username : user.username,
                                id: user.id && typeof user.id === 'string' ? user.id : '',
                                tail: '...'
                              }}
                            />
                          );
                        };

                        const alert = document && document.getElementById('system-alert-wrapper');
                        if (alert) {
                          ReactDOM.render(<NotifyUpserted />, alert);
                          modalClose(this.props.history);
                        }
                      }}
                    >
                      <FormGroup>
                        <React.Fragment>
                          <Input type="hidden" name="id" id="userId" defaultValue={user && user.id ? user.id : ''} />

                          <Label for="userName">FullName</Label>
                          <Input
                            onChange={e => {
                              this.handleFormChange();
                              this.handleUsernameChange(e);
                            }}
                            type="text"
                            autoComplete="off"
                            name="name"
                            id="userName"
                            disabled={!!user.id}
                            placeholder={`Enter a valid username...`}
                            defaultValue={user.provider === null ? 'N/A' : user.provider.fullName}
                            valid={this.state.validName && usernameFilled}
                            invalid={!this.state.validName && usernameFilled}
                          />
                          <FormFeedback valid>
                            <p className="mb-0">
                              <i className="fal fa-info-circle" /> The <strong>username</strong> you have selected is a
                              good one. This is good news!
                            </p>
                          </FormFeedback>
                          <FormFeedback>
                            <p className="mb-0">
                              <i className="fal fa-info-circle" /> The <strong>username</strong> you have selected is
                              already taken, please choose another.
                            </p>
                          </FormFeedback>
                        </React.Fragment>
                        <hr />
                      </FormGroup>

                      <FormGroup>
                        <React.Fragment>
                          <Label for="userPassword" className={`d-flex justify-content-between`}>
                            <span>Soarian Resource Mne</span>
                            <span className={!noPass ? 'd-block' : 'd-none'}>{this.state.passwordBadge}</span>
                          </Label>
                          <Input
                            onChange={e => {
                              this.handleFormChange();
                              //this.handleUsernameChange(e);
                            }}
                            // onChange={e => {
                            //   this.handleFormChange();
                            //   this.passwordStrengthTest(e.target.value);
                            //   this.changePassword(e.target.value);
                            // }}
                            type="text"
                            autoComplete="off"
                            name="password"
                            id="userPassword"
                            //placeholder={`Enter a password...`}
                            //disabled={true}
                            defaultValue={user.soarian_resource_mne}
                          // valid={passwordMatch && passwordsFilled}
                          // invalid={!passwordMatch && passwordsFilled}
                          />





                        </React.Fragment>
                        <hr />
                      </FormGroup>

                      <FormGroup>
                        <React.Fragment>
                          <Label for="soarianLocation" className={`d-flex justify-content-between`}>
                            <span>Soarian location Mne</span>
                            <span className={!noPass ? 'd-block' : 'd-none'}>{this.state.passwordBadge}</span>
                          </Label>
                          <Input
                            onChange={e => {
                              this.handleFormChange();
                              //this.handleUsernameChange(e);
                            }}
                            // onChange={e => {
                            //   this.handleFormChange();
                            //   this.passwordStrengthTest(e.target.value);
                            //   this.changePassword(e.target.value);
                            // }}
                            type="text"
                            autoComplete="off"
                            name="soarianLocation"
                            id="soarianLocation"
                            //placeholder={`Enter a password...`}
                            //disabled={true}
                            defaultValue={user.soarian_location_mne}
                          // valid={passwordMatch && passwordsFilled}
                          // invalid={!passwordMatch && passwordsFilled}
                          />





                        </React.Fragment>
                        <hr />
                      </FormGroup>
                      <FormGroup>
                        <React.Fragment>
                          <Label for="match" className={`d-flex justify-content-between`}>
                            <span>Match</span>
                            <span className={!noPass ? 'd-block' : 'd-none'}>{this.state.passwordBadge}</span>
                          </Label>
                          <Input
                            onChange={e => {
                              this.handleFormChange();
                              //this.handleUsernameChange(e);
                            }}
                            // onChange={e => {
                            //   this.handleFormChange();
                            //   this.passwordStrengthTest(e.target.value);
                            //   this.changePassword(e.target.value);
                            // }}
                            type="text"
                            autoComplete="off"
                            name="match"
                            id="match"
                            //placeholder={`Enter a password...`}
                            //disabled={true}
                            defaultValue={user.match}
                          // valid={passwordMatch && passwordsFilled}
                          // invalid={!passwordMatch && passwordsFilled}
                          />





                        </React.Fragment>
                        <hr />
                      </FormGroup>
                      <FormGroup>
                        <React.Fragment>
                          <Label for="match" className={`d-flex justify-content-between`}>
                            <span>Department Mne</span>
                            <span className={!noPass ? 'd-block' : 'd-none'}>{this.state.passwordBadge}</span>
                          </Label>
                          <Input
                            onChange={e => {
                              this.handleFormChange();
                              //this.handleUsernameChange(e);
                            }}
                            // onChange={e => {
                            //   this.handleFormChange();
                            //   this.passwordStrengthTest(e.target.value);
                            //   this.changePassword(e.target.value);
                            // }}
                            type="text"
                            autoComplete="off"
                            name="match"
                            id="departmentMne"
                            //placeholder={`Enter a password...`}
                            //disabled={true}
                            defaultValue={user.department_mne}
                          // valid={passwordMatch && passwordsFilled}
                          // invalid={!passwordMatch && passwordsFilled}
                          />





                        </React.Fragment>
                        <hr />
                      </FormGroup>

                      <FormGroup className={user.id ? '' : 'd-none'}>

                        <AssociationForm
                          searchQuery={emailQuery}
                          deleteMutation={userDeleteMutation}
                          associateMutation={updateEmailMutation}
                          mutationVariables={{
                            id: this.props.user && this.props.user.id ? this.props.user.id : ''
                          }}
                          refetch={emailQuery}
                          refetchVars={{ id: this.props.user && this.props.user.id ? this.props.user.id : '' }}
                          label={`Emails`}
                          slug={`practiceEmail`}
                          gqlFilterVar={`provider`}
                          gqlFirstVar={100}
                          elementField={``}
                          elements={JSON.parse(user.emails)}
                          callbacks={{

                            handleFormChange: () => { },
                            handleFormSaved: () => { }
                          }}
                        />
                        <hr />
                      </FormGroup>


                      <FormGroup className="mt-2 form-actions d-flex justify-content-between align-items-end">
                        <Button
                          id="saveUserButton"
                          type="submit"
                          size="md"
                          color="primary"
                          title="Save"
                          disabled={!this.state.submitAvailable}
                          onClick={() => {
                            this.handleFormChange();
                            this.handleFormSaved();
                          }}
                        >
                          <i className="fal fa-save" />
                          <span>Update Practice</span>
                        </Button>
                        {/* <Button
                          outline
                          className={`${doUsernamesMatch(user.username) || !user.id ? 'd-none' : 'd-inline-block'}`}
                          size="md"
                          color="danger"
                          title={`Delete ${user.username}`}
                          onClick={() => {
                            this.handleDeleteSubmit(user.id);
                          }}
                        >
                          <i className="fal fa-trash-alt" />
                          <span>Delete User</span>
                        </Button> */}
                      </FormGroup>
                    </Form>
                  );
                }}
              </Mutation>
            </React.Fragment>
          );
        }}
      </Query>
    );
  }
}

export class EmailsCopyModal extends React.Component<EditUserProps> {


  componentDidMount() {
    ModalSection.openModal();

  }

  render() {
    return (
      <ModalSection
        heading={`Copy Emails`}
        content={
          <React.Fragment>
            <EmailsCopyForm
              history={this.props.history}
              match={this.props.match}
              user={this.props.user}
              search={this.props.search}
              newUser={false}
            />
          </React.Fragment>
        }
        actions={false}
        history={this.props.history}
      />
    );
  }

  componentWillUnmount() {
    let newHistory = this.props.history;
    newHistory.location.pathname = '/email';
    ModalSection.closeModal(newHistory);
  }
}

export class BulkAddEmailModal extends React.Component<EditUserProps> {


  componentDidMount() {
    ModalSection.openModal();
  }

  render() {
    return (
      <ModalSection
        heading={`Bulk Add Email`}
        content={
          <React.Fragment>
            <BulkAddEmailForm
              history={this.props.history}
              match={this.props.match}
              user={this.props.user}
              search={this.props.search}
              newUser={false}
            />
          </React.Fragment>
        }
        actions={false}
        history={this.props.history}
      />
    );
  }

  componentWillUnmount() {
    ModalSection.closeModal();
  }
}

export class BulkRemoveEmailModal extends React.Component<DeleteUserProps> {
  componentDidMount() {
    setTimeout(() => {
      ModalSection.openModal();
    }, 100);
  }

  render() {
    return (
      <ModalSection
        heading="Bulk Remove Email"
        content={<BulkRemoveEmailForm history={this.props.history} user={this.props.user} />}
        actions={false}
        history={this.props.history}
      />
    );
  }

  componentWillUnmount() {
    let newHistory = this.props.history;
    newHistory.location.pathname = '/email';
    ModalSection.closeModal(newHistory);
  }

}

class BulkRemoveEmailForm extends React.Component<DeleteUserProps> {
  constructor(props) {
    super(props);
    this.state = {
       reasonForOverride: ""
    };
    this.handleBulkRemoveEmailSubmit = this.handleBulkRemoveEmailSubmit.bind(this);
    this.onReasonChange = this.onReasonChange.bind(this);
  }

  handleBulkRemoveEmailSubmit = (e,upsertGroup) => {
    e.preventDefault();
    
    upsertGroup({
      variables: {
        input: {
          update: {
            email: this.props.user.bulkRemoveEmail,
            reasonForOverride: this.state.reasonForOverride
          },
          bulkRemoveIds: this.props.user.bulkRemoveIds
        }
      }
    }).then((result) => {
      if (result.data) {
        const success = result.data.bulkRemoveEmail.success;
            const colorVar = (success === true) ? 'success' : 'danger';
            const actionVar = (success === true) ? 'Email removed successfully' : 'Error removing email, please check email or try again';
         
          const NotifyUpserted = () => {
            return (
              <SystemAlert
                autoClose
                color={colorVar}
                icon="alert-icon fal fa-check-circle"
                messageData={{
                  action: actionVar,
                  item: "",
                  id: '',
                  tail: '...'
                }}
              />
            );
          }
          
          const alert = document && document.getElementById('system-alert-wrapper');
          if (alert) {
            ReactDOM.render(<NotifyUpserted />, alert);
            let newHistory = this.props.history;
            newHistory.location.pathname = '/email';
            modalClose(newHistory);
          }
        }

    });
  }

  onReasonChange = (e) => {
    this.setState(() =>({
      reasonForOverride: e.target.value
    }));
  }



  render() {
    return (
      <React.Fragment>
        {(this.props.user.bulkRemoveIds.length <= 0 ) && (
          <Alert color="danger" className={`mb-3 d-block`}>
            <div className="alert--with-icon">
              <i className="alert-icon fal fa-info-circle" />
              <span>
                Please select rows to bulk remove email
              </span>
            </div>
          </Alert>)}
          {(this.props.user.bulkRemoveEmail === "") && (
          <Alert color="danger" className={`mb-3 d-block`}>
            <div className="alert--with-icon">
              <i className="alert-icon fal fa-info-circle" />
              <span>
                Please enter valid email search for bulk remove email
              </span>
            </div>
          </Alert>) }
          {(this.props.user.bulkRemoveIds.length > 0 ) && (this.props.user.bulkRemoveEmail !== "") && (
        <Mutation
          mutation={bulkRemoveEmailMutation}
        >

        {(upsertGroup, { loading, error }) => {
            if (loading) {
              return null;
            }
            if (error) {
              console.log(`Error in removing bulk email...`);
            }
         
            return (
              <React.Fragment>
                <Form name="submitBulkRemoveEmail"  onSubmit={(e) => this.handleBulkRemoveEmailSubmit(e, upsertGroup)}>
                  <FormGroup className='d-block'>
                    <Label className={`mb-0`}>Email to Bulk remove</Label>
                    <Input
                      type={`text`}
                      name={`bulkRemoveEmail`}
                      id={`log--cft`}
                      rows={4}
                      value={(this.props.user.bulkRemoveEmail === "") ? "No email" : this.props.user.bulkRemoveEmail}
                      disabled={true}
                    />
                  </FormGroup>
                  <FormGroup className='d-block'>
                  <Alert color="info" className={`mb-3 d-block`}>
            <div className="alert--with-icon">
              <i className="alert-icon fal fa-info-circle" />
              <span>
                Please provide a reason, including who authorized the change(s) to the Primary Provider Data. Your message should be at least 20 characters long in order to save the updates.
              </span>
            </div>
          </Alert>
                    <Input
                      type={`textarea`}
                      name={`reasonForOverride`}
                      id={`log--cft`}
                      rows={4}
                      value={this.state.reasonForOverride}
                      disabled={false}
                      onChange = {(e) => this.setState({ reasonForOverride: e.target.value })}
                    />
                    
                  </FormGroup>
                <div className="text-center">
                <Button
                    className=""
                    outline
                    size="lg"
                    color="primary"
                    title="Cancel"
                    onClick={()=> modalClose(this.props.history)}
                    > 
                    <span>Cancel</span>
                  </Button>
                  <Button
                    className="delete-item-button mx-4"
                    outline
                    size="lg"
                    color="primary"
                    title="Delete"
                    type="submit"
                    disabled={((this.state.reasonForOverride.length < 20) || !(this.props.user.bulkRemoveIds.length > 0) || this.props.user.bulkRemoveEmail === null)}
                  >
                    <i className="fal fa-trash-alt" />
                    <span>Save</span>
                  </Button>
                </div>
                </Form>
              </React.Fragment>
            );

          }}
        </Mutation>)}
      </React.Fragment>
    );
  }
}


class EmailsCopyForm extends React.Component {
  callbacks: SharedSectionCallbacks;

  constructor(props) {
    super(props);
    this.state = {
      id: this.props.user && this.props.user.id ? this.props.user.id : false,
      // copy_column: null,
      // copy_column_value: null,
      department_keyword: "",
      afterCurser: "",
      soarian_resource_keyword: "",
      soarian_location_keyword: "",
      copyEmailSearchResults: [],
      emails: this.props.user.rowData.emails,
      formChanged: false,
      formSubmitted: false,
      submitSearchQuery: false,
      offset: 0,
      pageNumber: 0,
      limit: 25,
      custom: false,
      error: false
    };
    
    this.callbacks = {
      handleChange: this.handleChange,
      clearFilter: this.clearFilter,
      updateFirst: this.updateFirst,
      handlePage: this.handlePage
    }

    this.pagehandler = {
      handlePage: this.handlePage
    }

    this.handleChange = this.handleChange.bind(this);
  }

  handleFormSaved = () => {
    this.setState(() => ({
      formChanged: false
    }));
  };

  handleChange = (e) => {
    let dpt_mne = this.state.department_keyword;
    const val = e.target.value;
    if (e.target.name === 'department_keyword') {
      dpt_mne = val;
      this.setState(() => ({
        department_keyword: val
      }));

    }
    let sr_mne = this.state.soarian_resource_keyword;
    if (e.target.name === 'soarian_resource_keyword') {
      sr_mne = val;
      this.setState(() => ({
        soarian_resource_keyword: val
      }));
    }
    let sl_mne = this.state.soarian_location_keyword;
    if (e.target.name === 'soarian_location_keyword') {
      sl_mne = val;
      this.setState(() => ({
        soarian_location_keyword: val
      }));
    }
    this.triggerChange(e.target, dpt_mne, sl_mne, sr_mne);
    this.setState(() => ({
      submitSearchQuery: true
    }));

  }

  clearFilter = () => {
    this.setState(() => ({
      filter: ''
    }));

    history.replace(``);
  };

  reFocus = (target: HTMLInputElement) => {
    const elemId = target.getAttribute('id');
    const element = document && elemId && document.getElementById(elemId);
    if (typeof element !== 'undefined' && element !== null && element instanceof HTMLInputElement) {
      element.focus();
    }
  };

  updateFirst = (n: number) => {
    this.setState(() => ({
      limit: n
    }));
  };

  triggerChange = (e, dpt_mne, sl_mne, sr_mne) => {
    this.setState(() => ({
      department_keyword: dpt_mne ? dpt_mne : '',
      soarian_resource_keyword: sr_mne ? sr_mne : '',
      soarian_location_keyword: sl_mne ? sl_mne : '',
      pageNumber: 0
    }));

    history.push(`?dpt_mne=${dpt_mne}&sl_mne=${sl_mne}&sr_mne=${sr_mne}`);
  }

  handlePage = (offset, page) => {
    this.setState(() => ({
      afterCurser: offset,
      pageNumber: page
    }));

    history.push(`?page=${page}&curser=${offset}`);
    this.setState(() => ({
      offset: offset
    }));
  }

  handleRefetchClick = refetch => {
    refetch(); // Manually trigger the query refetch
  };

  render() {
    const emailsList = (this.state.emails !== undefined) ? JSON.parse(this.state.emails) : "";

    const handleCopyEmailsSubmit = (e, upsertGroup, data) => {
      const itemIds = data.map(item => {
        return item.id;
      });
      e.preventDefault();
      upsertGroup({
        variables: {
          input: {
            update: {
              emailResultIds: itemIds,
              emails: this.state.emails
            }
          }
        }
      }).then((result) => {
        if (result.data) {
          const success = result.data.copyEmails.success;
          const colorVar = (success === true) ? 'success' : 'danger';
          const actionVar = (success === true) ? 'Emails copied successfully' : 'Error copying email, please try again';
       
        const NotifyUpserted = () => {
          return (
            <SystemAlert
              autoClose
              color={colorVar}
              icon="alert-icon fal fa-check-circle"
              messageData={{
                action: actionVar,
                item: "",
                id: '',
                tail: '...'
              }}
            />
          );
        }
          
            this.setState({
              submitSearchQuery: false
            });
            const alert = document && document.getElementById('system-alert-wrapper');
            if (alert) {
              ReactDOM.render(<NotifyUpserted />, alert);
              let newHistory = this.props.history;
              newHistory.location.pathname = '/email';
              modalClose(newHistory);
            }
        }

      });

    }

    return (
      <React.Fragment>
        <Mutation mutation={copyEmailsMutation}>
          {(upsertGroup, { loading, error }) => {
            if (loading) {
              return null;
            }
            const user = this.props.user;
            if (error) {
              console.log(`Error in saving copied emails...`);
            }
            return (
              <React.Fragment>
                <Form
                  name="searchFormCopy"
                >
                  <FormGroup>
                    <Input
                      onChange={e => {
                        this.handleChange(e);
                      }}
                      type="text"
                      autoComplete="off"
                      name="soarian_resource_keyword"
                      id="providerId"
                      placeholder={`Search by soarian resource mne..`}
                      defaultValue={''}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Input
                      onChange={e => {
                        this.handleChange(e);
                      }}
                      type="text"
                      autoComplete="off"
                      name="soarian_location_keyword"
                      id="providerId"
                      placeholder={`Search by soarian location mne..`}
                      defaultValue={''}

                    />
                  </FormGroup>
                  <FormGroup>
                    <Input
                      onChange={e => {
                        this.handleChange(e);
                      }}
                      type="text"
                      autoComplete="off"
                      name="department_keyword"
                      id="providerId"
                      placeholder={`Search by department..`}
                      defaultValue={''}
                    />
                  </FormGroup>
                </Form>
                <Query
                  query={emailListQuery}
                  pollInterval={0}
                  notifyOnNetworkStatusChange={true}
                  fetchPolicy="no-cache"
                  variables={{
                    dpt_mne: this.state.department_keyword,
                    sr_mne: this.state.soarian_resource_keyword,
                    sl_mne: this.state.soarian_location_keyword,
                    afterCurser: this.state.offset,
                    first: this.state.limit
                  }}
                >
                  {({ loading, data, networkStatus }) => {
                    // Skip loading icon/flash when we are polling for new results.
                    if (loading && networkStatus !== 6 && networkStatus !== 3) {
                      // Handle loading screen.
                      const row = {
                        classes: 'loading',
                        content: <i className="fal fa-spinner" />
                      }
                      return (
                        <SectionTable
                          render={() => (
                            <tbody>
                              <tr>
                                <td className={row.classes}>{row.content}</td>
                              </tr>
                            </tbody>
                          )}
                        />
                      );
                    }

                    const audits = (data && data.practiceEmails) ? data.practiceEmails : false;

                    if (audits && audits.length === 0 && !loading) {
                      // Handle a zero result set.
                      const errorContent = (
                        <div className="text-center">
                          <h4 className="text-warning">No Results Found.</h4>
                          <h2 className="text-warning">¯\_(ツ)_/¯</h2>
                        </div>
                      );
                      return <SectionError color="warning" classes="" content={errorContent} />;
                    }
                    if (!data && !loading) {
                      // We've encountered a error connecting to GraphQL server.
                      // We should on this account, disable the Create Specialty button.
                      // @todo: Should be an easier way to detect an epic failure to connect.
                      const button = document.getElementById('addUserLink');
                      if (typeof button !== 'undefined' && button !== null && button instanceof HTMLButtonElement) {
                        button.classList.add('disabled');
                        button.disabled = true;
                      }
                      return (
                        <Alert color="danger" className={`error--graphql-connection`}>
                          <div className={`text-center`}>
                            <h4 className="text-error">GraphQL connection failure.</h4>
                            <h2 className="text-error">¯\_(ツ)_/¯</h2>
                          </div>
                        </Alert>
                      );
                    }
                    // Pagination issues.
                    // @see https://github.com/apollographql/apollo-client/issues/2499
                    return <React.Fragment>
                      <Form name="submitCopyEmails"
                        onSubmit={(e) => handleCopyEmailsSubmit(e, upsertGroup, (audits.aggregate.count > 0) ? audits.allRecords : null)}>
                        <FormGroup className='d-block'>
                          <Label className={`mb-0`}>Associated Emails</Label>
                          <Input
                            type={`textarea`}
                            name={`log--cft`}
                            id={`log--cft`}
                            rows={4}
                            value={emailsList}
                            disabled={true}
                          />
                        </FormGroup>


                        <FormGroup className="mt-2 form-actions d-flex justify-content-between align-items-end">
                          <Button
                            id="saveCopyEmails"
                            type="submit"
                            size="md"
                            color="primary"
                            title="Save"
                            disabled={!(audits.aggregate.count > 0 && emailsList.length >0 && this.state.submitSearchQuery)}
                          >
                            <i className="fal fa-save" />
                            <span>Save</span>
                          </Button>
                        </FormGroup>
                      </Form>
                      <AuditFeed pageNumber={this.state.pageNumber} page={this.pagehandler} users={audits.edges || []} pages={audits.aggregate || []} limit={this.state.limit} />
                    </React.Fragment>
                  }}

                </Query>

              </React.Fragment>
            );
          }}
        </Mutation>
      </React.Fragment>
    );
  }
}

class BulkAddEmailForm extends React.Component {
  callbacks: SharedSectionCallbacks;

  constructor(props) {
    super(props);
    this.state = {
      id: this.props.user && this.props.user.id ? this.props.user.id : false,
      bulkEmail: "",
      reasonForOverride:"",
      department_keyword: "",
      afterCurser: "",
      soarian_resource_keyword: "",
      soarian_location_keyword: "",
      formChanged: false,
      formSubmitted: false,
      submitSearchQuery: false,
      offset: 0,
      pageNumber: 0,
      limit: 25,
      custom: false,
      error: false
    };

    this.callbacks = {
      handleChange: this.handleChange,
      clearFilter: this.clearFilter,
      updateFirst: this.updateFirst,
      handlePage: this.handlePage
    };

    this.pagehandler = {
      handlePage: this.handlePage
    }

    this.handleChange = this.handleChange.bind(this);
  }

  handleFormSaved = () => {
    this.setState(() => ({
      formChanged: false
    }));
  };

  handleChange = (e) => {

    let dpt_mne = this.state.department_keyword;
    const val = e.target.value;
    if (e.target.name === 'department_keyword') {
      dpt_mne = val;
      this.setState(() => ({
        department_keyword: val
      }));

    }
    let sr_mne = this.state.soarian_resource_keyword;
    if (e.target.name === 'soarian_resource_keyword') {
      sr_mne = val;
      this.setState(() => ({
        soarian_resource_keyword: val
      }));
    }
    let sl_mne = this.state.soarian_location_keyword;
    if (e.target.name === 'soarian_location_keyword') {
      sl_mne = val;
      this.setState(() => ({
        soarian_location_keyword: val
      }));
    }
    this.triggerChange(e.target, dpt_mne, sl_mne, sr_mne);
    this.setState(() => ({
      submitSearchQuery: true
    }));

  }

  clearFilter = () => {
    this.setState(() => ({
      filter: ''
    }));

    history.replace(``);
  };

  reFocus = (target: HTMLInputElement) => {
    const elemId = target.getAttribute('id');
    const element = document && elemId && document.getElementById(elemId);
    if (typeof element !== 'undefined' && element !== null && element instanceof HTMLInputElement) {
      element.focus();
    }
  };

  updateFirst = (n: number) => {
    this.setState(() => ({
      limit: n
    }));
  };

  triggerChange = (e, dpt_mne, sl_mne, sr_mne) => {
    this.setState(() => ({
      department_keyword: dpt_mne ? dpt_mne : '',
      soarian_resource_keyword: sr_mne ? sr_mne : '',
      soarian_location_keyword: sl_mne ? sl_mne : '',
      pageNumber: 0
    }));

    history.push(`?dpt_mne=${dpt_mne}&sl_mne=${sl_mne}&sr_mne=${sr_mne}`);
  }

  handlePage = (offset, page) => {
    this.setState(() => ({
      afterCurser: offset,
      pageNumber: page
    }));

    history.push(`?page=${page}&curser=${offset}`);
    this.setState(() => ({
      offset: offset
    }));

  }

  handleRefetchClick = refetch => {
    refetch(); // Manually trigger the query refetch
  };

  render() {
    const handleBulkAddEmailsSubmit = (e, upsertGroup, data) => {
      const itemIds = data.map(item => {
        return item.id;
      });
      e.preventDefault();
      upsertGroup({
        variables: {
          input: {
            update: {
              emailResultIds: itemIds,
              email: this.state.bulkEmail,
              reasonForOverride: this.state.reasonForOverride
            }
          }
        }
      }).then((result) => {
        if (result.data) {
          const success = result.data.bulkAddEmail.success;
          const colorVar = (success === true) ? 'success' : 'danger';
          const actionVar = (success === true) ? 'Bulk email added successfully' : 'Error adding bulk email, please try again';
       
        const NotifyUpserted = () => {
          return (
            <SystemAlert
              autoClose
              color={colorVar}
              icon="alert-icon fal fa-check-circle"
              messageData={{
                action: actionVar,
                item: "",
                id: '',
                tail: '...'
              }}
            />
          );
        }
            this.setState({
              submitSearchQuery: false
            });
            const alert = document && document.getElementById('system-alert-wrapper');
            if (alert) {
              ReactDOM.render(<NotifyUpserted />, alert);
              modalClose(this.props.history);
            }
        }

      });

    }

    const visibilityClass2 = this.state.filter && this.state.filter.length >= 1 ? 'active-search' : '';

    return (
      <React.Fragment>
        <Mutation mutation={bulkAddEmailMutation}>
          {(upsertGroup, { loading, error }) => {
            if (loading) {
              return null;
            }
            const user = this.props.user;
            if (error) {
              console.log(`Error in adding email...`);
            }
            return (
              <React.Fragment>
                <Form
                  name="searchFormAddEmail"
                >
                  <FormGroup>
                    <Input
                      onChange={e => {
                        this.handleChange(e);
                      }}
                      type="text"
                      autoComplete="off"
                      name="soarian_resource_keyword"
                      id="providerId"
                      placeholder={`Search by soarian resource mne..`}
                      defaultValue={''}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Input
                      onChange={e => {
                        this.handleChange(e);
                      }}
                      type="text"
                      autoComplete="off"
                      name="soarian_location_keyword"
                      id="providerId"
                      placeholder={`Search by soarian location mne..`}
                      defaultValue={''}

                    />
                  </FormGroup>
                  <FormGroup>
                    <Input
                      onChange={e => {
                        this.handleChange(e);
                      }}
                      type="text"
                      autoComplete="off"
                      name="department_keyword"
                      id="providerId"
                      placeholder={`Search by department..`}
                      defaultValue={''}
                    />
                  </FormGroup>

                </Form><Query
                  query={emailListQuery}
                  pollInterval={0}
                  notifyOnNetworkStatusChange={true}
                  fetchPolicy="no-cache"
                  variables={{
                    dpt_mne: this.state.department_keyword,
                    sr_mne: this.state.soarian_resource_keyword,
                    sl_mne: this.state.soarian_location_keyword,
                    afterCurser: this.state.offset,
                    first: this.state.limit
                  }}
                >
                  {({ loading, data, networkStatus }) => {
                    // Skip loading icon/flash when we are polling for new results.
                    if (loading && networkStatus !== 6 && networkStatus !== 3) {
                      // Handle loading screen.
                      const row = {
                        classes: 'loading',
                        content: <i className="fal fa-spinner" />
                      }
                      return (
                        <SectionTable
                          render={() => (
                            <tbody>
                              <tr>
                                <td className={row.classes}>{row.content}</td>
                              </tr>
                            </tbody>
                          )}
                        />
                      );
                    }

                    const audits = (data && data.practiceEmails) ? data.practiceEmails : false;

                    if (audits && audits.length === 0 && !loading) {
                      // Handle a zero result set.
                      const errorContent = (
                        <div className="text-center">
                          <h4 className="text-warning">No Results Found.</h4>
                          <h2 className="text-warning">¯\_(ツ)_/¯</h2>
                        </div>
                      );
                      return <SectionError color="warning" classes="" content={errorContent} />;
                    }
                    if (!data && !loading) {
                      // We've encountered a error connecting to GraphQL server.
                      // We should on this account, disable the Create Specialty button.
                      // @todo: Should be an easier way to detect an epic failure to connect.
                      const button = document.getElementById('addUserLink');
                      if (typeof button !== 'undefined' && button !== null && button instanceof HTMLButtonElement) {
                        button.classList.add('disabled');
                        button.disabled = true;
                      }
                      return (
                        <Alert color="danger" className={`error--graphql-connection`}>
                          <div className={`text-center`}>
                            <h4 className="text-error">GraphQL connection failure.</h4>
                            <h2 className="text-error">¯\_(ツ)_/¯</h2>
                          </div>
                        </Alert>
                      );
                    }

                    // Pagination issues.
                    // @see https://github.com/apollographql/apollo-client/issues/2499
                    return <React.Fragment>
                      <Form name="submitBulkAddEmail"
                        onSubmit={(e) => handleBulkAddEmailsSubmit(e, upsertGroup, (audits.aggregate.count) ? audits.allRecords : null)}>
                          <Card inverse color="primary">
                            <CardBody>
                              <CardTitle className="">Enter Email </CardTitle>
                              <div id={`${typeof this.props.id !== "undefined" ? this.props.id : this.props.slug}-search-field`}  style={{display:'inline-block'}} className={`${visibilityClass2} section-search-form--wrapper`}>
                                <Input
                                  id="bulkEmail"
                                  className="search section-search section-search--field"
                                  autoComplete="off"
                                  type="text"
                                  placeholder={`example`}
                                  value={this.state.bulkEmail}
                                  onChange = {(e)=>this.setState({'bulkEmail' : e.target.value})}
                                />
                              </div><p style={{display:'inline-block'}}>@northwell.edu</p>
                            </CardBody>
                            </Card>
                            <Alert color="info" className={`mb-3 d-block my-2`}>
            <div className="alert--with-icon">
              <i className="alert-icon fal fa-info-circle" />
              <span>
                Please provide a reason, including who authorized the change(s) to the Primary Provider Data. Your message should be at least 20 characters long in order to save the updates.
              </span>
            </div>
          </Alert>
                    <Input
                      type={`textarea`}
                      name={`reasonForOverride`}
                      id={`log--cft`}
                      rows={4}
                      value={this.state.reasonForOverride}
                      disabled={false}
                      onChange = {(e) => this.setState({ reasonForOverride: e.target.value })}
                    />
                        <div className="text-left my-2">
                        <Button
                        className=""
                        outline
                        size="md"
                        color="primary"
                        title="Cancel"
                        onClick={()=> modalClose(this.props.history)}
                        > 
                        <span>Cancel</span>
                      </Button>
                          <Button
                            id="saveAddEmails"
                            type="submit"
                            size="md"
                            color="primary"
                            title="Save"
                            className = "my-2 mx-2"
                            disabled={!(audits.aggregate.count > 0  && this.state.reasonForOverride.length > 20 && this.state.submitSearchQuery && isValidUsername(this.state.bulkEmail))}

                          >
                            <i className="fal fa-save" />
                            <span>Save</span>
                          </Button>
                        </div>
                      </Form>
                      <AuditFeed pageNumber={this.state.pageNumber} page={this.pagehandler} users={audits.edges || []} pages={audits.aggregate || []} limit={this.state.limit} />
                    </React.Fragment>
                  }}

                </Query>

              </React.Fragment>
            );
          }}
        </Mutation>
      </React.Fragment>
    );
  }
}

/**
 * Component to handle a delete confirmation message.
 */
export class DeleteUserModal extends React.Component<DeleteUserProps> {
  componentDidMount() {
    setTimeout(() => {
      ModalSection.openModal();
    }, 100);
  }

  render() {
    return (
      <ModalSection
        heading="Delete User"
        content={<UserDeleteForm history={this.props.history} user={this.props.user} />}
        actions={false}
        history={this.props.history}
      />
    );
  }
}

class UserDeleteForm extends React.Component<DeleteUserProps> {
  render() {
    return (
      <React.Fragment>
        <Query
          query={userInfoQuery}
          pollInterval={0}
          notifyOnNetworkStatusChange={true}
          variables={{
            id: this.props.user && this.props.user.id ? this.props.user.id : ''
          }}
          fetchPolicy="cache-and-network"
        >
          {({ data, loading }) => {
            if (loading) return null;
            const user =
              data && data.user
                ? data.user
                : {
                  // Set the default form values.
                  id: null,
                  username: '',
                  status: false,
                  is_admin: false
                };

            const ConfirmationDetails = () => {
              if (data && data.user) {
                return (
                  <React.Fragment>
                    <Alert color="danger" className="mb-3 delete-confirmation">
                      <div className="alert--with-icon">
                        <i className="alert-icon fal fa-trash-alt" />
                        <h4 className="mb-0">Are you sure you want to delete following User?</h4>
                      </div>
                    </Alert>
                    <hr />
                    <div className="delete-confirmation--details">
                      <p>
                        <span className="delete-confirmation-label">User ID: </span>
                        <strong>{user && user.id ? user.id : ''}</strong>
                      </p>
                      <p>
                        <span className="delete-confirmation-label">Username: </span>
                        <strong>{user && user.username ? user.username : ''}</strong>
                      </p>
                    </div>
                    <hr />
                  </React.Fragment>
                );
              }
              return null;
            }

            return (
              <React.Fragment>
                <Mutation
                  mutation={userDeleteMutation}
                  variables={{
                    input: {
                      id: user && user.id ? user.id : null
                    }
                  }}
                >
                  {(deleteGroup, { data, loading }) => {
                    if (loading) {
                      return null;
                    }
                    const success = data && data.removeUser && data.removeUser.success;

                    if (success) {
                      const status = {
                        success: success,
                        action: success ? 'Deleted' : 'Failed to Delete',
                        item: {
                          id: data && data.removeUser && data.removeUser.user ? data.removeUser.user.id : user.id,
                          name:
                            data && data.removeUser && data.removeUser.user
                              ? data.removeUser.user.username
                              : user.username
                        },
                        tail: 'from the User database...'
                      }

                      return (
                        <React.Fragment>
                          <Alert color="success">
                            <div className="alert--with-icon">
                              <i className="alert-icon fal fa-check-circle" />
                              <p className="mb-0">
                                Successfully {status.action} <strong>{status.item.name}</strong> (
                                <em>{status.item.id})</em> {status.tail}
                              </p>
                            </div>
                          </Alert>
                          <hr />
                          <div className={`text-center`}>
                            <Button
                              outline
                              className="delete-item-button--success"
                              size="lg"
                              color="success"
                              title="Done"
                              onClick={() => {
                                modalClose(this.props.history);
                              }}
                            >
                              <i className="fal fa-check-circle" />
                              <span>Done</span>
                            </Button>
                          </div>
                        </React.Fragment>
                      );
                    }

                    if (user && user.id !== null) {
                      return (
                        <React.Fragment>
                          <ConfirmationDetails />
                          <div className="text-center">
                            <Button
                              className="delete-item-button"
                              outline
                              size="lg"
                              color="danger"
                              title="Delete"
                              onClick={() => {
                                deleteGroup();
                              }}
                            >
                              <i className="fal fa-trash-alt" />
                              <span>Yes, I'm sure</span>
                            </Button>
                          </div>
                        </React.Fragment>
                      );
                    } else {
                      return (
                        <Alert color="danger">
                          <div className="alert--with-icon">
                            <i className="alert-icon fal fa-info-circle" />
                            <p className="mb-0">That User could not be found...</p>
                          </div>
                        </Alert>
                      );
                    }
                  }}
                </Mutation>
              </React.Fragment>
            );
          }}
        </Query>
      </React.Fragment>
    );
  }
}

/**
 * Component for pagination
 */
class AuditFeed extends React.Component<UserFeedProps> {



  handlePageClick = (data) => {
    // currentUrlParams.set("page", JSON.stringify(data.slected+1));

    let page = data.selected;
    let last = 25 * page;
    let total = last - 1;
    this.props.page.handlePage(total, page);
    //this.handlePage(total, page);
    //window.location = window.location.pathname +"?page="+ currentPage
  }
  render() {

    if (this.props.users && this.props.users.length) {

      let last = this.props.users.length;
      // Friendly method to display the text "Showing X-Y of Z total results.
      let fromItems = 1;
      let toItems = 25;
      if (this.props.pages.count < 25) {
        fromItems = 1;
        toItems = this.props.pages.count
      }
      else if (this.props.pageNumber != 0) {
        fromItems = (this.props.pageNumber * 25);
        toItems = (this.props.pageNumber * 25) + 24
      }
      // total: total page count
      const total = Math.ceil(this.props.pages.count / this.props.limit);
      // Compile all the table rows together.
      const FeedRows = () => {
        return this.props.users.map((user, id) => <AuditTableRow row={user.node} key={id} />);
      };
      // Primary return without errors or oddities.
      return (
        <React.Fragment>
          <h4>
            Showing{' '}
            <strong>
              {fromItems} - {toItems}
            </strong>{' '}
            of <strong>{this.props.pages.count}</strong> rows.
          </h4>
          <SectionTable
            render={() => (
              <React.Fragment>
                <thead className="thead-light">
                  <AuditTableHeaderRow />
                </thead>
                <tbody className="result-set">
                  <FeedRows />
                </tbody>

              </React.Fragment>
            )}
          />
          <ReactPaginate
            previousLabel={"previous"}
            nextLabel={"next"}
            breakLabel={"..."}
            breakClassName={"break-me"}
            pageCount={total}
            forcePage={this.props.pageNumber}
            onPageChange={this.handlePageClick}
            containerClassName={"pagination"}
            subContainerClassName={"pages pagination"}
            activeClassName={"active"}
          />

        </React.Fragment>
      )
    }

    // Last case error that something went terribly wrong.
    const errorContent = (
      <div>
        <h4 className="text-danger">No results found</h4>
        <h2 className="text-danger">¯\_(ツ)_/¯</h2>
      </div>
    );
    return <SectionError color="danger" classes="" content={errorContent} />
  }
}

/**
 * Component for Data rows
 */
class AuditTableRow extends React.Component<UserTableRowProps> {
  constructor(props) {
    super(props);
    this.checkboxContainerRef = React.createRef();
    this.state = {
      selectedCheckboxId: null,
    };
  }

  handleCopyButtonClick = (id, rowData, parentFunction) => {
    this.setState({ selectedCheckboxId: id });
    parentFunction(rowData);
  }

  render() {
    const user = this.props.row;
    const username = user.user != null ? user.user.username : '';
    const milliseconds = user.timestamp * 1000 // 1575909015000

    const dateObject = new Date(milliseconds)

    const humanDateFormat = dateObject.toLocaleString()
    if (getPermissions('emails') || doUsernamesMatch(user.username)) {
      return (
        <EmailSectionContext.Consumer>
          {(context) => (
            <tr id={user.id} className={`result-set--row`} >
              <td className="account-type d-none d-lg-table-cell text-left">{user.match}</td>
              <td className="account-type d-none d-lg-table-cell text-left">{user.department_mne}</td>
            </tr>
          )}
        </EmailSectionContext.Consumer>
      )
    } else {
      return null;
    }
  }
}

/**
 * Component to handle table header for CFT results.
 */
class AuditTableHeaderRow extends React.Component {
  render() {
    return (
      <tr>
        <th className="actions text-left" scope="col">
          <span className="col-label">Match</span>
        </th>
        <th className="actions text-left" scope="col">
          <span className="col-label">Department Mne</span>
        </th>
      </tr>
    );
  }
}

