function  convertUnixTimestampToDate(unixTimestamp) {
    // Create a new Date object from the timestamp (in milliseconds)
    const dateObj = new Date(unixTimestamp * 1000);
  
    // Extract date components with zero-padding
    const month = String(dateObj.getMonth() + 1).padStart(2, '0'); // Zero-pad month
    const day = String(dateObj.getDate()).padStart(2, '0'); // Zero-pad day
    const year = dateObj.getFullYear();
  
    // Format the date string (mm/dd/yyyy)
    return `${month}/${day}/${year}`;
}

function convertUnixTimestampToDateAmPm(unixTimestamp) {
    // Create a new Date object from the timestamp (in milliseconds)
    const dateObj = new Date(unixTimestamp * 1000);
  
    // Extract date and time components
    const year = dateObj.getFullYear();
    const month = String(dateObj.getMonth() + 1).padStart(2, '0'); // Zero-pad month
    const day = String(dateObj.getDate()).padStart(2, '0'); // Zero-pad day
  
    // Extract hours, minutes, and seconds with AM/PM format
    const hours = dateObj.getHours() % 12 || 12; // Convert to 12-hour format (12 for midnight)
    const minutes = String(dateObj.getMinutes()).padStart(2, '0');
    const amPm = dateObj.getHours() >= 12 ? 'PM' : 'AM';
  
    // Format the date and time strings
    //const formattedDate = `${year}-${month}-${day}`;
    const formattedTime = `${hours}:${minutes} ${amPm}`;
  
    return `${formattedTime}`;
  }

  const slugify = (name) => {
    const normalized = name.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '');
    const hyphenated = normalized.replace(/[\W']/g, '-').replace(/[?']/g, '-');;
    return hyphenated.replace(/-{2,}/g, '-').replace(/^-+|-+$/g, '');
  };

module.exports = {
    convertUnixTimestampToDate,
    convertUnixTimestampToDateAmPm,
    slugify
}