/* @flow */
import * as React from 'react';
import ContentSection from 'elements/ContentSection';
import { ErrorBoundary } from 'elements/ErrorBoundary';
import { Alert } from 'reactstrap';
import type { SectionDataType, SectionProps } from 'shared/types';

class Faq extends React.Component<SectionProps> {
  render() {
    const SectionContent = () => {
      return (
        <React.Fragment>
          <Alert color="warning">
            <div className="alert--with-icon">
              <i className="alert-icon fal fa-info-circle" />
              <p className="mb-0">This section is currently unavailable. It is scheduled for future development.</p>
            </div>
          </Alert>
          <article className="markdown-body">
            <div className="faq-item mb-4">
              <h4 className="faq-question">FAQ Item 1</h4>
              <div className="faq-answer">
                <p>Faq item details.</p>
              </div>
            </div>
            <div className="faq-item mb-4">
              <h4 className="faq-question">FAQ Item 2</h4>
              <div className="faq-answer">
                <p>Faq item details.</p>
              </div>
            </div>
            <div className="faq-item mb-4">
              <h4 className="faq-question">FAQ Item 3</h4>
              <div className="faq-answer">
                <p>Faq item details.</p>
              </div>
            </div>
            <div className="faq-item mb-4">
              <h4 className="faq-question">FAQ Item 4</h4>
              <div className="faq-answer">
                <p>Faq item details.</p>
              </div>
            </div>
          </article>
        </React.Fragment>
      );
    };
    const sectionData: SectionDataType = {
      heading: 'Frequently Asked Questions',
      content: <SectionContent />,
      actions: false,
      modals: false
    };
    return (
      <ErrorBoundary>
        <ContentSection data={sectionData} history={this.props.history} />
      </ErrorBoundary>
    );
  }
}

export default Faq;
