import gql from 'graphql-tag';

export const promotedProgramSpecialtiesQuery = gql`
  query promotedProgramSpecialties($id: ID) {
    promotedProgramSpecialties(program_id: $id) {
      id
      slug
      name
    }
  }
`;

export const removeProgramSpecialtyMutation = gql`
  mutation removePromotedProgramSpecialty($input: removePromotedProgramSpecialtyInput!) {
    removePromotedProgramSpecialty(input: $input) {
      success
    }
  }
`;

export const createPromotedProgramSpecialtyMutation = gql`
  mutation createPromotedProgramSpecialty($input: createPromotedProgramSpecialtyInput!) {
    createPromotedProgramSpecialty(input: $input) {
      success
      specialty{
      id
      name
      program_id
      specialty_id
      use_inherited_terms
      }
    }
  }
`;

export const removeProgramTermMutation = gql`
  mutation removePromotedProgramTermAssociation($input: removePromotedProgramTermAssociationInput!) {
    removePromotedProgramTermAssociation(input: $input) {
      success
    }
  }
`;

export const createProgramTermMutation = gql`
  mutation createPromotedProgramTermAssociation($input: createPromotedProgramTermAssociationInput!) {
    createPromotedProgramTermAssociation(input: $input) {
      success
    }
  }
`;

export const createPromotedProgramSchedule = gql `
   mutation createPromotedProgramSchedule($input: createPromotedProgramScheduleInput!) {
    createPromotedProgramSchedule(input: $input) {
      success
      schedule{
      id
      sunday
      monday
      tuesday
      wednesday
      thursday
      friday
      saturday
      start_date
      end_date
      start_time
      end_time
      status
      }
    }
  }
`;

export const promotedProgramInfoQuery = gql`
query program($id:ID, $slug: String) {
    program(id: $id, slug: $slug){
        id
        name
        title
        slug
        copy
        button_text
        priority
        icon_id
        url
        status
        specialties {
          id,
          name,
          slug,
          use_inherited_terms
        }
        terms {
        id,
        name,
        slug
        }
        schedules {
          id
          sunday
          monday
          tuesday
          wednesday
          thursday
          friday
          saturday
          start_date
          end_date
          start_time
          end_time
          status 
        }
    }
}`;

/**
 * Mutation to handle creating a new CFT.
 */
export const insertPromotedProgramMutation = gql`
  mutation createPromotedProgram($input: createPromotedProgramInput!) {
    createPromotedProgram(input: $input) {
      success
    }
  }
`;

/**
 * Mutation to handle updating an existing CFT.
 */
export const updatePromotedProgramMutation = gql`
  mutation updatePromotedProgram($input: updatePromotedProgramInput!) {
    updatePromotedProgram(input: $input) {
      success
    }
  }
`;

export const programScheduleListQuery = gql`
  query programSchedules($programId: ID!, $first: Int, $afterCurser: String) {
    programSchedules(program_id:$programId, first: $first,afterCurser: $afterCurser) {
      aggregate {
          count
      }
      edges {
           node {
                id
                sunday
                monday
                tuesday
                wednesday
                thursday
                friday
                saturday
                start_date
                end_date
                start_time
                end_time
                status 
              }   
      cursor
      }
    }
  }
`;

export const programSpecialtyListQuery = gql`
  query programSpecialties($programId: ID!, $first: Int, $afterCurser: String) {
    programSpecialties(program_id:$programId, first: $first,afterCurser: $afterCurser) {
      aggregate {
          count
      }
      edges {
           node {
                id
                name
                specialty_id
                program_id
                use_inherited_terms
              }   
      cursor
      }
    }
  }
`;

export const programScheduleQuery = gql`
   query programSchedule($id: ID){
      id
      sunday
      monday
      tuesday
      wednesday
      thursday
      friday
      saturday
      start_date
      end_date
      start_time
      end_time
      status 
   }
`;

export const changeProgramScheduleStatusMutation = gql `
  mutation changeProgramScheduleStatus($input: changeProgramScheduleStatusInput!) {
  changeProgramScheduleStatus(input: $input) {
    success
  }
}
`;

export const removeProgramScheduleMutation = gql`
  mutation removePromotedProgramSchedule($input: removePromotedProgramScheduleInput!) {
    removePromotedProgramSchedule(input: $input) {
      success
    }
  }
`;

export const changeProgramSpecialtyIncludeTermsMutation = gql`
mutation changeProgramSpecialtyIncludeTerms($input: changeProgramSpecialtyIncludeTermsInput!) {
  changeProgramSpecialtyIncludeTerms(input: $input) {
    success
  }
}
`;

export const programConnectionQuery = gql`
query promotedPrograms($filter: String,$status: String, $day: String, $startDate: String, $endDate: String, $startTime: String, $endTime: String, $orderBy: PromotedProgramOrdering, $first: Int, $afterCurser: String) {
    promotedPrograms(filter: $filter,status: $status, day: $day, startDate: $startDate, endDate: $endDate, startTime:$startTime, endTime: $endTime, orderBy: $orderBy, first: $first,afterCurser: $afterCurser) {
      aggregate {
          count
      }
      edges {
           node {
                id
                name
                title
                slug
                url
                copy
                priority
                button_text
                status 
              }   
        cursor
      }
    }
  }
`;

export const deletePromotedProgramMutation = gql`
mutation deletePromotedProgram($input: deletePromotedProgramInput!) {
    deletePromotedProgram(input: $input) {
      success
    }
  }
`;

export const programIconListQuery = gql`
  query programIconsList($first: Int, $afterCurser: String) {
    programIconsList(first: $first,afterCurser: $afterCurser) {
      edges {
           node {
               id
               name
               display_name
              }   
          cursor
      }
    }
  }
`;
