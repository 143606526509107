/* @flow */
import * as React from 'react';

/**
 * Table sorting.
 *
 * @code
 * <TableColumnSort
 *   callbacks={{
 *     updateSort: FUNCTION
 *   }}
 *   order={"CURRENT_SORT"}
 *   sort={{
 *     asc: "type_ASC",
 *     desc: "type_DESC",
 *     default: "type_ASC",
 *   }}
 * />
 *
 * The asc/desc/default items are the actual flag that is used for the orderBy variable
 * passed into the Apollo <Query> component. Available options are easily found in the schema.
 */

type TableColumnSortProps = {
  sort: {
    sortField: string,
    default: string
  },
  order: {
    sortDirection: string,
    sortField: string
  },
  callbacks: {
    updateSort: (field: string, direction: string) => void
  }
};

class TableColumnSort extends React.Component<TableColumnSortProps> {
  render() {
    // console.log(this.props);

    const defaultAsc = this.props.sort.default === 'ASC';
    const defaultDesc = this.props.sort.default === 'DESC';
    const activeAsc =
      this.props.sort.sortField === this.props.order.sortField && this.props.order.sortDirection === 'ASC';
    const activeDesc =
      this.props.sort.sortField === this.props.order.sortField && this.props.order.sortDirection === 'DESC';
    const activeSortType = activeAsc || activeDesc;
    const activeClass = activeSortType ? ' text-primary' : '';

    return (
      <span className="sorting-icons">
        <a
          className={`${activeClass} ${activeAsc || (defaultAsc && !activeSortType) ? 'd-inline' : 'd-none'}`}
          onClick={() => {
            const sortTo = activeSortType ? 'DESC' : this.props.sort.default;
            this.props.callbacks.updateSort(this.props.sort.sortField, sortTo);
          }}
        >
          <i className={`sorting-icon sorting-icon--asc fal fa-caret-up`} />
        </a>
        <a
          className={`${activeClass} ${activeDesc || (defaultDesc && !activeSortType) ? 'd-inline' : 'd-none'}`}
          onClick={() => {
            const sortTo = activeSortType ? 'ASC' : this.props.sort.default;
            this.props.callbacks.updateSort(this.props.sort.sortField, sortTo);
          }}
        >
          <i className={`sorting-icon sorting-icon--desc fal fa-caret-down`} />
        </a>
      </span>
    );
  }
}

export default TableColumnSort;
