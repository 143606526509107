/* @flow */
/* eslint-disable no-unused-vars */
import * as React from 'react';
import ContentSection from 'elements/ContentSection';
import { Query } from 'react-apollo';
import TableColumnSort from 'layout/TableColumnSort';
import { Link, Route } from 'react-router-dom';
import ReactPaginate from "react-paginate";
import type {
  ProviderContentProps,
  ProviderContentState,
  ProviderFeedProps,
  ProviderList,
  ProviderQueryProps,
  ProviderTableHeaderRowProps,
  ProviderTableRowProps,
  StatusIconProps
} from 'provider/types';
import type {
  connectionQueryType,
  SectionDataType,
  SectionListProps,
  SectionListState,
  SharedSectionCallbacks,
  SidebarProps,
  SidebarState
} from 'shared/types';
import { Alert } from 'reactstrap';
import SectionSearchField from 'elements/SectionSearchField';
import SectionTable from 'elements/SectionTable';
import SectionLoadMoreButton from 'elements/SectionLoadMoreButton';
import SectionError from 'elements/SectionError';
import { providerConnectionQuery } from 'provider/queries';
import { animateScroll } from 'functions/Functions';
import { EditProviderModal } from './ProviderEdit';
import {createBrowserHistory} from "history";
import qs from "qs";
const history = createBrowserHistory();
class ProviderSection extends React.Component<SectionListProps, SectionListState> {
  waitInterval: number;
  enterKey: number;
  timer: any;
  callbacks: SharedSectionCallbacks;
  
  constructor(props: SectionListProps) {
    super(props);
    this.state = {
      filter: '',
      afterCurser: '',
      order: {
        sortField: 'FULLNAME_FIRST_LAST',
        sortDirection: 'ASC'
      },
      limit: 25,
      offset: 0,
      pageNumber: 0,
      awards:[]
    };
  
    
    this.handleChange = this.handleChange.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.triggerChange = this.triggerChange.bind(this);
    this.reFocus = this.reFocus.bind(this);
    this.clearFilter = this.clearFilter.bind(this);
    this.updateSort = this.updateSort.bind(this);
    this.handlePage = this.handlePage.bind(this);

    this.waitInterval = 500;
    this.enterKey = 13;

    /**
     * The following object of references allows us to pass our logic to the various
     * lower components that require some of the state data from this top level.
     */
    this.callbacks = {
      handleChange: this.handleChange,
      handleKeyDown: this.handleKeyDown,
      clearFilter: this.clearFilter,
      updateSort: this.updateSort,
      updateFirst: this.updateFirst
    };

    this.pagehandler = {
      handlePage: this.handlePage
    };
  }



  UNSAFE_componentWillMount() {
    this.timer = null;
  }
  componentDidMount(){
    const filterParams = history.location.search.substr(1);
    const filtersFromParams = qs.parse(filterParams);
    if(filtersFromParams.search){
     
      this.setState(() => ({
        filter: filtersFromParams.search
      }));
    }
    if(filtersFromParams.page){
     
      this.setState(() => ({
        pageNumber: filtersFromParams.page
      }));
    }
    if(filtersFromParams.curser){
     
      this.setState(() => ({
        afterCurser: filtersFromParams.curser
      }));
    }
  }

  reFocus = (target: HTMLInputElement) => {
    //console.log(target);
   
    const elemId = target.getAttribute('id');
    const element = document && elemId && document.getElementById(elemId);
    //console.log(element instanceof HTMLInputElement);
    if (typeof element !== 'undefined' && element !== null && element instanceof HTMLInputElement) {
      element.focus();
    }
  };

  updateSort = (field: string, direction: string) => {
    const sortField = field ? field : this.state.order.sortField;
    const sortDirection = direction ? direction : this.state.order.sortDirection;

    this.setState(() => ({
      order: {
        sortField: sortField,
        sortDirection: sortDirection
      }
    }));
  };

  clearFilter = () => {
    this.setState(() => ({
      filter: ''
    }));
  //  console.log(history.location);
    history.replace(``);
    
  };

  updateFirst = (n: number) => {
    this.setState(() => ({
      limit: n
    }));
  };

  handleChange = ({ target }: { target: HTMLInputElement }) => {
    clearTimeout(this.timer);
    this.timer = setTimeout(
      function doChange() {
        this.triggerChange(target);
      }.bind(this),
      this.waitInterval
    );
  };

  handleKeyDown = (e: KeyboardEvent) => {
    if (e.key === this.enterKey) {
      const target = e.target;
      if (target instanceof HTMLInputElement) {
        const attribute: string | null | void = target.getAttribute('id');
        const elemId: string = attribute && typeof attribute === 'string' ? attribute : '';
        const element: HTMLElement | null = document.getElementById(elemId);
        if (typeof element !== 'undefined' && element !== null && element instanceof HTMLInputElement) {
          this.triggerChange(target);
        }
      }
    }
  };

  triggerChange = (target: HTMLInputElement) => {
    this.setState(() => ({
      filter: target.value ? target.value : ''
    }));
    this.setState(() => ({
      afterCurser: '',
      pageNumber:''
    }));
    history.push(`?search=${target.value}`);
    if (target.value) {
      this.reFocus(target);
    }
  };

  handlePage = (id,page) => {
    // console.log(this.state.afterCurser);
     
     
     this.setState(() => ({
       afterCurser: id,
       pageNumber:page
     }));
    // console.log('green',page);
     history.push(`?page=${page}&curser=${id}`);
    
  
     //window.location = window.location.pathname +"?page="+ page
     //this.props.propcallbacks.handleChange();
   };
  
  

  render() {

 
    const match = this.props.match;
    const sharedSearchData = {
      filter: this.state.filter,
      order: {
        sortField: this.state.order.sortField,
        sortDirection: this.state.order.sortDirection
      },
      limit: this.state.limit,
      offset: this.state.offset
    };

    const ModalRoutes = () => {
      return (
        <React.Fragment>
          <Route
            exact
            path={`${match.path}/edit/:id`}
            render={(props: SectionListProps) => (
              <EditProviderModal
                {...props}
                search={sharedSearchData}
                provider={{
                  id: props.match.params && typeof props.match.params.id === 'string' ? props.match.params.id : ''
                }}
                
              />
            )}
          />
        </React.Fragment>
      );
    };

    // Pass all the things to content wrapper.
    const sectionData: SectionDataType = {
      heading: 'Provider Override Management',
      content: (
        <ProviderContent
          callbacks={this.callbacks}
          data={{
            filter: this.state.filter,
            order: {
              sortField: this.state.order.sortField,
              sortDirection: this.state.order.sortDirection
            },
            offset: this.state.afterCurser,
            limit: this.state.limit,
            page: this.pagehandler,
            pageNumber: this.state.pageNumber
          }}
          location={this.props.location}
        />
      ),
      actions: false,
      modals: <ModalRoutes />
    };

    return (
      <React.Fragment>
        <ContentSection data={sectionData} history={this.props.history} />
      </React.Fragment>
    );
  }
}

/**
 * Component to handle entire table.
 */
class ProviderContent extends React.Component<ProviderContentProps, ProviderContentState> {
  render() {
    return (
      <div className="group-landing--wrapper">
        <SectionSearchField
          filter={this.props.data.filter}
          callbacks={this.props.callbacks}
          element={{
            id: 'groupList',
            placeholder: 'Search Providers...'
          }}
        />
        <div className="table-responsive">
          <ProviderQuery data={this.props.data} callbacks={this.props.callbacks} location={this.props.location} />
        </div>
      </div>
    );
  }
}

/**
 * Default class to handle returning the formatted list of results.
 */
class ProviderQuery extends React.Component<ProviderQueryProps> {
  render() {
    // @todo: This may need to have the offset & limit sent back to the parent state.
    // This is the filter sent from the parent form(s).
    const FILTER = this.props.data.filter ? this.props.data.filter : '';
    // Default offset.
    const OFFSET = this.props.data.offset ? this.props.data.offset : 0;
    // Default item to sort on.
    const ORDER = this.props.data.order
      ? {
          sort: this.props.data.order.sortField,
          direction: this.props.data.order.sortDirection
        }
      : {
          sort: 'FULLNAME_FIRST_LAST',
          direction: 'ASC'
        };
    // Default items per page to display.
    const LIMIT = this.props.data.limit ? this.props.data.limit : 25;

    return (
      <Query
        query={providerConnectionQuery}
        pollInterval={0}
        notifyOnNetworkStatusChange
        variables={{
          filter: FILTER,
          afterCurser: OFFSET,
          first: LIMIT,
          orderBy: ORDER
        }}
        fetchPolicy="cache-and-network"
      >
        {({ variables, loading, data, fetchMore, networkStatus }) => {
          // Skip loading icon/flash when we are polling for new results.
          if (loading && networkStatus !== 6 && networkStatus !== 3) {
            // Handle loading screen.
            const row = {
              classes: 'loading',
              content: <i className="fal fa-spinner" />
            };
            return (
              <SectionTable
                render={() => (
                  <tbody>
                    <tr>
                      <td className={row.classes}>{row.content}</td>
                    </tr>
                  </tbody>
                )}
              />
            );
          }

          const query: [] = data && data.providers ? data.providers : [];
          const awards: [] = data && data.awards ? data.awards:[];
       
          
          //console.log(awards);
          if (query && query.length === 0 && !loading) {
            // Handle a zero result set.
            const errorContent = (
              <div className="text-center">
                <h4 className="text-warning">No Results Found.</h4>
                <h2 className="text-warning">¯\_(ツ)_/¯</h2>
              </div>
            );
            return <SectionError color="warning" classes="" content={errorContent} />;
          }

          if (!data && !loading) {
            // We've encountered a error connecting to GraphQL server.
            // We should on this account, disable the Create Provider button.
            // @todo: Should be an easier way to detect an epic failure to connect.
            const button = document.getElementById('addProviderLink');
            if (typeof button !== 'undefined' && button !== null && button instanceof HTMLButtonElement) {
              button.classList.add('disabled');
              button.disabled = true;
            }
            const search = document.getElementById('groupList');
            if (typeof search !== 'undefined' && search !== null && search instanceof HTMLInputElement) {
              search.disabled = true;
            }
            return (
              <Alert color="danger" className={`error--graphql-connection`}>
                <div className={`text-center`}>
                  <h4 className="text-error">GraphQL connection failure.</h4>
                  <h2 className="text-error">¯\_(ツ)_/¯</h2>
                </div>
              </Alert>
            );
          }

          // Pagination issues.
          // @see https://github.com/apollographql/apollo-client/issues/2499
          return (
            <React.Fragment>
              <ProviderFeed
                providers={query.edges || []}
                awards={awards || []}
                callbacks={this.props.callbacks}
                pages={query.aggregate}
                data={{
                  total: query && query.length >= 1 ? query.length : 0,
                  filter: FILTER,
                  order: {
                    sortField: ORDER.sort,
                    sortDirection: ORDER.direction
                  },
                  offset: OFFSET,
                  limit: LIMIT,
                  page:this.props.data.page,
                  pageNumber:this.props.data.pageNumber
                }}
              />
              <Alert color="info" className={`my-0 ${query.length >= 100 ? 'd-block' : 'd-none'}`}>
                <div className="alert--with-icon">
                  <i className="alert-icon fal fa-info-circle" />
                  <div>
                    <span>
                      Not all providers can be listed at once. Only the first 100 results are shown. Please use the
                      search form to narrow down the results.
                    </span>
                  </div>
                </div>
              </Alert>
            </React.Fragment>
          );
        }}
      </Query>
    );
  }
}

class ProviderFeed extends React.Component<ProviderFeedProps> {
  handlePageClick = (data) => {
    //console.log(data.selected+1)
    
    let page = data.selected;
   
  
    let last = 25 * page;
    let total = last - 1;
 
   // console.log(page);
   // currentUrlParams.set("page", JSON.stringify(data.slected+1));
   
   
 

    
    this.props.data.page.handlePage(total,page);
    //window.location = window.location.pathname +"?page="+ currentPage
  };
  render() {
    const data = this.props.data;
    if (this.props.providers && this.props.providers.length) {
      const total = Math.ceil(this.props.pages.count/this.props.data.limit);
     
      // Friendly method to display the text "Showing X-Y of Z total results.
      // const fromItems = 1;
      // const toItems = this.props.providers.length;
      const filterData = {
        filter: this.props.data.filter,
        order: this.props.data.order,
        limit: this.props.data.limit,
        offset: this.props.data.offset
      };

      // Compile all the table rows together.
      const FeedRows = () => {
        return this.props.providers.map((group, id) => <ProviderTableRow row={group.node} key={id} search={filterData} />);
      };

      // Primary return without errors or oddities.
      return (
        <React.Fragment>
          {/*<h4>*/}
          {/*Showing{' '}*/}
          {/*<strong>*/}
          {/*{fromItems} - {toItems}*/}
          {/*</strong>{' '}*/}
          {/*of <strong>{data.total}</strong> total providers.*/}
          {/*</h4>*/}
          <SectionTable
            render={() => (
              <React.Fragment>
                <thead className="thead-light">
                  <ProviderTableHeaderRow order={this.props.data.order} callbacks={this.props.callbacks} />
                </thead>
                <tbody className="result-set">
                  <FeedRows />
                </tbody>
              </React.Fragment>
            )}
          />
              <ReactPaginate
       previousLabel={"previous"}
       nextLabel={"next"}
       breakLabel={"..."}
       breakClassName={"break-me"}
       pageCount={total}
       forcePage={this.props.data.pageNumber}
       // marginPagesDisplayed={2}
       //pageRangeDisplayed={5}
       //callbacks={this.props.callbacks}
       onPageChange={this.handlePageClick}
       containerClassName={"pagination"}
       subContainerClassName={"pages pagination"}
       activeClassName={"active"}
     />
        </React.Fragment>
      );
    }

    // Last case error that something went terribly wrong.
    const errorContent = (
      <div>
        <h4 className="text-danger">Unknown error occurred.</h4>
        <h2 className="text-danger">¯\_(ツ)_/¯</h2>
      </div>
    );
    return <SectionError color="danger" classes="" content={errorContent} />;
  }
}

class StatusCircle extends React.Component<StatusIconProps> {
  render() {
    return !!this.props.status ? (
      <i className="fal fa-check-circle text-success" title={this.props.title} />
    ) : (
      <i className="fal fa-times-circle text-danger" title={this.props.title} />
    );
  }
}

/**
 * Component to handle single table row of results.
 */
class ProviderTableRow extends React.Component<ProviderTableRowProps> {
  render() {
    const provider: ProviderList = this.props.row;

    // const specialtyCount =
    //   provider.specialties && typeof provider.specialties === 'object' ? provider.specialties.length : 0;
    let ImageIcon = null;
    // provider.imageOriginal && provider.imageOriginal.length >= 1 ? <i className="fal fa-image" /> : null;
    if (provider.imageOriginal && provider.imageOriginal.length >= 1) {
      ImageIcon = <i className="fal fa-image" />;
    }
    if (provider.imageNew && provider.imageNew.length >= 1) {
      ImageIcon = <i className="fal fa-image" />;
    }

    const onFad =
      provider && provider.isFad ? (
        <StatusCircle status={true} title={`This provider IS on Find a Doctor`} />
      ) : (
        <StatusCircle status={false} title={`This provider IS NOT on Find a Doctor`} />
      );
    const staffPhysican =
      provider && provider.isStaff ? (
        <StatusCircle status={true} title={`This provider IS a staff physician`} />
      ) : (
        <StatusCircle status={false} title={`This provider IS NOT a staff physician`} />
      );
    const isPcp =
      provider && provider.isPcp ? (
        <StatusCircle status={true} title={`This provider IS a Primary Care Physician`} />
      ) : (
        <StatusCircle status={false} title={`This provider IS NOT a Primary Care Physician`} />
      );

    const newPatients =
      provider && provider.isAcceptingNewPatients ? (
        <StatusCircle status={true} title={`This provider IS accepting new patients`} />
      ) : (
        <StatusCircle status={false} title={`This provider IS NOT accepting new patients`} />
      );
      
      const isBookable =
      provider && provider.isBookable ? (
        <StatusCircle status={true} title={`This provider IS Schedulable`} />
      ) : (
        <StatusCircle status={false} title={`This provider IS Schedulable`} />
      );
      const DisableTerm =
      provider && provider.override && provider.override.disableClinicalFocusTerms ? (
        <StatusCircle status={true} title={`This provider IS Schedulable`} />
      ) : (
        <StatusCircle status={false} title={`This provider IS Schedulable`} />
      );

    return (
      <tr id={provider.id} className={`result-set--row`}>
        <td className={`has-image`}>{ImageIcon}</td>
        <th className="main-cell term" scope="row">
          <Link to={`/provider/edit/${provider.id}`} title={`Edit ${provider.fullName}`}>
            {provider.fullName}
          </Link>
        </th>
        <td className="d-none d-xl-table-cell text-center">{onFad}</td>
        <td className="d-none d-xl-table-cell text-center">{isPcp}</td>
        <td className="d-none d-xl-table-cell text-center">{staffPhysican}</td>
        <td className="d-none d-xl-table-cell text-center">{newPatients}</td>
        <td className="d-none d-xl-table-cell text-center">{isBookable}</td>
        <td className="d-none d-xl-table-cell text-center">{DisableTerm}</td>
        {/*<td className="d-none d-xl-table-cell text-center">{specialtyCount}</td>*/}
        <td className="actions">
          <Link to={`/provider/edit/${provider.id}`} title={`Edit ${provider.fullName}`}  className="btn btn-edit">
            <i className="fas fa-edit" />
          </Link>
        </td>
      </tr>
    );
  }
}

/**
 * Component to handle table header for CFT results.
 */
class ProviderTableHeaderRow extends React.Component<ProviderTableHeaderRowProps> {
  render() {
    const name_sort = {
      sortField: 'FULLNAME_FIRST_LAST',
      default: 'ASC'
    };

    return (
      <tr>
        <th scope="col" className={`icon-header`}>
          <i className="fal fa-image" title={`Provider has an image if icon is present...`} />
        </th>
        <th scope="col">
          <div className="sorting-cell">
            <span className="col-label">Provider Name</span>
            {/* <TableColumnSort callbacks={this.props.callbacks} order={this.props.order} sort={name_sort} /> */}
          </div>
        </th>
        <th scope="col" className="d-none d-xl-table-cell text-center">
          <span title={`Denotes if a Provider is on Find a Doctor`}>FAD</span>
        </th>
        <th scope="col" className="d-none d-xl-table-cell text-center">
          <span title={`Denotes if a Provider is a Primary Care Physician`}>PCP</span>
        </th>
        <th scope="col" className="d-none d-xl-table-cell text-center">
          <span title={`Denotes if a Provider is on Staff`}>Staff</span>
        </th>
        <th scope="col" className="d-none d-xl-table-cell text-center">
          <span title={`Denotes if a Provider is accepting new patients`}>New</span>
        </th>
        <th scope="col" className="d-none d-xl-table-cell text-center">
          <span title={`Denotes if a Provider is bookable`}>Schedulable</span>
        </th>
        <th scope="col" className="d-none d-xl-table-cell text-center">
          <span title={`Denotes if a Provider is accepting new patients`}>disableClinicalFocusTerms</span>
        </th>
        {/*<th scope="col" className="d-none d-xl-table-cell text-center">*/}
        {/*<div className="sorting-cell">*/}
        {/*<span className="col-label">Specialties</span>*/}
        {/*</div>*/}
        {/*</th>*/}
        <th className="actions" scope="col">
          <span className="col-label">Actions</span>
        </th>
      </tr>
    );
  }
}

/**
 * Return the ProviderSection component as the default.
 */
export default ProviderSection;
