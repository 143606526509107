/* @flow */
/* eslint no-unused-vars: "off" */
import * as React from 'react';
import {
  Alert,
  Input,
  Button,
  Badge,
  Card,
  CardTitle,
  CardBody,
  CardFooter,
  FormText,
  InputGroupAddon,
  Label,
  InputGroup
} from 'reactstrap';

import { Association, AssociationRemoved, AssociationAdded, AssociationAddable, Search,Award, Filter } from './';
import { isRole,getPermissions } from 'functions/AuthFunctions';
import type { AssociationElementProps, AssociationElementState } from 'shared/types';

import { toggleAssociationSearch } from 'functions/SectionFunctions';

/**
 * Component to handle attaching a one element to another.
 */
class AwardAssociationInput extends React.Component<AssociationElementProps, AssociationElementState> {
  waitInterval: number;
  enterKey: number;
  callbacks: {};
  timer: any;
  state = {
    elements: this.props.elements,
    visibility: {
      filter: false,
      search: false
    },
    values: {
      filter: '',
      search: ''
    }
  };

  filterCurrentBadges = (elements: Array<any> = []): Array<any> => {
    const {
      values: { filter }
    } = this.state;
    if (filter.length > 0) {
      return elements.filter(x => x.name.toLowerCase().includes(filter.toLowerCase()));
    }
    return elements;
  };

  toggleBadgeFilter = () => {
    // Focus on the field when needed.
    if (!this.state.badgeFilterVisible) {
      setTimeout(() => {
        this.focusBadgeFilter();
      }, 500);
    }

    // Toggle the input field.
    this.state.badgeFilterVisible
      ? this.setState(() => ({
          badgeFilterVisible: false
        }))
      : this.setState(() => ({
          badgeFilterVisible: true
        }));
  };

  focusBadgeFilter = () => {
    const target = document && document.getElementById(`${this.props.slug}AssociationFilter`);
    if (typeof target !== 'undefined' && target !== null && target instanceof HTMLInputElement) {
      target.focus();
    }
  };

  handleChange = ({ target }: { target: HTMLInputElement }) => {
    clearTimeout(this.timer);
    this.timer = setTimeout(
      function doChange() {
        this.triggerChange(target);
      }.bind(this),
      this.waitInterval
    );
  };

  setFilterValue = ({ target: { value } }: string) => {
    this.setState({
      filter: value
    });
  };

  clear = key => () => {
    this.setState({
      values: {
        ...this.state.values,
        [key]: ''
      }
    });
  };

  triggerChange = (target: HTMLInputElement) => {
    this.setState(() => ({
      filter: target.value ? target.value : ''
    }));
    if (target.value) {
      this.reFocus(target);
    }
  };

  handleChange = key => ({ target: { value } }) => {
    this.setState({
      values: {
        ...this.state.values,
        [key]: value
      }
    });
  };

  toggleVisibility = key => e => {
    const currentVisibility = this.state.visibility[key];
    this.setState({
      visibility: {
        ...this.state.visibility,
        [key]: !currentVisibility
      }
    });
  };

  UNSAFE_componentWillMount() {
    this.timer = null;
  }

  render() {
    
    const {
      values: { filter: filterValue, search: searchValue },
      visibility: { filter: filterVisible, search: searchVisible }
    } = this.state;
    const visibilityClass = this.state.filter && this.state.filter.length >= 1 ? 'd-block active-search' : 'd-block';
    const currentAssociations = this.props.elements && this.props.elements.length > 0 ? this.props.elements.length : 0;
   
    const filteredAssociations = this.filterCurrentBadges(this.props.elements);
   
    // const activeOverrides = (this.props.overridesAdded && this.props.overridesAdded.length >= 1) || (this.props.overridesRemoved && this.props.overridesRemoved.length >= 1);
    const props = this.props;
    
    return (
      <React.Fragment>
        <Label for={`relation-list--${this.props.slug}`} className={`d-flex justify-content-between`}>
          <div className={`${filteredAssociations > 0 && this.state.badgeFilterVisible ? 'filtered-list' : ''}`}>
           
            {this.props.label}

            <span className={`${filteredAssociations.length > 0 && filterValue ? '' : 'd-none'}`}>
              <Badge className={`label-badge`} color="warning">
                {filteredAssociations.length}
              </Badge>
              <span className={`label-badge-text`}>of</span>
            </span>

         

            <span className={`${filteredAssociations.length > 0 && filterValue ? '' : 'd-none'}`}>
              <span className={`label-badge-text`}>visible</span>
            </span>
          </div>
          <div>
            <Button
              title={`Filter current associations. Useful for long lists.`}
              className={`association-filter--button ${currentAssociations < 10 ? 'd-none' : ''}${
                this.state.badgeFilterVisible ? 'active' : ''
              }`}
              onClick={this.toggleVisibility('filter')}
            >
              <i className="association-filter--icon fas fa-filter" />
            </Button>
          </div>
        </Label>
        <Filter
          {...this.props}
          value={filterValue}
          visible={filterVisible}
          onChange={this.handleChange('filter')}
          onClick={this.clear('filter')}
        />
        <InputGroup className="relation-list--group">
          <div
            id={`relation-list--${this.props.slug}`}
            className={`relation-list relation-list--${this.props.slug} editable-content form-control`}
            contentEditable={false}
          >
            <div>
              <h6>Active Associations</h6>
              
              {filteredAssociations &&
                filteredAssociations.map((current, id) => {
                  
                  return (
                    <Association
                      key={id}
                      action={props.remove.remove}
                      association={current}
                      slug={'awards'}
                      
                    >
                      {current.name}
                    </Association>
                  );
                })}
            </div>

            <div
              className={`${
                this.props.overridesRemoved && this.filterCurrentBadges(this.props.overridesRemoved).length >= 1
                  ? 'd-block'
                  : 'd-none'
              }`}
            >
              <hr />
              <h6>Previously Removed Associations (overrides)</h6>
              {this.filterCurrentBadges(this.props.overridesRemoved).map(removed => {
                return (
                  <AssociationRemoved key={removed.id} {...props} removed={removed}>
                    {removed.name}
                  </AssociationRemoved>
                );
              })}
            </div>

            <div className={`${this.props.added && this.props.added.length > 0 ? 'd-block' : 'd-none'}`}>
              <hr />
              <h6>
                {this.props.label} to be added when form is <strong>saved</strong>.
              </h6>
              {props.added &&
                props.added.map(added => {
                  return (
                    <AssociationAdded key={added.id} {...props} action={props.callbacks.remove} association={added}>
                      {added.name}
                    </AssociationAdded>
                  );
                })}
            </div>

            <div className={`${this.props.removed && this.props.removed.length > 0 ? 'd-block' : 'd-none'}`}>
              <hr />
              <h6>
                {this.props.label} to be removed when form is <strong>saved</strong>.
              </h6>
              {props.removed &&
                props.removed.map(removed => {
                  return (
                    <AssociationRemoved key={removed.id} {...props} removed={removed}>
                      {removed.name}
                    </AssociationRemoved>
                  );
                })}
            </div>
          </div>
          <InputGroupAddon addonType="append">
          {getPermissions('truly') == false ? '':
            <Button
              id={`toggle${this.props.slug}Association`}
              color="primary"
              className="association-button association-closed"
              title={`${this.props.label}`}
              onClick={this.toggleVisibility('search')}
            >
              <i className="fas fa-plus" />
              <i className="fas fa-times" />
            </Button>
          }
          </InputGroupAddon>
          
        </InputGroup>
        <div style={{height:"20px"}}>

        </div>
        
        <Award {...props} visible={searchVisible} />
        
      </React.Fragment>
    );
  }
}

export default AwardAssociationInput;
