/* @flow */
import * as React from 'react';
import { Input, Label, FormText, FormFeedback } from 'reactstrap';
import { client } from 'functions/Connect';

type FieldProps = {
  id: string,
  name: string,
  label: string,
  defaultValue: string,
  placeholder?: string
};

type Props = {
  target: FieldProps,
  source: FieldProps,
  autoChangeOnExisting: boolean,
  existing: boolean,
  isUniqueCallback: string => boolean,
  changeCallback: () => void
};

type State = {
  slug: string,
  source: string,
  target: string,
  unique: boolean,
  fieldLabel: string,
  autoAlertVisible: boolean,
  custom: boolean,
  modified: boolean
};

/**
 * MachineName component.
 */
class MachineName extends React.Component<Props, State> {
  waitInterval: number;
  timer: any;

  constructor(props: Props) {
    super(props);
    this.state = {
      slug:
        this.props.target.defaultValue && typeof this.props.target.defaultValue === 'string'
          ? this.props.target.defaultValue
          : '',
      source:
        this.props.source.defaultValue && typeof this.props.source.defaultValue === 'string'
          ? this.props.source.defaultValue
          : '',
      target:
        this.props.target.defaultValue && typeof this.props.target.defaultValue === 'string'
          ? this.props.target.defaultValue
          : '',
      unique: true,
      autoAlertVisible: false,
      modified: this.props.target.defaultValue !== this.formatSlug(this.props.source.defaultValue),
      custom: false,
      fieldLabel:
        this.props.target.label && typeof this.props.target.label === 'string'
          ? this.props.target.label
          : 'Machine Name'
    };

    this.waitInterval = 750;
    this.timer = null;

    this.sourceChanged = this.sourceChanged.bind(this);
    this.targetChanged = this.targetChanged.bind(this);
    this.changeSlug = this.changeSlug.bind(this);
    this.getSourceValue = this.getSourceValue.bind(this);
    this.formatSlug = this.formatSlug.bind(this);
    this.handleSourceChange = this.handleSourceChange.bind(this);
    this.handleTargetChange = this.handleTargetChange.bind(this);
    this.showCustomField = this.showCustomField.bind(this);
    this.reFocus = this.reFocus.bind(this);
  }

  UNSAFE_componentWillMount() {
    // console.log(`Running <MachineName> UNSAFE_componentWillMount()...`);
    if (typeof this.props.target.defaultValue === 'string') {
      if (this.props.target.defaultValue === '' && this.props.source.defaultValue.length > 1) {
        const slugValue = this.formatSlug(this.props.source.defaultValue);
        this.changeSlug(slugValue);
      }
    }
  }

  /**
   * Determine if we should truly update the component.
   * @see https://alligator.io/react/lifecycle-functions/#shouldcomponentupdate
   */
  shouldComponentUpdate = (nextProps: Props, nextState: State) => {
    // console.log(`Running shouldComponentUpdate()...`);
    const { slug, unique, custom, modified, source, target } = this.state;
    return !(
      nextState.custom === custom &&
      nextState.modified === modified &&
      nextState.slug === slug &&
      nextState.unique === unique &&
      nextState.source === source &&
      nextState.target === target
    );
  };

  /**
   * Handle ensuring the field we are editing doesn't lose focus upon state change.
   */
  reFocus = (id: string): void => {
    const element = document && document.getElementById(id);
    if (typeof element !== 'undefined' && element !== null && element instanceof HTMLInputElement) {
      element.focus();
    }
  };

  /**
   * onChange callback for the source field.
   */
  handleSourceChange = () => {
    // Give focus back to our field.
    this.reFocus(this.props.source.id);

    clearTimeout(this.timer);
    this.timer = setTimeout(
      function() {
        this.sourceChanged();
        // Give focus back to our field.
        this.reFocus(this.props.source.id);
      }.bind(this),
      this.waitInterval
    );

    if (this.props.changeCallback && typeof this.props.changeCallback === 'function') {
      // Call the callback supplied by the props.
      this.props.changeCallback();
    }
  };

  /**
   * onChange callback for the target field.
   */
  handleTargetChange = () => {
    // Give focus back to our field.
    this.reFocus(this.props.target.id);

    if (this.state.custom) {
      clearTimeout(this.timer);
      this.timer = setTimeout(
        function() {
          this.targetChanged();
          // Give focus back to our field.
          this.reFocus(this.props.target.id);
        }.bind(this),
        this.waitInterval
      );
    }

    if (this.props.changeCallback && typeof this.props.changeCallback === 'function') {
      // Call the callback supplied by the props.
      this.props.changeCallback();
    }
  };

  /**
   * Handle formatting the machine name as desired.
   * Defaults:
   * - Make the string lowercase
   * - Trim whitespaces
   * - Replace spaces with a dash (-)
   * - Replace non word characters with a dash (-)
   * - Replace any instances of a double dash (--) with a single dash (-) after previous steps.
   *
   * @todo: Allow formatSlug to be powered by a custom regex or replaced with a custom function.
   */
  formatSlug = (slug: string): string => {
    return slug
      .toLowerCase()
      .trim() // Trim leading and trailing spaces
      .replace(/\s+/g, '-') // Replace spaces with -
      .replace(/[^\w-]+/g, '') // Remove all non-word chars
      .replace(/--+/g, '-'); // Replace multiple - with single -
  };

  /**
   * Get the value of the source text field.
   *  - this.props.source
   */
  getSourceValue = (): string => {
    const field = document && document.getElementById(this.props.source.id);
    if (typeof field !== 'undefined' && field !== null && field instanceof HTMLInputElement) {
      return field.value;
    }
    console.error('Returning "null" for getSourceValue()... something failed...');
    return '';
  };

  /**
   * Get the value of the target text field.
   *  - this.props.target
   */
  getTargetValue = (): string => {
    const field = document && document.getElementById(`${this.props.target.id}`);
    if (typeof field !== 'undefined' && field !== null && field instanceof HTMLInputElement) {
      return field.value;
    }
    console.error('Returning "null" for getTargetValue()... something failed...');
    return '';
  };

  /**
   * This function is the one that updates state for the slug value,
   * as well as the target.
   *
   * Assumptions:
   * - If the slug HAS been updated through either the sourceChanged or
   *   targetChanged methods, then the value should be updated on the
   *   target field.
   * - Each implementation of a MachineName component should provide a
   *   callback (isUniqueCallback) that returns a boolean value. If the
   *   target field is NOT required to be unique, then it can simply return
   *   true. Otherwise, this should handle any logic to determine if the
   *   slug will be valid potential value for the target field.
   *
   * @todo: Make it so isUniqueCallback is optional (function or null)
   */
  changeSlug = async (slug: string) => {
    if (slug !== this.state.slug) {
      if (slug.length === 0) {
        this.setState({
          slug: '',
          target: ''
        });
      } else {
        // Invoke the referenced function to check if the field is unique.
        const isUnique = await this.props.isUniqueCallback(slug);
        // console.log(isUnique);
        this.setState({
          slug: slug,
          target: slug,
          unique: isUnique,
          autoAlertVisible: !isUnique
        });
      }
    }
  };

  /**
   * This function runs after invocation from the handleSourceChange method.
   *
   * - Handle changing this.state.source to the new value of the source field.
   * - Optionally update the slug, and thus the target field if the appropriate
   *   conditions exist.
   */
  sourceChanged = () => {
    const slugValue = this.formatSlug(this.getSourceValue());
    const sourceValue = this.getSourceValue();

    if (sourceValue !== this.state.source) {
      this.setState({
        source: sourceValue
      });

      // IF NOT custom AND NOT modified
      // OR
      // IF NOT existing OR change existing IS TRUE AND NOT custom.
      if (
        !this.state.custom &&
        !this.state.modified &&
        (!this.state.custom && (!this.props.existing || this.props.autoChangeOnExisting))
      ) {
        this.changeSlug(slugValue);
      }
    }
  };

  /**
   * This function runs after invocation from the handleTargetChanged method.
   *
   * - Handle changing this.state.target to the new value of the target field.
   * - Optionally update the slug, and thus the target field if the appropriate
   *   conditions exist.
   */
  targetChanged = () => {
    const slugValue = this.formatSlug(this.getTargetValue());
    const targetValue = this.formatSlug(this.getTargetValue());

    if (targetValue !== this.state.target) {
      this.setState({
        target: targetValue
      });

      if (this.state.custom) {
        this.changeSlug(slugValue);
      }
    }
  };

  /**
   * Handle showing the target field when the edit button/link is clicked.
   * This will set this.state.custom to true, and allow a user to directly
   * edit the value of the slug.
   */
  showCustomField = () => {
    this.setState({
      custom: true
    });
  };

  /**
   * Handle showing the target field when the edit button/link is clicked.
   * This will set this.state.custom to true, and allow a user to directly
   * edit the value of the slug.
   */
  hideCustomField = () => {
    this.setState({
      custom: false
    });
  };

  render() {
    /**
     * Source field.
     */
    const MachineNameSource = () => {
      return (
        <div className={`machine-name--source`}>
          <Label for={this.props.source.id}>{this.props.source.label}</Label>
          <Input
            onChange={this.handleSourceChange}
            type="text"
            autoComplete="off"
            name={this.props.source.name}
            id={this.props.source.id}
            defaultValue={this.state.source}
            placeholder={this.props.source.placeholder}
            // We ONLY place feedback on this element if we aren't in a custom entry scenario.
            invalid={!this.state.unique && !this.state.custom}
            valid={this.state.unique && this.state.slug.length > 0 && !this.state.custom}
          />
          <FormFeedback>
            <p className="mb-0">
              The <em>{this.state.fieldLabel}</em> that was automatically generated (<strong>{this.state.slug}</strong>)
              is not unique. Please adjust the primary text, or edit the <em>{this.state.fieldLabel}</em> manually.
            </p>
          </FormFeedback>
        </div>
      );
    };

    /**
     * Section to display the data for the slug (value of target) and provide
     * a link to set this.state.custom to true, and show the actual field.
     */
    const MachineNameData = () => {
      return this.state.slug.length >= 1 ? (
        <div className={`${this.state.custom ? 'd-none' : 'd-block'} machine-name--auto`}>
          <FormText color="muted">
            <span className="machine-name--label">{this.state.fieldLabel}</span>:{` `}
            <span className={`${this.state.unique ? 'unique text-success' : 'non-unique text-danger'}`}>
              <strong>{this.state.slug}</strong>
            </span>
            {` `}
            <button className="btn btn-edit plain" onClick={this.showCustomField}>
              <i className="fas fa-edit" />
            </button>
          </FormText>
        </div>
      ) : null;
    };

    /**
     * Target field.
     */
    const MachineNameTarget = () => {
      return (
        <div className={`${this.state.custom ? 'd-block' : 'd-none'} machine-name--target mt-3`}>
          <Label for={this.props.target.id}>Custom {this.state.fieldLabel}</Label>
          <Input
            onChange={this.handleTargetChange}
            type="text"
            autoComplete="off"
            name={this.props.target.name}
            id={this.props.target.id}
            defaultValue={this.state.target}
            placeholder={`Enter custom ${this.state.fieldLabel}...`}
            // We use this element for feedback if we are using custom input.
            invalid={!this.state.unique && this.state.custom}
            valid={this.state.unique && this.state.slug.length > 0 && this.state.custom}
          />
          <button
            className="btn close-icon input-absolute-button"
            title="Close the custom field. Changes will still be applied."
            onClick={this.hideCustomField}
          >
            <i className="fas fa-times" />
          </button>
          <FormFeedback>
            <p className="mb-0">
              The {this.state.fieldLabel} above (<strong>{this.state.slug}</strong>) is not unique. Please adjust the{' '}
              <em>Custom {this.state.fieldLabel}</em> field accordingly.
            </p>
          </FormFeedback>
          <FormText color="muted">
            <i className="fal fa-info-circle" /> A custom{' '}
            <strong>
              <em>{this.state.fieldLabel}</em>
            </strong>{' '}
            should typically have no spaces, and be all lowercase letters.
          </FormText>
        </div>
      );
    };

    /**
     * Return the MachineName component.
     */
    return (
      <div className="machine-name-field">
        <MachineNameSource />
        <MachineNameData />
        <MachineNameTarget />
      </div>
    );
  }
}

export default MachineName;

/**
 * Function to use with MachineName field to see if a slug is unique.
 *
 * @param slug
 * @param query
 * @param queryName
 * @param buttonId
 */
export const uniqueSlugCheck = (slug: string, query: any, queryName: string, buttonId: string | null): boolean => {
  const match: boolean = client
    .query({
      query: query,
      // Ensure we ALWAYS reach out immediately and don't rely on cache for this query.
      fetchPolicy: 'no-cache',
      variables: {
        slug: slug
      }
    })
    .then(response => {
      const data = response.data[queryName];
      const button = buttonId !== null && typeof buttonId === 'string' ? document.getElementById(buttonId) : null;
      if (data !== null && data.id) {
        // Disable the Save button.
        if (button !== null && button instanceof HTMLButtonElement) {
          button.classList.add('disabled');
          button.disabled = true;
        }
        return false;
      }
      // Enable the Save button.
      if (button !== null && button instanceof HTMLButtonElement) {
        button.classList.remove('disabled');
        button.disabled = false;
      }
      return true;
    })
    .catch(error => console.log(error));

  return match;
};
