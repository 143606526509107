import { useQuery } from '@apollo/react-hooks';
import * as Queries from 'excludedTerm/queries';

export const fetchExcludedTerms = providerID => {
 
  const { loading: excludedTermsLoading, data, refetch } = useQuery(Queries.GET_PROVIDER_SPECIALTY_TERMS, {
    variables: {
      providerID: providerID
    },
  });
 
  if (!excludedTermsLoading && data.provider) {
    const {
      provider: {
        disableSpecialtyTermsAssociation,
        specialties,
        specialtyAssociatedTerms: { edges: specialtyTerms }
      }
    } = data;
    return { disableSpecialtyTermsAssociation, specialtyTerms, specialties, refetch };
  }
  return { disableSpecialtyTermsAssociation: false, specialtyTerms: null, specialties: null, refetch };
};

export const changeSpecialtyTerm = (variables, mutation, refetch) => async () => {
  const {
    data: {
      updateProviderSpecialtyTerms: { success }
    }
  } = await mutation({ ...variables });
  if (success) {
    refetch();
  }
};

export const toggleSpecialtyTermsAssociation = (variables, mutation, refetch) => async () => {
  const {
    data: {
      updateProviderOverrides: { success }
    }
  } = await mutation({ ...variables });
  if (success) {
    refetch();
  }
};
