import gql from 'graphql-tag';

export const emailListQuery = gql`
  query practiceEmails($first: Int, $afterCurser: String,$filter: String,$dpt_mne:String,$sl_mne:String,$sr_mne:String) {
    practiceEmails(first: $first,afterCurser: $afterCurser,filter: $filter, dpt_mne:$dpt_mne, sl_mne:$sl_mne, sr_mne:$sr_mne) {
      aggregate {
          count
      }
      allRecords{
        id
      }
      edges {
           node {
                id
                provider {
                  fullName
                }
                soarian_resource_mne
                soarian_location_mne
                match
                emails
                department_mne     
              }   
        cursor
      }
    }
  }
`;

/**
 * Mutation to handle creating a new CFT.
 */
export const insertEmailMutation = gql`
 mutation createEmail($input: CreateEmailInput!) {
   createEmail(input: $input) {
     success
     email {
       
      provider {
        fullName
      }
       soarian_resource_mne
       soarian_location_mne
       match
       department_mne
     }
   }
 }
`;

export const emailQuery = gql`
  query practiceEmail($id: ID!) {
    practiceEmail(id: $id) {
      id
      provider {
        fullName
      }
      soarian_resource_mne
      soarian_location_mne
      match
      emails
      department_mne
    }
  }
`;


export const updateEmailMutation = gql`
  mutation updateEmail($input: UpdateEmailInput!) {
    updateEmail(input: $input) {
      success
      
    }
  }
`;

export const copyEmailsMutation = gql`
mutation copyEmails($input: copyEmailsInput!) {
  copyEmails(input: $input) {
    success    
  }
}
`;

export const bulkRemoveEmailMutation = gql`
mutation bulkRemoveEmail($input: bulkRemoveEmailInput!) {
  bulkRemoveEmail(input: $input) {
    success    
  }
}
`;

export const bulkAddEmailMutation = gql`
mutation bulkAddEmail($input: bulkAddEmailInput!) {
  bulkAddEmail(input: $input) {
    success    
  }
}
`;

