/* @flow */
/* eslint-disable no-unused-vars */
import React from 'react';
import { Alert, Button } from 'reactstrap';

import { cftInfoQuery, deleteCftMutation } from 'clinicalFocusTerm/queries';
import { topspecialtyInfoQuery,UpdateTopSpecialty } from 'topspecialties/queries';
import { Query, Mutation } from 'react-apollo';

import { Link, Redirect } from 'react-router-dom';
import type { DeleteCftProps } from 'clinicalFocusTerm/types';
import { modalClose, modalHide } from 'elements/ModalSection';

export class TopSpecialtySaveForm extends React.Component<DeleteCftProps> {
  render() {
 
    console.log(this.props)
    return (

    
              <React.Fragment>
            
                <Mutation
                  mutation={UpdateTopSpecialty}
                  variables={{
                    input: {
                      order:this.props.order
                    }
                  }}
                >
                  {(deleteCFT, { data, loading }) => {
                    if (loading) {
                      return null;
                    }
                    const success = data && data.deleteTopSpecialty && data.deleteTopSpecialty.success;

                    if (success) {
                      const status = {
                        success: success,
                        action: success ? 'Deleted' : 'Failed to Delete',
               
                        tail: 'from the CFT database...'
                      };

                      return (
                        <React.Fragment>
                          <Alert color="success">
                            <div className="alert--with-icon">
                              <i className="alert-icon fal fa-check-circle" />
                              <p className="mb-0">
                                The New order has been Saved
                              </p>
                            </div>
                          </Alert>
                          <hr />
                          <div className={`text-center`}>
                            <Button
                              outline
                              className="delete-item-button--success"
                              size="lg"
                              color="success"
                              title="Done"
                              onClick={() => {
                                modalClose(this.props.history);
                              }}
                            >
                              <i className="fal fa-check-circle" />
                              <span>Done</span>
                            </Button>
                          </div>
                        </React.Fragment>
                      );
                    }
                    else{
                       return(
                        <React.Fragment>
                        <Alert color="danger" className="mb-3 delete-confirmation">
                          <div className="alert--with-icon">
                            <i className="alert-icon fal" />
                            <h4 className="mb-0">The order of Most Selected Specialties was not saved</h4>
                          </div>
                        </Alert>
                        <hr />
                       
                      
                      </React.Fragment>
                       );
                    }

       
                  }}
                </Mutation>
              </React.Fragment>
            
        
  
    );
  }
}
