/* @flow */
import React, { Component } from 'react';
import { Query } from 'react-apollo';
import { Alert, Card, CardBody, CardFooter, CardTitle, Input } from 'reactstrap';

import { AssociationAddable } from 'shared/inputs/association';
import  CreateGroupAdds  from 'elements/CreateGroupAdds';
import SectionDropdownField from 'elements/StandardSectionDropdownField';
export default class Search extends Component {
  state = {
    filter: ''
  };

  handleChange = ({ target: { value } }: { target: HTMLInputElement }) => {
    this.setState({ filter: value });
  };

  clearSearch = () => {
    this.setState({ filter: '' });
  };

  render() {
    const { added, elements, gqlFirstVar, label, searchQuery, slug, visible } = this.props;
    const { filter } = this.state;
    const hasSearchValue = filter.length >= 1;
    const visibilityClass = hasSearchValue ? 'active-search ' : '';
    console.log(this.props);
    let awards = this.props.awards;
    let awardOptions = [];
    let yearOptions = [];
    let statusOptions = [];
    statusOptions.push({ value: 'Finalist', label: 'Finalist' ,id:'status'})
    statusOptions.push({ value: 'Recipient', label: 'Recipient',id:'status' })
    let year  = new Date().getFullYear();
    let yearRange = year - 2013;
    yearOptions.push({ value: year, label: year, id:'year' })
    for(let y = yearRange; y>0; y--){
       yearOptions.push({ value: year - y, label: year - y, id:'year'})
    }
    for(let i=0; i < awards.length ; i++){
            
      awardOptions.push({ value: awards[i].id, label: awards[i].award_name,id:'award_name'})
      
    }
    awardOptions.sort(function(a,b) {
      return b.value - a.value;
  });
    yearOptions.sort(function(a,b) {
      return b.value - a.value;
  });
    if (visible) {
      return (
        <Card  color="primary">
          <CardBody>
            {/* <CardTitle className="">Associate {label}</CardTitle> */}
            <div id={`${slug}-select-field`} >
        
              <SectionDropdownField
                            id='awardType'
                           
                            //filter={this.props.data.filter}
                            onChange={this.props.callbacks}
                            element={{
                              id: 'roleList',
                              placeholder : 'Select Award Type'
                            }}
                            
                            options= {
                              awardOptions
                            }
                            
                            default={''}
               />
               <div style={{height:"20px"}}>

               </div>
                   <SectionDropdownField
                            id='awardStatus'
                            placeholder = 'Select Status'
                            //filter={this.props.data.filter}
                            onChange={this.props.callbacks}
                            element={{
                              id: 'roleList',
                              
                            }}
                            
                            options= {
                              statusOptions
                            }
                            
                            default={''}
               />
                 <div style={{height:"20px"}}>

                 </div>
                 <SectionDropdownField
                            id='awardYear'
                            placeholder = 'Select Year'
                            //filter={this.props.data.filter}
                            onChange={this.props.callbacks}
                            element={{
                              id: 'roleList',
                              
                            }}
                            
                            options= {
                              yearOptions
                            }
                            
                            default={''}
               />
            
            </div>
          </CardBody>
         
          {this.props.slug == 'cftTerms' ?
          <CreateGroupAdds group_id={this.props.mutationVariables}  refetch={this.props.refetch} mutation={this.props.associateMutation} setcreatedTerm={''} slug = {this.props.slug} />
          :''}
          {hasSearchValue && (
            <CardFooter className="bg-light">
              <div id={`${slug}Results`}>
                <Query
                  query={searchQuery}
                  variables={{
                    filter: filter,
                    first: gqlFirstVar
                  }}
                  fetchPolicy="cache-and-network"
                >
                  {({ variables, loading, data, error }) => {
                    if (loading) return null;
                    if (error) return `Error!: ${error}`;
                    const reducedElements = [];
                    data[slug].edges.map((element, id) => {
                      if (
                        typeof elements !== 'undefined' &&
                        !elements.find(x => x.id === element.id) &&
                        (typeof added !== 'undefined' && !added.find(x => x.id === element.id))
                      ) {
                        reducedElements.push(element.node);
                      }
                      return null;
                    });

                    if (reducedElements.length === 0) {
                      return (
                        <Alert color="warning" className="mb-0">
                          <div className="alert--with-icon">
                            <i className="alert-icon fal fa-info-circle" />
                            <p className="mb-0">
                              No <em>{label}</em> results found for search term of <strong>{filter}</strong>
                              ...
                            </p>
                          </div>
                        </Alert>
                      );
                    }

                    if (reducedElements.length > 0 && hasSearchValue) {
                      return reducedElements.map((element, id) => {
                        return (
                          <AssociationAddable
                            {...this.props}
                            key={element.id}
                            action={this.props.callbacks.associate}
                            association={element}
                          >
                            {element.name}
                          </AssociationAddable>
                        );
                      });
                    }
                    return null;
                  }}
                </Query>
              </div>
            </CardFooter>
          )}
        </Card>
      );
    } else {
      return null;
    }
  }
}

Search.defaultProps = {
  visible: true,
  gqlFirstVar: 100
};
