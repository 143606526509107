// Added following eslint-disable so we can group things logically in this file.
/* eslint-disable import/first */
// Import primary FA logic.
import fontawesome from '../../fonts/fa/fontawesome/index';

// Add 'hamburger' icon.
import faBars from '../../fonts/fa/solid/faBars';
fontawesome.library.add(faBars);

// Add close (x) icon.
import faTimes from '../../fonts/fa/solid/faTimes';
fontawesome.library.add(faTimes);

// Add search icon (light).
import faSearch from '../../fonts/fa/light/faSearch';
fontawesome.library.add(faSearch);

// Add search icon (solid).
import faSolidSearch from '../../fonts/fa/solid/faSearch';
fontawesome.library.add(faSolidSearch);

// Add Angle Up icon (solid).
import faAngleDoubleUp from '../../fonts/fa/solid/faAngleDoubleUp';
fontawesome.library.add(faAngleDoubleUp);

// Add plus icon.
import faPlus from '../../fonts/fa/solid/faPlus';
fontawesome.library.add(faPlus);

// Add edit icon.
import faEdit from '../../fonts/fa/solid/faEdit';
fontawesome.library.add(faEdit);

// Add filter icon.
import faFilter from '../../fonts/fa/solid/faFilter';
fontawesome.library.add(faFilter);

// Add spinner icon.
import faSpinner from '../../fonts/fa/light/faSpinner';
fontawesome.library.add(faSpinner);

// Add info icon (light).
import faInfo from '../../fonts/fa/light/faInfoCircle';
fontawesome.library.add(faInfo);

// Add check icon (light).
import faCheck from '../../fonts/fa/light/faCheckCircle';
fontawesome.library.add(faCheck);

// Add times icon (light).
import faTimesCircle from '../../fonts/fa/light/faTimesCircle';
fontawesome.library.add(faTimesCircle);

// Add save icon (light).
import faSave from '../../fonts/fa/light/faSave';
fontawesome.library.add(faSave);

// Add trash icon (light).
import faTrash from '../../fonts/fa/light/faTrashAlt';
fontawesome.library.add(faTrash);

// Add caret up icon (light).
import faCaretUp from '../../fonts/fa/light/faCaretUp';
fontawesome.library.add(faCaretUp);

// Add caret down icon (light).
import faCaretDown from '../../fonts/fa/light/faCaretDown';
fontawesome.library.add(faCaretDown);

// Add caret left icon (light).
import faCaretLeft from '../../fonts/fa/light/faCaretLeft';
fontawesome.library.add(faCaretLeft);

// Add caret right icon (light).
import faCaretRight from '../../fonts/fa/light/faCaretRight';
fontawesome.library.add(faCaretRight);

// Add user icon (light).
import faUser from '../../fonts/fa/light/faUser';
fontawesome.library.add(faUser);

// Add image icon (light).
import faImage from '../../fonts/fa/light/faImage';
fontawesome.library.add(faImage);

// Add mars icon (light).
import faMars from '../../fonts/fa/light/faMars';
fontawesome.library.add(faMars);

// Add mars icon (light).
import faVenus from '../../fonts/fa/light/faVenus';
fontawesome.library.add(faVenus);
