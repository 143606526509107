/* @flow*/
import { client } from 'functions/Connect';
import type { ProviderAssociationPackage, ProviderData, ProviderOverrideData } from 'provider/types';

export const fetchProviderData = (
  id: string,
  query: document,
  queryName: string
): ProviderData | ProviderOverrideData => {
  return client
    .query({
      query: query,
      // Ensure we ALWAYS reach out immediately and don't rely on cache for this query.
      fetchPolicy: 'no-cache',
      variables: {
        id: id
      }
    })
    .then(response => {
      const data = response.data[queryName];
      if (data !== null && data.id) {
      }
      return data;
    })
    .catch(error => console.log(error));
};

export const fetchAwardsData = (
  id: string,
  query: document,
  queryName: string
): ProviderData | ProviderOverrideData => {
  return client
    .query({
      query: query,
      // Ensure we ALWAYS reach out immediately and don't rely on cache for this query.
      fetchPolicy: 'no-cache',
      variables: {
        id: id
      }
    })
    .then(response => {
      const data = response.data['awards'];
      if (data !== null && data.id) {
      }
      return data;
    })
    .catch(error => console.log(error));
};

export const sortElementObjects = (a: ProviderAssociationPackage, b: ProviderAssociationPackage) => {
  if (a.name < b.name) return -1;
  if (a.name > b.name) return 1;
  return 0;
};
