import React, { Component } from 'react';
import { Mutation } from 'react-apollo';

class ToggleButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isToggled: this.props.isOpen,
    };
  }
  
  render() {
    const handleToggle = (stateVal) => {
      this.setState((prevState) => ({
        isToggled: !prevState.isToggled
      }));
      if(this.props.callbackToggle){
        this.props.callbackToggle(this.props.id);
      }
    };
    const inputVars = {...this.props.mutationInput, status:!this.state.isToggled};
    const submitMutation = (mutation)=>{
      mutation();
    }

    return (
        <Mutation
        mutation={this.props.mutationName}
        variables={{
          input: inputVars
        }}
      >
        {(toggleMutation, { loading, error }) => {
          if (loading) {
            return null;
          }
          if (error) {
            console.log(error);
          }
          return(
            <>
                <div className="sliding-toggle">
                <label className="switch mr-8">
                <input
                    type="checkbox"
                    checked={this.state.isToggled}
                    onChange={()=>handleToggle()}
                    disabled={this.props.disabled}
                    onClick={()=> submitMutation(toggleMutation)}
                    value={true}
                />
                <span className="slider round"></span>
                </label>
                <span className='small ml-2 pb-8'>
                <strong>{this.props.label}
               </strong>
               </span>
            </div>
           
                </>
          );  
        }}
      </Mutation>
    );
  }
}

export default ToggleButton;
