/* @flow */
import * as React from 'react';
import { Link } from 'react-router-dom';
import ContentSection from 'elements/ContentSection';
import { Alert, Card, CardGroup, CardTitle, CardText, CardBody } from 'reactstrap';
import type { SectionDataType, SectionProps } from 'shared/types';
import { doUsernamesMatch, getAuthenticatedUserId, isAuthenticated,getPermissions, isUserAdmin,isUserManager,isPracticeEmails } from 'functions/AuthFunctions';

class Dashboard extends React.Component<SectionProps> {
  render() {
    const SectionContent = () => {
 
      
      return (
        <div className="dashboard">
          <Alert color="warning">
            <div className="alert--with-icon">
              <i className="alert-icon fal fa-info-circle" />
              <p className="mb-0">
                Please note this Dashboard is just an entry point to the various sections of the{' '}
                <strong>Data Management Layer</strong>. Most of the current sections listed are only placeholders until
                actual functionality is developed in future work.
              </p>
            </div>
          </Alert>
          <CardGroup>
          {(getPermissions('terms')== true)?
            <Card>
              <CardBody className="d-flex flex-column justify-content-between">
                <div>
                  <CardTitle className="d-flex justify-content-between">Clinical Focus Terms</CardTitle>
                  <CardText>
                    <strong>Clinical Focus Term (CFT)</strong>: A condition or treatment which can be associated with{' '}
                    <em>Specialties</em> and <em>Physicians</em> and is optionally (and preferably) mapped to a{' '}
                    <em>UMLS term</em>.
                  </CardText>
                  <CardText>
                    Manage <strong>Clinical Focus Terms</strong> in this section. Search for, edit, delete and create
                    new CFTs.
                  </CardText>
                </div>
                <Link to="/cft" className="mt-4 btn btn-large btn-primary">
                  Clinical Focus Terms
                </Link>
              </CardBody>
            </Card>:
            ''
           }
           {(getPermissions('synonyms')== true)?
            <Card>
              <CardBody className="d-flex flex-column justify-content-between">
                <div>
                  <CardTitle className="d-flex justify-content-between">Synonyms</CardTitle>
                  <CardText>
                    <strong>Synonyms</strong>: A condition or treatment which can be associated with{' '}
                     <em>Clinical Focus Terms</em> and is optionally (and preferably) mapped to a{' '}
                    <em>UMLS term</em>.
                  </CardText>
                  <CardText>
                    Manage <strong>Synonyms</strong> in this section. Search for, edit, delete and create
                    new Synonyms.
                  </CardText>
                </div>
                <Link to="/synonyms" className="mt-4 btn btn-large btn-primary">
                  Synonyms
                </Link>
              </CardBody>
            </Card>:
            ''
            }
            {(getPermissions('groups')== true)?
            <Card>
              <CardBody className="d-flex flex-column justify-content-between">
                <div>
                  <CardTitle className="d-flex justify-content-between">Custom Groups</CardTitle>
                  <CardText>
                    <strong>Custom Groups</strong> allow <em>Physicians</em> to be associated with a Group. These groups
                    can be used to supply a related list of <em>Physicians</em> to various downstream locations.
                  </CardText>
                  <CardText>
                    Manage <strong>Custom Groups</strong> in this section. Search for, edit, delete and create new
                    Groups.
                  </CardText>
                </div>
                <Link to="/group" className="mt-4 btn btn-large btn-primary">
                  Custom Groups
                </Link>
              </CardBody>
            </Card>:
            ''
            } 
          </CardGroup>

          <CardGroup>
           {(getPermissions('specialties')== true)?
            <Card>
              <CardBody className="d-flex flex-column justify-content-between">
                <div>
                  <CardTitle className="d-flex justify-content-between">Specialties</CardTitle>
                  <CardText>Manage Specialties and their association with Clinical Focus Terms.</CardText>
                </div>
                <Link to="/specialty" className="mt-4 btn btn-large btn-primary">
                  Specialties
                </Link>
              </CardBody>
            </Card>:
            ''
            }
            {(getPermissions('top_specialties')== true)?
            <Card>
              <CardBody className="d-flex flex-column justify-content-between">
                <div>
                  <CardTitle className="d-flex justify-content-between">Most Searched Specialties</CardTitle>
                  <CardText>Manage Most Searched Specialties List.</CardText>
                </div>
                <Link to="/top_specialties" className="mt-4 btn btn-large btn-primary">
                  Most Searched Specialties
                </Link>
              </CardBody>
            </Card>:
            ''
            }
            {(getPermissions('provider_override')== true)?
            <Card>
              <CardBody className="d-flex flex-column justify-content-between">
                <div>
                  <CardTitle className="d-flex justify-content-between">Physician Overrides</CardTitle>
                  <CardText>Manage overrides of field data on Physician profiles.</CardText>
                </div>
                <Link to="/providers" className="mt-4 btn btn-large btn-primary">
                  Physician Overrides
                </Link>
              </CardBody>
            </Card>:
            ''
            }
            {(getPermissions('emails')== true)?
            <Card>
              <CardBody className="d-flex flex-column justify-content-between">
                <div>
                  <CardTitle className="d-flex justify-content-between">Practice Appointment Emails</CardTitle>
                  <CardText>Manage Emails For Doctor Offices.</CardText>
                </div>
               
                <Link to="/email" className="mt-4 btn btn-large btn-primary">
                  Practice Appointment Emails
                </Link>
               
             
              </CardBody>
            </Card>:
            ''
           }
          </CardGroup>

          <CardGroup>
          {(getPermissions('users')== true)?
            <Card>
              <CardBody className="d-flex flex-column justify-content-between">
                <div>
                  <CardTitle>User Management</CardTitle>
                  <CardText>
                    Manage access and permissions for users to the <strong>Data Management Layer</strong>.
                  </CardText>
                </div>
                <Link to="/user" className="mt-4 btn btn-large btn-primary">
                  User Management
                </Link>
              </CardBody>
            </Card>:
            ''
            }
            {(getPermissions('logs')== true)?
            <Card>
              <CardBody className="d-flex flex-column justify-content-between">
                <div>
                  <CardTitle>Audit Logs</CardTitle>
                  <CardText>History of edits and transactions.</CardText>
                </div>
               
                <Link to="/audit" className="mt-4 btn btn-large btn-primary">
                Audit Logs
                </Link>
            
              </CardBody>
            </Card>:
            ''
            }

            <Card>
              <CardBody className="d-flex flex-column justify-content-between">
                <div>
                  <CardTitle>Documentation</CardTitle>
                  <CardText>
                    Documentation and Frequently Asked Questions related to the <strong>Data Management Layer</strong>.
                  </CardText>
                </div>
                <Link to="/audit" className="disabled mt-4 btn btn-large btn-outline-primary">
                  Documentation
                </Link>
              </CardBody>
            </Card>
          </CardGroup>
        </div>
      );
                
    };

    const sectionData: SectionDataType = {
      heading: 'Dashboard',
      content: <SectionContent />,
      actions: false,
      modals: false
    };

    return <ContentSection data={sectionData} history={this.props.history} />;
  }
}

export default Dashboard;
