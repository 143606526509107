/* @flow */

import React from 'react';
import { Icon } from './';

const CircleCheckmark = props => {
  return <Icon icon="fa-check-circle" {...props} />;
};

CircleCheckmark.defaultProps = {
  color: 'text-success'
};

export default CircleCheckmark;
