/* @flow */
import * as React from 'react';
import type { ContentSectionProps, ContentSectionState } from 'shared/types';

class ContentSection extends React.Component<ContentSectionProps, ContentSectionState> {
  render(): React.Node {
    const Heading = () => {
      return this.props.data.heading;
    };

    const Content = () => {
      return this.props.data.content;
    };

    const Actions = () => {
      return this.props.data.actions;
    };

    const Modals = () => {
      return this.props.data.modals;
    };
    
    return (
      <React.Fragment>
        <div className="dml-content--wrapper" id="content-wrapper">
          <div className="dml-content">
            <div className="section-header">
              <h2>
                <Heading />
              </h2>
              <Actions />
            </div>
            <div className="dml-content--inner">
              <div id="system-alert-wrapper" />
              <Content />
            </div>
          </div>
        </div>
        <div id="dml-modal" className="dml-modal--wrapper">
          <Modals />
        </div>
      </React.Fragment>
    );
  }
}

export default ContentSection;
