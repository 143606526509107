/* @flow */
import * as React from 'react';
import ReactDOM from 'react-dom';
import AppContent from './js/AppContent';

// Ensure the document AND element we want to render to are ready.
const dmlApplication: HTMLElement | null = document && document.getElementById('dml-root');
// If we can render our content, proceed.
if (dmlApplication) {
  ReactDOM.render(<AppContent />, dmlApplication);
}
