/* @flow */
/* eslint-disable no-unused-vars */
import * as React from 'react';
import ContentSection from 'elements/ContentSection';
import { Query } from 'react-apollo';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd"
import { TableContainer, Table, TableBody, TableCell, TableHead, TableRow, Paper } from '@material-ui/core'
// import { EditSpecialtyModal } from './SpecialtyForms';
import TableColumnSort from 'layout/TableColumnSort';
import { Link, Route } from 'react-router-dom';
import ReactPaginate from "react-paginate";
import { client } from 'functions/Connect';
import type {
  SpecialtyContentProps,
  SpecialtyContentState,
  SpecialtyFeedProps,
  SpecialtyQueryProps,
  SpecialtyTableHeaderRowProps,
  SpecialtyTableRowProps
} from 'specialty/types';
import type {
  connectionQueryType,
  SectionDataType,
  SectionListProps,
  SectionListState,
  SharedSectionCallbacks
} from 'shared/types';
import { Alert } from 'reactstrap';
import SectionSearchField from 'elements/SectionSearchField';
import SectionTable from 'elements/SectionTable';
import SectionLoadMoreButton from 'elements/SectionLoadMoreButton';
import SectionError from 'elements/SectionError';
import { topspecialtyConnectionQuery,UpdateTopSpecialty } from 'topspecialties/queries';
import { animateScroll } from 'functions/Functions';
import { EditSpecialtyModal } from './TopSpecialtyForms';
import {createBrowserHistory} from "history";
import qs from "qs";
import SectionActionButtons from 'elements/SectionActionButtons';
import { NewTopSpecialtyModal, DeleteTopSpecialtyModal,SaveTopSpecialtyModal  } from  'topspecialties/modals';
import { SystemAlert } from 'elements/SystemAlert';
const history = createBrowserHistory();
var data_top_specialties = {};
const getItems = count =>
Array.from({ length: count }, (v, k) => k).map(k => ({
    id: `item-${k}`,
    primary: `item ${k}`,
    secondary: k % 2 === 0 ? `Whatever for ${k}` : `hello for ${k}`
}))

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
const result = Array.from(list)
const [removed] = result.splice(startIndex, 1)
result.splice(endIndex, 0, removed)

return result
}
class SpecialtySection extends React.Component<SectionListProps, SectionListState> {
  waitInterval: number;
  enterKey: number;
  timer: any;
  callbacks: SharedSectionCallbacks;

  constructor(props: SectionListProps) {
    super(props);
    this.state = {
      filter: '',
      afterCurser: '',
      order: {
        sortField: 'NAME',
        sortDirection: 'ASC'
      },
      limit: 25,
      offset: 0,
      pageNumber: 0,
      items: false,
      saved: false

    };

    
    

    this.handleChange = this.handleChange.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.triggerChange = this.triggerChange.bind(this);
    this.reFocus = this.reFocus.bind(this);
    this.clearFilter = this.clearFilter.bind(this);
    this.updateSort = this.updateSort.bind(this);
    this.handlePage = this.handlePage.bind(this);
    this.onDragEnd = this.onDragEnd.bind(this)
    this.setItems = this.setItems.bind(this);
    this.saveOrder = this.saveOrder.bind(this);


    this.waitInterval = 500;
    this.enterKey = 13;

    /**
     * The following object of references allows us to pass our logic to the various
     * lower components that require some of the state data from this top level.
     */
    this.callbacks = {
      handleChange: this.handleChange,
      handleKeyDown: this.handleKeyDown,
      clearFilter: this.clearFilter,
      updateSort: this.updateSort,
      updateFirst: this.updateFirst,
      onDragEnd: this.onDragEnd,
      setItems: this.setItems
      
    };

    this.pagehandler = {
      handlePage: this.handlePage
    };
  }

  UNSAFE_componentWillMount() {
    this.timer = null;
  }

  componentDidMount(){
    const filterParams = history.location.search.substr(1);
    const filtersFromParams = qs.parse(filterParams);
    if(filtersFromParams.search){
     
      this.setState(() => ({
        filter: filtersFromParams.search
      }));
    }
    if(filtersFromParams.page){
     
      this.setState(() => ({
        pageNumber: filtersFromParams.page
      }));
    }
    if(filtersFromParams.curser){
     
      this.setState(() => ({
        afterCurser: filtersFromParams.curser
      }));
    }
  }

  reFocus = (target: HTMLInputElement) => {
    //console.log(target);
    const elemId = target.getAttribute('id');
    const element = document && elemId && document.getElementById(elemId);
    //console.log(element instanceof HTMLInputElement);
    if (typeof element !== 'undefined' && element !== null && element instanceof HTMLInputElement) {
      element.focus();
    }
  };

  updateSort = (field: string, direction: string) => {
    const sortField = field ? field : this.state.order.sortField;
    const sortDirection = direction ? direction : this.state.order.sortDirection;

    this.setState(() => ({
      order: {
        sortField: sortField,
        sortDirection: sortDirection
      }
    }));
  };

  clearFilter = () => {
    this.setState(() => ({
      filter: ''
    }));

    history.replace(``);
  };

  
  saveOrder = async () => {
    let array = [];
    {this.state.items.map((item, index) => (
       
       array.push(item.id)
    ))}
   
    const status: boolean = await client
    .mutate({
    
      mutation: UpdateTopSpecialty,
      // Ensure we ALWAYS reach out immediately and don't rely on cache for this query.
      fetchPolicy: 'no-cache',
      variables: {
        input: {
          order: JSON.stringify(array),
          

     
        }
      }
    })
    .then(response => {
       
      const data: {
        success: boolean
      } = response.data.updateTopSpecialty;
      return data !== null && data.success;
    })
    .catch(error => console.log(error));

    this.setState(() => ({
      saved: true
    }));
  };

  updateFirst = (n: number) => {
    this.setState(() => ({
      limit: n
    }));
  };

  
  onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
        return
    }

   // console.log(`dragEnd ${result.source.index} to  ${result.destination.index}`)
    
    const items = reorder(
        data_top_specialties,
        result.source.index,
        result.destination.index
    )

    data_top_specialties = items;

  

    this.setState({
      items
  })
//console.log(data_top_specialties);
   // return data_top_specialties;
}


  handleChange = ({ target }: { target: HTMLInputElement }) => {
    clearTimeout(this.timer);
    this.timer = setTimeout(
      function doChange() {
        this.triggerChange(target);
      }.bind(this),
      this.waitInterval
    );
  };

  handleKeyDown = (e: KeyboardEvent) => {
    if (e.key === this.enterKey) {
      const target = e.target;
      if (target instanceof HTMLInputElement) {
        const attribute: string | null | void = target.getAttribute('id');
        const elemId: string = attribute && typeof attribute === 'string' ? attribute : '';
        const element: HTMLElement | null = document.getElementById(elemId);
        if (typeof element !== 'undefined' && element !== null && element instanceof HTMLInputElement) {
          this.triggerChange(target);
        }
      }
    }
  };

  triggerChange = (target: HTMLInputElement) => {
    this.setState(() => ({
      filter: target.value ? target.value : ''
    }));
    if (target.value) {
      this.reFocus(target);
    }

    history.push(`?search=${target.value}`);
  };

  
  setItems = (data) => {
    // console.log(this.state.afterCurser);
    
     
     this.setState(() => ({
       items: data,
       
     }));

     
   };

  handlePage = (id,page) => {
    // console.log(this.state.afterCurser);
     
     
     this.setState(() => ({
       afterCurser: id,
       pageNumber:page
     }));

     history.push(`?page=${page}&curser=${id}`);
  
     //window.location = window.location.pathname +"?page="+ page
     //this.props.propcallbacks.handleChange();
   };

  render() {
    const match = this.props.match;
    const sharedSearchData = {
      filter: this.state.filter,
      order: {
        sortField: this.state.order.sortField,
        sortDirection: this.state.order.sortDirection
      },
      limit: this.state.limit,
      offset: this.state.offset
    };

    const ModalRoutes = () => {
      return (
        <React.Fragment>
                  <Route
            exact
            path={`${match && match.path ? match.path : ''}/add`}
            render={(props: SectionListProps) => <NewTopSpecialtyModal {...props} search={sharedSearchData} />}
          />

          <Route
            exact
            path={`${match && match.path ? match.path : ''}/save`}
            render={(props: SectionListProps) => <SaveTopSpecialtyModal {...props} order={this.state.items} search={sharedSearchData} />}
          />


          <Route
            exact
            path={`${match && match.path ? match.path : ''}/delete/:slug`}
            render={(props: SectionListProps) => (
              <DeleteTopSpecialtyModal
                {...props}
                search={sharedSearchData}
                cft={{
                  slug: props.match.params && typeof props.match.params.slug === 'string' ? props.match.params.slug : ''
                }}
                callbacks={this.callbacks}
              />
            )}
          />
  
        </React.Fragment>
      );
    };

    const buttonLinks: Array<ActionButtonLink> = [
      {
        uri: '/top_specialties/add',
        id: 'addTopSpecialty',
        text: 'Add Specialty',
        icon: 'fas fa-plus'
      },

   
    ];

    const buttonLinks2: Array<ActionButtonLink> = [
 

      {
        uri: '/top_specialties/save',
        id: 'save',
        text: 'Save',
        icon: 'fal fa-save',
        onclick: this.saveOrder
      }
    ];
    

    // Pass all the things to content wrapper.
    const sectionData: SectionDataType = {
      heading: 'Most Searched Specialties',
      content: (
        <SpecialtyContent
          callbacks={this.callbacks}
          items = {this.state.items}
          saved = {this.state.saved}
          data={{
            filter: this.state.filter,
            order: {
              sortField: this.state.order.sortField,
              sortDirection: this.state.order.sortDirection
            },
            offset: this.state.afterCurser,
            limit: this.state.limit,
            page: this.pagehandler,
            pageNumber: this.state.pageNumber
          }}
          location={this.props.location}
        />
      ),
      actions: <SectionActionButtons links={buttonLinks} submits={buttonLinks2} />,
      modals: <ModalRoutes />
    };

    return (
      <React.Fragment>
        <ContentSection data={sectionData} history={this.props.history} />
      </React.Fragment>
    );
  }
}

/**
 * Component to handle entire table.
 */
class SpecialtyContent extends React.Component<SpecialtyContentProps, SpecialtyContentState> {
  
  render() {
   
    return (
      <div className="group-landing--wrapper">
       
        <SectionSearchField
          filter={this.props.data.filter}
          callbacks={this.props.callbacks}
          element={{
            id: 'groupList',
            placeholder: 'Search Specialties...'
          }}
        />
          <Alert color="info" className={`mb-3`}>
            <div className="alert--with-icon">
              <i className="alert-icon fal fa-info-circle" />
              <div>
                <span>
                Drag and Drop to reorder. Then Hit Save to make sure changes are saved
                </span>
              </div>
            </div>
          </Alert>
        
        <div className="table-responsive">
          <SpecialtyQuery saved={this.props.saved} items={this.props.items} data={this.props.data} callbacks={this.props.callbacks} location={this.props.location} />
        </div>
      </div>
    );
  }
}

/**
 * Default class to handle returning the formatted list of results.
 */
class SpecialtyQuery extends React.Component<SpecialtyQueryProps> {
  render() {
    // @todo: This may need to have the offset & limit sent back to the parent state.
    // This is the filter sent from the parent form(s).
    const FILTER = this.props.data.filter ? this.props.data.filter : '';
    // Default offset.
    const OFFSET = this.props.data.offset ? this.props.data.offset : 0;
    // Default item to sort on.
    const ORDER = this.props.data.order
      ? {
          sort: this.props.data.order.sortField,
          direction: this.props.data.order.sortDirection
        }
      : {
          sort: 'NAME',
          direction: 'ASC'
        };
    // Default items per page to display.
    const LIMIT = this.props.data.limit ? this.props.data.limit : 25;

    return (
      <Query
        query={topspecialtyConnectionQuery}
        pollInterval={0}
        notifyOnNetworkStatusChange
        variables={{
          filter: FILTER,
          afterCurser: OFFSET,
          first: LIMIT,
          orderBy: ORDER
        }}
        fetchPolicy="cache-and-network"
      >
        {({ variables, loading, data, fetchMore, networkStatus }) => {
          // Skip loading icon/flash when we are polling for new results.
          if (loading && networkStatus !== 6 && networkStatus !== 3) {
            // Handle loading screen.
            const row = {
              classes: 'loading',
              content: <i className="fal fa-spinner" />
            };
            return (
              <SectionTable
                render={() => (
                  <tbody>
                    
                    <tr>
                      <td className={row.classes}>{row.content}</td>
                    </tr>
                  </tbody>
                )}
              />
            );
          }
          let query = null;
          let query2 =null;
          
          if(!this.props.items){
              query  =  data && data.topSpecialties ? data.topSpecialties : [];
              query2 = data && data.topSpecialties ? data.topSpecialties : [];
              data_top_specialties = query;
              
          }

          else{
              query = this.props.items;
              query2 = data && data.topSpecialties ? data.topSpecialties : [];
          }

        
          
          //console.log(this.props.callbacks);
      
          //co  nsole.log(data)
          if (query && query.length === 0 && !loading) {
            // Handle a zero result set.
            const errorContent = (
              <div className="text-center">
                <h4 className="text-warning">No Results Found.</h4>
                <h2 className="text-warning">¯\_(ツ)_/¯</h2>
              </div>
            );
            return <SectionError color="warning" classes="" content={errorContent} />;
          }

          if (!data && !loading) {
            // We've encountered a error connecting to GraphQL server.
            // We should on this account, disable the Create Specialty button.
            // @todo: Should be an easier way to detect an epic failure to connect.
            const button = document.getElementById('addSpecialtyLink');
            if (typeof button !== 'undefined' && button !== null && button instanceof HTMLButtonElement) {
              button.classList.add('disabled');
              button.disabled = true;
            }
            const search = document.getElementById('groupList');
            if (typeof search !== 'undefined' && search !== null && search instanceof HTMLInputElement) {
              search.disabled = true;
            }
            return (
              <Alert color="danger" className={`error--graphql-connection`}>
                <div className={`text-center`}>
                  <h4 className="text-error">GraphQL connection failure.</h4>
                  <h2 className="text-error">¯\_(ツ)_/¯</h2>
                </div>
              </Alert>
            );
          }
             
    
         
          // Pagination issues.
          // @see https://github.com/apollographql/apollo-client/issues/2499
          return (
         
            <React.Fragment>
              <SpecialtyFeed
                items={query}
                specialties={this.props.specialties || []}
                saved = {this.props.saved}
                callbacks={this.props.callbacks}
                // pages={query2.aggregate}
                data={{
                  total: query && query.length >= 1 ? query.length : 0,
                  filter: FILTER,
                  order: {
                    sortField: ORDER.sort,
                    sortDirection: ORDER.direction
                  },
                  offset: OFFSET,
                  limit: LIMIT,
                  page:this.props.data.page,
                  pageNumber:this.props.data.pageNumber
                }}
              />
              <Alert color="info" className={`my-0 ${query.length >= 100 ? 'd-block' : 'd-none'}`}>
                <div className="alert--with-icon">
                  <i className="alert-icon fal fa-info-circle" />
                  <div>
                    <span>
                      Not all specialties can be listed at once. Only the first 100 results are shown. Please use the
                      search form to narrow down the results.
                    </span>
                  </div>
                </div>
              </Alert>
            </React.Fragment>
          );
        }}
      </Query>
    );
  }
}

class SpecialtyFeed extends React.Component<SpecialtyFeedProps> {
  
  handlePageClick = (data) => {
    //console.log(data.selected+1)
    
    // let page = data.selected;
   
  
    // let last = 25 * page;
    // let total = last - 1;
 
   // console.log(page);
   // currentUrlParams.set("page", JSON.stringify(data.slected+1));
   
   
 
  
    
   // this.props.data.page.handlePage(total,page);
    //window.location = window.location.pathname +"?page="+ currentPage
  };
  
  render() {
    //this.props.callbacks.setItems(this.props.items)
 
    const data = this.props.data;
    if (this.props.items && this.props.items.length) {
     
      // Friendly method to display the text "Showing X-Y of Z total results.
      // const fromItems = 1;
      // const toItems = this.props.specialties.length;
      const filterData = {
        filter: this.props.data.filter,
        order: this.props.data.order,
        limit: this.props.data.limit,
        offset: this.props.data.offset
      };

    
      

      

      // Compile all the table rows together.
      // const FeedRows = () => {
      //   console.log(data_top_specialties)
      //   return data_top_specialties.map((group, id) => (
          
      //     <SpecialtyTableRow row={group.node} key={id} search={filterData} />
      //   ));
      // };

      const getItemStyle = (isDragging, draggableStyle) => ({
        // styles we need to apply on draggables
        ...draggableStyle,
    
        ...(isDragging && {
            background: "rgb(235,235,235)"
        })
    })
    
        const DraggableComponent = (id, index) => (props) => {
       
          return (
              <Draggable draggableId={id} index={index}>
                  {(provided, snapshot) => (
                       <TableRow
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
      
                          {...props}
                      >
                          {props.children}
                      </TableRow>
                  )}
              </Draggable>
          )
      }
      const DroppableComponent = (
        
        onDragEnd: (result, provided) => void) => (props) =>
    {
      
      
        return (
       
          
            <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId={'1'} direction="vertical">
                    {(provided) => {
                   
                        return (
                            <TableBody ref={provided.innerRef} {...provided.droppableProps} {...props}>
                                {props.children}
                                {provided.placeholder}
                            </TableBody>
                        )
                    }}
                </Droppable>
            </DragDropContext>
        )
    }



    

      // Primary return without errors or oddities.
      return (
        
        <React.Fragment>
         
          {/*<h4>*/}
          {/*Showing{' '}*/}
          {/*<strong>*/}
          {/*{fromItems} - {toItems}*/}
          {/*</strong>{' '}*/}
          {/*of <strong>{data.total}</strong> total results.*/}
          {/*</h4>*/}
          {/* <SectionTable */}
          
          <SystemAlert
              autoClose
              className = {`${this.props.saved ? 'd-block' : 'd-none'}`}
              color="success"
              icon="alert-icon fal fa-check-circle"
              messageData={{
                action: 'Most Saved Specialty Order Has Been Saved',
                tail: '...'
              }}/>
               <TableContainer component={Paper}>
                <Table>
                 <TableHead className="thead-light">
                  <SpecialtyTableHeaderRow order={this.props.data.order} callbacks={this.props.callbacks} />
                  </TableHead>
                  <TableBody className="result-set" component={DroppableComponent(this.props.callbacks.onDragEnd)}>
                  {this.props.items.map((item, index) => (
                  
                  <TableRow  className={`result-set--row`} component={DraggableComponent(item.id, index)} key={item.id}>
        
         
                   <TableCell className="d-none d-xl-table-cell">{item.specialty[0].name}</TableCell>
                   <TableCell className="actions">
                    <Link to={`/top_specialties/delete/${item.specialty[0].id}`} title={`Delete ${item.specialty[0].name}`} className="btn btn-delete">
                    <i className="fas fa-times" />
                    </Link>
                    </TableCell>
          
       
                  {/* <TableCell className="d-none d-xl-table-cell">{item.node.order}</TableCell> */}
        {/* <td className="actions">
        {specialty.order}
        </td> */}
                 </TableRow>
                 ))}
                  </TableBody>
                  </Table>
                  </TableContainer>
              
          
          {/* /> */}
  
        </React.Fragment>
      );
    }

    // Last case error that something went terribly wrong.
    const errorContent = (
      <div>
        <h4 className="text-danger">Unknown error occurred.</h4>
        <h2 className="text-danger">¯\_(ツ)_/¯</h2>
      </div>
    );
    return <SectionError color="danger" classes="" content={errorContent} />;
  }
}

/**
 * Component to handle single table row of results.
 */

class SpecialtyTableRow extends React.Component<SpecialtyTableRowProps> {
  render() {
  let specialty = this.props.row;



    


    return (
      ""
      // // <Draggable type="foo" data="bar">
      // <TableRow id={specialty.id} className={`result-set--row`} component={DraggableComponent(specialty.id, specialty.order-1)}>
        
         
      //   <TableCell className="d-none d-xl-table-cell">{specialty.specialty[0].name}</TableCell>
          
       
      //   <TableCell className="d-none d-xl-table-cell">{specialty.order}</TableCell>
      //   {/* <td className="actions">
      //   {specialty.order}
      //   </td> */}
      // </TableRow>
      // </Draggable>
    );
  }
}

/**
 * Component to handle table header for CFT results.
 */
class SpecialtyTableHeaderRow extends React.Component<SpecialtyTableHeaderRowProps> {
  render() {
    const name_sort = {
      sortField: 'NAME',
      default: 'ASC'
    };

    return (
      <TableRow>
        
          
            <TableCell className="col-label">Name</TableCell>
            {/* <TableColumnSort callbacks={this.props.callbacks} order={this.props.order} sort={name_sort} /> */}
         
   
       
          
            {/* <TableCell className="col-label">Order</TableCell> */}
        
    
        <TableCell className="actions" scope="col">
          <span className="col-label">Actions</span>
        </TableCell>
      </TableRow>
    );
  }
}

/**
 * Return the SpecialtySection component as the default.
 */
export default SpecialtySection;
