/* @flow */

import React from 'react';
import { Icon } from './';

const Close = props => {
  return <Icon fontType="fas" icon="fa-times" {...props} />;
};

export default Close;
