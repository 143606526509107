/* @flow */
/* eslint-disable no-unused-vars */
import * as React from 'react';

import ModalSection, { modalClose, modalHide } from 'elements/ModalSection';
import { CftEditForm } from 'clinicalFocusTerm';
import type { EditCftProps } from 'clinicalFocusTerm/types';

export class EditCftModal extends React.Component<EditCftProps> {
  componentDidMount() {
    setTimeout(() => {
      ModalSection.openModal();
    }, 100);
  }

  render() {
    const { cft, history, match, search } = this.props;
    return (
      <ModalSection
        heading={`Edit CFT`}
        content={
          <React.Fragment>
            <CftEditForm history={history} match={match} cft={cft} search={search} />
          </React.Fragment>
        }
        actions={false}
        history={this.props.history}
      />
    );
  }
}
