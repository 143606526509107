/* @flow */
/* eslint-disable no-unused-vars */
import * as React from 'react';
import {
  Alert,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  FormText,
  Badge,
  CardFooter,
  Card,
  CardHeader,
  CardBody,
  UncontrolledAlert
} from 'reactstrap';

import {
  overrideProviderInfoQuery,
  providerInfoQuery,
  rawProviderInfoQuery,
  updateProviderOverrides
} from 'provider/queries';

import type { EditProviderProps, EditProvidersState, ProviderData } from 'provider/types';
import { isRole } from 'functions/AuthFunctions';
import { fetchProviderData } from './ProviderFunctions';
import { TextFieldWithOverride } from 'elements/TextFieldWithOverride';
import missingImage from '../../images/missingProvider.svg';
import { client } from 'functions/Connect';

export class ProviderDataForm extends React.Component<EditProviderProps, EditProvidersState> {
  waitInterval: number;
  timer: any;

  constructor(props: EditProviderProps) {
    super(props);
    this.state = {
      formChanged: false,
      formSubmitted: false,
      prevSearch: {
        filter: this.props.search && this.props.search.filter ? this.props.search.filter : '',
        after: this.props.search && this.props.search.after ? this.props.search.after : '',
        first: this.props.search && this.props.search.first ? this.props.search.first : 25,
        limit: this.props.search && this.props.search.limit ? this.props.search.limit : 25,
        order:
          this.props.search && this.props.search.order
            ? this.props.search.order
            : {
                sortField: '',
                sortDirection: ''
              }
      },
      overrideText: {},
      overrideCount:1,
      imageSource: false,
      videoSource: false,
      provider: false,
      fieldMatches: {},
      providerRaw: {},
      providerOverrides: {},
      providerMerged: {},
      payload: {},
      submitReady: false,
      saved: false,
      providerSpecialties: []
    };

    this.waitInterval = 1500; // 1.5 second wait.
    this.timer = null;

    this.handleFormChange = this.handleFormChange.bind(this);
    this.handleFormSaved = this.handleFormSaved.bind(this);
    this.setSubmitReady = this.setSubmitReady.bind(this);
  }

  handleFormChange = () => {
    this.setState(() => ({
      formChanged: true,
      saved: false
    }));
  };

  handleFormSaved = () => {
    this.setState(() => ({
      formChanged: false
    }));
  };

  setSubmitReady = (form: boolean, log: boolean) => {
    this.setState(() => ({
      submitReady: form && log,
      formChanged: form
    }));
  };

  getProviderRawData = async (id: string) => {
    const data = await fetchProviderData(id, rawProviderInfoQuery, 'providerRaw');
    if (data && typeof data.id === 'string') {
      return data;
    }
    return false;
  };

 

  getProviderMergedData = async (id: string) => {
    const data = await fetchProviderData(id, providerInfoQuery, 'provider');
    if (data) {
      return data;
    }
    return false;
  };

  getProviderOverrideData = async (id: string) => {
    const data = await fetchProviderData(id, overrideProviderInfoQuery, 'providerOverrides');
    if (data) {
      return data;
    }
  };

  processProviderData = async () => {
    
   
    const merged = await this.getProviderMergedData(this.props.provider.id);
    const raw = await this.getProviderRawData(this.props.provider.id);
    const overrides = await this.getProviderOverrideData(this.props.provider.id);
    let overrideCount = 0;
    for(const key in overrides){
      if(overrides[key] != null && overrides[key].length != 0 && key != 'id' && overrides[key] != 'ProviderOverrides'){
        overrideCount++;
      }
      
    }
    if(overrideCount < 1){
    this.setState({
      overrideCount: overrideCount,
    });
  }

  
    
    
    // console.log(`Running processProvderData...`);
    // console.log({
    //   m: merged,
    //   r: raw,
    //   o: overrides,
    // });
    // console.log(`----------------`);

    if (merged && typeof merged === 'object' && raw && typeof raw === 'object') {
     
      let matches = {};
      for (const prop in merged) {
        if (prop !== '__typename') {
          if (prop === 'bioNew' && merged[prop].length > 0) {
          
            matches = Object.assign({}, matches, {
              [prop]:
              typeof raw['bioOriginal'][0] !== 'undefined' && merged[prop][0].bio === raw['bioOriginal'][0].bio ||
                (typeof merged[prop] === 'undefined' && typeof raw[prop] === 'undefined')
            });
          } else if (prop === 'bioOriginal' && merged[prop].length > 0) {
         
            matches = Object.assign({}, matches, {
              [prop]:
                merged[prop][0].bio === raw[prop][0].bio ||
                (typeof merged[prop] === 'undefined' && typeof raw[prop] === 'undefined')
            });
          } else if (prop === 'bioOriginal' && merged[prop].length === 0) {
           
            matches = Object.assign({}, matches, {
              [prop]:
                merged[prop][0] === raw[prop][0] ||
                (typeof merged[prop] === 'undefined' && typeof raw[prop] === 'undefined')
            });
          } else if (prop === 'bioNew' && merged[prop].length === 0) {
        
            matches = Object.assign({}, matches, {
              [prop]:
                merged[prop][0] === raw[prop][0] ||
                (typeof merged[prop] === 'undefined' && typeof raw[prop] === 'undefined')
            });
          } else if (prop === 'imageOriginal' && merged[prop].length > 0) {
            matches = Object.assign({}, matches, {
              [prop]:
                merged[prop][0].url === raw[prop][0].url ||
                (typeof merged[prop] === 'undefined' && typeof raw[prop] === 'undefined')
            });
          } else if (prop === 'imageOriginal' && merged[prop].length === 0) {
            matches = Object.assign({}, matches, {
              [prop]:
                merged[prop][0] === raw[prop][0] ||
                (typeof merged[prop] === 'undefined' && typeof raw[prop] === 'undefined')
            });
          } else if (prop === 'imageNew' && merged[prop].length > 0) {
            matches = Object.assign({}, matches, {
              [prop]:
              typeof raw['imageOriginal'][0] !== 'undefined'&& merged[prop][0].url === raw['imageOriginal'][0].url||
                (typeof merged[prop] === 'undefined' && typeof raw[prop] === 'undefined')
            });
          } else if (prop === 'imageNew' && merged[prop].length === 0) {
            matches = Object.assign({}, matches, {
              [prop]:
                merged[prop][0] === raw[prop][0] ||
                (typeof merged[prop] === 'undefined' && typeof raw[prop] === 'undefined')
            });
          } else if (prop === 'video' && merged[prop].length > 0) {
            matches = Object.assign({}, matches, {
              [prop]:
                merged[prop][0].youtubeVideoId === raw[prop][0].youtubeVideoId ||
                (typeof merged[prop] === 'undefined' && typeof raw[prop] === 'undefined')
            });
          } else if (prop === 'video' && merged[prop].length === 0) {
            matches = Object.assign({}, matches, {
              [prop]:
                merged[prop][0] === raw[prop][0] ||
                (typeof merged[prop] === 'undefined' && typeof raw[prop] === 'undefined')
            });
          } else {
            
            matches = Object.assign({}, matches, {
              [prop]:
                merged[prop] === raw[prop] || (typeof merged[prop] === 'undefined' && typeof raw[prop] === 'undefined')
            });
          }
        }
      }


      this.setState({
        fieldMatches: matches,
        providerRaw: raw,
        providerOverrides: overrides,
        providerMerged: merged,
        provider: true,
        submitReady: false
      });
      
      return true;
    }
    return false;
  };

  prepareTextOverrideField = (id: string) => {
    let variable = this.getTextFieldValue(id);
    if (id === 'video') {
      var regExp = /^.*(youtu\.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
      var match = variable.match(regExp);
      if (match && match[2].length == 11) {
        let videoVariable = match[2];
        return this.state.providerRaw[id] === videoVariable ? null : videoVariable;
      } else {
        return null;
        //error
      }
    } else {
      if (id === 'bio') {
        
        if (typeof this.state.providerRaw['bioNew'][0] === 'undefined') {

          id = 'bioOriginal';
          variable = variable === '' ? null : variable;
        } else {
          id = 'bioNew';
          variable = variable === '' ? null : variable;
        }
     
      }
      if (id === 'image') {
        if (typeof this.state.providerRaw['imageNew'][0] === 'undefined') {
          id = 'imageOriginal';
          variable = variable === '' ? null : variable;
        } else {
          id = 'imageNew';
          variable = variable === '' ? null : variable;
        }
      }
   
      // If the raw (original) data for this field matches the current value, we
      // pass null back so that any exiting override is removed, and a new override is not added.
      
      if(this.state.providerRaw[id] == null && variable == ''){
        variable = null;
      }
      return this.state.providerRaw[id] === variable ? null : variable;
    }
  };

  prepareNumberOverrideField = (id: string) => {
    // Here we need to ensure an empty string is returned as a zero.
    // If the original value (providerRaw) was NULL, then the value it translates to is zero.
    // So this ensures we compare apples to apples if an empty string is submitted.
    const variable = this.getTextFieldValue(id) !== '' ? this.getTextFieldValue(id) : null;

    // If the raw (original) data for this field matches the current value, we
    // pass null back so that any exiting override is removed, and a new override is not added.
    return parseInt(this.state.providerRaw[id], 10) === parseInt(variable, 10) ? null : variable;
  };

  prepareRadioOverrideField = (name: string) => {
    const variable = this.getRadioFieldValue(name);
    let raw = null;
    if(name == 'disableClinicalFocusTerms'){
       raw = false;
    }
    else{
       raw = this.state.providerRaw[name];
    }
    let override = null;
    if(typeof this.state.providerOverrides != 'undefined'){
       override = this.state.providerOverrides[name]
    }
  
 
    // If the raw (original) data for this field matches the current value, we
    // pass null back so that any exiting override is removed, and a new override is not added.
    // if(override != null){
     
    //   return override === variable ? null : variable;
    // }
   
      return raw === variable ? null : variable;
    
  };

  prepareVariables = (initial: boolean = false) => {
    
    const payload = {
      // Strings use the ID to get the field.
      firstName: this.prepareTextOverrideField('firstName'),
      middleName: this.prepareTextOverrideField('middleName'),
      lastName: this.prepareTextOverrideField('lastName'),
      suffix:this.prepareTextOverrideField('suffix'),
      image: this.prepareTextOverrideField('image'),
      video: this.prepareTextOverrideField('video'),
      bio: this.prepareTextOverrideField('bio'),
      yearBeganPractice: this.prepareNumberOverrideField('yearBeganPractice'),
      gender: this.prepareRadioOverrideField('gender'),
      isFad: this.prepareRadioOverrideField('isFad'),
      isStaff: this.prepareRadioOverrideField('isStaff'),
      isPcp: this.prepareRadioOverrideField('isPcp'),
      isAcceptingNewPatients: this.prepareRadioOverrideField('isAcceptingNewPatients'),
      isBookable: this.prepareRadioOverrideField('isBookable'),
      isOnlineScheduling: this.prepareRadioOverrideField('isOnlineBookable'),
      disableClinicalFocusTerms:this.prepareRadioOverrideField('disableClinicalFocusTerms'),
      providerSpecialties : this.state.specialties
    };
   
    // if (payload.firstName === null) {
    //   delete payload.firstName;
    // }

    // if (payload.middleName === null || payload.middleName == '') {
    //   delete payload.middleName;
    // }
    // if (payload.lastName === null) {
    //   delete payload.lastName;
    // }

  
//     if (this.state.providerOverrides && (payload.image === null || payload.image.length === 0 )&& this.state.providerOverrides.imageNew.length > 0) {
//           payload.image = null;
//     }
//     else if ((payload.image === null || payload.image.length === 0 )&& typeof this.state.providerOverrides === 'undefined') {
//       payload.image = null;
//     }
//     else if( payload.image === null  ){
      
//       delete payload.image ;
//     }
//     else if(this.state.providerOverrides && this.state.providerOverrides.imageNew.length > 0 && payload.image == this.state.providerOverrides.imageNew[0].url){
//       // console.log('here57');
//           delete payload.image;
//     }
//     else if(typeof this.state.providerRaw.imageNew[0] !== 'undefined' && payload.image == this.state.providerRaw.imageNew[0].url){
//       // console.log('here58');
//       delete payload.image;
// }
if(typeof this.state.providerRaw.imageNew[0] !== "undefined" && this.state.providerRaw.imageOriginal[0].url === this.state.providerRaw.imageNew[0].url ){
  
  payload.image = null;
}
if(typeof this.state.providerRaw.imageNew[0] !== "undefined" && this.state.providerRaw.imageNew.length === 0 && this.state.providerRaw.imageOriginal[0].url === payload.image ){

   payload.image = null;
}
if(typeof this.state.providerRaw.imageOriginal[0] !== "undefined" && this.state.providerRaw.imageOriginal[0].url === payload.image ){

   payload.image = null;
}
  if (this.state.providerOverrides && (payload.video === null || payload.video.length === 0) && this.state.providerRaw.video.length > 0) {
   
       payload.video = null;
  }
  else if ( (payload.video === null || payload.video.length === 0) && this.state.providerRaw.video.length == 0) {
   // console.log('here54');
      delete payload.video;
  }
  else if( this.state.providerRaw.video.length > 0 && payload.video == this.state.providerRaw.video[0].youtubeVideoId){
    delete payload.video;
  }
  if (this.state.providerOverrides && (payload.bio === null || payload.bio.length === 0) && typeof this.state.providerOverrides !== 'undefined') {
   
      payload.bio = null;
  }

  else if (typeof this.state.providerRaw['bioOriginal'][0] !== 'undefined' && this.state.providerRaw['bioOriginal'][0].bio === payload.bio && typeof this.state.providerOverrides === 'undefined' ){
    //console.log('here');
    delete payload.bio
  }

  else if( payload.bio === null  ){
    
    delete payload.bio ;
  }
  else if( (payload.bio === null || payload.bio.length === 0) && typeof this.state.providerOverrides === 'undefined' ){
 
    delete payload.bio ;
  }

  else if(this.state.providerOverrides && this.state.providerOverrides.bioNew.length > 0 && payload.bio == this.state.providerOverrides.bioNew[0].bio ){

    delete payload.bio;
  }
  else if(typeof this.state.providerRaw.bioNew[0] !== 'undefined' && payload.bio == this.state.providerRaw.bioNew[0].bio ){
  
    delete payload.bio;
  }
    // if (this.state.providerOverrides && (payload.bio === null || payload.bio.length === 0) && typeof this.state.providerOverrides !== 'undefined') {
     
    //     payload.bio = null;
    // }

    // else if (typeof this.state.providerRaw['bioOriginal'][0] !== 'undefined' && this.state.providerRaw['bioOriginal'][0].bio === payload.bio && typeof this.state.providerOverrides === 'undefined' ){
    //   console.log('here');
    //   delete payload.bio
    // }

    // else if( payload.bio === null  ){
      
    //   delete payload.bio ;
    // }
    // else if( (payload.bio === null || payload.bio.length === 0) && typeof this.state.providerOverrides === 'undefined' ){
   
    //   delete payload.bio ;
    // }

    // else if(this.state.providerOverrides && this.state.providerOverrides.bioNew.length > 0 && payload.bio == this.state.providerOverrides.bioNew[0].bio ){

    //   delete payload.bio;
    // }
    // else if(typeof this.state.providerRaw.bioNew[0] !== 'undefined' && payload.bio == this.state.providerRaw.bioNew[0].bio ){
    
    //   delete payload.bio;
    // }
    // if (payload.yearBeganPractice === null) {
    //   delete payload.yearBeganPractice;
    // }
    // if (payload.gender === null) {
    //   delete payload.gender;
    // }
    // if (payload.isFad === null) {
    //   delete payload.isFad;
    // }
    // if (payload.isStaff === null) {
    //   delete payload.isStaff;
    // }
    // if (payload.isPcp === null) {
    //   delete payload.isPcp;
    // }
    // if (payload.isAcceptingNewPatients === null) {
    //   delete payload.isAcceptingNewPatients;
    // }
    if (payload.disableClinicalFocusTerms === null && typeof this.state.providerOverrides !== 'undefined') {
         payload.disableClinicalFocusTerms = 0;
    }
    else if(payload.disableClinicalFocusTerms === null && !this.state.providerOverrides === 'undefined'){
      
      delete payload.disableClinicalFocusTerms;
    }

  

    if (initial) {
    
      this.setState({
        payload: payload
      });
    }
    return payload;
  };

  getLogValue = () => {
    return this.getTextFieldValue('log--primaryData');
  };

  resetLogValue = () => {
    const log = this.getField('log--primaryData');

    if (log instanceof HTMLInputElement || log instanceof HTMLTextAreaElement) {
      log.value = '';
    }
  };

  submitPayload = async () => {
    
    const payload = this.prepareVariables();
  
     const status: boolean = await client
      .mutate({
      
        mutation: updateProviderOverrides,
        // Ensure we ALWAYS reach out immediately and don't rely on cache for this query.
        fetchPolicy: 'no-cache',
        variables: {
          input: {
            providerId: this.state.providerMerged.id,
            log: this.getLogValue(),

            update: {
              firstname: payload.firstName,
              middlename: payload.middleName,
              lastname: payload.lastName,
              suffix: payload.suffix,
              gender: payload.gender,
              bio: payload.bio,
              is_fad: payload.isFad,
              is_staff: payload.isStaff,
              is_pcp: payload.isPcp,
              year_began_practice: payload.yearBeganPractice,
              is_accepting_new_patients: payload.isAcceptingNewPatients,
              is_bookable: payload.isBookable,
              is_online_scheduling: payload.isOnlineScheduling,
              disable_clinical_focus_terms:payload.disableClinicalFocusTerms,
              image: payload.image,
              video: payload.video
            }
          }
        }
      })
      .then(response => {
         
        const data: {
          success: boolean
        } = response.data.updateProviderOverrides;
        return data !== null && data.success;
      })
      .catch(error => console.log(error));

      if (status) {
        // Update the state value(s) as required.
        this.setState({
          formChanged: false,
          saved: true
        });
        
  
        // Reset the log field value for the next edit.
        this.resetLogValue();
  
        // Update the stored data.
        this.processProviderData().then(response => {
          this.prepareVariables(true);
        });
      }
      return status;
    
    // else{
    //   const status: boolean = await client
    //   .mutate({
      
    //     mutation: RemoveOverrides,
    //     // Ensure we ALWAYS reach out immediately and don't rely on cache for this query.
    //     fetchPolicy: 'no-cache',
    //     variables: {
          
    //         Id: this.state.providerMerged.id,
    //         log: this.getLogValue(),

          
          
    //     }
    //   })
    //   .then(response => {
    //     // console.log(response);
    //     const data: {
    //       success: boolean
    //     } = response.data.RemoveOverrides;
    //     return data !== null && data.success;
    //   })
    //   .catch(error => console.log(error));

    //   if (status) {
    //     // Update the state value(s) as required.
    //     this.setState({
    //       formChanged: false,
    //       saved: true
    //     });
        
  
    //     // Reset the log field value for the next edit.
    //     this.resetLogValue();
  
    //     // Update the stored data.
    //     this.processProviderData().then(response => {
    //       this.prepareVariables(true);
    //     });
    //   }
    //   return status;
    // }
    // console.log(`Provider Mutation status: ${status.toString()}`);
   

    
  };

  /**
   * Function handles determining if the form is ready to submit.
   */
  readySubmit = () => {
    const variables = this.prepareVariables();
    let submitReady = false;
    let logReady = false;
    
    if(variables.length > 0){
    for (let field in variables) {
     
      // If any individual field doesn't match its counterpart, then we CAN submit the form.
      if (this.state.payload[field] !== variables[field]) {
        
        submitReady = true;
      }
    }
  }
  else{

    submitReady=true;
  }
    

    const log = this.getLogValue();
   

    if (log && typeof log === 'string' && log.length > 20) {
      logReady = true;
    }
    // If the current state of the form is the same as the initial values when the form was loaded.
    if (submitReady && logReady) {
     
      this.setSubmitReady(submitReady, logReady);
    } else {
      this.setSubmitReady(submitReady, logReady);
    }
  };

  getField = (id: string) => {
    const field = document && document.getElementById(id);
    if (
      typeof field !== 'undefined' &&
      field !== null &&
      (field instanceof HTMLInputElement || field instanceof HTMLTextAreaElement)
    ) {
      return field;
    } else {
      console.error(`Field: ${id} not found in getField()...`);
    }
  };

  getRadioFieldValue = (name: string) => {
    const selected = document && document.querySelector(`input[name="${name}"]:checked`);

    if (typeof selected !== 'undefined' && selected instanceof HTMLInputElement) {
      let value = selected.value;

      // Handle some boolean adjustments.
      if (value === 'enabled') {
        return true;
      }
      if (value === 'disabled') {
        return false;
      }
      return value;
    }
    return null;
  };

  getTextFieldValue = (id: string) => {
    
    const field = document && document.getElementById(id);
    
    if (
      typeof field !== 'undefined' &&
      field !== null &&
      (field instanceof HTMLInputElement || field instanceof HTMLTextAreaElement)
    ) {
      return field.value;
    }
  };

  updateImageSource = (e: Event) => {
    // console.log(`Running updateImageSource()...`);
    const target: EventTarget = e.currentTarget;
    if (target && target instanceof HTMLInputElement) {
      clearTimeout(this.timer);

      this.timer = setTimeout(
        function() {
          this.setState(() => ({
            imageSource: target.value
          }));
        }.bind(this),
        this.waitInterval
      );
    }
  };

  updateVideoSource = (e: Event) => {
    //console.log(`Running updateImageSource()...`);
    const target: EventTarget = e.currentTarget;
    if (target && target instanceof HTMLInputElement) {
      clearTimeout(this.timer);

      this.timer = setTimeout(
        function() {
          this.setState(() => ({
            videoSource: target.value
          }));
        }.bind(this),
        this.waitInterval
      );
    }
  };

  editFieldValue = (e: Event) => {
    // Target is the actual target of the action. So the Button rather than the nested icon.
    const target: EventTarget = e.currentTarget;

    const associatedFieldId =
      target && target instanceof HTMLButtonElement ? target.getAttribute('data-target') : false;
    const associatedFieldName =
      target && target instanceof HTMLButtonElement ? target.getAttribute('data-field') : false;

    if (associatedFieldId && associatedFieldName) {
      this.setState({
        overrideText: Object.assign({}, this.state.overrideText, {
          [associatedFieldName]: true
        })
      });
    }
  };

  revertAllFields = () => {
    const fields = {
      strings: [
        // Strings use the ID to get the field.
        'firstName',
        'suffix',
        'middleName',
        'lastName',
        'image',
        'bio',
        'video',
        'yearBeganPractice'
      ],
      radios: ['gender', 'isFad', 'isStaff', 'isPcp', 'isAcceptingNewPatients','isBookable','disableClinicalFocusTerms']
    };

    const stateAdjust = this.state.overrideText;
    const matches = this.state.fieldMatches;

    fields.strings.forEach(id => {
      const field = this.getField(id);
      if (field) {
        delete stateAdjust[id];
        // Update the field.
        field.value = this.state.providerRaw[id] ? this.state.providerRaw[id] : '';
        matches[id] = true;
      }
    });

    fields.radios.forEach(id => {
      let originalValue;
      if (this.state.providerRaw[id] === true) {
        originalValue = 'enabled';
      } else if (this.state.providerRaw[id] === false) {
        originalValue = 'disabled';
      } else {
        originalValue = this.state.providerRaw[id];
      }
      if (originalValue) {
        const original = document && document.querySelector(`input[name="${id}"][value="${originalValue}"]`);
        if (original && original instanceof HTMLInputElement) {
         
          original.checked = true;
          matches[id] = true;
        }
      }
    });

    // Update the state.
    this.setState({
      overrideText: stateAdjust,
      fieldMatches: matches,
      formChanged: true,
      // saved: false,
      imageSource: false,
      videoSource: false
    });

    this.readySubmit();
  };

  revertRadioFieldValue = (e: Event, o: string = '') => {
    
    const target: EventTarget = e.currentTarget;
    const associatedFieldName =
      target && target instanceof HTMLButtonElement ? target.getAttribute('data-field') : false;

    if (associatedFieldName) {
      const selected = document && document.querySelector(`input[name="${associatedFieldName}"]:checked`);
      const original = document && document.querySelector(`input[name="${associatedFieldName}"][value="${o}"]`);
     
      if (original && original instanceof HTMLInputElement) {
        
        original.checked = true;
        this.setState({
         
          fieldMatches: Object.assign({}, this.state.fieldMatches, {
            [associatedFieldName]: true
          }),
          formChanged: true,
          saved: false
        });
      }
    }

    this.readySubmit();
  };

  revertTextFieldValue = (e: Event, o: string = '') => {
    
    // Target is the actual target of the action. So the Button rather than the nested icon.
    const target: EventTarget = e.currentTarget;

    const associatedFieldId =
      target && target instanceof HTMLButtonElement ? target.getAttribute('data-target') : false;
    let associatedFieldName = target && target instanceof HTMLButtonElement ? target.getAttribute('data-field') : false;

    if (associatedFieldName === 'bio') {
      associatedFieldName = 'bioOriginal';
    }
    if (associatedFieldName === 'image') {
      associatedFieldName = 'imageOriginal';
    }
    if (associatedFieldId && associatedFieldName) {
 
      const field = this.getField(associatedFieldId);
      if (field) {
        const stateAdjust = this.state.overrideText;
        delete stateAdjust[associatedFieldName];
        field.value = o;
        this.setState({
          overrideText: stateAdjust,
          fieldMatches: Object.assign({}, this.state.fieldMatches, {
            [associatedFieldName]: true
          }),
          formChanged: true,
          saved: false
        });
      }
    }

    this.readySubmit();
  };

  checkTextFieldValue = (e: Event) => {
    const target = e.currentTarget;
    let field = '';
    let key = '';

    if (target instanceof HTMLInputElement || target instanceof HTMLTextAreaElement) {
      if (target.name === 'bio' ) {
        field = 'bioOriginal';
        key = 'bio';
      
      } else if (target.name === 'image' && typeof this.state.providerRaw['imageNew'][0] !== 'undefined') {
        field = 'imageNew';
        key = 'url';
      } else if (target.name === 'image' && typeof this.state.providerRaw['imageNew'][0] === 'undefined') {
        field = 'imageOriginal';
        key = 'url';
      } else {
        field = target.name;
      }
      const value = target.value;
      const originalValue = this.state.providerRaw[field];
      
   
      // console.log('here3', originalValue[0]);
      if (target.name === 'bio' || target.name === 'image') {

        if (
          typeof originalValue[0] !== 'undefined' &&
          value !== originalValue[0][key]
         // this.state.fieldMatches[field] === false
        ) {
         
          this.setState({
            fieldMatches: Object.assign({}, this.state.fieldMatches, {
              [field]: false
            }),
            formChanged: true,
            saved: false
          });
        } else if (typeof originalValue[0] === 'undefined' && this.state.fieldMatches[field] === true) {
  
          this.setState({
            fieldMatches: Object.assign({}, this.state.fieldMatches, {
              [field]: false
            }),
            formChanged: true,
            saved: false
          });
        }
      } else if (target.name === 'video') {
        if (
          typeof originalValue[0] !== 'undefined' &&
          value === originalValue[0].youtubeVideoId &&
          this.state.fieldMatches[field] === false
        ) {
          this.setState({
            fieldMatches: Object.assign({}, this.state.fieldMatches, {
              [field]: true
            }),
            formChanged: true,
            saved: false
          });
        } else if (typeof originalValue[0] === 'undefined' && this.state.fieldMatches[field] === true) {
          this.setState({
            fieldMatches: Object.assign({}, this.state.fieldMatches, {
              [field]: false
            }),
            formChanged: true,
            saved: false
          });
        }
      } else {
        if (value === originalValue && this.state.fieldMatches[field] === false) {
          this.setState({
            fieldMatches: Object.assign({}, this.state.fieldMatches, {
              [field]: true
            }),
            formChanged: true,
            saved: false
          });
        } else if (value !== originalValue && this.state.fieldMatches[field] === true) {
          this.setState({
            fieldMatches: Object.assign({}, this.state.fieldMatches, {
              [field]: false
            }),
            formChanged: true,
            saved: false
          });
        }
      }
    }
    // console.groupEnd();
  };

  checkRadioFieldValue = (e: Event, o: string = '') => {

     //console.log(`Running checkRadioFieldValue()...`);
    const target = e.currentTarget;

    if (target instanceof HTMLInputElement) {
      
      const field = target.name;
      let value = target.value;
    
      // Handle some boolean adjustments.
      if (value === 'enabled') {
        value = true;
      }
      if (value === 'disabled') {
        value = false;
      }

      if (value === this.state.providerRaw[field]) {
       
        this.setState({
          fieldMatches: Object.assign({}, this.state.fieldMatches, {
            [field]: true
          }),
          formChanged: true,
          saved: false
        });
        
      } 
      else {
       
        this.setState({
          fieldMatches: Object.assign({}, this.state.fieldMatches, {
            [field]: false
          }),
          formChanged: true,
          saved: false
        });
      }
    }
  };

   componentDidMount() {
    this.processProviderData().then(response => {
      this.prepareVariables(true);
    });
  }

  render() {
    const rawProviderData: ProviderData = this.state.providerRaw;
    const provider: ProviderData = this.state.providerMerged;
    // Throw an Alert for any bad id passed into props that doesn't match a result.
    if (!this.state.provider) {
      return (
        <div className="loading">
          <i className="fal fa-spinner" />
        </div>
      );
    }
    const ProviderVideo = () => {
      if (this.state.videoSource && typeof this.state.videoSource === 'string' && this.state.videoSource.length > 0) {
        let source = this.state.videoSource;
        var regExp = /^.*(youtu\.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
        var match = source.match(regExp);
        if (match && match[2].length == 11) {
          source = match[2];
        } else {
          //error
        }

        return (
          <div className={`ml-3`}>
            <iframe
              className={`rounded`}
              onError={e => {
                e.target.src = missingImage;
              }}
              src={`https://www.youtube.com/embed/${source}?version=3&enablejsapi=1&rel=0&playerapiid=ytplayer`}
              alt={`${provider && provider.video ? provider.video : ''}`}
            ></iframe>
          </div>
        );
      } else if (provider && provider.video && provider.video.length > 0) {
        return (
          <div className={`ml-3`}>
            <iframe
              className={`rounded`}
              onError={e => {
                e.target.src = missingImage;
              }}
              src={`https://www.youtube.com/embed/${provider.video[0].youtubeVideoId}?version=3&enablejsapi=1&rel=0&playerapiid=ytplayer}`}
              alt={`${provider && provider.video ? provider.video : ''}`}
            ></iframe>
          </div>
        );
      }

      return null;
    };

    const ProviderImage = () => {
      // If we do NOT have an image source customized (during this form edit).
      if (this.state.imageSource && typeof this.state.imageSource === 'string' && this.state.imageSource.length > 0) {
        return (
          <div className={`ml-3`}>
            <img
              className={`rounded`}
              onError={e => {
                e.target.src = missingImage;
              }}
              src={`https://fadapi.northwell.io/${this.state.imageSource}`}
              alt={`${provider && provider.fullName ? provider.fullName : ''}`}
            />
          </div>
        );
      }
      // Otherwise, IF we have an image already assigned (original value)
      else if (provider && provider.imageOriginal && provider.imageOriginal.length > 0) {
        return (
          <div className={`ml-3`}>
            <img
              className={`rounded`}
              onError={e => {
                e.target.src = missingImage;
              }}
              src={`https://fadapi.northwell.io/${provider.imageOriginal[0].url}`}
              alt={`${provider && provider.fullName ? provider.fullName : ''}`}
            />
          </div>
        );
      } else if (provider && provider.imageNew && provider.imageNew.length > 0) {
        return (
          <div className={`ml-3`}>
            <img
              className={`rounded`}
              onError={e => {
                e.target.src = missingImage;
              }}
              src={`https://fadapi.northwell.io/${provider.imageNew[0].url}`}
              alt={`${provider && provider.fullName ? provider.fullName : ''}`}
            />
          </div>
        );
      }

      return null;
    };

    const firstOverride = typeof this.state.overrideText.firstName !== 'undefined' && this.state.overrideText.firstName;
    const suffixOverride = typeof this.state.overrideText.suffix !== 'undefined' && this.state.overrideText.suffix;
    const middleOverride =
      typeof this.state.overrideText.middleName !== 'undefined' && this.state.overrideText.middleName;
    const lastOverride = typeof this.state.overrideText.lastName !== 'undefined' && this.state.overrideText.lastName;
    const imageOverride = typeof this.state.overrideText.image !== 'undefined' && this.state.overrideText.image;
    const yearOverride =
      typeof this.state.overrideText.yearBeganPractice !== 'undefined' && this.state.overrideText.yearBeganPractice;
    const bioOverride = typeof this.state.overrideText.bio !== 'undefined' && this.state.overrideText.bio;
    const imageOriginal = typeof provider.imageOriginal[0] === 'undefined' ? '' : provider.imageOriginal[0].url;
    const videoOverride = typeof provider.video !== 'undefined' && this.state.overrideText.video;

    const textFieldFunctions = {
      change: this.checkTextFieldValue,
      edit: this.editFieldValue,
      revert: this.revertTextFieldValue
    };

    return (
      <React.Fragment>
        <Form
          onChange={() => {
            this.readySubmit();
          }}
          onSubmit={(e: Event) => {
            e.preventDefault();
            this.submitPayload();
          }}
        >
          <Alert color="info" className={`mb-3 w-100`}>
            <div className="alert--with-icon">
              <i className="alert-icon fal fa-info-circle" />
              <span>
                This section allows you to manage data override(s) for the Provider:{' '}
                <strong>{provider.fullName}</strong>.
              </span>
            </div>
          </Alert>

          <Card className={`mb-4`}>
            <CardHeader>
              <strong>Primary Provider Data</strong>
            </CardHeader>
            <CardBody>
              <Alert color="warning" className={`mb-3 ${this.state.formChanged ? 'd-block' : 'd-none'}`}>
                <div className="alert--with-icon">
                  <i className="alert-icon fal fa-info-circle" />
                  <span>
                    The <em>Primary Provider Data</em> form has been updated. Use the{' '}
                    <strong>Save Provider Data</strong> button to avoid losing any changes.
                  </span>
                </div>
              </Alert>
              <UncontrolledAlert className={`mb-3 ${this.state.saved ? 'd-block' : 'd-none'}`} color={'success'}>
                <div className="alert--with-icon">
                  <i className="alert-icon fal fa-info-circle" />
                  <span>Primary Provider Data Form saved...</span>
                </div>
              </UncontrolledAlert>

              <FormGroup>
                <React.Fragment>
                  <Input
                    type="hidden"
                    name="id"
                    id="providerId"
                    defaultValue={provider && provider.id ? provider.id : ''}
                  />

                  <Label className={`mb-0`}>Provider Name</Label>
                  <FormText color="muted" className={`mb-2`}>
                    <i className="fal fa-info-circle" /> You can edit the <strong>First</strong>,{' '}
                    <strong>Middle</strong>, and <strong>Last</strong> name for a provider using the fields below.
                  </FormText>
                  <div className={`provider-names-wrapper d-flex justify-content-between`}>
                    <div>
                      <strong>First</strong>
                      <TextFieldWithOverride
                        type={`text`}
                        name={`firstName`}
                        id={`firstName`}
                        
                        default={provider && provider.firstName ? provider.firstName : ''}
                        match={
                          typeof this.state.fieldMatches.firstName !== 'undefined' 
                            ? this.state.fieldMatches.firstName 
                            : false
                        }
                        override={firstOverride}
                        callbacks={textFieldFunctions}
                        original={rawProviderData && rawProviderData.firstName ? rawProviderData.firstName : ''}
                      />
                    </div>
                    <div className={`ml-2 mr-2`}>
                      <strong>Middle</strong>
                      <TextFieldWithOverride
                        type={`text`}
                        name={`middleName`}
                        id={`middleName`}
                        default={provider && provider.middleName ? provider.middleName : ''}
                        match={
                          typeof this.state.fieldMatches.middleName !== 'undefined'
                            ? this.state.fieldMatches.middleName
                            : false
                        }
                        override={middleOverride}
                        callbacks={textFieldFunctions}
                        original={rawProviderData && rawProviderData.middleName ? rawProviderData.middleName : ''}
                      />
                    </div>
                    <div className={`ml-2 mr-2`}>
                      <strong>Last</strong>
                      <TextFieldWithOverride
                        type={`text`}
                        name={`lastName`}
                        id={`lastName`}
                        default={provider && provider.lastName ? provider.lastName : ''}
                        match={
                          typeof this.state.fieldMatches.lastName !== 'undefined'
                            ? this.state.fieldMatches.lastName
                            : false
                        }
                        override={lastOverride}
                        callbacks={textFieldFunctions}
                        original={rawProviderData && rawProviderData.lastName ? rawProviderData.lastName : ''}
                      />
                    </div>
                    <div>
                      <strong>Suffix</strong>
                      <TextFieldWithOverride
                        type={`text`}
                        name={`suffix`}
                        id={`suffix`}
                        default={provider && provider.suffix ? provider.suffix : ''}
                        match={
                          typeof this.state.fieldMatches.suffix !== 'undefined'
                            ? this.state.fieldMatches.suffix
                            : false
                        }
                        override={suffixOverride}
                        callbacks={textFieldFunctions}
                        original={rawProviderData && rawProviderData.suffix ? rawProviderData.suffix : ''}
                      />
                    </div>
                  </div>
                </React.Fragment>
                <hr />
              </FormGroup>
              <FormGroup>
                <div className={'d-flex justify-content-between mb-3'}>
                  <div className={`w-100`}>
                    <Label className={`mb-0`}>Provider Image</Label>
                    <FormText color="muted" className={`mb-2`}>
                      <i className="fal fa-info-circle" /> You can edit path to the provider image below. The image path
                      should only include the portion after <em>https://api.northwell.edu</em>.
                    </FormText>
                    <TextFieldWithOverride
                      type={`text`}
                      name={`image`}
                      id={`image`}
                      default={
                        typeof provider.imageNew[0] === 'undefined'
                          ? typeof provider.imageOriginal[0] === 'undefined'
                            ? ''
                            : provider.imageOriginal[0].url
                          : provider.imageNew[0].url
                      }
                      match={
                        typeof this.state.fieldMatches.imageOriginal !== 'undefined'
                          ? this.state.fieldMatches.imageOriginal
                          : false
                        //typeof this.state.fieldMatches.image !== 'undefined' ? true : false
                      }
                      override={imageOverride}
                      callbacks={textFieldFunctions}
                      changeExtra={e => {
                        this.updateImageSource(e);
                      }}
                      revertExtra={e => {
                        this.setState(() => ({
                          imageSource: false
                        }));
                      }}
                      original={
                        typeof rawProviderData.imageOriginal[0] === 'undefined'
                          ? ''
                          : rawProviderData.imageOriginal[0].url
                      }
                    />
                  </div>
                  <ProviderImage />
                </div>
                <hr />
              </FormGroup>

              <FormGroup>
                <Label className={`mb-0`}>Year Began Practice</Label>
                <FormText color="muted" className={`mb-2`}>
                  <i className="fal fa-info-circle" /> You can edit the path to the provider image below. The image path
                  should only include the portion after <em>https://api.northwell.edu</em>.
                </FormText>
                <TextFieldWithOverride
                  type={`text`}
                  name={`yearBeganPractice`}
                  id={`yearBeganPractice`}
                  default={provider && provider.yearBeganPractice ? provider.yearBeganPractice : ''}
                  match={
                    typeof this.state.fieldMatches.yearBeganPractice !== 'undefined'
                      ? this.state.fieldMatches.yearBeganPractice
                      : false
                  }
                  override={yearOverride}
                  callbacks={textFieldFunctions}
                  original={
                    rawProviderData && rawProviderData.yearBeganPractice ? rawProviderData.yearBeganPractice : ''
                  }
                />
                <hr />
              </FormGroup>

              <FormGroup>
                <Label className={`mb-0`}>Provider Bio</Label>
                <FormText color="muted" className={`mb-2`}>
                  <i className="fal fa-info-circle" /> You can edit the provider bio below..
                </FormText>
                <TextFieldWithOverride
                  type={`textarea`}
                  rows={8}
                  name={'bio'}
                  id={`bio`}
                  default={
                    typeof provider.bioNew[0] === 'undefined'
                      ? typeof provider.bioOriginal[0] === 'undefined'
                        ? ''
                        : provider.bioOriginal[0].bio
                      : provider.bioNew[0].bio
                  }
                  match={
                    typeof this.state.fieldMatches.bioNew !== 'undefined'
                      ? this.state.fieldMatches.bioNew && this.state.fieldMatches.bioOriginal
                      : false
                  }
                  override={bioOverride}
                  callbacks={textFieldFunctions}
                  original={
                    rawProviderData && typeof rawProviderData.bioOriginal[0] !== 'undefined' ? rawProviderData.bioOriginal[0].bio : ''
                  }
                />
                <hr />
              </FormGroup>

              <FormGroup>
                <div className={'d-flex justify-content-between mb-3'}>
                  <div className={`w-100`}>
                    <Label className={`mb-0`}>Provider YouTube Video</Label>
                    <FormText color="muted" className={`mb-2`}>
                      <i className="fal fa-info-circle" /> You can edit path to the provider YouTube Video below.
                    </FormText>
                    <TextFieldWithOverride
                      type={`text`}
                      name={'video'}
                      id={`video`}
                      default={
                        typeof provider.video[0] !== 'undefined'
                          ? 'https://www.youtube.com/watch?v=' + provider.video[0].youtubeVideoId
                          : ''
                      }
                      match={
                        typeof this.state.fieldMatches.video !== 'undefined' ? this.state.fieldMatches.video : false
                        //
                      }
                      override={videoOverride}
                      callbacks={textFieldFunctions}
                      changeExtra={e => {
                        this.updateVideoSource(e);
                      }}
                      revertExtra={e => {
                        this.setState(() => ({
                          videoSource: false
                        }));
                      }}
                      // original={
                      //   typeof rawProviderData.imageOriginal[0] === 'undefined'
                      //     ? ''
                      //     : rawProviderData.imageOriginal[0].url
                      // }
                    />
                  </div>
                  <ProviderVideo />
                </div>
                <hr />
              </FormGroup>

              <FormGroup className={`my-4`}>
                <legend className={`mb-0`}>
                  <div className={`d-flex justify-content-between align-items-center`}>
                    <span>Provider Gender</span>
                    <Button
                      color={`danger`}
                      className={`enum-override-reset py-1 px-3 ${
                        this.state.fieldMatches.gender ? 'd-none' : 'd-inline'
                      }`}
                      title={`Remove this override.`}
                      data-field={`gender`}
                      onClick={e => {
                        this.revertRadioFieldValue(e, this.state.providerRaw.gender);
                      }}
                    >
                      <i className={`reset-icon fas fa-times`} />
                      <span className={`ml-2 mr-0`}>overridden</span>
                    </Button>
                  </div>
                </legend>
                <FormText color="muted" className={`mb-2`}>
                  <i className="fal fa-info-circle" /> This allows you to edit a Provider's gender if was incorrect.
                </FormText>
                <div className="form-radio-group">
                  <FormGroup check>
                    <Label check>
                      <Input
                        onClick={e => {
                          this.checkRadioFieldValue(e, this.state.providerRaw.gender);
                        }}
                        type="radio"
                        name="gender"
                        value="M"
                        disabled = {isRole('trulyAwardUser') == true ? true : false}
                        defaultChecked={provider.gender === 'M' ? 'checked' : false}
                      />{' '}
                      <span className="">
                        <i className="ml-1 mr-2 fal fa-mars" />
                        Male
                      </span>
                    </Label>
                  </FormGroup>
                  <FormGroup check>
                    <Label check>
                      <Input
                        onClick={e => {
                          this.checkRadioFieldValue(e, this.state.providerRaw.gender);
                        }}
                        type="radio"
                        name="gender"
                        value="F"
                        disabled = {isRole('trulyAwardUser') == true ? true : false}
                        defaultChecked={provider.gender === 'F' ? 'checked' : false}
                      />{' '}
                      <span className="">
                        <i className="ml-1 mr-2 fal fa-venus" />
                        Female
                      </span>
                    </Label>
                  </FormGroup>
                  <FormGroup check>
                    <Label check>
                      <Input
                        onClick={e => {
                          this.checkRadioFieldValue(e, this.state.providerRaw.gender);
                        }}
                        type="radio"
                        name="gender"
                        value=""
                        disabled = {isRole('trulyAwardUser') == true ? true : false}
                        defaultChecked={provider.gender === '' ? 'checked' : false}
                      />{' '}
                      <span className="">Undefined</span>
                    </Label>
                  </FormGroup>
                </div>
                <hr />
              </FormGroup>

              <FormGroup className={`my-4`}>
                <legend className={`mb-0`}>
                  <div className={`d-flex justify-content-between align-items-center`}>
                    <span>Is Provider on Find a Doctor?</span>
                    <Button
                      color={`danger`}
                      className={`enum-override-reset py-1 px-3 ${
                        this.state.fieldMatches.isFad ? 'd-none' : 'd-inline'
                      }`}
                      title={`Remove this override.`}
                      data-field={`isFad`}
                      onClick={e => {
                        this.revertRadioFieldValue(e, this.state.providerRaw.isFad ? 'enabled' : 'disabled');
                      }}
                    >
                      <i className={`reset-icon fas fa-times`} />
                      <span className={`ml-2 mr-0`}>overridden</span>
                    </Button>
                  </div>
                </legend>
                <FormText color="muted" className={`mb-2`}>
                  <i className="fal fa-info-circle" /> This allows you to edit if a Provider appears in{' '}
                  <strong>Find a Doctor</strong> searches.
                </FormText>
                <div className="form-radio-group">
                  <FormGroup check>
                    <Label check>
                      <Input
                        onClick={e => {
                          this.checkRadioFieldValue(e, this.state.providerRaw.isFad ? 'enabled' : 'disabled');
                        }}
                        type="radio"
                        name="isFad"
                        value="enabled"
                        disabled = {isRole('trulyAwardUser') == true ? true : false}
                        defaultChecked={provider.isFad ? 'checked' : false}
                      />{' '}
                      <span className="text-success">Yes</span>
                    </Label>
                  </FormGroup>
                  <FormGroup check>
                    <Label check>
                      <Input
                        onClick={e => {
                          this.checkRadioFieldValue(e, this.state.providerRaw.isFad ? 'enabled' : 'disabled');
                        }}
                        type="radio"
                        name="isFad"
                        value="disabled"
                        disabled = {isRole('trulyAwardUser') == true ? true : false}
                        defaultChecked={provider.isFad ? false : 'checked'}
                      />{' '}
                      <span className="text-warning">No</span>
                    </Label>
                  </FormGroup>
                </div>
                <hr />
              </FormGroup>

              <FormGroup className={`my-4`}>
                <legend className={`mb-0`}>
                  <div className={`d-flex justify-content-between align-items-center`}>
                    <span>Is Provider Staff?</span>
                    <Button
                      color={`danger`}
                      className={`enum-override-reset py-1 px-3 ${
                        this.state.fieldMatches.isStaff ? 'd-none' : 'd-inline'
                      }`}
                      title={`Remove this override.`}
                      data-field={`isStaff`}
                      onClick={e => {
                        this.revertRadioFieldValue(e, this.state.providerRaw.isStaff ? 'enabled' : 'disabled');
                      }}
                    >
                      <i className={`reset-icon fas fa-times`} />
                      <span className={`ml-2 mr-0`}>overridden</span>
                    </Button>
                  </div>
                </legend>
                <FormText color="muted" className={`mb-2`}>
                  <i className="fal fa-info-circle" /> This allows you to edit if a Provider is considered Staff or not.
                </FormText>
                <div className="form-radio-group">
                  <FormGroup check>
                    <Label check>
                      <Input
                        onClick={e => {
                          this.checkRadioFieldValue(e, this.state.providerRaw.isStaff ? 'enabled' : 'disabled');
                        }}
                        type="radio"
                      
                        disabled = {isRole('trulyAwardUser') == true ? true : false}
                        name="isStaff"
                        value="enabled"
                        defaultChecked={provider.isStaff ? 'checked' : false}
                      />{' '}
                      <span className="text-success">Yes</span>
                    </Label>
                  </FormGroup>
                  <FormGroup check>
                    <Label check>
                      <Input
                        onClick={e => {
                          this.checkRadioFieldValue(e, this.state.providerRaw.isStaff ? 'enabled' : 'disabled');
                        }}
                        type="radio"
                        name="isStaff"
                        value="disabled"
                        disabled = {isRole('trulyAwardUser') == true ? true : false}
                        defaultChecked={provider.isStaff ? false : 'checked'}
                      />{' '}
                      <span className="text-warning">No</span>
                    </Label>
                  </FormGroup>
                </div>
                <hr />
              </FormGroup>

              <FormGroup className={`my-4`}>
                <legend className={`mb-0`}>
                  <div className={`d-flex justify-content-between align-items-center`}>
                    <span>Is Provider a Primary Care Physician?</span>
                    <Button
                      color={`danger`}
                      className={`enum-override-reset py-1 px-3 ${
                        this.state.fieldMatches.isPcp ? 'd-none' : 'd-inline'
                      }`}
                      title={`Remove this override.`}
                      data-field={`isPcp`}
                      onClick={e => {
                        this.revertRadioFieldValue(e, this.state.providerRaw.isPcp ? 'enabled' : 'disabled');
                      }}
                    >
                      <i className={`reset-icon fas fa-times`} />
                      <span className={`ml-2 mr-0`}>overridden</span>
                    </Button>
                  </div>
                </legend>
                <FormText color="muted" className={`mb-2`}>
                  <i className="fal fa-info-circle" /> This allows you to edit if a Provider is a Primary Care Physician
                  or not.
                </FormText>
                <div className="form-radio-group">
                  <FormGroup check>
                    <Label check>
                      <Input
                        onClick={e => {
                          this.checkRadioFieldValue(e, this.state.providerRaw.isPcp ? 'enabled' : 'disabled');
                        }}
                        type="radio"
                        name="isPcp"
                        value="enabled"
                        disabled = {isRole('trulyAwardUser') == true ? true : false}
                        defaultChecked={provider.isPcp ? 'checked' : false}
                      />{' '}
                      <span className="text-success">Yes</span>
                    </Label>
                  </FormGroup>
                  <FormGroup check>
                    <Label check>
                      <Input
                        onClick={e => {
                          this.checkRadioFieldValue(e, this.state.providerRaw.isPcp ? 'enabled' : 'disabled');
                        }}
                        type="radio"
                        name="isPcp"
                        value="disabled"
                        disabled = {isRole('trulyAwardUser') == true ? true : false}
                        defaultChecked={provider.isPcp ? false : 'checked'}
                      />{' '}
                      <span className="text-warning">No</span>
                    </Label>
                  </FormGroup>
                </div>
                <hr />
              </FormGroup>

              <FormGroup className={`mt-4`}>
                <legend className={`mb-0`}>
                  <div className={`d-flex justify-content-between align-items-center`}>
                    <span>Is Provider accepting new patients?</span>
                    <Button
                      color={`danger`}
                      className={`enum-override-reset py-1 px-3 ${
                        this.state.fieldMatches.isAcceptingNewPatients ? 'd-none' : 'd-inline'
                      }`}
                      title={`Remove this override.`}
                      data-field={`isAcceptingNewPatients`}
                      onClick={e => {
                        this.revertRadioFieldValue(
                          e,
                          this.state.providerRaw.isAcceptingNewPatients ? 'enabled' : 'disabled'
                        );
                      }}
                    >
                      <i className={`reset-icon fas fa-times`} />
                      <span className={`ml-2 mr-0`}>overridden</span>
                    </Button>
                  </div>
                </legend>
                <FormText color="muted" className={`mb-2`}>
                  <i className="fal fa-info-circle" /> This allows you to edit if a Provider is accepting new patients
                  or not.
                </FormText>
                <div className="form-radio-group">
                  <FormGroup check>
                    <Label check>
                      <Input
                        onClick={e => {
                          this.checkRadioFieldValue(
                            e,
                            this.state.providerRaw.isAcceptingNewPatients ? 'enabled' : 'disabled'
                          );
                        }}
                        type="radio"
                        name="isAcceptingNewPatients"
                        value="enabled"
                        disabled = {isRole('trulyAwardUser') == true ? true : false}
                        defaultChecked={provider.isAcceptingNewPatients ? 'checked' : false}
                      />{' '}
                      <span className="text-success">Yes</span>
                    </Label>
                  </FormGroup>
                  <FormGroup check>
                    <Label check>
                      <Input
                        onClick={e => {
                          this.checkRadioFieldValue(
                            e,
                            this.state.providerRaw.isAcceptingNewPatients ? 'enabled' : 'disabled'
                          );
                        }}
                        type="radio"
                        name="isAcceptingNewPatients"
                        value="disabled"
                        disabled = {isRole('trulyAwardUser') == true ? true : false}
                        defaultChecked={provider.isAcceptingNewPatients ? false : 'checked'}
                      />{' '}
                      <span className="text-warning">No</span>
                    </Label>
                  </FormGroup>
                </div>
              </FormGroup>

              <FormGroup className={`mt-4`}>
                <legend className={`mb-0`}>
                  <div className={`d-flex justify-content-between align-items-center`}>
                    <span>Is Provider Schedulable?</span>
                    <Button
                      color={`danger`}
                      className={`enum-override-reset py-1 px-3 ${
                        this.state.fieldMatches.isBookable ? 'd-none' : 'd-inline'
                      }`}
                      title={`Remove this override.`}
                      data-field={`isBookable`}
                      onClick={e => {
                        this.revertRadioFieldValue(
                          e,
                          this.state.providerRaw.isBookable ? 'enabled' : 'disabled'
                        );
                      }}
                    >
                      <i className={`reset-icon fas fa-times`} />
                      <span className={`ml-2 mr-0`}>overridden</span>
                    </Button>
                  </div>
                </legend>
                <FormText color="muted" className={`mb-2`}>
                  <i className="fal fa-info-circle" /> This allows you to edit if a Provider is bookable
                  or not.
                </FormText>
                <div className="form-radio-group">
                  <FormGroup check>
                    <Label check>
                      <Input
                        onClick={e => {
                          this.checkRadioFieldValue(
                            e,
                            this.state.providerRaw.isBookable ? 'enabled' : 'disabled'
                          );
                        }}
                        type="radio"
                        name="isBookable"
                        value="enabled"
                        disabled = {isRole('trulyAwardUser') == true ? true : false}
                        defaultChecked={provider.isBookable ? 'checked' : false}
                      />{' '}
                      <span className="text-success">Yes</span>
                    </Label>
                  </FormGroup>
                  <FormGroup check>
                    <Label check>
                      <Input
                        onClick={e => {
                          this.checkRadioFieldValue(
                            e,
                            this.state.providerRaw.isBookable ? 'enabled' : 'disabled'
                          );
                        }}
                        type="radio"
                        name="isBookable"
                        value="disabled"
                        disabled = {isRole('trulyAwardUser') == true ? true : false}
                        defaultChecked={provider.isBookable ? false : 'checked'}
                      />{' '}
                      <span className="text-warning">No</span>
                    </Label>
                  </FormGroup>
                </div>
              </FormGroup>
              <FormGroup className={`mt-4`}>
                <legend className={`mb-0`}>
                  <div className={`d-flex justify-content-between align-items-center`}>
                    <span>Is Provider Online Schedulable?</span>
                    <Button
                      color={`danger`}
                      className={`enum-override-reset py-1 px-3 ${
                        this.state.fieldMatches.isOnlineScheduling ? 'd-none' : 'd-inline'
                      }`}
                      title={`Remove this override.`}
                      data-field={`isBookable`}
                      onClick={e => {
                        this.revertRadioFieldValue(
                          e,
                          this.state.providerRaw.isOnlineScheduling ? 'enabled' : 'disabled'
                        );
                      }}
                    >
                      <i className={`reset-icon fas fa-times`} />
                      <span className={`ml-2 mr-0`}>overridden</span>
                    </Button>
                  </div>
                </legend>
                <FormText color="muted" className={`mb-2`}>
                  <i className="fal fa-info-circle" /> This allows you to edit if a Provider is online bookable
                  or not.
                </FormText>
                <div className="form-radio-group">
                  <FormGroup check>
                    <Label check>
                      <Input
                        onClick={e => {
                          this.checkRadioFieldValue(
                            e,
                            this.state.providerRaw.isOnlineScheduling ? 'enabled' : 'disabled'
                          );
                        }}
                        type="radio"
                        name="isOnlineBookable"
                        value="enabled"
                        disabled = {isRole('trulyAwardUser') == true ? true : false}
                        defaultChecked={provider.isOnlineScheduling ? 'checked' : false}
                      />{' '}
                      <span className="text-success">Yes</span>
                    </Label>
                  </FormGroup>
                  <FormGroup check>
                    <Label check>
                      <Input
                        onClick={e => {
                          this.checkRadioFieldValue(
                            e,
                            this.state.providerRaw.isOnlineScheduling ? 'enabled' : 'disabled'
                          );
                        }}
                        type="radio"
                        name="isOnlineBookable"
                        value="disabled"
                        disabled = {isRole('trulyAwardUser') == true ? true : false}
                        defaultChecked={provider.isOnlineScheduling ? false : 'checked'}
                      />{' '}
                      <span className="text-warning">No</span>
                    </Label>
                  </FormGroup>
                </div>
              </FormGroup>
              <FormGroup className={`mt-4`}>
                <legend className={`mb-0`}>
                  <div className={`d-flex justify-content-between align-items-center`}>
                    <span>Disable Clinical Focus Terms Inherited From Specialties?</span>
                
                    <Button
                      color={`danger`}
                      className={`enum-override-reset py-1 px-3 ${
                        this.state.providerRaw.override == undefined  ||  this.state.providerRaw.override.disableClinicalFocusTerms == undefined || this.state.providerRaw.override.disableClinicalFocusTerms == false ? 'd-none' : 'd-inline'
                      }`}
                      title={`Remove this override.`}
                      data-field={`disableClinicalFocusTerms`}
                      onClick={e => {
                        this.revertRadioFieldValue(
                          e,
                          this.state.providerRaw.override && this.state.providerRaw.override.disableClinicalFocusTerms ? 'enabled' : 'disabled'
                        );
                      }}
                    >
                      <i className={`reset-icon fas fa-times`} />
                      <span className={`ml-2 mr-0`}>overridden</span>
                    </Button>
                  </div>
                </legend>
                <FormText color="muted" className={`mb-2`}>
                  <i className="fal fa-info-circle" /> This allows you to disable clinical focus terms from inherited specialties for this provider
                  or not.
                </FormText>
                <div className="form-radio-group">
                  <FormGroup check>
                    <Label check>
                      <Input
                        onClick={e => {
                          this.checkRadioFieldValue(
                            e,
                            this.state.providerRaw.override && this.state.providerRaw.override.disableClinicalFocusTerms ? 'enabled' : 'disabled'
                          );
                        }}
                        type="radio"
                        name="disableClinicalFocusTerms"
                        value="enabled"
                        disabled = {isRole('trulyAwardUser') == true ? true : false}
                        defaultChecked={provider.override && provider.override.disableClinicalFocusTerms ? 'checked' : false}
                      />{' '}
                      <span className="text-success">Yes</span>
                    </Label>
                  </FormGroup>
                  <FormGroup check>
                    <Label check>
                      <Input
                        onClick={e => {
                          this.checkRadioFieldValue(
                            e,
                            this.state.providerRaw.override && this.state.providerRaw.override.disableClinicalFocusTerms ? 'enabled' : 'disabled'
                          );
                        }}
                        type="radio"
                        name="disableClinicalFocusTerms"
                        value="disabled"
                        disabled = {isRole('trulyAwardUser') == true ? true : false}
                        defaultChecked={provider.override && provider.override.disableClinicalFocusTerms ? false : 'checked'}
                      />{' '}
                      <span className="text-warning">No</span>
                    </Label>
                  </FormGroup>
                </div>
              </FormGroup>
            </CardBody>
            <CardFooter>
              <FormGroup className={this.state.formChanged ? 'd-block' : 'd-none'}>
                <Label className={`mb-0`}>Reason for Override</Label>
                <FormText color="muted" className={`mb-2`}>
                  <i className="fal fa-info-circle" /> Please provide a reason, including who authorized the change(s)
                  to the <strong>Primary Provider Data</strong>. Your message should be at least 20 characters long in
                  order to save the updates.
                </FormText>
                <Input
                  type={`textarea`}
                  name={`log--primaryData`}
                  id={`log--primaryData`}
                  rows={4}
                  disabled={!this.state.formChanged}
                />
              </FormGroup>
              <Alert color="warning" className={`mb-3 ${this.state.formChanged ? 'd-block' : 'd-none'}`}>
                <div className="alert--with-icon">
                  <i className="alert-icon fal fa-info-circle" />
                  <span>
                    The <em>Primary Provider Data</em> form has been updated. Use the{' '}
                    <strong>Save Provider Data</strong> button to avoid losing any changes.
                  </span>
                </div>
              </Alert>
              <div className={`d-flex justify-content-between`}>
                <Button
                  id="saveProviderDataButton"
                  type="submit"
                  size="md"
                  color="primary"
                  title="Save Overrides"
                  disabled={!this.state.submitReady}
                >
                  <i className="fal fa-save" />
                  <span>Save Provider Data</span>
                </Button>

                <Button
                  id="resetProviderDataButton"
                  size="md"
                  outline
                  inverse={`true`}
                  color="danger"
                  title="Remove Overrides"
                  disabled = {isRole('trulyAwardUser') == true ? true : false}
                  onClick={() => {
                    this.revertAllFields();
                  }}
                >
                  <i className="fas fa-times" />
                  <span>Reset ALL Data Overrides</span>
                </Button>
              </div>
            </CardFooter>
          </Card>
        </Form>
      </React.Fragment>
    );
  }
}
