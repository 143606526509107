/* @flow */
import * as React from 'react';
// import ReactDOM from 'react-dom';
import { ApolloProvider } from 'react-apollo';
import { client } from 'functions/Connect'; // eslint-disable-line no-unused-vars
import { useHistory } from "react-router-dom";
import type { HistoryType } from 'shared/types';
import type { ModalProps, ModalState } from 'shared/types';

// This is ONLY 'hiding' the modal, then invokes ModalSection.closeModal,
// which will handle the proper path updates.
export const modalClose = (history: HistoryType) => {
  const modal = document && document.getElementById('dml-modal');
  if (modal) {
    modal.classList.remove('modal-visible');
    ModalSection.closeModal(history);
  }
};

export const modalHide = () => {
  const modal = document && document.getElementById('dml-modal');
  if (modal) {
    modal.classList.remove('modal-visible');
  }
};

/* eslint-disable no-console */
class ModalSection extends React.Component<ModalProps, ModalState> {
  escKey: number;
  constructor(props: ModalProps) {
    super(props);

    this.state = {
      heading: this.props.heading ? this.props.heading : 'Loading...',
      actions: this.props.actions && typeof this.props.actions !== 'boolean' ? this.props.actions : null,
      content: this.props.content ? (
        this.props.content
      ) : (
        <div className="loading-screen">
          <i className="fal fa-spinner" />
        </div>
      )
    };
    this.escKey = 27;
    this.handleKeyDown = this.handleKeyDown.bind(this);
  }

  handleKeyDown = (e: KeyboardEvent) => {
    if (e.keyCode === this.escKey) {
      ModalSection.closeModal(this.props.history);
    }
  };

  /**
   * Opens a 'modal' screen.
   */
  static openModal() {
  
    // Find the modal and add a class that will use CSS transition
    // to move the 'modal' into view.
    const modal = document && document.getElementById('dml-modal');
    if (modal) {
      modal.classList.add('modal-visible');
      // Find the cancel button and focus on it.
      setTimeout(() => {
        const focus = modal.querySelector('.btn-modal-close');
        if (typeof focus !== 'undefined' && focus !== null) {
          focus.focus();
        }
      }, 100);
    }
  }

  // It would be easier to look at the path and strip it down however, we need to
  // pass the history object in in order to properly adjust the path.
  static closeModal = (history: HistoryType) => {

    // Get the modal container. It is static so we can reference it anywhere.
    const modal = document && document.getElementById('dml-modal');
    // Based on argument passed to modalClose.
    if (history && history.location) {
      window.history.back()
      //history.back();
      // console.log(this.props.history);
      // const [, base] = history.location.pathname.split('/');
      // history.replace(`/${base}`);
     
    }

    if (modal) {
      // Hide the modal window wrapper.
      modal.classList.remove('modal-visible');
    }
  };

  componentDidMount() {
    // Add in a listener on the body to close the modal via the esc key.
    if (document && document.body) {
      document.body.addEventListener('keyup', this.handleKeyDown);
    }
  }

  componentWillUnmount() {
    if (document && document.body) {
      document.body.removeEventListener('keyup', this.handleKeyDown);
    }
  }

  render() {
    // console.log(this.props.history);

    const Heading = () => {
      return this.state.heading;
    };

    const Content = () => {
      return this.state.content;
    };

    const Actions = () => {
      return this.state.actions;
    };

    return (
      <div className="dml-modal">
        <div className="section-header">
          <h2>
            <Heading />
          </h2>
          <Actions />
          <CloseButton
            closeModal={() => {
              ModalSection.closeModal(this.props.history);
            }}
          />
        </div>
        <div className="dml-modal--inner">
          <ApolloProvider client={client}>
            <Content />
          </ApolloProvider>
        </div>
      </div>
    );
  }
}

/**
 * Component to handle entire table.
 */
class CloseButton extends React.Component<{ closeModal: () => void }> {
  /* eslint-disable jsx-a11y/href-no-hash */
  render() {
    return (
      <div className="modal-actions">
        <button className="btn-modal-close btn btn-secondary" onClick={this.props.closeModal}>
          <i className="fas fa-times" />
          <span className="d-none d-lg-inline-block">Close</span>
        </button>
      </div>
    );
  }
}

export default ModalSection;
