/* @flow */
import * as React from 'react';
import ContentSection from 'elements/ContentSection';
import { Alert, Form, FormGroup, Input, Label, Button } from 'reactstrap';
import { client } from 'functions/Connect';
import { Redirect } from 'react-router-dom';
import { createTokenMutation } from 'user/queries';
import { isAuthenticated } from 'functions/AuthFunctions';
import type { UserLoginFormElementsProps, UserLoginFormElementsState, UserLoginFormProps } from 'user/types';
import type { SectionDataType, tokenQueryData } from 'shared/types';

class LoginForm extends React.Component<UserLoginFormProps> {
  render() {
    // Pass all the things to content wrapper.
    const sectionData: SectionDataType = {
      heading: 'Login to DML',
      content: <LoginFormElements authentication={this.props.authentication} />,
      actions: false,
      modals: false
    };

    return (
      <React.Fragment>
        <ContentSection data={sectionData} history={this.props.history} />
      </React.Fragment>
    );
  }
}

class LoginFormElements extends React.Component<UserLoginFormElementsProps, UserLoginFormElementsState> {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      password: '',
      loginError: false,
      // @todo: Current implementation (API) prevents knowing if it was the username or password that failed the login.
      invalidAccount: false,
      invalidPassword: false,
      successfulLogin: false
    };

    this.changeUsername = this.changeUsername.bind(this);
    this.changePassword = this.changePassword.bind(this);
  }

  loginUser = token => {
    this.props.authentication.login(token, this.state.name);
  };

  loginTest = () => {
    const token: String = client
      .mutate({
        mutation: createTokenMutation,
        // Ensure we ALWAYS reach out immediately and don't rely on cache for this query.
        fetchPolicy: 'no-cache',
        variables: {
          input: {
            username: this.state.name,
            password: this.state.password
          }
        }
      })
      .then(response => {
        // console.log(response);
        const data: tokenQueryData = response.data.createToken;
        if (data !== null && data.token && data.success) {
          // Do something with token.
          this.loginUser(data.token);
          return true;
        } else {
          // For some reason login failed. Updates state, which will render Alert.
          if (data.success === false) {
            this.setState(() => ({
              loginError: true
            }));
          }
          return false;
        }
      })
      .catch(error => console.log(error));

    return token;
  };

  changeUsername = val => {
    this.setState(() => ({
      name: val
    }));
  };

  changePassword = val => {
    this.setState(() => ({
      password: val
    }));
  };


  

  render() {
    
    if (isAuthenticated()) {
      return <Redirect to={`/dashboard`} />;
    }

    const LoginError = this.state.loginError ? (
      <Alert color="danger" className="mb-3">
        <div className="alert--with-icon">
          <i className="alert-icon fal fa-info-circle" />
          <p className="mb-0">
            The <strong>username</strong> and <strong>password</strong> combination you entered is invalid. Please try
            again with your supplied credentials.
          </p>
        </div>
      </Alert>
    ) : null;

    return (
      <Form
        className={`login-form`}
        onSubmit={(e: Event) => {
          e.preventDefault();
          this.loginTest();
        }}
      >
        <Alert color="info" className="mb-3">
          <div className="alert--with-icon">
            <i className="alert-icon fal fa-info-circle" />
            <p className="mb-0">
              In order to access administrative features of the <strong>Data Management Layer</strong> (DML), you will
              need to login with your supplied credentials.
            </p>
          </div>
        </Alert>
        {LoginError}
        <FormGroup>
          <Label for={`loginName`}>Username</Label>
          <Input
            onChange={e => {
              this.changeUsername(e.target.value);
            }}
            type="text"
            autoComplete="off"
            name={`name`}
            id={`loginName`}
            defaultValue={``}
            placeholder={`Enter a valid username...`}
            invalid={this.state.invalidAccount}
          />
        </FormGroup>
        <FormGroup>
          <Label for={`loginPass`}>Password</Label>
          <Input
            onChange={e => {
              this.changePassword(e.target.value);
            }}
            type="password"
            autoComplete="off"
            name={`password`}
            id={`loginPass`}
            defaultValue={``}
            placeholder={`Enter password ${this.state.name.length >= 3 ? `for ${this.state.name}` : ''}...`}
            invalid={this.state.invalidPassword}
          />
        </FormGroup>

        <FormGroup className="mt-2 form-actions text-right">
          <Button id="loginButton" type="submit" size="md" color="primary" title="Login">
            <i className="fal fa-user" />
            <span>Login</span>
          </Button>
        </FormGroup>
      </Form>
    );
  }
}

export default LoginForm;
