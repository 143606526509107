/* @flow */
export const escapeRegExp = (string: string) => {
  return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
};

/**
 * Function to enable easing on animateScroll.
 */
const easeInOutQuad = (t, b, c, d) => {
  t /= d / 2;
  if (t < 1) return (c / 2) * t * t + b;
  t--;
  return (-c / 2) * (t * (t - 2) - 1) + b;
};

/**
 * Function to animate scrolling of an element.
 * Currently based on required logic for DML Listing pages.
 */
export const animateScroll = (element: string, wrapper: string, offset: number, duration: number): void => {
  // Scrolling wrapper element.
  const w: HTMLElement | null = document.getElementById(wrapper);
  // Element we are scrolling to.
  const el: HTMLElement | null =
    document && document.getElementById(element) instanceof HTMLElement ? document.getElementById(element) : null;
  const elp: HTMLElement | null =
    document && el instanceof HTMLElement && el.offsetParent instanceof HTMLElement ? el.offsetParent : null;

  if (w && el && elp) {
    // Let's add a class to the element so we can
    // SEE where the new items start.
    el.classList.add('first-new-item');
    // Offset of element (el).
    const o: number = el ? el.offsetTop : 0;
    // Offset of parent element (elp).
    const po: number = elp ? elp.offsetTop : 0;
    // Starting position of the scrolling wrapper element.
    const start: number = w && w.scrollTop ? w.scrollTop : 0;
    // Amount we need to shift element to position where we want.
    // 100 represents the header bar size.
    const change: number = o + po + offset - start;
    // Millisecond pauses between animation cycles.
    const increment: number = 20;
    // Starting time of zero.
    let currentTime: number = 0;
    // Start scrolling.
    const scrollToIt = (): void => {
      // Update current time.
      currentTime += increment;
      // Do the next scroll adjustment.
      if (w && w.scrollTop) {
        w.scrollTop = easeInOutQuad(currentTime, start, change, duration);
      }
      // If we haven't hit the end, pause and go again.
      if (currentTime < duration) {
        setTimeout(scrollToIt, increment);
      }
      // We should be done with the animation, so here is some additional
      // logic so that we can remove the visualization of new content.
      else {
        setTimeout(() => {
          el.classList.remove('first-new-item');
        }, 2500);
      }
    };

    // Go.
    scrollToIt();
  }
};
