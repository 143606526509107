/* @flow */
/* eslint-disable no-unused-vars */
import * as React from 'react';
import ContentSection from 'elements/ContentSection';
import { Query } from 'react-apollo';
import { Link, Redirect, Route } from 'react-router-dom';
import { Alert, Badge } from 'reactstrap';
import SectionTable from 'elements/SectionTable';
import SectionError from 'elements/SectionError';
import { userListQuery } from 'user/queries';
import SectionActionButtons from 'elements/SectionActionButtons';
import type { ActionButtonLink, LocationOnlyProps, MatchType, SectionDataType, SectionListProps } from 'shared/types';
import { DeleteUserModal, EditUserModal, NewUserModal } from './UserForms';
import { doUsernamesMatch, getAuthenticatedUserId, isAuthenticated, isRole } from 'functions/AuthFunctions';
import type { UserData, UserFeedProps, UserTableRowProps } from 'types/UserSectionTypes';

class UserSection extends React.Component<SectionListProps> {
  render() {
   // console.log(getRole('admin'))
    const match: MatchType = this.props.match;
    if (
      !isRole('admin') &&
      isAuthenticated() &&
      this.props.location.pathname !== `/user/edit/${getAuthenticatedUserId()}`
    ) {
      return <Redirect to={`/user/edit/${getAuthenticatedUserId()}`} />;
    }

    const ModalRoutes = () => {
      return (
        <React.Fragment>
          <Route
            exact
            path={`${match && match.path ? match.path : ''}/add`}
            render={(props: SectionListProps) => <NewUserModal {...props} />}
          />

          <Route
            exact
            path={`${match && match.path ? match.path : ''}/edit/:id`}
            render={(props: SectionListProps) => (
              <EditUserModal
                {...props}
                user={{
                  id: props.match.params && typeof props.match.params.id === 'string' ? props.match.params.id : ''
                }}
              />
            )}
          />

          <Route
            exact
            path={`${match.path}/delete/:id`}
            render={(props: SectionListProps) => (
              <DeleteUserModal
                {...props}
                user={{
                  id: props.match.params && typeof props.match.params.id === 'string' ? props.match.params.id : ''
                }}
              />
            )}
          />
        </React.Fragment>
      );
    };

    // This action (Adding a user) is ONLY available to users with is_admin set to true.
    const buttonLinks: Array<ActionButtonLink> | boolean = isRole('admin')
      ? [
          {
            uri: '/user/add',
            id: 'addUserLink',
            text: 'Create User',
            icon: 'fas fa-plus'
          }
        ]
      : false;

    // Pass all the things to content wrapper.
    const sectionData: SectionDataType = {
      heading: 'User Management',
      content: <UserListContent location={this.props.location} />,
      actions: buttonLinks ? <SectionActionButtons links={buttonLinks} /> : false,
      modals: <ModalRoutes />
    };

     return (
       <React.Fragment>
        <ContentSection data={sectionData} history={this.props.history} />
      </React.Fragment>
     );
  }
}

/**
 * Component to handle entire table.
 */
class UserListContent extends React.Component<LocationOnlyProps> {
  render() {
    return (
      <div className="group-landing--wrapper">
        <div className="table-responsive">
          <UsersQuery location={this.props.location} />
        </div>
      </div>
    );
  }
}

/**
 * Default class to handle returning the formatted list of results.
 */
class UsersQuery extends React.Component<{}> {
  render() {
    return (
      <Query query={userListQuery} pollInterval={0} notifyOnNetworkStatusChange fetchPolicy="cache-and-network">
        {({ variables, loading, data, fetchMore, networkStatus }) => {
          // Skip loading icon/flash when we are polling for new results.
          if (loading && networkStatus !== 6 && networkStatus !== 3) {
            // Handle loading screen.
            const row = {
              classes: 'loading',
              content: <i className="fal fa-spinner" />
            };
            return (
              <SectionTable
                render={() => (
                  <tbody>
                    <tr>
                      <td className={row.classes}>{row.content}</td>
                    </tr>
                  </tbody>
                )}
              />
            );
          }

          const users: [UserData] | boolean = data && data.users ? data.users : false;

          if (users && users.length === 0 && !loading) {
            // Handle a zero result set.
            const errorContent = (
              <div className="text-center">
                <h4 className="text-warning">No Results Found.</h4>
                <h2 className="text-warning">¯\_(ツ)_/¯</h2>
              </div>
            );
            return <SectionError color="warning" classes="" content={errorContent} />;
          }

          if (!data && !loading) {
            // We've encountered a error connecting to GraphQL server.
            // We should on this account, disable the Create Specialty button.
            // @todo: Should be an easier way to detect an epic failure to connect.
            const button = document.getElementById('addUserLink');
            if (typeof button !== 'undefined' && button !== null && button instanceof HTMLButtonElement) {
              button.classList.add('disabled');
              button.disabled = true;
            }
            return (
              <Alert color="danger" className={`error--graphql-connection`}>
                <div className={`text-center`}>
                  <h4 className="text-error">GraphQL connection failure.</h4>
                  <h2 className="text-error">¯\_(ツ)_/¯</h2>
                </div>
              </Alert>
            );
          }

          // Pagination issues.
          // @see https://github.com/apollographql/apollo-client/issues/2499
          return <UserFeed users={users || []} />;
        }}
      </Query>
    );
  }
}

class UserFeed extends React.Component<UserFeedProps> {
  render() {
    if (this.props.users && this.props.users.length) {
      // Friendly method to display the text "Showing X-Y of Z total results.
      const fromItems = 1;
      const toItems = this.props.users.length;

      // Compile all the table rows together.
      const FeedRows = () => {
        return this.props.users.map((user, id) => <UserTableRow row={user} key={id} />);
      };

      // Primary return without errors or oddities.
      return (
        <React.Fragment>
          <h4>
            Showing{' '}
            <strong>
              {fromItems} - {toItems}
            </strong>{' '}
            of <strong>{toItems}</strong> users.
          </h4>
          <SectionTable
            render={() => (
              <React.Fragment>
                <thead className="thead-light">
                  <UserTableHeaderRow />
                </thead>
                <tbody className="result-set">
                  <FeedRows />
                </tbody>
              </React.Fragment>
            )}
          />
        </React.Fragment>
      );
    }

    // Last case error that something went terribly wrong.
    const errorContent = (
      <div>
        <h4 className="text-danger">Unknown error occurred.</h4>
        <h2 className="text-danger">¯\_(ツ)_/¯</h2>
      </div>
    );
    return <SectionError color="danger" classes="" content={errorContent} />;
  }
}

/**
 * Component to handle single table row of results.
 */

class UserTableRow extends React.Component<UserTableRowProps> {
  render() {
    
    const user = this.props.row;
    
    const status = user.status ? <Badge color="success">enabled</Badge> : <Badge color="warning">disabled</Badge>;

    const account_type = typeof user.role[0] !== 'undefined' && user.role[0].item_name == 'admin' ? (
      <Badge color="success" className={`ml-2`}>
        admin
      </Badge>
    ) : (
      <Badge color="info" className={`ml-2`}>
        {user.role[0].item_name}
      </Badge>
    );

    // Admin or current user sees a link, otherise, plain text.
    // @todo: This would be better done using id matching rather than username.
    const TextLink =
      isRole('admin') || doUsernamesMatch(user.username) ? (
        <Link to={`/user/edit/${user.id}`} title={`Edit ${user.username}`}>
          {user.username}
        </Link>
      ) : (
        user.username
      );

    // Only admin or current user can see edit button.
    const EditLink =
      isRole('admin') || doUsernamesMatch(user.username) ? (
        <React.Fragment>
          <Link to={`/user/edit/${user.id}`} title={`Edit ${user.username}`} className="btn btn-edit">
            <i className="fas fa-edit" />
          </Link>
        </React.Fragment>
      ) : null;

    // Only admin can see delete link.
    const DeleteLink = isRole('admin') ? (
      <React.Fragment>
        <Link to={`/user/delete/${user.id}`} title={`Delete ${user.username}`} className="btn btn-delete">
          <i className="fas fa-times" />
        </Link>
      </React.Fragment>
    ) : null;

    if (isRole('admin') || doUsernamesMatch(user.username)) {
      return (
        <tr id={user.id} className={`result-set--row`}>
          <th className="main-cell term" scope="row">
            <div className={``}>{TextLink}</div>
          </th>
          <td className="status d-none d-lg-table-cell text-right">{status}</td>
          <td className="account-type d-none d-lg-table-cell text-right">{account_type}</td>
          <td className="actions">
            {EditLink}
            {DeleteLink}
          </td>
        </tr>
      );
    } else {
      return null;
    }
  }
}

/**
 * Component to handle table header for CFT results.
 */
class UserTableHeaderRow extends React.Component<{}> {
  render() {
    return (
      <tr>
        <th scope="col">
          <div className="sorting-cell">
            <span className="col-label">Username</span>
          </div>
        </th>
        <th className="actions text-right" scope="col">
          <span className="col-label">User Status</span>
        </th>
        <th className="actions text-right" scope="col">
          <span className="col-label">Account Type</span>
        </th>
        <th className="actions" scope="col">
          <span className="col-label">Actions</span>
        </th>
      </tr>
    );
  }
}

/**
 * Return the SpecialtySection component as the default.
 */
export default UserSection;
