/* @flow */
/* eslint-disable no-unused-vars */
import React from 'react';
import ReactDOM from 'react-dom';
import { Alert, Badge, Button, Card, CardHeader, CardBody, Form, FormGroup, Label, Input, FormText } from 'reactstrap';
import { Danger } from 'shared/messaging';
import { Info } from 'shared/icons';
import Select from 'react-select';

import {
  cftInfoQuery,
  cftUniqueSlugQuery,
  associatePhysicianWithTermMutation,
  associateSpecialtyWithTermMutation,
  associateSpecialtyWithTermMasterMutation,
  removePhysicianFromTermMutation,
  removeSpecialtyFromTermMutation,
  removeSpecialtyFromTermMasterMutation,
  updateCftMutation,
  insertCftMutation
} from 'clinicalFocusTerm/queries';


import { searchProviderQuery } from 'provider/queries';
import { searchSpecialtyQuery } from 'specialty/queries';

import { Query, Mutation } from 'react-apollo';
import { SystemAlert } from 'elements/SystemAlert';
import { modalClose, modalHide } from 'elements/ModalSection';

import { Link, Redirect } from 'react-router-dom';
import type { EditCftProps, EditCftState, UmlsTerm } from 'clinicalFocusTerm/types';

import AssociationForm from 'elements/AssociationForm';
import { AssociationInput } from 'shared/inputs/association';
import MachineName, { uniqueSlugCheck , formatSlug} from 'shared/inputs/MachineName';
import UmlsSearch from 'elements/UmlsSearch';
import SynonymInput from 'synonym/inputs/SynonymInput';

export class CftEditForm extends React.Component<EditCftProps, EditCftState> {
  typeOptions: {
    value: string,
    label: string
  }[];
  constructor(props: EditCftProps) {
    super(props);
    this.state = {
      formChanged: false,
      formSubmitted: false,
      deleteRequest: false,
      deleteTermSlug: false,
      newTerm: typeof this.props.newTerm !== 'undefined' && this.props.newTerm === true,
      umls: false,
      custom: false,
      prevSearch: {
        filter: this.props.search && this.props.search.filter ? this.props.search.filter : '',
        offset: this.props.search && this.props.search.offset ? this.props.search.offset : 0,
        limit: this.props.search && this.props.search.limit ? this.props.search.limit : 100,
        order:
          this.props.search && this.props.search.order
            ? this.props.search.order
            : {
                sortField: '',
                sortDirection: ''
              }
      },
      submitReady: false,
      specialties: [],
      specialtiesMaster: [],
      providers: [],
      synonyms: {}
    };

    // Array of "Type" objects.
    // @todo: Types may need to be converted to GraphQL schema if we want to avoid hard coding them.
    this.typeOptions = [{ value: 'treatment', label: 'Treatment' }, { value: 'condition', label: 'Condition' }];

    this.createCustom = this.createCustom.bind(this);
    this.handleFormChange = this.handleFormChange.bind(this);
    this.handleFormSaved = this.handleFormSaved.bind(this);
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.handleDeleteSubmit = this.handleDeleteSubmit.bind(this);
    this.handleUmlsSelected = this.handleUmlsSelected.bind(this);
  }

  createCustom = () => {
    this.setState(() => ({
      custom: true
    }));
  };

  handleFormChange = () => {
    this.setState(() => ({
      formChanged: true
    }));

    setTimeout(() => {
      this.readySubmit();
    }, 250);
  };

  handleFormSaved = () => {
    this.setState(() => ({
      formChanged: false
    }));
  };

  handleDeleteSubmit = slug => {
    modalHide();

    // Wait a tick and change the state, which will open the delete modal.
    setTimeout(() => {
      this.setState(() => ({
        deleteRequest: true,
        deleteTermSlug: slug
      }));
    }, 500);
  };

  handleUmlsSelected = (term: UmlsTerm) => {
    this.handleFormChange();
   
    this.setState(() => ({
      umls: term
    }));
  };

  /**
   * @todo: Handle sanitizing bogus input. #TRUSTNOONE
   */
  handleFormSubmit = () => {
    const status = document && document.querySelector('input[name="termPublishedStatus"]:checked');
    const term = document && document.getElementById('termName');
    const slug = document && document.getElementById('termName');
    const type = document && document.getElementsByName('termType')[0];
    const cuid = document && document.getElementById('cuidValue');
    const termStatusString =
      typeof status !== 'undefined' && status !== null && status instanceof HTMLInputElement
        ? status.value
        : 'disabled';
    const termStatus = termStatusString === 'enabled'; // Returns true or false;
    const termName: string =
      typeof term !== 'undefined' && term !== null && term instanceof HTMLInputElement ? term.value : '';
    const termType: string | null =
      typeof type !== 'undefined' && type !== null && type instanceof HTMLInputElement && type.value.length >= 1
        ? type.value
        : null;
    const termSlug: string =
      typeof slug !== 'undefined' && slug !== null && slug instanceof HTMLInputElement ? slug.value.toLowerCase()
      .trim() // Trim leading and trailing spaces
      .replace(/\s+/g, '-') // Replace spaces with -
      .replace(/[^\w-]+/g, '') // Remove all non-word chars
      .replace(/--+/g, '-') : '';
    const cuidValue: string | null =
      typeof cuid !== 'undefined' && cuid !== null && cuid instanceof HTMLInputElement && cuid.value.length >= 1
        ? cuid.value
        : null;

    const submittedData: {
      name: string,
      slug: string,
      taxonomy?: string,
      type: string,
      status: boolean,
      umls_cuid?: string
    } = {
      name: termName,
      slug: termSlug,
      type: cuidValue ? 'UMLS' : 'CFT',
      status: termStatus
    };

    if (termType) {
      submittedData.taxonomy = termType.toUpperCase();
    }

    if (cuidValue) {
      submittedData.umls_cuid = cuidValue;
    }

    return submittedData;
  };

  /**
   * Function handles determining if the form is ready to submit.
   */
  readySubmit = () => {
    const data = this.handleFormSubmit();
    if (data.name.length >= 1) {
      this.setState(() => ({
        submitReady: true
      }));
    } else {
      this.setState(() => ({
        submitReady: false
      }));
    }
  };

  render() {
    const { primarySynonym } = this.props;
    const { synonyms } = this.state;
    // const synonymQuery = useQuery(getCFTSynonyms, {
    //   variables: { cid: cft.id}
    // })
    if (this.state.deleteRequest === true && typeof this.state.deleteTermSlug === 'string') {
      return <Redirect to={`/cft/delete/${this.state.deleteTermSlug}`} />;
    }

    if (this.state.newTerm && this.state.umls === false && this.state.custom === false) {
      return (
        <React.Fragment>
          <Alert color="info" className={`mb-3`}>
            <div className="alert--with-icon">
              <i className="alert-icon fal fa-info-circle" />
              <div>
                <span>
                  To avoid term duplication, please search for the <strong>Clinical Focus Term</strong> you would like
                  to create. We will query the UMLS database, and show results while highlighting any terms that may
                  already exist in the CFT database.
                </span>
              </div>
            </div>
          </Alert>
          <UmlsSearch termSelected={this.handleUmlsSelected} />
          <Alert color="warning" className={`mt-3 mb-0`}>
            <div className="alert--with-icon">
              <i className="alert-icon fal fa-info-circle" />
              <div>
                <span className={`mr-2`}>
                  If you are sure the term you are creating is NOT in the UMLS database, you may
                </span>
                <a
                  className={`custom-cft-button text-primary`}
                  onClick={() => {
                    this.createCustom();
                  }}
                >
                  <i className="fas fa-plus mr-1" />
                  Create a Custom Term
                </a>
              </div>
            </div>
          </Alert>
        </React.Fragment>
      );
    }

    return (
      <Query
        query={cftInfoQuery}
        pollInterval={0}
        notifyOnNetworkStatusChange={true}
        variables={{
          slug: this.props.cft && this.props.cft.slug ? this.props.cft.slug : 'nothingtoseehere'
        }}
        fetchPolicy="cache-and-network"
      >
        {({ data, loading }) => {
          if (loading) return null;
          const cft =
            data && data.cftTerm
              ? data.cftTerm
              : {
                  // Set the default form values.
                  id: '',
                  taxonomy: '',
                  slug: '',
                  name:
                    this.state.newTerm && typeof this.state.umls === 'object' && this.state.umls.name
                      ? this.state.umls.name
                      : '',
                  status: false,
                  specialties: [],
                  specialtiesMaster: [],
                  providers: [],
                  umls_cuid:
                    this.state.newTerm && typeof this.state.umls === 'object' && this.state.umls.cuid
                      ? this.state.umls.cuid
                      : '',
                  type: 'cft'
                };

          // Figure out the object that matches the type.
          // We use this in the <Select/> item to preselect the appropriate value.
          const typeValue =
            cft && cft.taxonomy ? this.typeOptions.find(taxonomy => taxonomy.value === cft.taxonomy) : null;

          // As the API doesn't have a 'true' upsert query, we determine
          // which GraphQL query we want to use here.
       
          const upsertMutation = cft.id ? updateCftMutation : insertCftMutation;

          return (
            <React.Fragment>
              <Alert color="warning" className={`mb-3 ${this.state.formChanged ? 'd-block' : 'd-none'}`}>
                <div className="alert--with-icon">
                  <i className="alert-icon fal fa-info-circle" />
                  <span>
                    The form has been updated. Use the <strong>Save Term</strong> button to avoid losing any changes.
                  </span>
                </div>
              </Alert>

              <Mutation mutation={upsertMutation}>
                {(upsertCft, { loading, error }) => {
                  if (loading) {
                    return null;
                  }
                  if (error) {
                    console.log(`ERROR UPSERTING CFT...`);
                  }

                  return (
                    <Form
                      onLoad={() => {
                        setTimeout(() => {
                          this.readySubmit();
                        }, 250);
                      }}
                      onChange={() => {
                        this.handleFormChange();
                      }}
                      onSubmit={(e: Event) => {
                        e.preventDefault();
                        const cftData = this.handleFormSubmit();
                       
                        if (cft && cft.id) {
                          // We have an ID, so this is an existing CFT, so
                          // we will update that CFT now.
                          upsertCft({
                            variables: {
                              input: {
                                termId: cft.id,
                                update: cftData
                              }
                            }
                          });
                        } else {
                          // This looks like a newly created CFT, so we will
                          // insert the new CFT now.
                          upsertCft({
                            variables: {
                              input: cftData
                            }
                          });
                        }

                        // Send a message to the screen.
                        const NotifyUpserted = () => {
                          return (
                            <SystemAlert
                              autoClose
                              color="success"
                              icon="alert-icon fal fa-check-circle"
                              messageData={{
                                action: cft.id ? 'Updated' : 'Created',
                                item: cftData.name,
                                id: cft.id,
                                tail: '...'
                              }}
                            />
                          );
                        };

                        const alert = document && document.getElementById('system-alert-wrapper');
                        if (alert) {
                          ReactDOM.render(<NotifyUpserted />, alert);
                          modalClose(this.props.history);
                        }
                      }}
                    >
                      <FormGroup>
                        <React.Fragment>
                          <Input type="hidden" name="id" id="termId" defaultValue={cft && cft.id ? cft.id : ''} />
                          <MachineName
                            isUniqueCallback={(slug: string) => {
                              return uniqueSlugCheck(slug, cftUniqueSlugQuery, 'cftTerm', 'saveCftButton');
                            }}
                            // The field that is used to store the final version before submission.
                            // The field that also allows you to customize the slug.
                            target={{
                              // What this type of field is called: Machine Name/System Name/Slug
                              label: 'Machine Name',
                              id: 'termSlug',
                              name: 'custom',
                              defaultValue: cft && cft.slug ? cft.slug : ''
                            }}
                            // The source field for the generated slug.
                            source={{
                              label: 'Term Name',
                              id: 'termName',
                              name: 'name',
                              placeholder: 'Enter a descriptive term name...',
                              defaultValue: cft && cft.name ? cft.name : ''
                            }}
                            // If existing elements (based on source.defaultValue) should be
                            // automatically updated. Otherwise, a saved/preexisting value would not
                            // be updated immediately and would require manual (thoughtful) editing.
                            autoChangeOnExisting={false}
                            // Callback (from parent(s)) to be fired
                            changeCallback={this.handleFormChange}
                            existing={!!(cft && cft.id)}
                          />
                        </React.Fragment>
                        <hr />
                      </FormGroup>

                      <FormGroup className={cft.id || cft.umls_cuid !== '' ? '' : 'd-none'}>
                        <Label for="cuidValue">UMLS CUID</Label>
                        <Input
                          type="text"
                          defaultValue={cft.umls_cuid}
                          autoComplete="off"
                          name="umls_cuid"
                          id="cuidValue"
                          disabled
                        />
                        <FormText color="muted" className={`${cft.umls_cuid ? 'd-none' : 'd-block'}`}>
                          This <strong>Clinical Focus Term</strong> is <strong>not</strong> associated with a UMLS term.
                          In order to associate this term with a UMLS term, it is advised to{' '}
                          <Link to={`/cft/delete/${cft.slug}`} className={`text-danger`}>
                            <strong>delete</strong>
                          </Link>{' '}
                          this custom version, and create the term from scratch. This allows the system to search for
                          and create any synonym associations provided by the UMLS database.
                        </FormText>
                        <hr />
                      </FormGroup>

                      <FormGroup>
                        <Label for="termType">Term Type</Label>
                        <Select
                          id="termType"
                          name="termType"
                          options={this.typeOptions}
                          onChange={this.handleFormChange}
                          defaultValue={typeValue}
                          placeholder="Select term type..."
                        />
                        <hr />
                      </FormGroup>
                      {cft.id !== "" && <SynonymInput term={cft} />}
                   
                      <FormGroup className={cft.id !== "" ? '' : 'd-none'}>
                        <AssociationForm
                          searchQuery={searchSpecialtyQuery}
                          deleteMutation={removeSpecialtyFromTermMutation}
                          associateMutation={associateSpecialtyWithTermMutation}
                          mutationVariables={{
                            termId: cft.id
                          }}
                          refetch={cftInfoQuery}
                          refetchVars={{ slug: cft.slug }}
                          label={`Specialties (Inherited Term Lists)`}
                          slug={`specialties`}
                          gqlFilterVar={`specialty`}
                          gqlFirstVar={100}
                          elementField={`name`}
                          elements={cft.specialties}
                          callbacks={{
                            handleFormChange: this.handleFormChange,
                            handleFormSaved: this.handleFormSaved
                          }}
                        />
                        <hr />
                      </FormGroup>

                      <FormGroup className={cft.id !== "" ? '' : 'd-none'}>
                        <AssociationForm
                          searchQuery={searchSpecialtyQuery}
                          deleteMutation={removeSpecialtyFromTermMasterMutation}
                          associateMutation={associateSpecialtyWithTermMasterMutation}
                          mutationVariables={{
                            termId: cft.id
                          }}
                          refetch={cftInfoQuery}
                          refetchVars={{ slug: cft.slug }}
                          label={`Specialties (Specialty Term Lists)`}
                          id= {`specialtiesMaster`}
                          slug={`specialties`}
                          gqlFilterVar={`specialty`}
                          gqlFirstVar={100}
                          elementField={`name`}
                          elements={cft.specialtiesMaster}
                          callbacks={{
                            handleFormChange: this.handleFormChange,
                            handleFormSaved: this.handleFormSaved
                          }}
                        />
                        <hr />
                      </FormGroup>

                      <FormGroup className={cft.id !== "" ? '' : 'd-none'}>
                        <AssociationForm
                          searchQuery={searchProviderQuery}
                          deleteMutation={removePhysicianFromTermMutation}
                          associateMutation={associatePhysicianWithTermMutation}
                          mutationVariables={{
                            termId: cft.id
                          }}
                          refetch={cftInfoQuery}
                          refetchVars={{ slug: cft.slug }}
                          label={`Physicians`}
                          slug={`providers`}
                          gqlFilterVar={`provider`}
                          gqlFirstVar={200}
                          elementField={`fullName`}
                          elements={cft.providers.sort((a,b) => (a.lastName > b.lastName) ? 1 : ((b.lastName > a.lastName) ? -1 : 0))}
                          callbacks={{
                            handleFormChange: this.handleFormChange,
                            handleFormSaved: this.handleFormSaved
                          }}
                        />
                        <hr />
                      </FormGroup>

                      <FormGroup>
                        <legend>Publishing Status</legend>
                        <div className="form-radio-group">
                          <FormGroup check>
                            <Label check>
                              <Input
                                type="radio"
                                name="termPublishedStatus"
                                value="enabled"
                                defaultChecked={cft.status ? 'checked' : false}
                              />{' '}
                              <span className="text-success">Enabled</span>
                            </Label>
                          </FormGroup>
                          <FormGroup check>
                            <Label check>
                              <Input
                                type="radio"
                                name="termPublishedStatus"
                                value="disabled"
                                defaultChecked={cft.status ? false : 'checked'}
                              />{' '}
                              <span className="text-warning">Disabled</span>
                            </Label>
                          </FormGroup>
                        </div>
                        <FormText color="muted">
                          <i className="fal fa-info-circle" /> By default the status of a new CFT term is set to{' '}
                          <strong>
                            <span className="text-warning">Disabled</span>
                          </strong>
                          . Set the status to{' '}
                          <strong>
                            <span className="text-success">Enabled</span>
                          </strong>{' '}
                          to activate this term.
                        </FormText>
                        <hr />
                      </FormGroup>

                      <FormGroup className="mt-2 form-actions d-flex justify-content-between align-items-end">
                        <Button
                          id="saveCftButton"
                          type="submit"
                          size="md"
                          color="primary"
                          title="Save"
                          disabled={!(this.state.submitReady && this.state.formChanged)}
                        >
                          <i className="fal fa-save" />
                          <span>Save Term</span>
                        </Button>
                        <span className={cft.id ? 'd-inline-block' : 'd-none'}>
                          <Button
                            outline
                            size="md"
                            color="danger"
                            title={`Delete ${cft.name}`}
                            onClick={() => {
                              this.handleDeleteSubmit(cft.slug);
                            }}
                          >
                            <i className="fal fa-trash-alt" />
                            <span>Delete Term</span>
                          </Button>
                        </span>
                      </FormGroup>
                    </Form>
                  );
                }}
              </Mutation>
            </React.Fragment>
          );
        }}
      </Query>
    );
  }
}
