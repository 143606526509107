/* @flow */
/* eslint no-unused-vars: "off" */
import * as React from 'react';

import ModalSection, { modalClose, modalHide } from 'elements/ModalSection';
import { TopSepcialtyEditForm } from 'topspecialties/forms';


export class NewTopSpecialtyModal extends React.Component<AddCftProps> {
  componentDidMount() {
    setTimeout(() => {
      ModalSection.openModal();
    }, 100);
  }

  render() {
    const emptyCft = {
      slug: '0'
    };
    console.log(this.props)
    return (
      <ModalSection
        heading="Add Specialty To Most Searched List"
        content={
          <TopSepcialtyEditForm
            history={this.props.history}
            match={this.props.match}
            cft={emptyCft}
            search={this.props.search}
            newTerm
          />
        }
        actions={false}
        history={this.props.history}
      />
    );
  }
}
