/* @flow */
import React from 'react';
import { Association } from './';
import type { ProviderAssociationPackage } from 'provider/types';

type Props = {
  callbacks: {
    associate: (element: ProviderAssociationPackage, slug: string) => void
  },
  remove: {
    associate: (element: ProviderAssociationPackage, slug: string) => void
  },
  children: string,
  removed: Object,
  slug: string
};

const AssociationRemoved = (props: Props) => {
  
  if(props.callbacks.associate){
  const {
    callbacks: { associate },
    removed
  } = props;
  return <Association {...props} action={associate} association={removed} />;
}
else{
  const {
    remove: { associate },
    removed
  } = props;
  return <Association {...props} action={associate} association={removed} />;
}
};

AssociationRemoved.defaultProps = {
  colors: {
    badge: 'danger',
    button: 'danger'
  },
  icon: 'fa-plus'
};

export default AssociationRemoved;
