import React, { useContext } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { Badge, Button } from 'reactstrap';

import { Close, Plus } from 'shared/icons';
import { ExcludedTerms } from 'excludedTerm/container';
import { changeSpecialtyTerm } from 'excludedTerm/actions';
import { MUTATE_SPECIALTY_TERM } from 'excludedTerm/queries';

const ExcludeableBadge = ({ className, children, excluded, provider, term }) => {
  const { refetch } = useContext(ExcludedTerms);
  const [excludeTerm] = useMutation(MUTATE_SPECIALTY_TERM);
  return (
    <Badge className={`linked-reference-item${className ? ` ${className}` : ''}`} color="tertiary">
      {children}
      {!term.isExcluded && (
        <Button
          color="danger"
          onClick={changeSpecialtyTerm(
            {
              variables: {
                termIDs: term.node.id,
                providerID: provider.id,
                action: 'EXCLUDE'
              }
            },
            excludeTerm,
            refetch
          )}
        >
          <Close />
        </Button>
      )}
      {term.isExcluded && (
        <Button
          color="success"
          onClick={changeSpecialtyTerm(
            {
              variables: {
                termIDs: term.node.id,
                providerID: provider.id,
                action: 'INCLUDE'
              }
            },
            excludeTerm,
            refetch
          )}
        >
          <Plus />
        </Button>
      )}
    </Badge>
  );
};

export default ExcludeableBadge;
