/* @flow */
import * as React from 'react';

import ContentSection from 'elements/ContentSection';
import { Query } from 'react-apollo';
import type {
  AuditQueryProps,
  AuditContentProps,

} from 'audit/types';
import type { SectionDataType, SectionProps, SectionListProps, SharedSectionCallbacks, } from 'shared/types';
import { DeleteUserModal, EditUserModal, NewUserModal, EmailsCopyModal, BulkRemoveEmailModal, BulkAddEmailModal } from './EmailForms';
import ReactPaginate from "react-paginate";
import SectionTable from 'elements/SectionTable';
import SectionError from 'elements/SectionError';
import { emailListQuery } from './queries';
import { Link, Route } from 'react-router-dom';
import { Alert, Button, Input } from 'reactstrap';
import SectionActionButtons from 'elements/SectionActionButtons';
import SectionSearchField from 'elements/SectionSearchField';
import { doUsernamesMatch,  getPermissions } from 'functions/AuthFunctions';
import { createBrowserHistory } from "history";
import qs from "qs";


const EmailSectionContext = React.createContext();
var allCheckBoxesIds = [];

const history = createBrowserHistory();
const isValidEmail = (email) => {
  // Regular expression pattern for basic email validation
  const emailPattern = /^[a-zA-Z0-9._%+-]+@northwell\.edu$/;
  return emailPattern.test(email);
};
let currentUrlParams = new URLSearchParams(window.location.search);
class EmailSection extends React.Component<SectionProps> {
  callbacks: SharedSectionCallbacks;
  constructor(props: SectionListProps) {
    super(props);
    this.state = {
      filter: '',
      afterCurser: '',
      order: {
        sortField: 'FULLNAME_FIRST_LAST',
        sortDirection: 'ASC'
      },
      limit: 25,
      offset: 0,
      pageNumber: 0,
      rowCopied: [],
      rowsSelected: [],
      selectAll: false
    };
    this.callbacks = {
      handleChange: this.handleChange,
      handleKeyDown: this.handleKeyDown,
      clearFilter: this.clearFilter,
      updateSort: this.updateSort,
      updateFirst: this.updateFirst,
      handlePage: this.handlePage,
      handleCopyEmails: this.handleCopyEmails,
      handleBulkRemoveEmails: this.handleBulkRemoveEmails,   
    };
    this.pagehandler = {
      handlePage: this.handlePage
    }
  };

  UNSAFE_componentWillMount() {
    this.timer = null;
  }

  componentDidMount() {
    const filterParams = history.location.search.substr(1);
    const filtersFromParams = qs.parse(filterParams);
    if (filtersFromParams.search) {

      this.setState(() => ({
        filter: filtersFromParams.search
      }));
    }
    if (filtersFromParams.page) {

      this.setState(() => ({
        pageNumber: filtersFromParams.page
      }));
    }
    if (filtersFromParams.curser) {

      this.setState(() => ({
        afterCurser: filtersFromParams.curser
      }));
    }
  }

  shouldComponentUpdate(_nextProps, nextState) {
    // Compare the next state with the current state
    if (this.state.rowCopied !== nextState.rowCopied && nextState.rowCopied != null) {
      return false;
    }
    if (this.state.rowsSelected !== nextState.rowsSelected && nextState.rowsSelected != null) {
      return false;
    }
    return true; // Allow re-render for other state changes
  }

  reFocus = (target: HTMLInputElement) => {
    const elemId = target.getAttribute('id');
    const element = document && elemId && document.getElementById(elemId);
    if (typeof element !== 'undefined' && element !== null && element instanceof HTMLInputElement) {
      element.focus();
    }
  };

  updateSort = (field: string, direction: string) => {
    const sortField = field ? field : this.state.order.sortField;
    const sortDirection = direction ? direction : this.state.order.sortDirection;

    this.setState(() => ({
      order: {
        sortField: sortField,
        sortDirection: sortDirection
      }
    }));
  };

  clearFilter = () => {
    this.setState(() => ({
      filter: ''
    }));

    history.replace(``);
  };



  updateFirst = (n: number) => {
    this.setState(() => ({
      limit: n
    }));
  };

  handleCopyEmails = (row)=>{
      this.setState(() =>({
        rowCopied: row
      }));
  }

  handleBulkRemoveEmails = (id=null, isChecked=null)=>{
    //id =null for parent select all click
    if(id === null){
      if(isChecked){ 
        
        document.querySelectorAll('input[name="remove-email[]"]').forEach(checkbox => {
          checkbox.checked = true;
        });
        this.setState(() => ({
          rowsSelected: []
        }));
        this.setState(() => ({
          rowsSelected: allCheckBoxesIds
        }));
      }else {
        document.querySelectorAll('input[name="remove-email[]"]').forEach(checkbox => {
          checkbox.checked = false;
        });
        this.setState(() => ({
          rowsSelected: []
        }));
      }
    }else{
      if(isChecked){   
        if(((this.state.rowsSelected.length+1) === allCheckBoxesIds.length) && allCheckBoxesIds.length > 0){
          document.querySelector('input[name="select-all"]').checked = true;
        }
        this.setState(prevState => ({
          rowsSelected: Array.from(new Set([...prevState.rowsSelected, id]))
        }));
      }else{
        document.querySelector('input[name="select-all"]').checked = false;
        this.setState(prevState => ({
          rowsSelected: prevState.rowsSelected.filter(selectedId => selectedId !== id)
        }));
      }
    }
  }

  handleChange = ({ target }: { target: HTMLInputElement }) => {

    clearTimeout(this.timer);
    this.timer = setTimeout(
      function doChange() {
        this.triggerChange(target);
      }.bind(this),
      this.waitInterval
    );
  };

  handleKeyDown = (e: KeyboardEvent) => {
    if (e.key === this.enterKey) {
      const target = e.target;
      if (target instanceof HTMLInputElement) {
        const attribute: string | null | void = target.getAttribute('id');
        const elemId: string = attribute && typeof attribute === 'string' ? attribute : '';
        const element: HTMLElement | null = document.getElementById(elemId);
        if (typeof element !== 'undefined' && element !== null && element instanceof HTMLInputElement) {
          this.triggerChange(target);
        }
      }
    }
  };

  triggerChange = (target: HTMLInputElement) => {
    this.setState(() => ({
      filter: target.value ? target.value : '',
      pageNumber: 0
    }));
    if (target.value) {
      this.reFocus(target);
    }
    history.push(`?search=${target.value}`);
  };
  handlePage = (id, page) => {


    this.setState(() => ({
      afterCurser: id,
      pageNumber: page
    }));

    history.push(`?page=${page}&curser=${id}`);
    //window.location = window.location.pathname +"?page="+ page
    //this.props.propcallbacks.handleChange();
  };


  render() {
    const match: MatchType = this.props.match;
    const sharedSearchData = {
      filter: this.state.filter,
      order: {
        sortField: this.state.order.sortField,
        sortDirection: this.state.order.sortDirection
      },
      limit: this.state.limit,
      offset: this.state.offset
    };
    

    const ModalRoutes = () => {
      const bulkRemoveIds =  this.state.rowsSelected;
      const searchedEmail = this.state.filter;
     
      this.setState(prevState => ({
        rowsSelected: []
      }));

      return (

        <React.Fragment>

          <Route
            exact
            path={`${match && match.path ? match.path : ''}/add`}
            render={(props: SectionListProps) => <NewUserModal {...props} search={sharedSearchData} />}
          />
          <Route
            exact
            path={`${match && match.path ? match.path : ''}/bulk-add`}
            render={(props: SectionListProps) => <BulkAddEmailModal {...props} search={sharedSearchData} 
            user={{
              id: props.match.params && typeof props.match.params.id === 'string' ? props.match.params.id : '',
              bulkRemoveIds:bulkRemoveIds,
              bulkRemoveEmail:this.state.filter
            }}  />}
          />
          <Route
            exact
            path={`${match && match.path ? match.path : ''}/remove`}
            render={(props: SectionListProps) => <BulkRemoveEmailModal {...props} search={sharedSearchData} 
            user={{
              id: props.match.params && typeof props.match.params.id === 'string' ? props.match.params.id : '',
              bulkRemoveIds:bulkRemoveIds,
              bulkRemoveEmail:this.state.filter
            }}  />}
          />

          <Route
            exact
            path={`${match && match.path ? match.path : ''}/copy/:id`}
            render={(props: SectionListProps) => 
              <EmailsCopyModal
              {...props}

              user={{
                id: props.match.params && typeof props.match.params.id === 'string' ? props.match.params.id : '',
                rowData:this.state.rowCopied
              }}
            />
            }
          />

          <Route
            exact
            path={`${match && match.path ? match.path : ''}/edit/:id`}
            render={(props: SectionListProps) => (
              <EditUserModal
                {...props}

                user={{
                  id: props.match.params && typeof props.match.params.id === 'string' ? props.match.params.id : ''
                }}
              />
            )}
          />

          <Route
            exact
            path={`${match.path}/delete/:id`}
            render={(props: SectionListProps) => (
              <DeleteUserModal
                {...props}
                user={{
                  id: props.match.params && typeof props.match.params.id === 'string' ? props.match.params.id : ''
                }}
              />
            )}
          />
        </React.Fragment>
      );
    };
    
    // This action (Adding a user) is ONLY available to users with is_admin set to true.
    const buttonLinks: Array<ActionButtonLink> | boolean = getPermissions('emails')
      ? [
        {
          uri: '/email/add',
          id: 'addUserLink',
          text: 'Add Practice',
          icon: 'fas fa-plus'
        },
        {
          uri: '/email/bulk-add',
          id: 'addBulkEmailLink',
          text: 'Bulk Add Email',
          icon: 'fas fa-plus'
        },
        {
          uri: '/email/remove',
          id: 'removeEmailLink',
          text: 'Bulk Remove Email',
          icon: '',
          visible: isValidEmail(this.state.filter)
        }
        ]
      : false;

    const sectionData: SectionDataType = {
      heading: 'Practice Appointment Emails',
      content: <AuditListContent
        callbacks={this.callbacks}
        page={this.pagehandler}
        pageNumber={this.state.pageNumber}
        data={{
          filter: this.state.filter,
          order: {
            sortField: this.state.order.sortField,
            sortDirection: this.state.order.sortDirection
          },
          offset: this.state.afterCurser,
          limit: this.state.limit
        }}
        location={this.props.location}
      />,
      actions: <SectionActionButtons links={buttonLinks} />,
      modals: <ModalRoutes />
    };
    return (
      <React.Fragment>
        <EmailSectionContext.Provider value={{handleCopyEmails:this.handleCopyEmails,handleBulkRemoveEmails:this.handleBulkRemoveEmails,filter:this.state.filter,selectAll:this.state.selectAll,handleSelectAll:this.handleSelectAll}} >
        <ContentSection data={sectionData} history={this.props.history} />
        </EmailSectionContext.Provider>
      </React.Fragment>
    );
    // }

  }

  
}
class AuditsQuery extends React.Component<AuditQueryProps> {
  render() {

    const FILTER = this.props.data.filter ? this.props.data.filter : '';
    // Default offset.
    const OFFSET = this.props.data.offset ? this.props.data.offset : '';
    const LIMIT = this.props.data.limit ? this.props.data.limit : 25;


    return (
      <Query
        query={emailListQuery}
        pollInterval={0}
        notifyOnNetworkStatusChange
        fetchPolicy="cache-and-network"
        variables={{
          filter: FILTER,
          afterCurser: OFFSET,
          first: LIMIT,

        }}
        fetchPolicy="cache-and-network"

      >
        {({ variables, loading, data, fetchMore, networkStatus }) => {
          // Skip loading icon/flash when we are polling for new results.
          if (loading && networkStatus !== 6 && networkStatus !== 3) {
            // Handle loading screen.
            const row = {
              classes: 'loading',
              content: <i className="fal fa-spinner" />
            };
            return (
              <SectionTable
                render={() => (
                  <tbody>
                    <tr>
                      <td className={row.classes}>{row.content}</td>
                    </tr>
                  </tbody>
                )}
              />
            );
          }

          const audits: [AuditData] | boolean = data && data.practiceEmails ? data.practiceEmails : false;

          if (audits && audits.length === 0 && !loading) {
            // Handle a zero result set.
            const errorContent = (
              <div className="text-center">
                <h4 className="text-warning">No Results Found.</h4>
                <h2 className="text-warning">¯\_(ツ)_/¯</h2>
              </div>
            );
            return <SectionError color="warning" classes="" content={errorContent} />;
          }

          if (!data && !loading) {
            // We've encountered a error connecting to GraphQL server.
            // We should on this account, disable the Create Specialty button.
            // @todo: Should be an easier way to detect an epic failure to connect.
            const button = document.getElementById('addUserLink');
            if (typeof button !== 'undefined' && button !== null && button instanceof HTMLButtonElement) {
              button.classList.add('disabled');
              button.disabled = true;
            }
            return (
              <Alert color="danger" className={`error--graphql-connection`}>
                <div className={`text-center`}>
                  <h4 className="text-error">GraphQL connection failure.</h4>
                  <h2 className="text-error">¯\_(ツ)_/¯</h2>
                </div>
              </Alert>
            );
          }

          // Pagination issues.
          // @see https://github.com/apollographql/apollo-client/issues/2499
            
          allCheckBoxesIds = [];
            return <AuditFeed pageNumber={this.props.pageNumber} page={this.props.page} users={audits.edges || []} pages={audits.aggregate || []} limit={this.props.data.limit || []} />;
         
        }}
      </Query>
    );
  }

 
  
}

/**
 * Component for search
 */
class AuditListContent extends React.Component<LocationOnlyProps, AuditContentProps> {
  render() {
    return (
      <div className="group-landing--wrapper">
        <SectionSearchField
          filter={this.props.data.filter}
          callbacks={this.props.callbacks}
          element={{
            id: 'groupList',
            placeholder: 'Search provider name, location, department, associated emails, etc...'
          }}
        />
        <div className="table-responsive">
          <AuditsQuery pageNumber={this.props.pageNumber} page={this.props.callbacks} data={this.props.data} location={this.props.location} />
        </div>
      </div>
    );
  }
}

/**
 * Component for pagination
 */
class AuditFeed extends React.Component<UserFeedProps> {

  handlePageClick = (data) => {
   
    let page = data.selected;
    let last = 25 * page;
    let total = last - 1;
    this.props.page.handlePage(total, page);
    //window.location = window.location.pathname +"?page="+ currentPage
  };
  render() {

    if (this.props.users && this.props.users.length) {

      let last = this.props.users.length;
      // Friendly method to display the text "Showing X-Y of Z total results.
      let fromItems = 1;
      let toItems = 25;

      if (this.props.pages.count < 25) {
        fromItems = 1;
        toItems = this.props.pages.count
      }

      else if (this.props.pageNumber != 0) {
        fromItems = (this.props.pageNumber * 25);
        toItems = (this.props.pageNumber * 25) + 24
      }


      const total = Math.ceil(this.props.pages.count / this.props.limit);

      // Compile all the table rows together.
      const FeedRows = () => {
        return this.props.users.map((user, id) => <AuditTableRow row={user.node} key={id} />);
      };

      // Primary return without errors or oddities.
      return (
        <React.Fragment>
          <h4>
            Showing{' '}
            <strong>
              {fromItems} - {toItems}
            </strong>{' '}
            of <strong>{this.props.pages.count}</strong> rows.
          </h4>
          <SectionTable
            render={() => (
              <React.Fragment>
                <thead className="thead-light">
                  <AuditTableHeaderRow/>
                </thead>
                <tbody className="result-set">
                  <FeedRows />
                </tbody>

              </React.Fragment>
            )}
          />
          <ReactPaginate
            previousLabel={"previous"}
            nextLabel={"next"}
            breakLabel={"..."}
            breakClassName={"break-me"}
            pageCount={total}
            forcePage={this.props.pageNumber}
            // marginPagesDisplayed={2}
            //pageRangeDisplayed={5}
            //callbacks={this.props.callbacks}
            onPageChange={this.handlePageClick}
            containerClassName={"pagination"}
            subContainerClassName={"pages pagination"}
            activeClassName={"active"}
          />

        </React.Fragment>
      );
    }

    // Last case error that something went terribly wrong.
    const errorContent = (
      <div>
        <h4 className="text-danger">Unknown error occurred.</h4>
        <h2 className="text-danger">¯\_(ツ)_/¯</h2>
      </div>
    );
    return <SectionError color="danger" classes="" content={errorContent} />;
  }

  componentDidMount() {
    allCheckBoxesIds = this.props.users.map(user=>user.node.id);
  }
}

/**
 * Component for Data rows
 */
class AuditTableRow extends React.Component<UserTableRowProps> {
  constructor(props) {
    super(props);
    this.handleCheckBoxClick = this.handleCheckBoxClick.bind(this);
    this.handleCopyButtonClick = this.handleCopyButtonClick.bind(this);
    this.state = {
      selectedRowId: null
    }
  }

  handleCheckBoxClick = (event,id, context) => {
    let isChecked = event.target.checked;
    if(isChecked){  
      this.setState(() => ({
        selectedRowId: id
      }));
    }else{
      this.setState(() => ({
        selectedRowId:null
      }));
    }

    context.handleBulkRemoveEmails(id, isChecked);
  }

  handleCopyButtonClick = (rowData, parentFunction) => {    
    parentFunction(rowData);
  }

  // shouldComponentUpdate(_nextProps, nextState) {
  //   // Compare the next state with the current state
  //   if (this.state.selectedRowId !== nextState.selectedRowId && nextState.selectedRowId !== null) {
  //     return false;
  //   }
  //   return true; // Allow re-render for other state changes
  // }

  render() {
    const user = this.props.row;
    const username = user.user != null ? user.user.username : '';

    // Admin or current user sees a link, otherise, plain text.
    const milliseconds = user.timestamp * 1000 // 1575909015000

    const dateObject = new Date(milliseconds);
    const humanDateFormat = dateObject.toLocaleString();
    
    if (getPermissions('emails') || doUsernamesMatch(user.username)) {
      return (
        <EmailSectionContext.Consumer>
        {(context) => 
        {
          return (
        <tr id={user.id} className={`result-set--row`} >
          <td className="main-cell term" scope="row">
            {/* {user.provider.fullName} */}
            <Link to={`/email/edit/${user.id}`} title={`Edit ${user.provider === null ? user.soarian_resource_mne : user.provider.fullName}`}>
              {user.provider === null ? 'N/A' : user.provider.fullName}
            </Link>
          </td>
          <td className="account-type d-none d-lg-table-cell text-center">{user.soarian_resource_mne}</td>
          <td className="account-type d-none d-lg-table-cell text-center">{user.soarian_location_mne}</td>
          <td className="account-type d-none d-lg-table-cell text-center">{user.match}</td>
          <td className="account-type d-none d-lg-table-cell text-center">{user.department_mne}</td>
          <td className="account-type d-lg-table-cell text-center">
            <Link to={`/email/edit/${user.id}`} title={`Edit ${user.id}`} className="btn btn-edit">
              <i className="fas fa-edit" />
            </Link>
            
            <span>
            <Link to={`/email/copy/${user.id}`} title={`Copy Email for ${user.id}`} className="btn btn-edit"> 
             <Button className="btn btn-primary" color="primary" onClick={()=>this.handleCopyButtonClick(user, context.handleCopyEmails)}>
             Copy emails</Button>
            </Link>
            </span>
            <span>
              {(isValidEmail(context.filter)) && 
            (<Input 
              type="checkbox"
              id= {user.id}
              name="remove-email[]"
              className="custom-checkbox mx-2 my-3"
              value={user.id}              
              checked={(this.state.selectedRowId === user.id)}
              onChange={(e)=>this.handleCheckBoxClick(e, user.id, context)}
              
            />)
            }
            </span>
          </td>
        </tr>
        )}}
        </EmailSectionContext.Consumer>
      )
    } else {
      return null;
    }
  }
}

/**
 * Component to handle table header for CFT results.
 */
class AuditTableHeaderRow extends React.Component<{}> {
  constructor(props: SectionListProps) {
    super(props);
    this.state = {
      isChecked: false
    };
    this.handleSelectAllCheckBoxes = this.handleSelectAllCheckBoxes.bind(this);
  }

  handleSelectAllCheckBoxes = (e,context) =>{
    //calling parent function
    context.handleBulkRemoveEmails(null,e.target.checked);
  }


  render() {
   
    return (
      <React.Fragment>
      <EmailSectionContext.Consumer>
        {(context) => (
      <tr>
        <th scope="col">
          <div className="sorting-cell">
            <span className="col-label">Provider Name</span>
          </div>
        </th>
        <th className="actions text-right" scope="col">
          <span className="col-label">Soarian Resource Mne</span>
        </th>
        <th className="actions text-right" scope="col">
          <span className="col-label">Soarian Location Mne</span>
        </th>
        <th className="actions" scope="col">
          <span className="col-label">Match</span>
        </th>
        <th className="actions" scope="col">
          <span className="col-label">Department Mne</span>
        </th>
        <th className="text-center" scope="col">
          <span className="col-label pl-16  mb-4 pr-2 ">Action </span>
          {isValidEmail(context.filter) && (
              <Input 
              type="checkbox"
              id= "select-all"
              name="select-all"
              className="custom-checkbox mx-2 ml-4"
              value="Select All"
              onChange={(e)=>this.handleSelectAllCheckBoxes(e,context)}
            />
             
          )}
         
        </th> 
      </tr>
        )}
        </EmailSectionContext.Consumer>
        </React.Fragment>
    );
  }
}



export default EmailSection;
