import React, { createContext } from 'react';
import { fetchExcludedTerms } from 'excludedTerm/actions';

export const ExcludedTerms = createContext();

export const connectExcludedTerm = WrappedComponent => {
  return props => {
    const { provider } = props;
    
    const { disableSpecialtyTermsAssociation, specialties, specialtyTerms, refetch } = fetchExcludedTerms(provider.id);
    const actions = {
      refetch
    };
    return (
      <ExcludedTerms.Provider value={actions}>
        <WrappedComponent
          disableSpecialtyTermsAssociation={disableSpecialtyTermsAssociation}
          specialtyTerms={specialtyTerms}
          specialties={specialties}
          {...props}
        />
      </ExcludedTerms.Provider>
    );
  };
};
