/* @flow */

import React from 'react';
import { Icon } from './';

const Save = props => {
  return <Icon icon="fa-search" {...props} />;
};

export default Save;
