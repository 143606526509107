/* @flow */
import * as React from 'react';

export class VisualError extends React.Component<{}> {
  render() {
    return (
      <div className="dml-content--wrapper">
        <div className="dml-content">
          <div className="alert alert-danger react-alert">
            <h2 className="text-danger">Something went wrong. Really, really wrong.</h2>
            <h3 className="text-danger display-3">¯\_(ツ)_/¯</h3>
          </div>
        </div>
      </div>
    );
  }
}

export default VisualError;
