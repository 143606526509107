/* @flow */
/* eslint-disable no-unused-vars */
import * as React from 'react';
import {
  Alert,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  FormText,
  CardFooter,
  Card,
  CardHeader,
  CardBody,
  UncontrolledAlert
} from 'reactstrap';
import { searchGroupsQuery } from 'group/queries';
import { AwardAssociationInput } from 'shared/inputs/association';
import { providerInfoQuery, providerGroupQuery,providerAwardQuery, updateProviderOverrides } from 'provider/queries';
import { fetchProviderData, sortElementObjects,fetchAwardsData } from './ProviderFunctions';
import type {
  EditProviderProps,
  ProviderAssociateProps,
  ProviderAssociateState,
  ProviderData,
  ProviderAssociationPackage
} from 'provider/types';
import type { GroupDataPackage } from 'shared/types';
import { client } from 'functions/Connect';

let Awards = [];
let AwardsObj = {};
export class ProviderTrulyAwardsForm extends React.Component<ProviderAssociateProps, ProviderAssociateState> {
  
  constructor(props: EditProviderProps) {
    super(props);
    this.state = {
      formChanged: false,
      formSubmitted: false,
      provider: false,
      providerData: {},
      elements: [],
      removed: [],
      added: [],
      payload: [],
      submitReady: false,
      saved: false,
      awards: [],
      error: false
    };

    this.handleFormChange = this.handleFormChange.bind(this);
    // this.handleFormSaved = this.handleFormSaved.bind(this);
    // this.setSubmitReady = this.setSubmitReady.bind(this);

    this.processProviderData = this.processProviderData.bind(this);
    
  }
 
  handleFormChange = (e) => {
   
    
    
    AwardsObj[e.id] = e.value;

    this.setState({
      payload: [...this.state.payload, {id:e.id,value:e.value}]
    })
    
    if(AwardsObj.award_name && AwardsObj.status && AwardsObj.year){
      Awards=[AwardsObj];
  
    this.setState(() => ({
      formChanged: true,
      saved: false,
      payload: JSON.stringify(Awards),
    }));
  }
  };

  addElementAssociation = (element: ProviderAssociationPackage, slug: string) => () => {
    // Get a list of original items.
    const originalElements =
      this.state.providerData[slug] && this.state.providerData[slug].length > 0 ? this.state.providerData[slug] : [];
    // An array of elements that match this one. If length === 1, it DID exist when the form loaded,
    // otherwise, if its length is 0, it did not.
    const originallyExisted = originalElements.filter(x => x.id === element.id);
    // No mater what, we add the element to the form submission payload.
    const payload = [...this.state.payload, element.id];
   
    // Create the vars we want to use.
    let elements, removed, added;

    if (originallyExisted.length === 1) {
      // Add it back to the primary set of elements.
      elements = [...this.state.elements, element];
      // No matter what, we can filter this out of our removed state array.
      removed = this.state.removed.filter(x => x.id !== element.id);
      // No matter what, we can filter this out of our added state array.
      // This is because it was already added and it just gets added (above) to the previous
      // element array.
      added = this.state.added.filter(x => x.id !== element.id);
    } else {
      elements = this.state.elements.filter(x => x.id !== element.id);
      removed = this.state.removed.filter(x => x.id !== element.id);
      // No matter what, we can filter this out of our added state array.
      added = [...this.state.added, element];
    }

    this.setState({
      elements: elements.sort((a, b) => {
        return sortElementObjects(a, b);
      }),
      payload: payload,
      removed: removed.sort((a, b) => {
        return sortElementObjects(a, b);
      }),
      added: added.sort((a, b) => {
        return sortElementObjects(a, b);
      }),
      formChanged: true
    });

    setTimeout(() => {
      this.readySubmit();
    }, 350);
  };

  removeElementAssociation = (element: ProviderAssociationPackage, slug: string) => () => {
   
    // Get a list of original items.
    const originalElements =
      this.state.providerData[slug] && this.state.providerData[slug].length > 0 ? this.state.providerData[slug] : [];
    // An array of elements that match this one. If length === 1, it DID exist when the form loaded,
    // otherwise, if its length is 0, it did not.
    const originallyExisted = originalElements.filter(x =>  (x.award_name[0].award_name + ','+ x.status+','+x.year) === element.name);
   
   
    // No mater what, we remove the element from the form submission payload.
    const payload = this.state.payload.filter(x =>  x.name !== element.name);
    
    // Create the vars we want to use.
    let elements, removed, added;
 
    if (originallyExisted.length === 1) {
      
      elements = this.state.elements.filter(x => x.name !== element.name);
      
      removed = [...this.state.removed, element];
      // No matter what, we can filter this out of our added state array.
      added = this.state.added.filter(x => x.award_name.id !== element.id);
    } else {
      elements = this.state.elements.filter(x => x.id !== element.id && x.name !== element.name);
      removed = this.state.removed;
      
      // No matter what, we can filter this out of our added state array.
      added = this.state.added.filter(x => x.award_name.id !== element.id);
    }
  
    this.setState({
      elements: elements.sort((a, b) => {
        return sortElementObjects(a, b);
      }),
      payload: payload.length >0 ? JSON.stringify(payload):payload,
      removed: removed.sort((a, b) => {
        return sortElementObjects(a, b);
      }),
      added: added.sort((a, b) => {
        return sortElementObjects(a, b);
      }),
      formChanged: true
    });

    setTimeout(() => {
      this.readySubmit();
    }, 350);
  };

  submitPayload = async () => {
    
    const status: boolean = await client
      .mutate({
        mutation: updateProviderOverrides,
        // Ensure we ALWAYS reach out immediately and don't rely on cache for this query.
        fetchPolicy: 'no-cache',
        variables: {
          input: {
            providerId: this.state.providerData.id,
            log: this.getLogValue(),
            update: {
              awards: this.state.payload
            }
          }
        }
      })
      .then(response => {
        // console.log(response);
        const data: {
          success: boolean
        } = response.data.updateProviderOverrides;
        return data !== null && data.success;
      })
      .catch(error => console.log(error));

    // console.log(`Provider Mutation status: ${status.toString()}`);
    if (status) {
      // Update the state value(s) as required.
      this.setState({
        formChanged: false,
        saved: true,
        error: false
      });

      // Reset the log field value for the next edit.
      this.resetLogValue();

      // Update the stored data.
      this.processProviderData().then(response => {
        if (response !== false) {
          this.prepareVariables(response);
        }
      });
    }
    else{
      this.setState({
        formChanged: false,
        saved: true,
        error: true
      });
      this.resetLogValue();
    }

    return status;
  };

  /**
   * Function handles determining if the form is ready to submit.
   */
  readySubmit = () => {

    const originalElements =
      this.state.providerData.awards && this.state.providerData.awards.length > 0
        ? [...this.state.providerData.awards]
        : [];
    const currentElements = [...this.state.elements, ...this.state.added];
    const sortedCurrentElements = currentElements.sort((a, b) => {
      return sortElementObjects(a, b);
    });

    const sortedElements = originalElements.sort((a, b) => {
      return sortElementObjects(a, b);
    });
    // We can skip this logic if the lengths are different as that means they
    // were DEFINITELY different.
    let elementsMatch;
    if (sortedElements.length !== sortedCurrentElements.length) {
      elementsMatch = false;
    } else {
      // console.log(`Current and Original term array lengths match, continue comparison.`);
      for (let i = 0; i < sortedCurrentElements.length; i++) {
        if (sortedCurrentElements[i].id === sortedElements[i].id) {
          // console.log(`${currentTerms[i].id} matches ${originalElements[i].id}`);
          elementsMatch = true;
        } else {
          // console.log(`${currentTerms[i].id} DOES NOT MATCH ${originalElements[i].id}... BREAK`);
          elementsMatch = false;
          break;
        }
      }
      // console.log(`Current and Original terms match perfectly. Disable form submit and such.`);
    }

    let submitReady = !elementsMatch;
    let logReady = false;

    




    const log = this.getLogValue();

    if (log && typeof log === 'string' && log.length > 20) {
      logReady = true;
    }
    this.setSubmitReady(submitReady, logReady);
  };

  setSubmitReady = (form: boolean, log: boolean) => {
   
    this.setState(() => ({
      submitReady: form && log,
      formChanged: form
    }));
  };

  getTextFieldValue = (id: string) => {
    const field = document && document.getElementById(id);
    if (
      typeof field !== 'undefined' &&
      field !== null &&
      (field instanceof HTMLInputElement || field instanceof HTMLTextAreaElement)
    ) {
      return field.value;
    }
  };

  getField = (id: string) => {
    const field = document && document.getElementById(id);
    if (
      typeof field !== 'undefined' &&
      field !== null &&
      (field instanceof HTMLInputElement || field instanceof HTMLTextAreaElement)
    ) {
      return field;
    } else {
      console.error(`Field: ${id} not found in getField()...`);
    }
  };

  getLogValue = () => {
    return this.getTextFieldValue('log--awards');
  };

  resetLogValue = () => {
    const log = this.getField('log--awards');

    if (log instanceof HTMLInputElement || log instanceof HTMLTextAreaElement) {
      log.value = '';
    }
  };

  getProviderData = async (id: string): ProviderData => {
    const data = await fetchProviderData(id, providerAwardQuery, 'provider');

    if (data) {
      return data;
    }
    return false;
  };

  getAwardsData = async (id: string): ProviderData => {
    const data = await fetchAwardsData(id, providerAwardQuery, 'provider');

    if (data) {
      return data;
    }
    return false;
  };

  prepareVariables = (data: ProviderData) => {
    
    const awards = data && data.awards ? [...data.awards] : [];
    let elements = [];
    for(let i = 0 ; awards.length > i; i++){
      
      elements.push({id:awards[i].award_name[0].id,name:awards[i].award_name[0].award_name + ','+ awards[i].status+','+awards[i].year});
    }
    //const payload = groups.map((group: GroupDataPackage) => group.id);
    return {
      //payload: payload ? payload : [],
      elements: elements.sort((a, b) => {
        return sortElementObjects(a, b);
      })
    };
  };

  

  processProviderData = async () => {
    const data = await this.getProviderData(this.props.provider.id);
    const awards = await this.getAwardsData(this.props.provider.id)
    if (data && typeof data === 'object') {
        
        
      let elements = this.prepareVariables(data);
     
      this.setState({
        providerData: data,
        elements: elements.elements, // includes term id and name (Array of objects)
        payload: elements.elements, // includes only term id's (Array of strings)
        provider: true,
        submitReady: false,
        awards : awards,
        // Reset the removed/added arrays.
        removed: [],
        added: []
      });

      return data.provider;
    }
    return false;
  };

  UNSAFE_componentWillMount() {
    this.processProviderData().then(response => {
      // Ensures processProviderData didn't return false.
      if (response) {
        this.prepareVariables(response);
      }
    });
  }

  componentDidUpdate() {
    // console.log(`Running componentDidUpdate...`);
    // this.readySubmit();
  }

  render() {
    const provider: ProviderData = this.state.providerData;

    if (!this.state.provider) {
      return null;
    }

    if (!!this.state.provider) {
      // console.log(provider);
      // console.log(this.state);
    }

    return (
      <React.Fragment>
        <Form
          onChange={() => {
            this.readySubmit();
          }}
          onSubmit={(e: Event) => {
            e.preventDefault();
            this.submitPayload();
          }}
        >
    

          <Card className={`mb-4`}>
            <CardHeader>
              <strong>Physician Awards</strong>
            </CardHeader>
            <CardBody>
              <Alert color="warning" className={`mb-3 ${this.state.formChanged ? 'd-block' : 'd-none'}`}>
                <div className="alert--with-icon">
                  <i className="alert-icon fal fa-info-circle" />
                  <span>
                    The <em>Award Associations</em> form has been updated. Use the{' '}
                    <strong>Save Awards </strong> button to avoid losing any changes.
                  </span>
                </div>
              </Alert>
              <UncontrolledAlert className={`mb-3 ${this.state.saved && this.state.error != true ? 'd-block' : 'd-none'}`} color={'success'}>
                <div className="alert--with-icon">
                  <i className="alert-icon fal fa-info-circle" />
                  <span>Award Association Form saved...</span>
                </div>
              </UncontrolledAlert>

              <FormGroup>
                <AwardAssociationInput
                  searchQuery={searchGroupsQuery}
                  mutationVariables={{
                    providerId: provider.id
                  }}
                  refetch={providerInfoQuery}
                  refetchVars={{ id: provider.id }}
                  label={`Truly Awards`}
                  slug={`awards`}
                  gqlFilterVar={`group`}
                  gqlFirstVar={100}
                  awards={this.state.awards}
                  elements={this.state.elements && this.state.elements.length > 0 ? this.state.elements : []}
                  removed={this.state.removed && this.state.removed.length > 0 ? this.state.removed : []}
                  added={this.state.added && this.state.added.length > 0 ? this.state.added : []}
                  callbacks={this.handleFormChange}
                  remove={{
                    associate: this.addElementAssociation,
                    remove: this.removeElementAssociation,
                    handleFormChange: this.handleFormChange,
                    handleFormSaved: () => {}
                  }}
                />
              </FormGroup>
            </CardBody>
            <CardFooter>
            <Alert color="danger" className={`mb-3 ${this.state.error ? 'd-block' : 'd-none'}`}>
                <div className="alert--with-icon">
                  <i className="alert-icon fal fa-info-circle" />
                  <span>
                    Award Already Exists For This Provider
                  </span>
                </div>
              </Alert>
              <FormGroup className={this.state.formChanged ? 'd-block' : 'd-none'}>
                <Label className={`mb-0`}>Reason for Update</Label>
                <FormText color="muted" className={`mb-2`}>
                  <i className="fal fa-info-circle" /> Please provide a reason, including who authorized the change(s)
                  to the <strong>Award Associations</strong>.
                </FormText>
                <Input
                  type={`textarea`}
                  name={`log--awards`}
                  id={`log--awards`}
                  rows={4}
                  disabled={!this.state.formChanged}
                />
              </FormGroup>
              <Alert color="warning" className={`mb-3 ${this.state.formChanged ? 'd-block' : 'd-none'}`}>
                <div className="alert--with-icon">
                  <i className="alert-icon fal fa-info-circle" />
                  <span>
                    The <em>Awards</em> form has been updated. Use the{' '}
                    <strong>Save Awards</strong> button to avoid losing any changes.
                  </span>
                </div>
              </Alert>
              <div className={`d-flex justify-content-between`}>
                <Button
                  id="saveProviderGroupsButton"
                  type="submit"
                  size="md"
                  color="primary"
                  title="Save Awards"
                  disabled={!this.state.submitReady}
                >
                  <i className="fal fa-save" />
                  <span>Save Awards</span>
                </Button>
              </div>
            </CardFooter>
          </Card>
        </Form>
      </React.Fragment>
    );
  }
}
