import gql from 'graphql-tag';

export const userListQuery = gql`
  query users {
    users {
      id
      username
      role{
        item_name
      }
  
      status
   
    }
    
  }
  
`;

export const authItemListQuery = gql`
  query authItems  {
    authItems {
      name
      type
      description
   
   
    }
  }
`;



export const userInfoQuery = gql`
  query ($id: ID!) {
    user(id: $id) {
      id
      username
      role{
        item_name
      }
    
      status
   
    }
    authItems {
      name
      type
      description
   
   
    }
 
  }
`;

export const userNameQuery = gql`
  query user($username: String!) {
    user(username: $username) {
      id
      username
    }
  }
`;

export const userDeleteMutation = gql`
  mutation removeUser($input: RemoveUserInput!) {
    removeUser(input: $input) {
      success
      user {
        username
      }
    }
  }
`;

export const insertUserMutation = gql`
  mutation createUser($input: CreateUserInput!) {
    createUser(input: $input) {
      success
      user {
        username
      }
    }
  }
`;

export const updateUserMutation = gql`
  mutation updateUser($input: UpdateUserInput!) {
    updateUser(input: $input) {
      success
      user {
        username
      }
    }
  }
`;

export const createTokenMutation = gql`
  mutation createToken($input: CreateTokenInput!) {
    createToken(input: $input) {
      token
      success
    }
  }
`;
