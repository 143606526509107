import React, { useState, useContext } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { Button, Badge } from 'reactstrap';

import { PROMOTE_SYNONYM_TO_PRIMARY, REMOVE_SYNONYM_TERM_ASSOCIATION, DEMOTE_PRIMARY_SYNONYM } from 'synonym/queries';
import { AngleDoubleUp, Close } from 'shared/icons';
import { dissociateSynonym, promoteSynonym } from 'synonym/actions';
import { Synonyms } from 'synonym/container';

const SynonymBadge = ({ className, association, synonym, term }) => {
  const [hovering, setHovering] = useState({ show: false, initial: false });
  const { refetch } = useContext(Synonyms);
  const [promoteMutation] = useMutation(PROMOTE_SYNONYM_TO_PRIMARY);
  const [dissociationMutation] = useMutation(REMOVE_SYNONYM_TERM_ASSOCIATION);
  const [demotionMutation] = useMutation(DEMOTE_PRIMARY_SYNONYM);
  const primarySynonym = term.primarySynonym ? term.primarySynonym.id === synonym.id : false;
  const dissacociate = () => {
    if (primarySynonym) {
      dissociateSynonym(
        { variables: { termID: term.id, synonymID: synonym.id } },
        dissociationMutation,
        refetch,
        demotionMutation
      );
    } else {
      dissociateSynonym({ variables: {input:  { termId: term.id, synonymId: synonym.id } }}, dissociationMutation, refetch);
    }
  };
  return (
    <Badge
      onMouseEnter={() => setHovering({ show: true, initial: true })}
      onMouseLeave={() => setHovering({ show: false, initial: true })}
      color={primarySynonym ? 'primary' : 'tertiary'}
      className={`${association ? 'dml-badge' : ''} linked-reference-item${className ? ` ${className}` : ''}`}
    >
      {synonym.name}
      {!primarySynonym && (
        <Button
          className={hovering.show ? '' : 'btn-hidden'}
          color="primary"
          onClick={promoteSynonym(
            {
              variables: {
                termID: term.id,
                synonymID: synonym.id
              }
            },
            promoteMutation,
            refetch
          )}
        >
          <AngleDoubleUp />
        </Button>
      )}
      <Button color="danger" onClick={dissacociate}>
        <Close />
      </Button>
    </Badge>
  );
};

SynonymBadge.defaultProps = {
  association: true
};

export default SynonymBadge;
