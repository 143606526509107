import * as React from 'react';
import { fetchProviderData } from './ProviderFunctions';
import SpecialtyToggle from './SpecialtyToggle';
import {
    providerSpecialtyQuery
  } from 'provider/queries';

import{
  Alert,
  Card,
  CardHeader,
  CardBody
} from 'reactstrap';

export class SpecialtiesList extends React.Component {
    
    constructor(props) {
        super(props);
        this.state = {
          providerSpecialties:[]
        };
    }

    getProviderAddedSpecialtyData = async(id) =>{
        try{
            const data = await fetchProviderData(id, providerSpecialtyQuery, 'provider');
            this.setState({providerSpecialties: data});
        }catch(e){
            console.log(e);
        }
      }

      showAddedSpecialties = (provider_id, specialties) =>{
        if(specialties.specialtiesAdd && specialties.specialtiesAdd.length > 0){
          const specialtiesJSX = specialties.specialtiesAdd.map(s => {
            return (
              <React.Fragment key={s.id}>
                <div className="override-blocks">
                <div className={`large`}>
                  <strong>{s.name}</strong>
                </div>
                <SpecialtyToggle resetFormChanged={this.resetFormChanged}  data={{specialty_id: s.id, provider_id: provider_id, is_visible:s.is_visible, enable_inherited_list: s.enable_inherited_list }} />
                </div>
              </React.Fragment>
            );
          });
          return specialtiesJSX;
        }
      }

    componentDidMount() {
        this.getProviderAddedSpecialtyData(this.props.provider.id);
    }

    render() {
        return (
            <React.Fragment>
                 { this.state.providerSpecialties.specialtiesAdd && this.state.providerSpecialties.specialtiesAdd.length >0 && (
                    <div className={'d-flex justify-content-between mb-3'}>
                    <div className={`w-100`}>
                    <Alert color="info" className={`mb-3 w-100`}>
                      <div className="alert--with-icon">
                        <i className="alert-icon fal fa-info-circle" />
                        <span>
                        This section allows you to override term lists for provider specialties
                          <strong>{this.props.provider.fullName}</strong>.
                        </span>
                      </div>
                    </Alert>
                       <Card className={`mb-4`}>
                      <CardHeader>
                        <strong>Specialty Term List Overrides</strong>
                      </CardHeader>
                      <CardBody>
                        {
                            this.showAddedSpecialties(this.props.provider.id, this.state.providerSpecialties)
                        }
                        </CardBody>
                        </Card>
                    </div>
                    <hr />
                  </div>
             ) }
            </React.Fragment>
           
        )
    }

}