import gql from 'graphql-tag';

/**
 * Query to handle a paginated list of CFTs, including aggregation and page information.
 */
// Version that supported pagination via mariadb
// export const cftTermsQuery = gql`
//   query cftTerms($filter: String, $orderBy: CftTermOrdering, $first: Int!, $before: String, $after: String) {
//     cftTerms(filter: $filter, orderBy: $orderBy, first: $first, after: $after, before: $before) {
//       pageInfo {
//         hasNextPage
//         hasPreviousPage
//         startCursor
//         endCursor
//       }
//       aggregate {
//         count
//       }
//       edges {
//         node {
//           id
//           name
//           slug
//           taxonomy
//           updated_at
//           umls_cuid
//           status
//         }
//       }
//     }
//   }
// `;

export const searchCftsQuery = gql`
  query cftTerms($filter: String, $orderBy: CftTermOrdering, $first: Int, $afterCurser: String) {
    cftTerms(filter: $filter, orderBy: $orderBy, first: $first,afterCurser: $afterCurser) {
      edges {
        node {
          id
          name
          slug
        }
      }
    }
  }
`;

export const cftTermsQuery = gql`
  query cftTerms($filter: String, $orderBy: CftTermOrdering,  $first: Int, $afterCurser: String) {
    cftTerms(filter: $filter, orderBy: $orderBy, first: $first,afterCurser: $afterCurser) {
      aggregate {
        count
      }
      edges {
        node {
      id
      number_id
      name
      slug
      taxonomy
      updated_at
      umls_cuid
      status
        }
        cursor
      }
    }
  }
`;

/**
 * Query for getting a single CFT.
 */
export const cftInfoQuery = gql`
  query cftTerm($id: ID, $slug: String, $umls_cuid: String) {
    cftTerm(id: $id, slug: $slug, umls_cuid: $umls_cuid) {
      id
      name
      preferredName
      slug
      primarySynonym {
        id
        name
      }
      specialties {
        id
        name
      }
      specialtiesMaster {
        id
        name
      }
      providers {
        id
        fullName
        lastName
      }
      type
      umls_cuid
      status
      taxonomy
    }
  }
`;

/**
 * Mutation to handle removing a CFT completely.
 */
export const deleteCftMutation = gql`
  mutation removeTerm($input: RemoveTermInput!) {
    removeTerm(input: $input) {
      success
      term {
        id
        name
      }
    }
  }
`;

/**
 * Mutation to handle creating a new CFT.
 */
export const insertCftMutation = gql`
  mutation createTerm($input: CreateTermInput!) {
    createTerm(input: $input) {
      success
      term {
        id
        slug
        name
      }
    }
  }
`;

/**
 * Mutation to handle updating an existing CFT.
 */
export const updateCftMutation = gql`
  mutation udpateTerm($input: UpdateTermInput!) {
    updateTerm(input: $input) {
      success
      term {
        id
        slug
        name
      }
    }
  }
`;

/**
 * Query too see if a group exists based on the slug match.
 */
export const cftUniqueSlugQuery = gql`
  query cftUniqueSlugQuery($slug: String!) {
    cftTerm(slug: $slug) {
      id
      slug
      name
    }
  }
`;

/**
 * Mutation to handle removing the association between a Specialty and a CFT.
 */
export const removeSpecialtyFromTermMutation = gql`
  mutation removeTermSpecialtyAssociation($input: RemoveTermSpecialtyAssociationInput!) {
    removeTermSpecialtyAssociation(input: $input) {
      success
    }
  }
`;

/**
 * Mutation to handle removing the association between a Specialty and a CFT.
 */
export const removeInheritedTermSpecialtyMutation = gql`
  mutation removeInheritedTermServiceSpecialtyAssociation($input: removeInheritedTermServiceSpecialtyAssociationInput!) {
    removeInheritedTermServiceSpecialtyAssociation(input: $input) {
      success
    }
  }
`;


export const removeSpecialtyFromTermMasterMutation = gql`
  mutation removeTermSpecialtyMasterAssociation($input: RemoveTermSpecialtyMasterAssociationInput!) {
    removeTermSpecialtyMasterAssociation(input: $input) {
      success
    }
  }
`;

/**
 * Mutation to handle removing the association between a Physician and a CFT.
 */
export const removePhysicianFromTermMutation = gql`
  mutation removePhysicianFromTerm($input: RemoveTermProviderAssociationInput!) {
    removeTermProviderAssociation(input: $input) {
      success
    }
  }
`;

/**
 * Mutation to handle adding an association between a Specialty and a CFT.
 */
export const associateSpecialtyWithTermMutation = gql`
  mutation createDefaultTermSpecialtyAssociation($input: createDefaultTermSpecialtyAssociationInput!) {
    createDefaultTermSpecialtyAssociation(input: $input) {
      success
    }
  }
`;

/**
 * Mutation to handle adding an association between a Specialty and a CFT.
 */
export const associateSpecialtyWithInheritedTermMutation = gql`
  mutation createInheritedTermServiceSpecialtyAssociation($input: createInheritedTermServiceSpecialtyAssociationInput!) {
    createInheritedTermServiceSpecialtyAssociation(input: $input) {
      success
    }
  }
`;

export const copySpecialtyDefaultVariantTermsMutation = gql`
mutation copySpecialtyDefaultVariantTermsAssociation($input: copySpecialtyDefaultVariantTermsAssociationInput!) {
  copySpecialtyDefaultVariantTermsAssociation(input: $input) {
    success
  }
}
`;

/**
 * Mutation to handle adding an association between a Specialty and a CFT.
 */
 export const associateSpecialtyWithTermMasterMutation = gql`
 mutation createTermSpecialtyMasterAssociation($input: CreateTermSpecialtyMasterAssociationInput!) {
   createTermSpecialtyMasterAssociation(input: $input) {
     success
   }
 }
`;

/**
 * Mutation to handle adding an association between a Physician and a CFT.
 */
export const associatePhysicianWithTermMutation = gql`
  mutation createTermProviderAssociation($input: CreateTermProviderAssociationInput!) {
    createTermProviderAssociation(input: $input) {
      success
    }
  }
`;

export const associatePhysicianWithTermsMutation = gql`
  mutation createTermsProviderAssociation($input: CreateTermsProviderAssociationInput!) {
    createTermsProviderAssociation(input: $input) {
      success
    }
  }
`;


export const associateTermByPhysician = gql`
  mutation createTermsByProviderAssociation($input: CreateTermsByProviderAssociationInput!) {
    createTermsByProviderAssociation(input: $input) {
      success
    }
  }
`;
