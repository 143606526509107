import React, { createContext } from 'react';
import { fetchSynonyms } from 'synonym/actions';

export const Synonyms = createContext();

export const connectSynonyms = WrappedComponent => {
  return props => {
    const { term } = props;
    const { cftTerm, synonyms, refetch } = fetchSynonyms(term.id);
    const actions = {
      refetch
    };
    return (
      <Synonyms.Provider value={actions}>
        <WrappedComponent cftTerm={cftTerm} synonyms={synonyms} {...props} />
      </Synonyms.Provider>
    );
  };
};
