/* @flow */
/* eslint-disable no-unused-vars */
import React from 'react';
import ReactDOM from 'react-dom';
import { Alert, Badge, Button, Card, CardHeader, CardBody, Form, FormGroup, Label, Input, FormText } from 'reactstrap';
import { Danger } from 'shared/messaging';
import { Info } from 'shared/icons';
import Select from 'react-select';

import {
  cftInfoQuery,
  cftUniqueSlugQuery,
  associatePhysicianWithTermMutation,
  associateSpecialtyWithTermMutation,
  removePhysicianFromTermMutation,
  removeSpecialtyFromTermMutation,
  updateCftMutation,
  insertCftMutation
} from 'clinicalFocusTerm/queries';
import { searchCftsQuery } from 'clinicalFocusTerm/queries';
import {
  CREATE_SYNONYM_ONLY,
  synonymUniqueSlugQuery,
  CREATE_TERM_SYNONYM_ASSOCIATION,
  REMOVE_SYNONYM_TERM_ASSOCIATION,
  updateSynonymMutation
} from 'synonym/queries'

import { searchProviderQuery } from 'provider/queries';
import { searchSpecialtyQuery } from 'specialty/queries';

import { Query, Mutation } from 'react-apollo';
import { SystemAlert } from 'elements/SystemAlert';
import { modalClose, modalHide } from 'elements/ModalSection';

import { Link, Redirect } from 'react-router-dom';
import type { EditCftProps, EditCftState, UmlsTerm } from 'clinicalFocusTerm/types';

import AssociationForm from 'elements/AssociationForm';
import { AssociationInput } from 'shared/inputs/association';
import MachineName, { uniqueSlugCheck } from 'shared/inputs/MachineName';
import UmlsSearch from 'elements/UmlsSearch';
import SynonymInput from 'synonym/inputs/SynonymInput';

export class CftEditForm extends React.Component<EditCftProps, EditCftState> {
  typeOptions: {
    value: string,
    label: string
  }[];
  constructor(props: EditCftProps) {
    super(props);
    this.state = {
      formChanged: false,
      formSubmitted: false,
      deleteRequest: false,
      deleteTermSlug: false,
      newTerm: typeof this.props.newTerm !== 'undefined' && this.props.newTerm === true,
      umls: false,
      custom: true,
      prevSearch: {
        filter: this.props.search && this.props.search.filter ? this.props.search.filter : '',
        offset: this.props.search && this.props.search.offset ? this.props.search.offset : 0,
        limit: this.props.search && this.props.search.limit ? this.props.search.limit : 100,
        order:
          this.props.search && this.props.search.order
            ? this.props.search.order
            : {
                sortField: '',
                sortDirection: ''
              }
      },
      submitReady: false,
      specialties: [],
      providers: [],
      synonyms: {}
    };

    // Array of "Type" objects.
    // @todo: Types may need to be converted to GraphQL schema if we want to avoid hard coding them.
    this.typeOptions = [{ value: 'treatment', label: 'Treatment' }, { value: 'condition', label: 'Condition' }];

    this.createCustom = this.createCustom.bind(this);
    this.handleFormChange = this.handleFormChange.bind(this);
    this.handleFormSaved = this.handleFormSaved.bind(this);
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.handleDeleteSubmit = this.handleDeleteSubmit.bind(this);
    this.handleUmlsSelected = this.handleUmlsSelected.bind(this);
  }

  createCustom = () => {
    this.setState(() => ({
      custom: true
    }));
  };

  handleFormChange = () => {
    this.setState(() => ({
      formChanged: true
    }));

    setTimeout(() => {
      this.readySubmit();
    }, 250);
  };

  handleFormSaved = () => {
    this.setState(() => ({
      formChanged: false
    }));
  };

  handleDeleteSubmit = slug => {
    modalHide();

    // Wait a tick and change the state, which will open the delete modal.
    setTimeout(() => {
      this.setState(() => ({
        deleteRequest: true,
        deleteTermSlug: slug
      }));
    }, 500);
  };

  handleUmlsSelected = (term: UmlsTerm) => {
    this.handleFormChange();
    this.setState(() => ({
      umls: term
    }));
  };

  /**
   * @todo: Handle sanitizing bogus input. #TRUSTNOONE
   */
  handleFormSubmit = () => {
    const status = document && document.querySelector('input[name="termPublishedStatus"]:checked');
    const term = document && document.getElementById('termName');
    const slug = document && document.getElementById('termSlug');
    const type = document && document.getElementsByName('termType')[0];
    const cuid = document && document.getElementById('cuidValue');
    const termStatusString =
      typeof status !== 'undefined' && status !== null && status instanceof HTMLInputElement
        ? status.value
        : 'disabled';
    const termStatus = termStatusString === 'enabled'; // Returns true or false;
    const termName: string =
      typeof term !== 'undefined' && term !== null && term instanceof HTMLInputElement ? term.value : '';
    const termType: string | null =
      typeof type !== 'undefined' && type !== null && type instanceof HTMLInputElement && type.value.length >= 1
        ? type.value
        : null;
    const termSlug: string =
      typeof slug !== 'undefined' && slug !== null && slug instanceof HTMLInputElement ? slug.value : '';
    const cuidValue: string | null =
      typeof cuid !== 'undefined' && cuid !== null && cuid instanceof HTMLInputElement && cuid.value.length >= 1
        ? cuid.value
        : null;

    const submittedData = {
      name: termName,
      slug: termSlug,
      status: termStatus
      
    } ;


    return submittedData;
  };

  /**
   * Function handles determining if the form is ready to submit.
   */
  readySubmit = () => {
    const data = this.handleFormSubmit();
    if (data.name.length >= 1) {
      this.setState(() => ({
        submitReady: true
      }));
    } else {
      this.setState(() => ({
        submitReady: false
      }));
    }
  };

  render() {
   

    // const synonymQuery = useQuery(getCFTSynonyms, {
    //   variables: { cid: cft.id}
    // })
    if (this.state.deleteRequest === true && typeof this.state.deleteTermSlug === 'string') {
      return <Redirect to={`/cft/delete/${this.state.deleteTermSlug}`} />;
    }

    if (this.state.newTerm && this.state.umls === false && this.state.custom === false) {
      return (
        <React.Fragment>
          <Alert color="info" className={`mb-3`}>
            <div className="alert--with-icon">
              <i className="alert-icon fal fa-info-circle" />
              <div>
                <span>
                  To avoid term duplication, please search for the <strong>Clinical Focus Term</strong> you would like
                  to create. We will query the UMLS database, and show results while highlighting any terms that may
                  already exist in the CFT database.
                </span>
              </div>
            </div>
          </Alert>
          
          {/* <UmlsSearch termSelected={this.handleUmlsSelected} /> */}
          {/* <Alert color="warning" className={`mt-3 mb-0`}>
            <div className="alert--with-icon">
              <i className="alert-icon fal fa-info-circle" />
              <div>
                <span className={`mr-2`}>
                  If you are sure the term you are creating is NOT in the UMLS database, you may
                </span>
                <a
                  className={`custom-cft-button text-primary`}
                  onClick={() => {
                    this.createCustom();
                  }}
                >
                  <i className="fas fa-plus mr-1" />
                  Create a Custom Term
                </a>
              </div>
            </div>
          </Alert> */}
        </React.Fragment>
      );
    }

   


          // Figure out the object that matches the type.
          // We use this in the <Select/> item to preselect the appropriate value.
          //const typeValue = cft && cft.taxonomy ? this.typeOptions.find(taxonomy => taxonomy.value === cft.taxonomy) : null;

          // As the API doesn't have a 'true' upsert query, we determine
          // which GraphQL query we want to use here.
        return (
          <Query
        query={synonymUniqueSlugQuery}
        pollInterval={0}
        notifyOnNetworkStatusChange={true}
        variables={{
          slug: this.props.cft && this.props.cft.slug ? this.props.cft.slug : 'nothingtoseehere'
        }}
        fetchPolicy="cache-and-network"
      >
        {({ data, loading }) => {
          if (loading) return null;
         // console.log(data);
          const editSynonoym = data && data.synonym
          ? data.synonym
          : {
              // Set the default form values.
              id: '',
              
              slug: '',
              terms: [],
              
            };

          // Figure out the object that matches the type.
          // We use this in the <Select/> item to preselect the appropriate value.
          // const typeValue =
          // editSynonoym && cft.taxonomy ? this.typeOptions.find(taxonomy => taxonomy.value === cft.taxonomy) : null;

          // As the API doesn't have a 'true' upsert query, we determine
          // which GraphQL query we want to use here.
          const upsertMutation = editSynonoym.id ? updateSynonymMutation : CREATE_SYNONYM_ONLY;
        //  const upsertMutation =  CREATE_SYNONYM_ONLY;
//console.log(editSynonoym);
          return (
            <React.Fragment>
              <Alert color="warning" className={`mb-3 ${this.state.formChanged ? 'd-block' : 'd-none'}`}>
                <div className="alert--with-icon">
                  <i className="alert-icon fal fa-info-circle" />
                  <span>
                    The form has been updated. Use the <strong>Save Synonym</strong> button to avoid losing any changes.
                  </span>
                </div>
              </Alert>

              <Mutation mutation={upsertMutation}>
                {(upsertSynonym, { loading, error }) => {
                  if (loading) {
                    return null;
                  }
                  if (error) {
                    console.log(`ERROR UPSERTING CFT...`);
                  }

                  return (
                    <Form
                      onLoad={() => {
                        setTimeout(() => {
                          this.readySubmit();
                        }, 250);
                      }}
                      onChange={() => {
                        this.handleFormChange();
                      }}
                      onSubmit={(e: Event) => {
                        e.preventDefault();
                        const synonym = this.handleFormSubmit();

                        //console.log(synonym);
                          // We have an ID, so this is an existing CFT, so
                          // we will update that CFT now.
                          if (editSynonoym && editSynonoym.id) {
                            // We have an ID, so this is an existing CFT, so
                            // we will update that CFT now.
                            upsertSynonym({
                              variables: {
                                input: {
                                 synonymId: editSynonoym.id,
                                 update: synonym
                                }
                              }
                            });
                          } else {
                            upsertSynonym({
                              variables: {
                                input: synonym
                              }
                            });
                          }

                        // Send a message to the screen.
                        const NotifyUpserted = () => {
                          return (
                            <SystemAlert
                              autoClose
                              color="success"
                              icon="alert-icon fal fa-check-circle"
                              messageData={{
                                action: editSynonoym.id ? 'Updated' : 'Created',
                                item: synonym.name,
                                id: synonym.id,
                                tail: '...'
                              }}
                            />
                          );
                        };
                         //console.log(editSynonoym);
                        const alert = document && document.getElementById('system-alert-wrapper');
                        if (alert) {
                          ReactDOM.render(<NotifyUpserted />, alert);
                          modalClose(this.props.history);
                        }
                      }}
                      
                    >
                      <FormGroup>
                        <React.Fragment>
                          <Input type="hidden" name="id" id="termId" defaultValue={ ''} />
                          <MachineName
                            isUniqueCallback={(slug: string) => {
                              return uniqueSlugCheck(slug, synonymUniqueSlugQuery, 'synonym', 'saveCftButton');
                            }}
                            // The field that is used to store the final version before submission.
                            // The field that also allows you to customize the slug.
                            target={{
                              // What this type of field is called: Machine Name/System Name/Slug
                              label: 'Machine Name',
                              id: 'termSlug',
                              name: 'custom',
                              defaultValue:  editSynonoym && editSynonoym.slug ? editSynonoym.slug : ''
                            }}
                            // The source field for the generated slug.
                            source={{
                              label: 'Synonym Name',
                              id: 'termName',
                              name: 'name',
                              placeholder: 'Enter a descriptive term name...',
                              defaultValue: editSynonoym && editSynonoym.name ? editSynonoym.name : ''
                            }}
                            // If existing elements (based on source.defaultValue) should be
                            // automatically updated. Otherwise, a saved/preexisting value would not
                            // be updated immediately and would require manual (thoughtful) editing.
                            autoChangeOnExisting={false}
                            // Callback (from parent(s)) to be fired
                            changeCallback={this.handleFormChange}
                           // existing={!!(cft && cft.id)}
                          />
                        </React.Fragment>
                        <hr />
                      </FormGroup>

                      <FormGroup className={'d-none'}>
                        <Label for="cuidValue">UMLS CUID</Label>
                        <Input
                          type="text"
                          //defaultValue={cft.umls_cuid}
                          autoComplete="off"
                          name="umls_cuid"
                          id="cuidValue"
                          disabled
                        />
                        <FormText color="muted" className={`'d-none'}`}>
                          This <strong>Clinical Focus Term</strong> is <strong>not</strong> associated with a UMLS term.
                          In order to associate this term with a UMLS term, it is advised to{' '}
                          <Link to={`/cft/delete/`} className={`text-danger`}>
                            <strong>delete</strong>
                          </Link>{' '}
                          this custom version, and create the term from scratch. This allows the system to search for
                          and create any synonym associations provided by the UMLS database.
                        </FormText>
                        <hr />
                      </FormGroup>

                      {/* <FormGroup>
                        <Label for="termType">Term Type</Label>
                        <Select
                          id="termType"
                          name="termType"
                          options={this.typeOptions}
                          onChange={this.handleFormChange}
                          defaultValue={typeValue}
                          placeholder="Select term type..."
                        />
                        <hr />
                      </FormGroup> */}
                      {/* {cft.id !== "" && <SynonymInput term={cft} />}

                      <FormGroup className={cft.id ? '' : 'd-none'}>
                        <AssociationForm
                          searchQuery={searchSpecialtyQuery}
                          deleteMutation={removeSpecialtyFromTermMutation}
                          associateMutation={associateSpecialtyWithTermMutation}
                          mutationVariables={{
                            termId: cft.id
                          }}
                          refetch={cftInfoQuery}
                          refetchVars={{ slug: cft.slug }}
                          label={`Specialties`}
                          slug={`specialties`}
                          gqlFilterVar={`specialty`}
                          gqlFirstVar={100}
                          elementField={`name`}
                          elements={cft.specialties}
                          callbacks={{
                            handleFormChange: this.handleFormChange,
                            handleFormSaved: this.handleFormSaved
                          }}
                        />
                        <hr />
                      </FormGroup> */}
                     
                      <FormGroup className={editSynonoym.id ? '' : 'd-none'}>
                        <AssociationForm
                          searchQuery={searchCftsQuery}
                          deleteMutation={REMOVE_SYNONYM_TERM_ASSOCIATION}
                          associateMutation={CREATE_TERM_SYNONYM_ASSOCIATION}
                          mutationVariables={{
                            synonymId: editSynonoym.id
                          }}
                          refetch={synonymUniqueSlugQuery}
                          refetchVars={{ slug: editSynonoym.slug }}
                          label={`CFTs`}
                          slug={`cftTerms`}
                          gqlFilterVar={`term`}
                          gqlFirstVar={200}
                          elementField={`name`}
                          elements={editSynonoym.terms.sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0))}
                          callbacks={{
                            handleFormChange: this.handleFormChange,
                            handleFormSaved: this.handleFormSaved
                          }}
                        />
                        <hr />
                      </FormGroup>

                      <FormGroup>
                        <legend>Publishing Status</legend>
                        <div className="form-radio-group">
                          <FormGroup check>
                            <Label check>
                              <Input
                                type="radio"
                                name="termPublishedStatus"
                                value="enabled"
                                defaultChecked={editSynonoym.status ? 'checked' : false}
                              />{' '}
                              <span className="text-success">Enabled</span>
                            </Label>
                          </FormGroup>
                          <FormGroup check>
                            <Label check>
                              <Input
                                type="radio"
                                name="termPublishedStatus"
                                value="disabled"
                                defaultChecked={editSynonoym.status ? false : 'checked'}
                              />{' '}
                              <span className="text-warning">Disabled</span>
                            </Label>
                          </FormGroup> 
                         </div>
                        <FormText color="muted">
                          <i className="fal fa-info-circle" /> By default the status of a new Synonym is set to{' '}
                          <strong>
                            <span className="text-warning">Disabled</span>
                          </strong>
                          . Set the status to{' '}
                          <strong>
                            <span className="text-success">Enabled</span>
                          </strong>{' '}
                          to activate this Synonym.
                        </FormText>
                        <hr />
                      </FormGroup>

                      <FormGroup className="mt-2 form-actions d-flex justify-content-between align-items-end">
                        <Button
                          id="saveCftButton"
                          type="submit"
                          size="md"
                          color="primary"
                          title="Save"
                          disabled={!(this.state.submitReady && this.state.formChanged)}
                        >
                          <i className="fal fa-save" />
                          <span>Save Synonym</span>
                        </Button>
                        <span className={ 'd-none'}>
                          <Button
                            outline
                            size="md"
                            color="danger"
                          //</span>  title={`Delete ${cft.name}`}
                            // onClick={() => {
                            //   this.handleDeleteSubmit(cft.slug);
                            // }}
                          >
                            <i className="fal fa-trash-alt" />
                            <span>Delete Term</span>
                          </Button>
                        </span>
                      </FormGroup>
                    </Form>
                  );
                }}
              </Mutation>
            </React.Fragment>
          );
        }}
        </Query>
       );
    
  }
}
