import React, { Component } from 'react';
import { Mutation } from 'react-apollo';
import { Button } from 'reactstrap';

class DeleteButtonMutation extends Component {
  constructor(props) {
    super(props);
  }

   handleScheduleDelete = (deleteItem)=>{
    let confirm = window.confirm('Are you sure you want to delete?');
    if(confirm){
        deleteItem();
    }
   
}
  render() {
    const inputVars = {...this.props.mutationInput};

    return (
        <Mutation
        mutation={this.props.mutationName}
        variables={{
          input: inputVars
        }}
        onCompleted={(data) => {
            this.props.handleDelete(this.props.id);
          }}
        key={this.props.id}
      >
        {(deleteMutation, { loading, error }) => {
          if (loading) {
            return null;
          }
          if (error) {
            console.log(error);
          }
            return (
            <div>
                <button type="button" className='btn-danger mb-3 '  onClick={()=> this.handleScheduleDelete(deleteMutation)} ><i className="fas fa-times" /></button>
            </div>
            );
        }}
      </Mutation>
    );
  }
}

export default DeleteButtonMutation;
