/* @flow */
/* eslint-disable no-unused-vars */
import React from 'react';
import ReactDOM from 'react-dom';
import { Alert, Badge, Button, Card, CardHeader, CardBody, Form, FormGroup, Label, Input, FormText } from 'reactstrap';
import { Danger } from 'shared/messaging';
import { Info } from 'shared/icons';
import Select from 'react-select';
import SectionSearchField from 'elements/SectionSearchField';
import { specialtyConnectionQuery } from 'specialty/queries';
import SectionTable from 'elements/SectionTable';
import SectionLoadMoreButton from 'elements/SectionLoadMoreButton';
import SectionError from 'elements/SectionError';
import ReactPaginate from "react-paginate";
import {
  cftInfoQuery,
  cftUniqueSlugQuery,
  associatePhysicianWithTermMutation,
  associateSpecialtyWithTermMutation,
  removePhysicianFromTermMutation,
  removeSpecialtyFromTermMutation,
  updateCftMutation,
  insertCftMutation
} from 'clinicalFocusTerm/queries';


import { searchProviderQuery } from 'provider/queries';
import { insertTopSpecialty } from 'topspecialties/queries';

import { Query, Mutation } from 'react-apollo';
import { SystemAlert } from 'elements/SystemAlert';
import { modalClose, modalHide } from 'elements/ModalSection';

import { Link, Redirect } from 'react-router-dom';
import type { EditCftProps, EditCftState, UmlsTerm } from 'clinicalFocusTerm/types';

import AssociationForm from 'elements/AssociationForm';
import { AssociationInput } from 'shared/inputs/association';
import MachineName, { uniqueSlugCheck , formatSlug} from 'shared/inputs/MachineName';
import SpecialtySearch from 'elements/SpecialtySearch';
import SynonymInput from 'synonym/inputs/SynonymInput';


export class TopSepcialtyEditForm extends React.Component<EditCftProps, EditCftState> {
  typeOptions: {
    value: string,
    label: string
  }[];
  constructor(props: EditCftProps) {
    super(props);
    this.state = {
      formChanged: false,
      formSubmitted: false,
      deleteRequest: false,
      deleteTermSlug: false,
      newTerm: typeof this.props.newTerm !== 'undefined' && this.props.newTerm === true,
      umls: false,
      custom: false,
      prevSearch: {
        filter: this.props.search && this.props.search.filter ? this.props.search.filter : '',
        offset: this.props.search && this.props.search.offset ? this.props.search.offset : 0,
        limit: this.props.search && this.props.search.limit ? this.props.search.limit : 100,
        order:
          this.props.search && this.props.search.order
            ? this.props.search.order
            : {
                sortField: '',
                sortDirection: ''
              }
      },
      submitReady: false,
      specialties: [],
      providers: [],
      synonyms: {},
      success: false
    };

    // Array of "Type" objects.
    // @todo: Types may need to be converted to GraphQL schema if we want to avoid hard coding them.
    this.typeOptions = [{ value: 'treatment', label: 'Treatment' }, { value: 'condition', label: 'Condition' }];

    this.createCustom = this.createCustom.bind(this);
    this.handleFormChange = this.handleFormChange.bind(this);
    this.handleFormSaved = this.handleFormSaved.bind(this);
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.handleDeleteSubmit = this.handleDeleteSubmit.bind(this);
    this.handleUmlsSelected = this.handleUmlsSelected.bind(this);
  }

  createCustom = () => {
    this.setState(() => ({
      custom: true
    }));
  };

  handleFormChange = () => {
    this.setState(() => ({
      formChanged: true
    }));

    setTimeout(() => {
      this.readySubmit();
    }, 250);
  };

  handleFormSaved = () => {
    this.setState(() => ({
      formChanged: false
    }));
  };

  handleDeleteSubmit = slug => {
    modalHide();

    // Wait a tick and change the state, which will open the delete modal.
    setTimeout(() => {
      this.setState(() => ({
        deleteRequest: true,
        deleteTermSlug: slug
      }));
    }, 500);
  };

  handleUmlsSelected = (term: UmlsTerm) => {
    this.handleFormChange();
    this.setState(() => ({
      umls: term
    }));
  };

  /**
   * @todo: Handle sanitizing bogus input. #TRUSTNOONE
   */
  handleFormSubmit = () => {
    const status = document && document.querySelector('input[name="termPublishedStatus"]:checked');
    const term = document && document.getElementById('termName');
    const slug = document && document.getElementById('termName');
    const type = document && document.getElementsByName('termType')[0];
    const cuid = document && document.getElementById('cuidValue');
    const termStatusString =
      typeof status !== 'undefined' && status !== null && status instanceof HTMLInputElement
        ? status.value
        : 'disabled';
    const termStatus = termStatusString === 'enabled'; // Returns true or false;
    const termName: string =
      typeof term !== 'undefined' && term !== null && term instanceof HTMLInputElement ? term.value : '';
    const termType: string | null =
      typeof type !== 'undefined' && type !== null && type instanceof HTMLInputElement && type.value.length >= 1
        ? type.value
        : null;
    const termSlug: string =
      typeof slug !== 'undefined' && slug !== null && slug instanceof HTMLInputElement ? slug.value.toLowerCase()
      .trim() // Trim leading and trailing spaces
      .replace(/\s+/g, '-') // Replace spaces with -
      .replace(/[^\w-]+/g, '') // Remove all non-word chars
      .replace(/--+/g, '-') : '';
    const cuidValue: string | null =
      typeof cuid !== 'undefined' && cuid !== null && cuid instanceof HTMLInputElement && cuid.value.length >= 1
        ? cuid.value
        : null;

    const submittedData: {
      specialty_id: string,
 
    } = {
      specialty_id: this.state.umls.id
     
    };

    if (termType) {
      submittedData.taxonomy = termType.toUpperCase();
    }

    if (cuidValue) {
      submittedData.umls_cuid = cuidValue;
    }

    return submittedData;
  };

  /**
   * Function handles determining if the form is ready to submit.
   */
  readySubmit = () => {
    const data = this.handleFormSubmit();
    if (data.specialty_id.length >= 1) {
      this.setState(() => ({
        submitReady: true
      }));
    } else {
      this.setState(() => ({
        submitReady: false
      }));
    }
  };

  render() {
    const { primarySynonym } = this.props;
    const { synonyms } = this.state;

   
    // const synonymQuery = useQuery(getCFTSynonyms, {
    //   variables: { cid: cft.id}
    // })
    if (this.state.deleteRequest === true && typeof this.state.deleteTermSlug === 'string') {
      return <Redirect to={`/cft/delete/${this.state.deleteTermSlug}`} />;
    }

    if (this.state.newTerm && this.state.umls === false && this.state.custom === false) {
      return (
        <React.Fragment>
          <Alert color="info" className={`mb-3`}>
            <div className="alert--with-icon">
              <i className="alert-icon fal fa-info-circle" />
              <div>
                <span>
                 Select Specialty To Be added To The Most Searched List
                </span>
              </div>
            </div>
          </Alert>
          <div className="group-landing--wrapper">
    
         <SpecialtySearch termSelected={this.handleUmlsSelected} />
    
      
      </div>
      

        </React.Fragment>
      );
      
    }
    
      
       
      

          // Figure out the object that matches the type.
          // We use this in the <Select/> item to preselect the appropriate value.
      

          // As the API doesn't have a 'true' upsert query, we determine
          // which GraphQL query we want to use here.
          const cft = this.state.umls;
          const upsertMutation = insertTopSpecialty;
          let success = false;
         
          return (
            <React.Fragment>
              <Alert color="warning" className={`mb-3 ${this.state.formChanged ? 'd-block' : 'd-none'}`}>
                <div className="alert--with-icon">
                  <i className="alert-icon fal fa-info-circle" />
                  <span>
                    The form has been updated. Use the <strong>Save Term</strong> button to avoid losing any changes.
                  </span>
                </div>
              </Alert>

              <Mutation mutation={upsertMutation}>
                {(upsertCft, { data,loading, error }) => {
                  
                  
                  if (loading) {
                    return null;
                  }
                 
                  if (error) {
                    console.log(`ERROR UPSERTING CFT...`);
                  }
      
                  return (
                 
                    <Form
                      onLoad={() => {
                        setTimeout(() => {
                          this.readySubmit();
                        }, 250);
                      }}
                      onChange={() => {
                        this.handleFormChange();
                      }}
                      onSubmit={(e: Event) => {
                        e.preventDefault();
                        const cftData = this.handleFormSubmit();
                        let test = '';
                       
                          // We have an ID, so this is an existing CFT, so
                          // we will update that CFT now.
                            test =  upsertCft({
                            variables: {
                              input: cftData
                              
                            }
                          }).then((data) => {
                          

                             success = data.data.createTopSpecialty.success;
                            
                        const NotifyUpserted = () => {
                          return (
                            <SystemAlert
                              autoClose
                              color={success == true ? "success" : "danger"}
                              icon="alert-icon fal fa-check-circle"
                              messageData={{
                                action: success == true ? 'Updated' : 'Error No More Top Sepcialties Allowed',
                                item: cftData.name,
                                id: success == true ? cft.id:'',
                                tail: '...'
                              }}
                            />
                          );
                        };
                 

                        const alert = document && document.getElementById('system-alert-wrapper');
                        if (alert) {
                          ReactDOM.render(<NotifyUpserted />, alert);
                          modalClose(this.props.history);
                        }
                           
                        
                          });
                          
                       
                        

                        // Send a message to the screen.
                       
                      }}
                    >
                      <FormGroup>
                        <React.Fragment>
                          <Input type="text" name="id" id="termId" defaultValue={cft && cft.name ? cft.name : ''} disabled={true} />
                          
                        </React.Fragment>
                        <hr />
                      </FormGroup>

            
                    

                 

                    

                

                      <FormGroup className="mt-2 form-actions d-flex justify-content-between align-items-end">
                        <Button
                          id="saveCftButton"
                          type="submit"
                          size="md"
                          color="primary"
                          title="Save"
                          disabled={!(this.state.submitReady && this.state.formChanged)}
                        >
                          <i className="fal fa-save" />
                          <span>Save Most Searched Specialty</span>
                        </Button>
            
                      </FormGroup>
                    </Form>
                  );
                }}
              </Mutation>
            </React.Fragment>
          );
        
    
    
   
    
  }
}



