/* @flow */
/* eslint-disable no-unused-vars */
import * as React from 'react';
import { umlsInternalSearch, umlsSearch } from 'unifiedMedicalLanguageSystem/queries';
import { Query } from 'react-apollo';
import { clearSearch } from 'functions/SectionFunctions';
import { animateScroll } from 'functions/Functions';
import { Button } from 'reactstrap';
import type { UmlsSearchProps, UmlsSearchState, UmlsTerm, UmlsFeedProps } from 'clinicalFocusTerm/types';
import { client } from 'functions/Connect';
import { Link } from 'react-router-dom';

/**
 * Component to handle search field.
 */
class UmlsSearch extends React.Component<UmlsSearchProps, UmlsSearchState> {
  waitInterval: number;
  enterKey: number;
  timer: any;

  constructor(props: UmlsSearchProps) {
    super(props);
    this.state = {
      filter: '',
      first: 10
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.triggerChange = this.triggerChange.bind(this);
    this.reFocus = this.reFocus.bind(this);
    this.clearFilter = this.clearFilter.bind(this);

    this.waitInterval = 1000;
    this.enterKey = 13;
  }

  reFocus = (target: HTMLInputElement) => {
    const elemId = target.getAttribute('id');
    const element = document && elemId && document.getElementById(elemId);
    if (typeof element !== 'undefined' && element !== null && element instanceof HTMLInputElement) {
      element.focus();
    }
  };

  clearFilter = () => {
    this.setState(() => ({
      filter: ''
    }));
  };

  handleChange = ({ target }: { target: HTMLInputElement }) => {
    clearTimeout(this.timer);
    this.timer = setTimeout(
      function doChange() {
        this.triggerChange(target);
      }.bind(this),
      this.waitInterval
    );
  };

  handleKeyDown = (e: KeyboardEvent) => {
    if (e.key === this.enterKey) {
      const target = e.target;
      if (target instanceof HTMLInputElement) {
        const attribute: string | null | void = target.getAttribute('id');
        const elemId: string = attribute && typeof attribute === 'string' ? attribute : '';
        const element: HTMLElement | null = document.getElementById(elemId);
        if (typeof element !== 'undefined' && element !== null && element instanceof HTMLInputElement) {
          this.triggerChange(target);
        }
      }
    }
  };

  triggerChange = (target: HTMLInputElement) => {
    this.setState(() => ({
      filter: target.value ? target.value : ''
    }));
    if (target.value) {
      this.reFocus(target);
    }
  };

  render() {
    const QueryResults = () => {
      if (this.state.filter !== '') {
        return (
          <Query
            query={umlsSearch}
            pollInterval={0}
            notifyOnNetworkStatusChange
            variables={{
              filter: this.state.filter,
              pageSize: this.state.first,
              pageNumber: 1
            }}
            fetchPolicy="cache-and-network"
          >
            {({ loading, data, fetchMore, networkStatus }) => {
              // Skip loading icon/flash when we are polling for new results
              // or getting more results via fetchMore.
              if (loading && networkStatus !== 6 && networkStatus !== 3) {
                // Handle loading screen.

                return (
                  <div className={`text-center loading mt-4`}>
                    <i className="fal fa-spinner" />
                  </div>
                );
              }

              const terms =
                data && data.umlsSearch && data.umlsSearch.result.results.length >= 1
                  ? data.umlsSearch.result.results
                  : [];
              const page = data && data.umlsSearch ? data.umlsSearch.pageNumber : 1;

              if (terms.length >= 1) {
                const umlsResults = terms.map((term: UmlsTerm, id: number) => {
                  return (
                    <Query
                      key={id}
                      query={umlsInternalSearch}
                      pollInterval={0}
                      notifyOnNetworkStatusChange
                      variables={{
                        umls_cuid: term.cuid
                      }}
                      fetchPolicy="cache-and-network"
                    >
                      {({ data, loading, error }) => {
                        if (loading || error) return null;

                        const result = data && data.cftTerm && data.cftTerm.id ? data.cftTerm : {};
                        const cft = Object.assign({}, result, {
                          exists: !!(result && result.id)
                        });

                        const ActionButton = () => {
                          if (cft.exists) {
                            return (
                              <Link
                                to={`/cft/edit/${cft.slug}`}
                                title={`Edit ${term.name}, which already exists in the CFT database.`}
                              >
                                <i className="fas fa-edit" />
                              </Link>
                            );
                          } else {
                            return (
                              <Button
                                onClick={(e: Event) => {
                                  e.preventDefault();
                                  this.props.termSelected(term);
                                }}
                                title={`Add ${term.name} to the CFT database.`}
                              >
                                <i className="fas fa-plus" />
                              </Button>
                            );
                          }
                        };

                        // console.log(cft.exists);

                        return (
                          <tr id={term.cuid} className={`umls-term${cft.exists ? ' text-success term-exists' : ''}`}>
                            <td className={`umls-term--name`}>{term.name}</td>
                            <td className={`umls-term--name`}>{cft.name ? cft.name : 'N/A'}</td>
                            <td className={`umls-term--cuid`}>{term.cuid}</td>
                            <td className={`umls-term--actions text-center`}>
                              <ActionButton />
                            </td>
                          </tr>
                        );
                      }}
                    </Query>
                  );
                });

                return (
                  <UmlsFeed
                    results={umlsResults}
                    onLoadMore={() =>
                      fetchMore({
                        variables: {
                          pageNumber: page + 1
                        },
                        updateQuery: (prevResult, { fetchMoreResult }) => {
                          if (!fetchMoreResult) return prevResult;
                          if (prevResult === fetchMoreResult) return prevResult;

                          const updatedResultSet = Object.assign({}, prevResult, {
                            umlsSearch: Object.assign({}, prevResult.umlsSearch, {
                              pageNumber: fetchMoreResult.umlsSearch.pageNumber,
                              result: Object.assign({}, prevResult.umlsSearch.result, {
                                results: [
                                  ...prevResult.umlsSearch.result.results,
                                  ...fetchMoreResult.umlsSearch.result.results
                                ]
                              })
                            })
                          });

                          return updatedResultSet;
                        }
                      })
                        .then(response => {
                          // A 75px offset leaves the last previous result partially
                          // in view. A 100px offset puts the first new row exactly
                          // at the top of the viewport.
                          animateScroll(
                            response.data.umlsSearch.result.results[0].cuid, // The primary element we are scrolling to.
                            'dml-modal', // Scrolling wrapper.
                            75, // Offset Adjustment.
                            350 // Duration of scroll.
                          );
                        })
                        .catch(err => {
                          console.warn('FATAL ERROR IN UmlsSearch.');
                          console.error(err);
                        })
                    }
                  />
                );
              }
              return null;
            }}
          </Query>
        );
      }

      return null;
    };

    const visibilityClass = this.state.filter && this.state.filter.length >= 1 ? 'active-search ' : '';
    return (
      <React.Fragment>
        <div id="umls-search-field" className={`${visibilityClass} umls-search-form--wrapper`}>
          <input
            id="umlsSearch"
            defaultValue=""
            className="search umls-search umls-search--field"
            type="text"
            placeholder="Search UMLS for term..."
            onChange={this.handleChange}
            onKeyDown={this.handleKeyDown}
          />
          <button className="btn search-icon">
            <i className="fal fa-search" />
          </button>
          <button
            className="btn close-icon"
            onClick={() => {
              clearSearch('umlsSearch', this.clearFilter);
            }}
          >
            <i className="fas fa-times" />
          </button>
        </div>

        <QueryResults />
      </React.Fragment>
    );
  }
}

class UmlsFeed extends React.Component<UmlsFeedProps> {
  render() {
    const HeaderRows = () => {
      return (
        <tr>
          <th scope="col">Umls Name</th>
          <th scope="col">Dml Name</th>
          <th scope="col">CUID</th>
          <th scope="col" className={`text-center`}>
            Actions
          </th>
        </tr>
      );
    };

    // console.log(this.props.results);

    return (
      <React.Fragment>
        <div className={``}>
          <div className={`text-success`} />
        </div>

        <table className="table table-hover mt-4">
          <tbody>
            <HeaderRows />
            {this.props.results}
          </tbody>
        </table>

        <div className="text-center">
          <Button color="primary" className={`btn btn-large button--load-more mt-3`} onClick={this.props.onLoadMore}>
            Load more
          </Button>
        </div>
      </React.Fragment>
    );
  }
}

export default UmlsSearch;
