import gql from 'graphql-tag';

// Version that supported pagination via mariadb
// export const specialtyConnectionQuery = gql`
//   query specialties($before: String, $after: String, $first: Int!, $filter: String, $orderBy: SpecialtyOrdering) {
//     specialties(before: $before, after: $after, first: $first, filter: $filter, orderBy: $orderBy) {
//       pageInfo {
//         hasNextPage
//         hasPreviousPage
//         startCursor
//         endCursor
//       }
//       aggregate {
//         count
//       }
//       edges {
//         node {
//           id
//           slug
//           name
//           status
//           cftTerms {
//             id
//           }
//         }
//       }
//     }
//   }
// `;

export const searchSpecialtyQuery = gql`
  query specialties($filter: String, $orderBy: SpecialtyOrdering,  $first: Int, $afterCurser: String) {
    specialties(filter: $filter, orderBy: $orderBy, first: $first,afterCurser: $afterCurser) {
      edges {
        node {
      id
      name
        }
      }
    }
  }
`;

export const topspecialtyConnectionQuery = gql`
  query topSpecialties($filter: String, $orderBy: TopSpecialtyOrdering,  $offset: Int, $limit: Int) {
    topSpecialties(filter: $filter, orderBy: $orderBy, offset: $offset, limit: $limit) {

      
        
      id
      order
     

      specialty{
        id
        name
      }
     
  
    
  }
    }
  
`;

/**
 * Query for getting a single CFT.
 */
export const topspecialtyInfoQuery = gql`
  query topSpecialty($specialty_id: ID) {
    topSpecialty(id: $specialty_id) {
      id
      order
      specialty{
        id
        name
      }
     
    }
  }
`;


/**
 * Mutation to handle creating a new CFT.
 */
 export const insertTopSpecialty = gql`
 mutation createTopSpecialty($input: createTopSpecialtyInput!) {
  createTopSpecialty(input: $input) {
     success
  
   }
 }
`;


export const RemoveTopSpecialty = gql`
   mutation deleteTopSpecialty($input: deleteTopSpecialtyInput!){
    deleteTopSpecialty(input: $input){
      success
    }
   }
   `;

   export const UpdateTopSpecialty = gql`
   mutation updateTopSpecialty($input: updateTopSpecialtyInput!){
    updateTopSpecialty(input: $input){
      success
    }
   }
   `;

