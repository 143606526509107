/* @flow */
/* eslint-disable no-unused-vars */
import * as React from 'react';
import ModalSection, { modalClose, modalHide } from 'elements/ModalSection';

import type { EditProviderProps } from 'provider/types';
// import { ProviderEditForm, ProviderCftForm, ProviderGroupForm, ProviderSpecialtyForm, ProviderSynonymForm } from './'
import { ProviderCftForm, ProviderDataForm, ProviderSpecialtyForm, ProviderGroupForm,ProviderTrulyAwardsForm } from './';
import { ExcludedTerms } from 'excludedTerm';
import { SpecialtiesList } from './SpecialtiesList';



export class EditProviderModal extends React.Component<EditProviderProps> {
  constructor(props) {
    super(props);
    this.state = {
      pageRefresh:0
    }
  }

  updatePageRefresh = () => {
    this.setState(prevState => ({
      pageRefresh: (prevState.pageRefresh+1),
    }));
  }

  componentDidMount() {
    setTimeout(() => {
      ModalSection.openModal();
    }, 100);
  }

  render() {
    return (
      <ModalSection
        heading={`Manage Provider Overrides`}
        content={
          <React.Fragment>
            <ProviderDataForm
              history={this.props.history}
              match={this.props.match}
              provider={this.props.provider}
              search={this.props.search}
            />
            <ProviderSpecialtyForm provider={this.props.provider} updatePageRefresh={this.updatePageRefresh} />
            <SpecialtiesList provider={this.props.provider} />
            {/* <ExcludedTerms provider={this.props.provider} /> */}
            <ProviderCftForm provider={this.props.provider} />
            <ProviderGroupForm provider={this.props.provider} />
            <ProviderTrulyAwardsForm provider={this.props.provider} awards={this.props.awards} />
          </React.Fragment>
        }
        actions={false}
        history={this.props.history}
      />
    );
  }
}
