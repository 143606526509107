/* @flow */
/* eslint no-unused-vars: "off" */
import * as React from 'react';

import ModalSection, { modalClose, modalHide } from 'elements/ModalSection';
import { CftEditForm } from 'clinicalFocusTerm';
import type { AddCftProps } from 'clinicalFocusTerm/types';

export class NewCftModal extends React.Component<AddCftProps> {
  componentDidMount() {
    setTimeout(() => {
      ModalSection.openModal();
    }, 100);
  }

  render() {
    const emptyCft = {
      slug: '0'
    };
    return (
      <ModalSection
        heading="Add New CFT"
        content={
          <CftEditForm
            history={this.props.history}
            match={this.props.match}
            cft={emptyCft}
            search={this.props.search}
            newTerm
          />
        }
        actions={false}
        history={this.props.history}
      />
    );
  }
}
