/** This Code creates a reducer that we can use to manipulate state for synonyms **/
/* eslint no-unused-vars: "off" */
import React, { useEffect } from 'react';
import { useQuery, useMutation } from '@apollo/react-hooks';

import * as Queries from 'synonym/queries';

export const fetchSynonyms = termID => {
  
  const {
    loading: synonymsLoading,
    data: { cftTerm, synonyms },
    refetch
  } = useQuery(Queries.GET_CFT_SYNONYMS, {
    variables: {
      termID: termID
    }
  });
  if (!synonymsLoading) {
    return { cftTerm, synonyms, refetch };
  }
  return { synonyms: [], refetch };
};

export const searchSynonyms = search => {

  const {
    loading,
    data: { synonyms }
  } = useQuery(Queries.GET_POSSIBLE_DML_SYNONYMS, { variables: { search: search } });
  if (!loading) {
    return synonyms;
  } else {
    return [];
  }
};

export const associateSynonym = (variables, associationMutation, refetch) => async () => {
  const {
    data: {
      createTermSynonymAssociation: { success }
    }
  } = await associationMutation({ ...variables });
  if (success) {
    refetch();
  }
};
export const createSynonym = (variables, creationMutation, refetch, setcreatedTerm,setModal) => async () => {
  const {
    data: {
      createSynonym: { success }
    }
  } = await creationMutation({ ...variables });
  if (success) {
    setcreatedTerm('');
    if(setModal){
     setModal(false);
    }
    refetch();
   
    
    
  }
};

export const dissociateSynonym = async (variables, dissociationMutation, refetch, demotionMutation) => {
  if (demotionMutation) {
    await demotionMutation({ ...variables });
  }
  const {
    data: {
      removeTermSynonymAssociation: { success }
    }
  } = await dissociationMutation({ ...variables });
  if (success) {
    refetch();
  }
};

export const promoteSynonym = (variables, promotionMutation, refetch) => async () => {
  const {
    data: {
      updateTerm: { success }
    }
  } = await promotionMutation({ ...variables });
  if (success) {
    refetch();
  }
};

export const demoteSynonym = (variables, demotionMutation, refetch) => async () => {
  const {
    data: {
      updateTerm: { success }
    }
  } = await demotionMutation({ ...variables });
  if (success) {
    refetch();
  }
};
