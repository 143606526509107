/* @flow */

import React from 'react';
import { Icon } from './';

const Info = props => {
  return <Icon icon="fa-info-circle" {...props} />;
};

export default Info;
