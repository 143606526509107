/* @flow */
import React from 'react';
import { Association } from './';
import type { ProviderAssociationPackage } from 'provider/types';

type Props = {
  associate: (element: ProviderAssociationPackage, slug: string) => void,
  children: string,
  addable: Object,
  slug: string
};

const AssociationAddable = (props: Props) => {
  return <Association {...props} />;
};

AssociationAddable.defaultProps = {
  colors: {
    badge: 'tertiary',
    button: 'success'
  },
  icon: 'fa-plus'
};

export default AssociationAddable;
