import React, { Component } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { FormControl, FormGroup, InputLabel } from '@material-ui/core';
import { Label } from 'reactstrap';
import 'bootstrap/dist/css/bootstrap.min.css'; // Ensure your Bootstrap styles are imported

class InlineDatePicker extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedDate: this.props.selectedDate?this.props.selectedDate:null,
    };
  }

  handleDateChange = (date) => {
    this.setState({ selectedDate: date });
    if (this.props.onDateChange) {
      this.props.onDateChange(date);
    }
  };

 

  render() {
    return (
      <FormControl className="ml-2">
        <Label for="timePicker">{this.props.label}</Label>
        <DatePicker
          name={this.props.name}
          selected={this.state.selectedDate}
          value={this.state.selectedDate}
          onChange={this.handleDateChange}
          className="form-control"
          id="datePicker"
          format="mm-dd-yyyy"
        />
      </FormControl>
    );
  }
}

export default InlineDatePicker;
