/* @flow */
/* eslint-disable no-unused-vars */
import * as React from 'react';
import { Alert, Badge, Button, Form, FormGroup, FormFeedback, Label, Input, FormText } from 'reactstrap';

import { insertUserMutation, updateUserMutation, userDeleteMutation, userInfoQuery, userNameQuery,authItemListQuery } from 'user/queries';
import { Query, Mutation } from 'react-apollo';
import ModalSection, { modalClose, modalHide } from 'elements/ModalSection';
import { client } from 'functions/Connect';
import { SystemAlert } from 'elements/SystemAlert';
import ReactDOM from 'react-dom';
import type { AddUserProps, DeleteUserProps, EditUserProps, EditUserState,RoleData} from 'user/types';
import SectionDropdownField from 'elements/StandardSectionDropdownField';
import { doUsernamesMatch } from 'functions/AuthFunctions';

export class NewUserModal extends React.Component<AddUserProps> {
  componentDidMount() {
    setTimeout(() => {
      ModalSection.openModal();
    }, 100);
  }

  render() {
    const emptyUser = {
      id: ''
    };
    return (
      <ModalSection
        heading="Add New User"
        content={
          <UserEditForm
            history={this.props.history}
            match={this.props.match}
            user={emptyUser}
            search={this.props.search}
            newUser
          />
        }
        actions={false}
        history={this.props.history}
      />
    );
  }
}

export class EditUserModal extends React.Component<EditUserProps> {
  componentDidMount() {
    setTimeout(() => {
      ModalSection.openModal();
    }, 100);
  }

  render() {
    return (
      <ModalSection
        heading={`Edit User`}
        content={
          <React.Fragment>
            <UserEditForm
              history={this.props.history}
              match={this.props.match}
              user={this.props.user}
              search={this.props.search}
              newUser={false}
            />
          </React.Fragment>
        }
        actions={false}
        history={this.props.history}
      />
    );
  }
}

class UserEditForm extends React.Component<EditUserProps, EditUserState> {
  waitInterval: number;
  enterKey: number;
  timer: any;

  constructor(props: {}) {
    super(props);
    this.state = {
      id: this.props.user && this.props.user.id ? this.props.user.id : false,
      name: '',
      validName: true,
      password: '',
      passwordMatch: '',
      passwordStrength: '',
      passwordBadge: null,
      submitAvailable: false,
      formChanged: false,
      formSubmitted: false,
      deleteRequest: false,
      deleteGroupSlug: false,
      newUser: typeof this.props.newUser !== 'undefined' && this.props.newUser === true,
      custom: false
    };

    this.handleFormChange = this.handleFormChange.bind(this);
    this.handleFormSaved = this.handleFormSaved.bind(this);
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.handleDeleteSubmit = this.handleDeleteSubmit.bind(this);
    this.changePassword = this.changePassword.bind(this);
    this.changeConfirmPassword = this.changeConfirmPassword.bind(this);
    this.passwordsMatch = this.passwordsMatch.bind(this);
    this.changeUsername = this.changeUsername.bind(this);
    this.handleUsernameChange = this.handleUsernameChange.bind(this);
    this.readySubmit = this.readySubmit.bind(this);
    this.passwordStrengthTest = this.passwordStrengthTest.bind(this);

    this.waitInterval = 500;
    this.enterKey = 13;
  }

  readySubmit = () => {
    setTimeout(async () => {
      // console.groupCollapsed(`Running readySubmit()...`);
      // Start out as a failure.
      let status = false;

      // Means we are on an edit screen.
      if (!!this.state.id) {
        // console.log(`This is an existing user. We are on the edit user screen.`);
        const doPasswordsMatch = this.passwordsMatch();
        const isPasswordFilled = this.state.password.length >= 3;
        // console.log(isPasswordFilled);
        // console.log(doPasswordsMatch);

        // This means we are updating the password.
        if (isPasswordFilled && doPasswordsMatch) {
          status = true;
        }

        // This means an Admin user (since standard users can't) edited either the
        // is_admin or status flags on the user.
        if (!isPasswordFilled && this.state.formChanged) {
          status = true;
        }
      }
      // Means we are on a create screen.
      else {
        // console.log(`This is a new user. We are on the create user screen.`);
        const isUsernameValid = await this.usernameCheck(this.state.name);
        const isUsernameFilled = this.state.name.length >= 3;
        const doPasswordsMatch = this.passwordsMatch();
        const isPasswordFilled = this.state.password.length >= 3;
        if (doPasswordsMatch && isPasswordFilled && isUsernameValid && isUsernameFilled) {
          status = true;
        }
      }

      // console.log(`status: ${status.toString()}`);
      // console.groupEnd()
      this.setState(() => ({
        submitAvailable: status
      }));
    }, 500);
  };

  changeUsername = val => {
    this.setState(() => ({
      name: val
    }));
  };

  handleUsernameChange = ({ target }: { target: HTMLInputElement }) => {
    clearTimeout(this.timer);
    this.timer = setTimeout(
      async function doChange() {
        const suppliedName = target.value;
        const validatedName = suppliedName.replace(/[^\w\s-]/gi, '');
        if (validatedName !== suppliedName) {
          // Since the user submitted a name with disallowed chars, we reset the field value.
          target.value = validatedName;
        }
        if (validatedName.length >= 1) {
          const validUsername = await this.usernameCheck(validatedName);
          this.setState(() => ({
            name: validatedName,
            validName: validUsername
          }));
        } else {
          this.setState(() => ({
            name: '',
            validName: true
          }));
        }
        this.readySubmit();
      }.bind(this),
      this.waitInterval
    );
  };

  usernameCheck = val => {
    const match: boolean = client
      .query({
        query: userNameQuery,
        // Ensure we ALWAYS reach out immediately and don't rely on cache for this query.
        fetchPolicy: 'no-cache',
        variables: {
          username: val
        }
      })
      .then(response => {
        const data = response.data.user;
        if (data !== null && data.id) {
          return false;
        }
        return true;
      })
      .catch(error => console.log(error));

    return match;
  };

  /**
   * @see https://www.thepolyglotdeveloper.com/2015/05/use-regex-to-test-password-strength-in-javascript/
   */
  passwordStrengthTest = val => {
    const strongRegex = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})');
    const mediumRegex = new RegExp(
      '^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,})'
    );

    if (strongRegex.test(val)) {
      this.setState(() => ({
        passwordStrength: 'strong',
        passwordBadge: <Badge color="success">strong</Badge>
      }));
    } else if (mediumRegex.test(val)) {
      this.setState(() => ({
        passwordStrength: 'average',
        passwordBadge: <Badge color="warning">average</Badge>
      }));
    } else {
      if (val.length >= 1) {
        this.setState(() => ({
          passwordStrength: 'weak',
          passwordBadge: <Badge color="danger">weak</Badge>
        }));
      } else {
        this.setState(() => ({
          passwordStrength: null
        }));
      }
    }
  };

  changePassword = val => {
    this.setState(() => ({
      password: val
    }));
    this.readySubmit();
  };

  changeConfirmPassword = val => {
    this.setState(() => ({
      passwordMatch: val
    }));
    this.readySubmit();
  };

  passwordsMatch = () => {
    return this.state.password === this.state.passwordMatch;
  };

  handleFormChange = async () => {
    this.readySubmit();
    this.setState(() => ({
      formChanged: true
    }));
  };

  handleFormSaved = () => {
    this.setState(() => ({
      formChanged: false
    }));
  };

  handleDeleteSubmit = id => {
    modalHide();

    // Wait a tick and change the state, which will open the delete modal.
    setTimeout(() => {
      this.setState(() => ({
        deleteRequest: true,
        deleteUserId: id
      }));
    }, 500);
  };

  /**
   * @todo: Handle sanitizing bogus input. #TRUSTNOONE
   */
  handleFormSubmit = () => {
    const status = document && document.querySelector('input[name="userActiveStatus"]:checked');
    const admin = document && document.getElementById('userAdminStatus');
    const username = document && document.getElementById('userName');
    const password = document && document.getElementById('userPassword');
    let userName, userPassword, userStatus, userAdmin;

    if (typeof status !== 'undefined' && status !== null && status instanceof HTMLInputElement) {
      userStatus = status.value === 'enabled'; // Returns true or false;
    }

    
    
      userAdmin = admin.textContent; // Returns true or false;
    

    if (typeof username !== 'undefined' && username !== null && username instanceof HTMLInputElement) {
      userName = username.value;
    }

    if (typeof password !== 'undefined' && password !== null && password instanceof HTMLInputElement) {
      userPassword = password.value;
    }

    // Create our initial Payload & definition.
    const userPayload: {
      username?: string,
      password?: string,
      status: boolean,
      is_admin?: string
    } = {
      // These can be sent safely every time, even if they are hidden to the user.
      status: userStatus ? userStatus : false,
      is_admin: userAdmin ? userAdmin : ''
    };

    // If we don't have a user id, then we are creating a new user and must send
    // the username of the new user. Otherwise, on edit screens, it is omitted.
    if (!this.state.id) {
      userPayload.username = userName ? userName : '';
    }

    // If the password is NOT an empty string (edit user without updating password).
    if (userPassword && userPassword.length !== 0) {
      userPayload.password = userPassword;
    }

    return userPayload;
  };

  render() {
    //console.log(this.state.newUser);
    const query = this.state.newUser !== true ? userInfoQuery : authItemListQuery;
    return (
     
      <Query
        query={query}
        pollInterval={0}
        notifyOnNetworkStatusChange={true}
        variables={{
          id: this.props.user && this.props.user.id ? this.props.user.id : ''
        }}
        fetchPolicy="cache-and-network"
      >
        {({ data, loading }) => {
          if (loading) return null;
          let user = null;
          if(this.state.newUser !== true){
           user =
            data && data.user
              ? data.user
              : {
                  // Set the default form values.
                  id: false,
                  username: '',
                  status: false,
                  is_admin: false
                };
          }
          else{
            user = {
              // Set the default form values.
              id: false,
              username: '',
              status: false,
              is_admin: false
            };
          }
          const authItems:[RoleData] | boolean =  data && data.authItems ? data.authItems : false;
          
        
      
          const passwordMatch = this.passwordsMatch();
          const passwordsFilled = this.state.password.length >= 1 && this.state.passwordMatch.length >= 1;
          const usernameFilled = this.state.name.length >= 1;
          const upsertMutation = user.id ? updateUserMutation : insertUserMutation;
          const noPass = this.state.passwordStrength === '' || this.state.password.length === 0;
          const notAverage = this.state.passwordStrength !== 'strong' && this.state.passwordStrength !== 'average';
          const notStrong = this.state.passwordStrength !== 'strong';
          var roleOptions = [];
          let roleDescriptions=[];
          
          for(let i=0; i < authItems.length; i++){
            
            roleOptions.push({ value: authItems[i].name, label: authItems[i].name })
            roleDescriptions.push(authItems[i].description);
          }
          const roleList = authItems.map((authItem)=>
          <p> <i className="fal fa-info-circle" /> Currently, an <em>{authItem.name}</em> <em>{authItem.description}</em>.</p>);
          // const submitAvailable = this.readySubmit(user);
         

          return (
            <React.Fragment>
              <Alert color="warning" className={`mb-3 ${this.state.formChanged ? 'd-block' : 'd-none'}`}>
                <div className="alert--with-icon">
                  <i className="alert-icon fal fa-info-circle" />
                  <span>
                    The form has been updated. Use the <strong>Save User</strong> button to avoid losing any changes.
                  </span>
                </div>
              </Alert>

              <Mutation mutation={upsertMutation}>
                {(upsertGroup, { loading, error }) => {
                  if (loading) {
                    return null;
                  }
                  if (error) {
                    console.log(`ERROR UPSERTING GROUP...`);
                  }

                  return (
                    <Form
                      onSubmit={(e: Event) => {
                        e.preventDefault();
                        const userData = this.handleFormSubmit();

                        if (user && user.id) {
                          upsertGroup({
                            variables: {
                              input: {
                                userId: user.id,
                                update: userData
                              }
                            }
                          });
                        } else {
                          upsertGroup({
                            variables: {
                              input: userData
                            }
                          });
                        }

                        // Send a message to the screen.
                        const NotifyUpserted = () => {
                          return (
                            <SystemAlert
                              autoClose
                              color="success"
                              icon="alert-icon fal fa-check-circle"
                              messageData={{
                                action: user.id ? 'Updated' : 'Created',
                                item: userData.username ? userData.username : user.username,
                                id: user.id && typeof user.id === 'string' ? user.id : '',
                                tail: '...'
                              }}
                            />
                          );
                        };

                        const alert = document && document.getElementById('system-alert-wrapper');
                        if (alert) {
                          ReactDOM.render(<NotifyUpserted />, alert);
                          modalClose(this.props.history);
                        }
                      }}
                    >
                      <FormGroup>
                        <React.Fragment>
                          <Input type="hidden" name="id" id="userId" defaultValue={user && user.id ? user.id : ''} />

                          <Label for="userName">Username</Label>
                          <Input
                            onChange={e => {
                              this.handleFormChange();
                              this.handleUsernameChange(e);
                            }}
                            type="text"
                            autoComplete="off"
                            name="name"
                            id="userName"
                            disabled={!!user.id}
                            placeholder={`Enter a valid username...`}
                            defaultValue={user.username}
                            valid={this.state.validName && usernameFilled}
                            invalid={!this.state.validName && usernameFilled}
                          />
                          <FormFeedback valid>
                            <p className="mb-0">
                              <i className="fal fa-info-circle" /> The <strong>username</strong> you have selected is a
                              good one. This is good news!
                            </p>
                          </FormFeedback>
                          <FormFeedback>
                            <p className="mb-0">
                              <i className="fal fa-info-circle" /> The <strong>username</strong> you have selected is
                              already taken, please choose another.
                            </p>
                          </FormFeedback>
                        </React.Fragment>
                        <hr />
                      </FormGroup>

                      <FormGroup>
                        <React.Fragment>
                          <Label for="userPassword" className={`d-flex justify-content-between`}>
                            <span>Password</span>
                            <span className={!noPass ? 'd-block' : 'd-none'}>{this.state.passwordBadge}</span>
                          </Label>
                          <Input
                            onChange={e => {
                              this.handleFormChange();
                              this.passwordStrengthTest(e.target.value);
                              this.changePassword(e.target.value);
                            }}
                            type="password"
                            autoComplete="off"
                            name="password"
                            id="userPassword"
                            placeholder={`Enter a password...`}
                            defaultValue={``}
                            valid={passwordMatch && passwordsFilled}
                            invalid={!passwordMatch && passwordsFilled}
                          />
                          <Input
                            onChange={e => {
                              this.handleFormChange();
                              this.changeConfirmPassword(e.target.value);
                            }}
                            type="password"
                            autoComplete="off"
                            name="password-confirm"
                            id="userPasswordConfirm"
                            placeholder={`Confirm password...`}
                            defaultValue={``}
                            className={`mt-2 ${
                              this.state.password.length >= 1 || this.state.passwordMatch.length >= 1
                                ? 'd-block'
                                : 'd-none'
                            }`}
                            valid={passwordMatch && passwordsFilled}
                            invalid={!passwordMatch && passwordsFilled}
                          />

                          <FormFeedback valid>
                            <p className="mb-0">
                              <i className="fal fa-info-circle" /> The <strong>password</strong> above and the{' '}
                              <strong>password confirmation</strong> match. You are really good at this!
                            </p>
                          </FormFeedback>

                          <FormFeedback>
                            <p className="mb-0">
                              <i className="fal fa-info-circle" /> The <strong>password</strong> above and the{' '}
                              <strong>password confirmation</strong> do not match. Please ensure these field match
                              before proceeding.
                            </p>
                          </FormFeedback>

                          <FormText color="muted" className={notStrong && !noPass ? 'd-block' : 'd-none'}>
                            <i className="fal fa-info-circle" /> A{' '}
                            <strong>
                              <span className="text-success">strong</span>
                            </strong>{' '}
                            password is at least 8 characters long, contains both lower and uppercase letters, contains
                            at least one number and one special character.
                          </FormText>

                          <FormText color="muted" className={notAverage && !noPass ? 'd-block' : 'd-none'}>
                            <i className="fal fa-info-circle" /> An{' '}
                            <strong>
                              <span className="text-warning">average</span>
                            </strong>{' '}
                            password is at least 6 characters long and contains both lower and uppercase letters or at
                            least one number.
                          </FormText>

                          <FormText color="muted" className={user.id ? 'd-block' : 'd-none'}>
                            <i className="fal fa-info-circle" /> The <strong>password</strong> and{' '}
                            <strong>password confirmation</strong> fields are only required if you are updating the
                            password for this user, <strong>{user.username}</strong>.
                          </FormText>
                        </React.Fragment>
                        <hr />
                      </FormGroup>

                      <FormGroup
                        className={`fad-profile-options mb-0 ${doUsernamesMatch(user.username) ? 'd-none' : 'd-block'}`}
                      >
                        <legend>User Type</legend>
                        
                        
                          <FormGroup>
                          <SectionDropdownField
                            id='userAdminStatus'
                            //filter={this.props.data.filter}
                            onChange={this.handleFormChange}
                            element={{
                              id: 'roleList',
                              placeholder: 'Search ...'
                            }}
                            options= {
                              roleOptions
                            }
                            
                            default={this.state.newUser !== true ?{value:user.role[0].item_name, label:user.role[0].item_name}:''}
                          />
                          </FormGroup>
              
                        
                        <FormText color="muted">
                         {roleList}
                        </FormText>
                        <hr />
                      </FormGroup>

                      <FormGroup className={`${doUsernamesMatch(user.username) ? 'd-none' : 'd-block'}`}>
                        <legend>User Status</legend>
                        <div className="form-radio-group">
                          <FormGroup check>
                            <Label check>
                              <Input
                                onChange={this.handleFormChange}
                                type="radio"
                                name="userActiveStatus"
                                value="enabled"
                                defaultChecked={user.status ? 'checked' : false}
                              />{' '}
                              <span className="text-success">Enabled</span>
                            </Label>
                          </FormGroup>
                          <FormGroup check>
                            <Label check>
                              <Input
                                onChange={this.handleFormChange}
                                type="radio"
                                name="userActiveStatus"
                                value="disabled"
                                defaultChecked={user.status ? false : 'checked'}
                              />{' '}
                              <span className="text-warning">Disabled</span>
                            </Label>
                          </FormGroup>
                        </div>
                        <FormText color="muted">
                          <i className="fal fa-info-circle" /> This determines if a user will be able to log in or not.
                        </FormText>
                        <hr />
                      </FormGroup>

                      <FormGroup className="mt-2 form-actions d-flex justify-content-between align-items-end">
                        <Button
                          id="saveUserButton"
                          type="submit"
                          size="md"
                          color="primary"
                          title="Save"
                          disabled={!this.state.submitAvailable}
                        >
                          <i className="fal fa-save" />
                          <span>Save User</span>
                        </Button>
                        <Button
                          outline
                          className={`${doUsernamesMatch(user.username) || !user.id ? 'd-none' : 'd-inline-block'}`}
                          size="md"
                          color="danger"
                          title={`Delete ${user.username}`}
                          onClick={() => {
                            this.handleDeleteSubmit(user.id);
                          }}
                        >
                          <i className="fal fa-trash-alt" />
                          <span>Delete User</span>
                        </Button>
                      </FormGroup>
                    </Form>
                  );
                }}
              </Mutation>
            </React.Fragment>
          );
        }}
      </Query>
    );
  }
}

/**
 * Component to handle a delete confirmation message.
 */
export class DeleteUserModal extends React.Component<DeleteUserProps> {
  componentDidMount() {
    setTimeout(() => {
      ModalSection.openModal();
    }, 100);
  }

  render() {
    return (
      <ModalSection
        heading="Delete User"
        content={<UserDeleteForm history={this.props.history} user={this.props.user} />}
        actions={false}
        history={this.props.history}
      />
    );
  }
}

class UserDeleteForm extends React.Component<DeleteUserProps> {
  render() {
    return (
      <React.Fragment>

        <Query
          query={authItemListQuery}
          pollInterval={0}
          notifyOnNetworkStatusChange={true}
          variables={{
            id: this.props.user && this.props.user.id ? this.props.user.id : ''
          }}
          fetchPolicy="cache-and-network"
        >
          {({ data, loading }) => {
            if (loading) return null;
            const {user} =
              data && data.user
                ? data.user
                : {
                    // Set the default form values.
                    id: null,
                    username: '',
                    status: false,
                    is_admin: false
                  };

            const ConfirmationDetails = () => {
              if (data && data.user) {
                return (
                  <React.Fragment>
                    <Alert color="danger" className="mb-3 delete-confirmation">
                      <div className="alert--with-icon">
                        <i className="alert-icon fal fa-trash-alt" />
                        <h4 className="mb-0">Are you sure you want to delete following User?</h4>
                      </div>
                    </Alert>
                    <hr />
                    <div className="delete-confirmation--details">
                      <p>
                        <span className="delete-confirmation-label">User ID: </span>
                        <strong>{user && user.id ? user.id : ''}</strong>
                      </p>
                      <p>
                        <span className="delete-confirmation-label">Username: </span>
                        <strong>{user && user.username ? user.username : ''}</strong>
                      </p>
                    </div>
                    <hr />
                  </React.Fragment>
                );
              }
              return null;
            };

            return (
              <React.Fragment>
                <Mutation
                  mutation={userDeleteMutation}
                  variables={{
                    input: {
                      id: user && user.id ? user.id : null
                    }
                  }}
                >
                  {(deleteGroup, { data, loading }) => {
                    if (loading) {
                      return null;
                    }
                    const success = data && data.removeUser && data.removeUser.success;

                    if (success) {
                      const status = {
                        success: success,
                        action: success ? 'Deleted' : 'Failed to Delete',
                        item: {
                          id: data && data.removeUser && data.removeUser.user ? data.removeUser.user.id : user.id,
                          name:
                            data && data.removeUser && data.removeUser.user
                              ? data.removeUser.user.username
                              : user.username
                        },
                        tail: 'from the User database...'
                      };

                      return (
                        <React.Fragment>
                          <Alert color="success">
                            <div className="alert--with-icon">
                              <i className="alert-icon fal fa-check-circle" />
                              <p className="mb-0">
                                Successfully {status.action} <strong>{status.item.name}</strong> (
                                <em>{status.item.id})</em> {status.tail}
                              </p>
                            </div>
                          </Alert>
                          <hr />
                          <div className={`text-center`}>
                            <Button
                              outline
                              className="delete-item-button--success"
                              size="lg"
                              color="success"
                              title="Done"
                              onClick={() => {
                                modalClose(this.props.history);
                              }}
                            >
                              <i className="fal fa-check-circle" />
                              <span>Done</span>
                            </Button>
                          </div>
                        </React.Fragment>
                      );
                    }

                    if (user && user.id !== null) {
                      return (
                        <React.Fragment>
                          <ConfirmationDetails />
                          <div className="text-center">
                            <Button
                              className="delete-item-button"
                              outline
                              size="lg"
                              color="danger"
                              title="Delete"
                              onClick={() => {
                                deleteGroup();
                              }}
                            >
                              <i className="fal fa-trash-alt" />
                              <span>Yes, I'm sure</span>
                            </Button>
                          </div>
                        </React.Fragment>
                      );
                    } else {
                      return (
                        <Alert color="danger">
                          <div className="alert--with-icon">
                            <i className="alert-icon fal fa-info-circle" />
                            <p className="mb-0">That User could not be found...</p>
                          </div>
                        </Alert>
                      );
                    }
                  }}
                </Mutation>
              </React.Fragment>
            );
          }}
        </Query>
      </React.Fragment>
    );
  }
}
