/* @flow */
import * as React from 'react';
import type { PaginationProps } from 'shared/types';
import { Button } from 'reactstrap';

class SectionLoadMoreButton extends React.Component<PaginationProps> {
  render() {
    if (this.props.data.count <= this.props.data.items) {
      return null;
    }

    return (
      <tfoot className="pagination-wrapper">
        <tr>
          <td colSpan={5} className="text-center">
            <Button
              color="primary"
              className={`btn btn-large button--load-more mt-3`}
              onClick={this.props.data.callbacks.onLoadMore}
            >
              Load more
            </Button>
          </td>
        </tr>
      </tfoot>
    );
  }
}

export default SectionLoadMoreButton;
