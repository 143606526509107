/* @flow */
import * as React from 'react';
import { Link } from 'react-router-dom';
import MenuItems from '../data/MenuItems';
import { toggleMobileMenu } from '../functions/Mobile';
import { escapeRegExp } from '../functions/Functions';
import { Alert, Badge, Button, Card, CardBody, CardHeader } from 'reactstrap';
import { ListGroup } from 'reactstrap';
import { isMobile } from '../functions/ResponsiveClasses';
import { modalHide } from '../elements/ModalSection';
import type { SidebarMenuLinkProps, SidebarProps, SidebarState } from '../shared/types';
import type { LocationOnlyProps } from '../shared/types';
import { getStorageValue } from '../functions/AuthFunctions';

class Sidebar extends React.Component<SidebarProps, SidebarState> {
  constructor() {
    super();
    this.state = {
      alertVisible: false
    };

    this.onAlertDismiss = this.onAlertDismiss.bind(this);
  }

  onAlertDismiss = () => {
    this.setState({ alertVisible: false });
  };

  render() {
    const UserData = () => {
      const username = getStorageValue('DML_USER');
      if (this.props.authentication.auth && username) {
        return (
          <Card className={`user-info`}>
            <CardHeader>
              Hello, <strong>{username}</strong>!
            </CardHeader>
            <CardBody className={``}>
              <Button className={`logout-button`} size="sm" color="primary" onClick={this.props.authentication.logout}>
                Logout
              </Button>
            </CardBody>
          </Card>
        );
      }

      return null;
    };
    const cssClasses = isMobile() ? 'nav-hidden' : 'nav-visible';
    return (
      <div id="sidebar" className={cssClasses + ' dml-sidebar'}>
        <div className="sidebar-content">
          <Alert color="info" isOpen={this.state.alertVisible} toggle={this.onAlertDismiss}>
            Many of the menu items here are placeholders only. The{' '}
            <Link to="/cft">
              <strong>CFT</strong>
            </Link>{' '}
            section is in active development and is the default landing page.
          </Alert>
          <SidebarMenu location={this.props.location} links={MenuItems} authentication={this.props.authentication} />
          <UserData />
        </div>
      </div>
    );
  }
}

class SidebarMenu extends React.Component<LocationOnlyProps> {
  render() {
    const listItems = MenuItems.map((item, index) => {
      if (item.auth && this.props.authentication && !this.props.authentication.auth) {
        return null;
      } else {
        return <SidebarMenuLink location={this.props.location} link={item} key={index} />;
      }
    });
    return <ListGroup>{listItems}</ListGroup>;
  }
}

const ensureModalCollapse = () => {
  modalHide();
};

class SidebarMenuLink extends React.Component<SidebarMenuLinkProps> {
  render() {
    const path: string = this.props.location.pathname;
    let DefaultListItemClasses = 'list-group-item list-group-item-action';
    const search = new RegExp(escapeRegExp(this.props.link.uri), 'ig');
    if (path.match(search)) {
      DefaultListItemClasses = 'list-group-item list-group-item-action active';
    }

    const LinkBadge = () => {
      if (!this.props.link.badge) {
        return null;
      }
      return (
        <Badge
          className="sidebar-menu-badge d-md-none d-lg-inline"
          color={
            this.props.link.badge && typeof this.props.link.badge.color === 'string'
              ? this.props.link.badge.color
              : 'primary'
          }
        >
          {this.props.link.badge && typeof this.props.link.badge.text === 'string' ? this.props.link.badge.text : ''}
        </Badge>
      );
    };
    const linkClasses = this.props.link.classes
      ? `${this.props.link.classes} ${DefaultListItemClasses}`
      : DefaultListItemClasses;

    return (
      <Link
        to={this.props.link.uri}
        className={`sidebar-menu-link ${linkClasses}`}
        onClick={() => {
          ensureModalCollapse();
          toggleMobileMenu();
        }}
      >
        <span>{this.props.link.title}</span>
        <LinkBadge />
      </Link>
    );
  }
}

export default Sidebar;
