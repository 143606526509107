/* @flow */
import { doUsernamesMatch, getAuthenticatedUserId, isAuthenticated,getPermissions, isUserAdmin,isUserManager,isPracticeEmails } from 'functions/AuthFunctions';
let MenuItems = [];
MenuItems.push({
  title: 'Dashboard',
  uri: '/dashboard',
  classes: '',
  badge: false,
  auth: false
})

if(getPermissions('terms') == true){
  MenuItems.push({
    title: 'Clinical Focus Terms',
    uri: '/cft',
    classes: '',
    badge: false,
   
    auth: true
  })
}

if(getPermissions('synonyms') == true){
  MenuItems.push({
    title: 'Synonyms',
    uri: '/synonyms',
    classes: '',
    badge: false,
    auth: true
  })
}

if(getPermissions('groups') == true){
  MenuItems.push({
    title: 'Custom Groups',
    uri: '/group',
    classes: '',
    badge: false,
    auth: true
  })
}

if(getPermissions('groups') == true){
  MenuItems.push({
    title: 'Promoted Search',
    uri: '/promoted_search',
    classes: '',
    badge: false,
    auth: true
  })
}

if(getPermissions('specialties') == true){
  MenuItems.push({
    title: 'Specialties',
    uri: '/specialty',
    classes: '',
    badge: false,
    auth: true
  })
}

if(getPermissions('top_specialties') == true){
  MenuItems.push({
    title: 'Most Searched Specialties',
    uri: '/top_specialties',
    classes: '',
    badge: false,
    auth: true
  })
}

if(getPermissions('provider_override') == true){
  MenuItems.push({
    title: 'Physician Overrides',
    uri: '/provider',
    classes: '',
    badge: false,
    auth: true
  })
}

if(getPermissions('emails') == true){
  MenuItems.push({
    title: 'Practice Appointment Emails',
    uri: '/email',
    classes: '',
    badge: false,
    auth: true
  })
}

if(getPermissions('users') == true){
  MenuItems.push({
    title: 'User Management',
    uri: '/user',
    classes: '',
    badge: false,
    auth: true
  })
}

if(getPermissions('logs') == true){
  MenuItems.push({
    title: 'Audit Logs',
    uri: '/audit',
    classes: '',
    badge:false,
    auth: true
  })
}

MenuItems.push({
  title: 'Documentation & FAQ',
  uri: '/faq',
  classes: 'disabled',
  badge: {
        color: 'warning',
        text: 'todo'
      },
  auth: true
})


export default MenuItems;
