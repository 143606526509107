/* @flow */
import { isMobile } from './ResponsiveClasses';

/**
 * Simple method to toggle the appropriate classes and attributes to
 * close the sidebar menu on a mobile breakpoint (xs and sm).
 */
export const toggleMobileMenu = () => {
  // Grab the sidebar element.
  const sidebar = document.getElementById('sidebar');
  // Grab the button used to hide/show the sidebar on mobile.
  const button = document.getElementById('sidebarToggle');
  // If appropriate breakpoint values return true, then
  // adjust classes and attributes as required.
  if (isMobile && typeof sidebar !== 'undefined' && sidebar !== null) {
    sidebar.classList.remove('nav-visible');
    sidebar.classList.add('nav-hidden');
    if (typeof button !== 'undefined' && button !== null) {
      button.setAttribute('aria-expanded', 'false');
    }
  }
};
