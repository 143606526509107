/* eslint no-unused-vars: "off" */
import React, { useState } from 'react';

const Button = ({ label, checked, color, input, type, ...option }) => {
  return (
    <label className={`btn btn-${color} ${checked ? ' active ' : ''}`} style={{ cursor: 'pointer' }}>
      <input type={type} checked={checked} name={input.name} {...option} /> {label}
    </label>
  );
};

Button.defaultProps = {
  color: 'tertiary',
  input: {},
  type: 'radio'
};

const ButtonToggle = ({ className, options, ...props }) => {
  return (
    <div className={`btn-group btn-group-toggle${className ? ` ${className}` : ''}`} {...props}>
      {options.map((element, id) => {
        return <Button key={id} {...element} />;
      })}
    </div>
  );
};

ButtonToggle.defaultProps = {
  color: 'tertiary',
  type: 'checkbox'
};

export default ButtonToggle;
