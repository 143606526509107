import React, { Component } from 'react';
import { client } from 'functions/Connect';
import { updateSpecialtyInheritedListStatusMutation, updateSpecialtyHeaderVisibiltyMutation} from './queries';


class SpecialtyToggle extends Component {
  constructor(props) {
    super(props);
    this.state = {
      is_visible: this.props.data.is_visible,
      enable_inherited_list: this.props.data.enable_inherited_list,
      providerSpecialties:[]
      
    };
  }

  handleToggleHeaderVisibility = () => {
    this.setState(prevState => ({
        is_visible: !prevState.is_visible
    }));
  };

  handleToggleInheritedList = () => {
    this.setState(prevState => ({
        enable_inherited_list: !prevState.enable_inherited_list
    }));
  };

  

  render() {
    
    const submitMutation = async (mutationName, statusColumn, statusValue)=>{
      const inputVars = {
        specialtyId: this.props.data.specialty_id,
        providerId: this.props.data.provider_id,
        [statusColumn]: !statusValue
      };
        const status = await client.mutate({
          mutation: mutationName,
          // Ensure we ALWAYS reach out immediately and don't rely on cache for this query.
          fetchPolicy: 'no-cache',
          variables: {
            input: inputVars
          }
        });
    }
    return (
            <>  <div className='row mr-4 '>
                <div className="sliding-toggle pt-sm-2 px-4">
                <label className="switch">
                <input
                    type="checkbox"
                    checked={this.state.is_visible}
                    onChange={this.handleToggleHeaderVisibility}
                    disabled={false}
                    onClick={()=> submitMutation(updateSpecialtyHeaderVisibiltyMutation, 'is_visible',this.state.is_visible)}
                    value={true}
                />
                <span className="slider round"></span>
                </label>
                <span className='ml-2 pb-8'>
                <strong>Visible in specialty header
               </strong>
               </span>
            </div>
            <div className="sliding-toggle pt-sm-2">
                <label className="switch">
                <input
                    type="checkbox"
                    checked={this.state.enable_inherited_list}
                    onChange={this.handleToggleInheritedList}
                    disabled={false}
                    onClick={()=> submitMutation(updateSpecialtyInheritedListStatusMutation, 'enable_inherited_list', this.state.enable_inherited_list)}
                    value={true}
                />
                <span className="slider round"></span>
                </label>
                <span className='ml-2 pb-8'>
                <strong>Enable Inherited List
               </strong>
               </span>
            </div>
            </div>
           </>
    );
  }
}

export default SpecialtyToggle;
