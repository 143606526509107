/* @flow */
/* eslint-disable no-unused-vars */
import * as React from 'react';
import { Alert, Button, Form, FormGroup, Label, Input, FormText } from 'reactstrap';

import { searchSpecialtyQuery, specialtyInfoQuery } from 'specialty/queries';
import { Query, Mutation } from 'react-apollo';
import ModalSection, { modalClose, modalHide } from 'elements/ModalSection';

import { Redirect } from 'react-router-dom';
import type { EditSpecialtyProps, EditSpecialtiesState } from 'specialty/types';

import AssociationForm from 'elements/AssociationForm';
import MachineName, { uniqueSlugCheck } from 'shared/inputs/MachineName';
import {
  searchCftsQuery,
  associateSpecialtyWithTermMutation,
  cftInfoQuery,
  removeSpecialtyFromTermMutation
} from 'clinicalFocusTerm/queries';

export class EditSpecialtyModal extends React.Component<EditSpecialtyProps> {
  componentDidMount() {
    setTimeout(() => {
      ModalSection.openModal();
    }, 100);
  }

  render() {
    return (
      <ModalSection
        heading={`Edit Specialty`}
        content={
          <React.Fragment>
            <SpecialtyEditForm
              history={this.props.history}
              match={this.props.match}
              specialty={this.props.specialty}
              search={this.props.search}
            />
          </React.Fragment>
        }
        actions={false}
        history={this.props.history}
      />
    );
  }
}

class SpecialtyEditForm extends React.Component<EditSpecialtyProps, EditSpecialtiesState> {
  constructor(props: EditSpecialtyProps) {
    super(props);
    this.state = {
      cfts: []
    };
  }

  render() {
    return (
      <Query
        query={specialtyInfoQuery}
        pollInterval={0}
        notifyOnNetworkStatusChange={true}
        variables={{
          slug: this.props.specialty && this.props.specialty.slug ? this.props.specialty.slug : ''
        }}
        fetchPolicy="cache-and-network"
      >
        {({ data, loading }) => {
          if (loading) return null;

          const specialty =
            data && data.specialty
              ? data.specialty
              : {
                  // Set the default form values.
                  id: '',
                  slug: '',
                  name: '',
                  status: false,
                  cftTerms: []
                };
          return (
            <React.Fragment>
              <Alert color="info" className={`mb-3`}>
                <div className="alert--with-icon">
                  <i className="alert-icon fal fa-info-circle" />
                  <span>
                    This form allows you to edit the relationship(s) between the Specialty{' '}
                    <strong>{specialty.name}</strong> and <em>Clinical focus terms.</em>
                  </span>
                </div>
              </Alert>
              <Form
                onSubmit={(e: Event) => {
                  e.preventDefault();
                }}
              >
                <FormGroup>
                  <React.Fragment>
                    <Input
                      type="hidden"
                      name="id"
                      id="specialtyId"
                      defaultValue={specialty && specialty.id ? specialty.id : ''}
                    />

                    <Label for="specialtyName">Specialty Name</Label>
                    <Input
                      type="text"
                      disabled
                      autoComplete="off"
                      name="name"
                      id="specialtyName"
                      defaultValue={specialty.name}
                    />
                    <FormText color="muted">
                      Specialty slug: <strong>{specialty.slug}</strong>
                    </FormText>
                  </React.Fragment>
                  <hr />
                </FormGroup>

        
              </Form>
            </React.Fragment>
          );
        }}
      </Query>
    );
  }
}
