/* @flow */
import * as React from 'react';
import type { SectionErrorProps } from 'shared/types';
import { Alert } from 'reactstrap';

/**
 * React Component to handle returning an error row when GraphQL queries
 * return an error or zero results, etc.
 */
class SectionError extends React.Component<SectionErrorProps> {
  render() {
    return (
      <Alert color={this.props.color} className={this.props.classes}>
        {this.props.content}
      </Alert>
    );
  }
}

export default SectionError;
