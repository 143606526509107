/* @flow */
/* eslint-disable no-unused-vars */
import * as React from 'react';

import ModalSection, { modalClose, modalHide } from 'elements/ModalSection';
import { TopSpecialtyDeleteForm } from 'topspecialties/forms';
import type { DeleteCftProps } from 'clinicalFocusTerm/types';

export class DeleteTopSpecialtyModal extends React.Component<DeleteCftProps> {
  componentDidMount() {
    setTimeout(() => {
      ModalSection.openModal();
    }, 100);
  }

  render() {
    return (
      <ModalSection
        heading="Delete Most Saved Specialty"
        content={
          <TopSpecialtyDeleteForm
            history={this.props.history}
            cft={this.props.cft}
            search={this.props.search}
            callbacks={this.props.callbacks}
          />
        }
        actions={false}
        history={this.props.history}
      />
    );
  }
}
