import gql from 'graphql-tag';

export const searchServiceLineQuery = gql`
query serviceLines($filter: String) {
    serviceLines(filter: $filter) {
      edges {
        node {
            id
            name
            slug
        }
      }
    }
  }
`;

/**
 * Mutation to handle creating the association between a Specialty and service lines
 */
export const createServiceLineSpecialtyMutation = gql`
  mutation createServiceLineSpecialtyAssociation($input: createServiceLineSpecialtyAssociationInput!) {
    createServiceLineSpecialtyAssociation(input: $input) {
      success
    }
  }
`;


/**
 * Mutation to handle removing the association between a Specialty and service lines
 */
export const removeServiceLineSpecialtyMutation = gql`
  mutation removeServiceLineSpecialtyAssociation($input: removeServiceLineSpecialtyAssociationInput!) {
    removeServiceLineSpecialtyAssociation(input: $input) {
      success
    }
  }
`;

export const changeVariantsSearchStatusMutation = gql`
mutation changeVariantsSearchStatusAssociation($input: changeVariantsSearchStatusAssociationInput!) {
  changeVariantsSearchStatusAssociation(input: $input) {
    success
  }
}
`;