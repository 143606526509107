/* @flow */
import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { createHttpLink } from 'apollo-link-http';
import { setContext } from 'apollo-link-context';
import { getStorageValue } from './AuthFunctions';

const https = require('https');

// Pick an api based on our current environment.
const connection = window.location.protocol;
const host = window.location.host;
/**
 * Added in the process.env.REACT_APP_** values as an option as ANY environment variable MUST
 * be prefixed with REACT_APP_ in order for CRA to grab it during build time.
 */
const api_host = process.env.REACT_APP_API_HOST || 'localhost';
const api_port = process.env.REACT_APP_API_PORT || 80;
const api_endpoint = process.env.REACT_APP_API_ENDPOINT || 'graphql';

const localDev = host.substring(host.length - 3) === '.vm' || host.substring(host.length - 9) === 'localhost';

// If it's insecure, use the corresponding insecure api.
let graphqlServer = api_host + ':' + api_port + '/' + api_endpoint;

let fetchOptions;
if (localDev) {
  fetchOptions = { agent: new https.Agent({ rejectUnauthorized: false }) };
} else {
  fetchOptions = https.globalAgent;
}

// Create an http link:
const httpLink = createHttpLink({
  uri: `${connection}//${graphqlServer}`,
  fetchOptions: fetchOptions
});

const authLink = setContext((_, { headers }) => {
  const token = getStorageValue('DML_JWT_AUTH');
  // Return the headers to the context so httpLink can read them.
  return {
    headers: {
      ...headers,
      authorization: token && typeof token === 'string' ? `Bearer ${token}` : ''
    }
  };
});

export const client: {
  link: any,
  cache: any,
  query: any,
  mutate: any,
  resetStore: () => void
} = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache()
});
