/* @flow */
/* eslint no-unused-vars: "off" */
import React, { Component } from 'react';
import { Input } from 'reactstrap';

const Filter = ({ onClick, label, onChange, slug, value, visible }) => {
  if (visible) {
    return (
      <div id={`${slug}-filter-field`} className="section-search-form--wrapper">
        <Input
          id={`${slug}AssociationFilter`}
          className="search section-search section-search--field mb-2"
          autoComplete="off"
          type="text"
          placeholder={`Filter existing ${label}...`}
          value={value}
          onChange={onChange}
        />
        {value.length > 0 && (
          <button className="btn" onClick={onClick}>
            <i className="fas fa-times" />
          </button>
        )}
      </div>
    );
  } else {
    return null;
  }
};

export default Filter;
