/* @flow */
/* eslint-disable no-unused-vars */
import * as React from 'react';
import {
  Alert,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  FormText,
  CardFooter,
  Card,
  CardHeader,
  CardBody,
  UncontrolledAlert
} from 'reactstrap';

import { searchSpecialtyQuery } from 'specialty/queries';

import { AssociationInput } from 'shared/inputs/association';
import { providerInfoQuery, providerSpecialtyQuery, updateProviderOverrides } from 'provider/queries';
import { fetchProviderData, sortElementObjects } from './ProviderFunctions';
import type {
  EditProviderProps,
  ProviderAssociateProps,
  ProviderAssociateState,
  ProviderData,
  ProviderAssociationPackage
} from 'provider/types';
import type { SpecialtyDataPackage } from 'shared/types';
import { client } from 'functions/Connect';

export class ProviderSpecialtyForm extends React.Component<ProviderAssociateProps, ProviderAssociateState> {
  constructor(props: EditProviderProps) {
    super(props);
    this.state = {
      formChanged: false,
      formSubmitted: false,
      provider: false,
      providerData: {},
      elements: [],
      elementsOriginal: [],
      elementsAdded: [], // Added (overrides)
      elementsRemoved: [], // Removed (overrides)
      removed: [], // Removed (current iteration)
      added: [], // Added (current iteration)
      payload: [],
      submitReady: false,
      saved: false
    };

    this.handleFormChange = this.handleFormChange.bind(this);
    this.processProviderData = this.processProviderData.bind(this);
  }

  handleFormChange = () => {
    this.setState(() => ({
      formChanged: true,
      saved: false
    }));
  };

  revertSpecialties = () => {
    // We've chosen to reset the associations completely.
    // The payload should match the original elementsOriginal array.
    const payload = this.state.elementsOriginal
      ? this.state.elementsOriginal.map((specialty: SpecialtyDataPackage) => specialty.id)
      : [];
    const elements = this.state.elementsOriginal ? this.state.elementsOriginal : [];

    const removed = [];
    const added = [];

    this.setState({
      elements: elements.sort((a, b) => {
        return sortElementObjects(a, b);
      }),
      elementsRemoved: [],
      elementsAdded: [],
      payload: payload,
      removed: removed.sort((a, b) => {
        return sortElementObjects(a, b);
      }),
      added: added.sort((a, b) => {
        return sortElementObjects(a, b);
      })
    });

    setTimeout(() => {
      this.readySubmit();
    }, 350);
  };

  addElementAssociation = (element: ProviderAssociationPackage, slug: string) => () => {
    // Get a list of original items.
    const originalElements =
      this.state.providerData[slug] && this.state.providerData[slug].length > 0 ? this.state.providerData[slug] : [];
    // An array of elements that match this one. If length === 1, it DID exist when the form loaded,
    // otherwise, if its length is 0, it did not.
    const originallyExisted = originalElements.filter(x => x.id === element.id);
    // No mater what, we add the element to the form submission payload.
    const payload = [...this.state.payload, element.id];

    // Get a list of originally added items (from overrides)
    const originalAdded =
      this.state.providerData.specialtiesAdd && this.state.providerData.specialtiesAdd.length > 0
        ? this.state.providerData.specialtiesAdd
        : [];

    const originallyAdded = originalAdded.filter(x => x.id === element.id);

    // Create the vars we want to use.
    let elements, removed, added, overrideRemoved, overrideAdded;

    if (originallyExisted.length === 1) {
      // Add it back to the primary set of elements.
      elements = [...this.state.elements, element];
      // No matter what, we can filter this out of our removed state array.
      removed = this.state.removed.filter(x => x.id !== element.id);
      overrideRemoved = this.state.elementsRemoved ? this.state.elementsRemoved.filter(x => x.id !== element.id) : [];
      // No matter what, we can filter this out of our added state array.
      // This is because it was already added and it just gets added (above) to the previous
      // element array.
      added = this.state.added.filter(x => x.id !== element.id);
      overrideAdded = this.state.elementsAdded ? this.state.elementsAdded.filter(x => x.id !== element.id) : [];
    } else if (originallyAdded.length === 1) {
      elements = this.state.elements.filter(x => x.id !== element.id);
      // No matter what, we can filter this out of our removed state array.
      removed = this.state.removed.filter(x => x.id !== element.id);
      overrideRemoved = this.state.elementsRemoved ? this.state.elementsRemoved.filter(x => x.id !== element.id) : [];
      // No matter what, we can filter this out of our added state array.
      added = this.state.added.filter(x => x.id !== element.id);
      // We're removing it, but it was already removed (added and removed again in same iteration),
      // so we just add it back to the elementsRemoved state.
      overrideAdded = this.state.elementsAdded ? this.state.elementsAdded.filter(x => x.id !== element.id) : [];
    } else {
      elements = this.state.elements.filter(x => x.id !== element.id);
      // No matter what, we can filter this out of our removed state array.
      removed = this.state.removed.filter(x => x.id !== element.id);
      overrideRemoved = this.state.elementsRemoved ? this.state.elementsRemoved.filter(x => x.id !== element.id) : [];
      // No matter what, we can filter this out of our added state array.
      overrideAdded = this.state.elementsAdded ? this.state.elementsAdded.filter(x => x.id !== element.id) : [];
      added = [...this.state.added, element];
    }


    this.setState({
      elements: elements.sort((a, b) => {
        return sortElementObjects(a, b);
      }),
      elementsRemoved: overrideRemoved.sort((a, b) => {
        return sortElementObjects(a, b);
      }),
      elementsAdded: overrideAdded.sort((a, b) => {
        return sortElementObjects(a, b);
      }),
      payload: payload,
      removed: removed.sort((a, b) => {
        return sortElementObjects(a, b);
      }),
      added: added.sort((a, b) => {
        return sortElementObjects(a, b);
      }),
      formChanged: true
    });

    setTimeout(() => {
      this.readySubmit();
    }, 350);
  };

  removeElementAssociation = (element: ProviderAssociationPackage, slug: string) => () => {
    // Get a list of original items.
    const originalElements =
      this.state.providerData[slug] && this.state.providerData[slug].length > 0 ? this.state.providerData[slug] : [];

    // Get a list of originally removed items (from overrides)
    const originalRemoved =
      this.state.providerData.specialtiesRemove && this.state.providerData.specialtiesRemove.length > 0
        ? this.state.providerData.specialtiesRemove
        : [];

    // An array of elements that match this one. If length === 1, it DID exist when the form loaded,
    // otherwise, if its length is 0, it did not.
    const originallyExisted = originalElements.filter(x => x.id === element.id);
    // No mater what, we remove the element from the form submission payload.
    const payload = this.state.payload.filter(x => x !== element.id);

    const originallyRemoved = originalRemoved.filter(x => x.id === element.id);

    // Create the vars we want to use.
    let elements, removed, added, overrideRemoved, overrideAdded;

    if (originallyExisted.length === 1) {
      elements = this.state.elements.filter(x => x.id !== element.id);
      removed = [...this.state.removed, element];
      // No matter what, we can filter this out of our added state arrays.
      added = this.state.added.filter(x => x.id !== element.id);
      overrideAdded = this.state.elementsAdded ? this.state.elementsAdded.filter(x => x.id !== element.id) : [];

      overrideRemoved = this.state.elementsRemoved ? this.state.elementsRemoved.filter(x => x.id !== element.id) : [];
    } else if (originallyRemoved.length === 1) {
      elements = this.state.elements.filter(x => x.id !== element.id);
      removed = this.state.removed.filter(x => x.id !== element.id);
      // No matter what, we can filter this out of our added state array.
      added = this.state.added.filter(x => x.id !== element.id);
      // We're removing it, but it was already removed (added and removed again in same iteration),
      // so we just add it back to the elementsRemoved state.
      overrideRemoved = this.state.elementsRemoved ? [...this.state.elementsRemoved, element] : [];
      overrideAdded = this.state.elementsAdded ? this.state.elementsAdded.filter(x => x.id !== element.id) : [];
    } else {
      elements = this.state.elements.filter(x => x.id !== element.id);
      removed = this.state.removed;
      // No matter what, we can filter this out of our added state array.
      added = this.state.added.filter(x => x.id !== element.id);
      overrideRemoved = this.state.elementsRemoved ? this.state.elementsRemoved.filter(x => x.id !== element.id) : [];
      overrideAdded = this.state.elementsAdded ? this.state.elementsAdded.filter(x => x.id !== element.id) : [];
    }

    this.setState({
      elements: elements.sort((a, b) => {
        return sortElementObjects(a, b);
      }),
      elementsRemoved: overrideRemoved.sort((a, b) => {
        return sortElementObjects(a, b);
      }),
      elementsAdded: overrideAdded.sort((a, b) => {
        return sortElementObjects(a, b);
      }),
      payload: payload,
      removed: removed.sort((a, b) => {
        return sortElementObjects(a, b);
      }),
      added: added.sort((a, b) => {
        return sortElementObjects(a, b);
      }),
      formChanged: true
    });

    setTimeout(() => {
      this.readySubmit();
    }, 350);
  };

  submitPayload = async () => {
    const status: boolean = await client
      .mutate({
        mutation: updateProviderOverrides,
        // Ensure we ALWAYS reach out immediately and don't rely on cache for this query.
        fetchPolicy: 'no-cache',
        variables: {
          input: {
            providerId: this.state.providerData.id,
            log: this.getLogValue(),
            update: {
              specialties: this.state.payload
            }
          }
        }
      })
      .then(response => {
        const data: {
          success: boolean
        } = response.data.updateProviderOverrides;
        return data !== null && data.success;
      })
      .catch(error => console.log(error));

    // console.log(`Provider Mutation status: ${status.toString()}`);
    if (status) {
      // Update the state value(s) as required.
      this.setState({
        formChanged: false,
        saved: true
      });
      this.props.updatePageRefresh();

      // Reset the log field value for the next edit.
      this.resetLogValue();

      // Update the stored data.
      this.processProviderData().then(response => {
        if (response !== false) {
          this.prepareVariables(response);
          
        }
      });
    }

    return status;
  };


  /**
   * Function handles determining if the form is ready to submit.
   */
  readySubmit = () => {
    const originalElements =
      this.state.providerData.specialties && this.state.providerData.specialties.length > 0
        ? [...this.state.providerData.specialties]
        : [];
    const currentElements = [...this.state.elements, ...this.state.added];
    const sortedCurrentElements = currentElements.sort((a, b) => {
      return sortElementObjects(a, b);
    });

    const sortedElements = originalElements.sort((a, b) => {
      return sortElementObjects(a, b);
    });
    // We can skip this logic if the lengths are different as that means they
    // were DEFINITELY different.
    let elementsMatch;
    if (sortedElements.length !== sortedCurrentElements.length) {
      elementsMatch = false;
    } else {
      // console.log(`Current and Original term array lengths match, continue comparison.`);
      for (let i = 0; i < sortedCurrentElements.length; i++) {
        if (sortedCurrentElements[i].id === sortedElements[i].id) {
          // console.log(`${currentTerms[i].id} matches ${originalElements[i].id}`);
          elementsMatch = true;
        } else {
          // console.log(`${currentTerms[i].id} DOES NOT MATCH ${originalElements[i].id}... BREAK`);
          elementsMatch = false;
          break;
        }
      }
      // console.log(`Current and Original terms match perfectly. Disable form submit and such.`);
    }

    let submitReady = !elementsMatch;
    let logReady = false;

    const log = this.getLogValue();

    if (log && typeof log === 'string' && log.length > 20) {
      logReady = true;
    }
    this.setSubmitReady(submitReady, logReady);
  };

  setSubmitReady = (form: boolean, log: boolean) => {
    this.setState(() => ({
      submitReady: form && log,
      formChanged: form
    }));
  };

  getTextFieldValue = (id: string) => {
    const field = document && document.getElementById(id);
    if (
      typeof field !== 'undefined' &&
      field !== null &&
      (field instanceof HTMLInputElement || field instanceof HTMLTextAreaElement)
    ) {
      return field.value;
    }
  };

  getField = (id: string) => {
    const field = document && document.getElementById(id);
    if (
      typeof field !== 'undefined' &&
      field !== null &&
      (field instanceof HTMLInputElement || field instanceof HTMLTextAreaElement)
    ) {
      return field;
    } else {
      console.error(`Field: ${id} not found in getField()...`);
    }
  };

  getLogValue = () => {
    return this.getTextFieldValue('log--specialties');
  };

  resetLogValue = () => {
    const log = this.getField('log--specialties');

    if (log instanceof HTMLInputElement || log instanceof HTMLTextAreaElement) {
      log.value = '';
    }
  };

  getProviderData = async (id: string): ProviderData => {
    const data = await fetchProviderData(id, providerSpecialtyQuery, 'provider');
    if (data) {
      return data;
    }
    return false;
  };

  prepareVariables = (data: ProviderData) => {
    // console.log(`Running prepareVariables:`);
    // console.log(data);
    // console.log(`----------`)
    // The Merged array of specialties.
    const merged = data && data.specialties ? [...data.specialties] : [];
    merged.sort((a, b) => {
      return sortElementObjects(a, b);
    });
    // The Original set of specialties.
    const original = data && data.specialtiesOriginal ? [...data.specialtiesOriginal] : [];
    // The Specialties ADDED to the original set.
    const added = data && data.specialtiesAdd ? [...data.specialtiesAdd] : [];
    // The Specialties ADDED to the original set.
    const removed = data && data.specialtiesRemove ? [...data.specialtiesRemove] : [];

    removed.sort((a, b) => {
      return sortElementObjects(a, b);
    });

    const removedAdjusted = removed.map(element => {
      return { ...element, removed: true };
    });
    const overrideGrouping = [...merged, ...removedAdjusted];

    // The payload we intend to send back to our mutation. It ONLY includes current and new elements.
    const payload = merged.map((specialty: SpecialtyDataPackage) => specialty.id);

    return {
      payload: payload ? payload : [],
      elements: merged,
      original: original,
      added: added,
      removed: removed
    };
  };

  processProviderData = async () => {
    const data = await this.getProviderData(this.props.provider.id);

    if (data && typeof data === 'object') {
      const specialtyData: {
        elements: Array<SpecialtyDataPackage>,
        added: Array<SpecialtyDataPackage>,
        removed: Array<SpecialtyDataPackage>,
        original: Array<SpecialtyDataPackage>,
        payload: Array<string>
      } = this.prepareVariables(data);

      // console.log(specialtyData);

      this.setState({
        providerData: data,
        elements: specialtyData.elements, // includes term id and name (Array of objects)
        elementsOriginal: specialtyData.original,
        elementsAdded: specialtyData.added,
        elementsRemoved: specialtyData.removed,
        payload: specialtyData.payload, // includes only term id's (Array of strings)
        provider: true,
        submitReady: false,
        removed: [],
        added: []
      });

      return data;
    }
    return false;
  };

  UNSAFE_componentWillMount() {
    this.processProviderData().then(response => {
      // Ensures processProviderData didn't return false.
      if (response) {
        this.prepareVariables(response);
      }
    });
  }

  render() {
    const provider: ProviderData = this.state.providerData;

    if (!this.state.provider) {
      return null;
    }

    

    return (
      <React.Fragment>
        <Form
          onChange={() => {
            this.readySubmit();
          }}
          onSubmit={(e: Event) => {
            e.preventDefault();
            this.submitPayload();
          }}
        >
          <Alert color="info" className={`mb-3 w-100`}>
            <div className="alert--with-icon">
              <i className="alert-icon fal fa-info-circle" />
              <span>
                This section allows you to manage the Specialty associations for the Provider:{' '}
                <strong>{provider.fullName}</strong>.
              </span>
            </div>
          </Alert>

          <Card className={`mb-4`}>
            <CardHeader>
              <strong>Specialty Association Overrides</strong>
            </CardHeader>
            <CardBody>
              <Alert color="warning" className={`mb-3 ${this.state.formChanged ? 'd-block' : 'd-none'}`}>
                <div className="alert--with-icon">
                  <i className="alert-icon fal fa-info-circle" />
                  <span>
                    The <em>Specialty Associations</em> form has been updated. Use the{' '}
                    <strong>Save Specialty Associations</strong> button to avoid losing any changes.
                  </span>
                </div>
              </Alert>
              <UncontrolledAlert className={`mb-3 ${this.state.saved ? 'd-block' : 'd-none'}`} color={'success'}>
                <div className="alert--with-icon">
                  <i className="alert-icon fal fa-info-circle" />
                  <span>Specialty Association Form saved...</span>
                </div>
              </UncontrolledAlert>

              <FormGroup>
                <AssociationInput
                  searchQuery={searchSpecialtyQuery}
                  mutationVariables={{
                    providerId: provider.id
                  }}
                  refetch={providerInfoQuery}
                  refetchVars={{ id: provider.id }}
                  label={`Specialties`}
                  slug={`specialties`}
                  gqlFilterVar={`specialty`}
                  gqlFirstVar={100}
                  elements={this.state.elements && this.state.elements.length > 0 ? this.state.elements : []}
                  removed={this.state.removed && this.state.removed.length > 0 ? this.state.removed : []}
                  added={this.state.added && this.state.added.length > 0 ? this.state.added : []}
                  overridesAdded={
                    this.state.elementsAdded && this.state.elementsAdded.length > 0 ? this.state.elementsAdded : []
                  }
                  overridesRemoved={
                    this.state.elementsRemoved && this.state.elementsRemoved.length > 0
                      ? this.state.elementsRemoved
                      : []
                  }
                  callbacks={{
                    associate: this.addElementAssociation,
                    remove: this.removeElementAssociation,
                    handleFormChange: this.handleFormChange,
                    handleFormSaved: () => {}
                  }}
                />
              </FormGroup>
            </CardBody>
            <CardFooter>
              <FormGroup className={this.state.formChanged ? 'd-block' : 'd-none'}>
                <Label className={`mb-0`}>Reason for Override</Label>
                <FormText color="muted" className={`mb-2`}>
                  <i className="fal fa-info-circle" /> Please provide a reason, including who authorized the change(s)
                  to the <strong>Specialty Associations</strong>.
                </FormText>
                <Input
                  type={`textarea`}
                  name={`log--specialties`}
                  id={`log--specialties`}
                  rows={4}
                  disabled={!this.state.formChanged}
                />
              </FormGroup>
              <Alert color="warning" className={`mb-3 ${this.state.formChanged ? 'd-block' : 'd-none'}`}>
                <div className="alert--with-icon">
                  <i className="alert-icon fal fa-info-circle" />
                  <span>
                    The <em>Specialty Associations</em> form has been updated. Use the{' '}
                    <strong>Save Specialty Associations</strong> button to avoid losing any changes.
                  </span>
                </div>
              </Alert>
              <div className={`d-flex justify-content-between`}>
                <Button
                  id="saveProviderSpecialtiesButton"
                  type="submit"
                  size="md"
                  color="primary"
                  title="Save Specialties"
                  disabled={!this.state.submitReady}
                >
                  <i className="fal fa-save" />
                  <span>Save Specialty Associations</span>
                </Button>

                <Button
                  id="resetSpecialtyDataButton"
                  size="md"
                  outline
                  inverse={`true`}
                  color="danger"
                  title="Remove Overrides"
                  onClick={() => {
                    this.revertSpecialties();
                  }}
                >
                  <i className="fas fa-times" />
                  <span>Reset Specialty Overrides</span>
                </Button>
              </div>
            </CardFooter>
          </Card>
        </Form>
      </React.Fragment>
    );
  }
}
