/* @flow */
/* eslint no-unused-vars: "off" */
import React, { Fragment, useState, useContext, useCallback } from 'react';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { Badge, Button, Card, CardBody, Input, InputGroup, Label,Modal } from 'reactstrap';
import { Search, Close } from 'shared/icons';

import { CreateSynonym } from 'synonym/regions';
import { GET_SYNONYMS, GET_UMLS_SYNONYMS, CREATE_SYNONYM, CREATE_TERM_SYNONYM_ASSOCIATION } from 'synonym/queries';
import { associateSynonym, createSynonym, searchSynonyms } from 'synonym/actions';
import { Synonyms } from 'synonym/container';

const SynonymsSearch = ({ searchVisible, term, synonyms: currentSynonyms }) => {

  const [search, setSearch] = useState('');
  const [createdTerm, setcreatedTerm] = useState('');
  let filteredSynonyms = [];
  let filteredUMLSSynonyms = [];
  if (term.umls_cuid) {
    const {
      loading: umlsLoading,
      data: result
    } = useQuery(GET_UMLS_SYNONYMS, { variables: { cui: term.umls_cuid.toUpperCase() } });
    const searchRegex = search && search !== '' ? RegExp(`${search}*`, 'i') : RegExp('.*');
    if (result && result.umlsConcept && result.umlsConcept.atoms.result) {
      // console.log(result)
      filteredUMLSSynonyms = result.umlsConcept.atoms.result.filter((atom) => {
        const matchesSearch = searchRegex.test(atom.name)
        const synonymExists = currentSynonyms.some((currentSynonym) => currentSynonym.name === atom.name)
        return (matchesSearch && !synonymExists)
      })
    }
  }
  const [creationMutation] = useMutation(CREATE_SYNONYM);
  const { refetch } = useContext(Synonyms);
  const [createSynonymAssociation, { data: associationData }] = useMutation(CREATE_TERM_SYNONYM_ASSOCIATION);
  if (searchVisible) {
    return (
      <Fragment>
        
        <Card className="mt-3" color="primary">
          <CardBody>
            <div id="synonym-search-field" className="section-search-form--wrapper">
              <Input
                className="search section-search section-search--field form-control"
                placeholder="Search Synonyms"
                value={search}
                onChange={({ target: { value } }) => setSearch(value)}
              />
              {search.length === 0 && (
                <button className="btn search-icon" onClick={e => e.preventDefault()}>
                  <Search />
                </button>
              )}
              {search.length > 0 && (
                <button className="btn" onClick={() => setSearch('')}>
                  <Close />
                </button>
              )}
            </div>
            {filteredUMLSSynonyms.length > 0 && (
              <InputGroup className="relation-list linked-reference-item form-control">
                {filteredUMLSSynonyms.map(synonym => {
                  return (
                    <Badge
                      data-synonym={synonym.umls_auid}
                      key={synonym.umls_auid}
                      color="tertiary"
                      className="linked-reference-item"
                    >
                      {synonym.name}
                      <Button
                        color="success"
                        onClick={
                          createSynonym({
                            variables: {
                              name: synonym.name,
                              termID: term.id
                            }
                          }, creationMutation, refetch, setSearch)}
                      >
                        <i className="fas fa-plus" />
                      </Button>
                    </Badge>
                  );
                })}
              </InputGroup>
            )}
          </CardBody>
        </Card>
       <CreateSynonym name={createdTerm} term={term}  setcreatedTerm={setcreatedTerm} />
         
 
      </Fragment>
    );
  } else {
    return null;
  }
};

export default SynonymsSearch;
