/* @flow */
import React from 'react';
import { Association } from './';
import type { ProviderAssociationPackage } from 'provider/types';

type Props = {
  associate: (element: ProviderAssociationPackage, slug: string) => void,
  children: string,
  added: Object,
  slug: string
};

const AssociationAdded = (props: Props) => {
  return <Association {...props} />;
};

AssociationAdded.defaultProps = {
  colors: {
    badge: 'success',
    button: 'success'
  },
  icon: 'fa-times'
};

export default AssociationAdded;
