/* @flow */
import jwt_decode from 'jwt-decode';
import type { tokenData } from 'shared/types';

export const getStorageValue = (name: string) => {
  const storageLocation = localStorage.getItem('DML_AUTH_LOCATION');
  if (storageLocation === 'local') {
    return localStorage.getItem(name);
  } else if (storageLocation === 'session') {
    return sessionStorage.getItem(name);
  }
  return false;
};

// Get the authentication token from local/session storage if it exists.
export const isAuthenticated = () => {
  return !!getStorageValue('DML_JWT_AUTH');
};

export const checkActivity = () => {
  // Time in milliseconds to require a user to log in again.
  // 1000 (ms in s) * 60 (s in min) * 60 (min in hr) * 24 (hr in day) * DAYS.
  const lastLoginTimeout = 1000 * 60 * 60 * 24 * 3; // 3 DAYS
  // 1000 (ms in s) * 60 (s in min) * 60 (min in hr) * HOURS.
  const lastActiveTimeout = 1000 * 60 * 60 * 2; // 2 HOURS
  const currentDate = new Date();
  const currentTimestamp = currentDate.getTime();
  const lastLogin = parseInt(localStorage.getItem('DML_LAST_LOGIN'), 10);
  const timeSinceLogin = currentTimestamp - lastLogin;
  const lastActive = parseInt(localStorage.getItem('DML_LAST_ACTIVE'), 10);
  const timeSinceActive = currentTimestamp - lastActive;
  // console.log(`Time since last activity: ${timeSinceActive}ms`);
  // console.log(`Time since last login: ${timeSinceLogin}ms`);
  if (timeSinceActive > lastActiveTimeout || timeSinceLogin > lastLoginTimeout) {
    logoutUser();
  } else {
    // Updated DML_LAST_ACTIVE timestamp.
    localStorage.setItem('DML_LAST_ACTIVE', currentTimestamp.toString());
  }
};

export const logoutUser = () => {
  const tokenLocation = localStorage.getItem('DML_AUTH_LOCATION');
  if (tokenLocation === 'local') {
    localStorage.removeItem('DML_JWT_AUTH');
    localStorage.removeItem('DML_USER');
  } else if (tokenLocation === 'session') {
    sessionStorage.removeItem('DML_JWT_AUTH');
    sessionStorage.removeItem('DML_USER');
  }
  localStorage.removeItem('DML_AUTH_LOCATION');
  // Reload the App to clear all stored data.
  window.location.reload();
};

export const loginUser = (token: string, name: string, save: boolean = true) => {
  // Store the data in local storage for a persisted login.
  if (save) {
    //console.log(token);
    // Set the token in localStorage.
    localStorage.setItem('DML_JWT_AUTH', token);
    // Set the username in localStorage.
    localStorage.setItem('DML_USER', name);
    // Set a flag (always in localStorage) to show our storage method.
    localStorage.setItem('DML_AUTH_LOCATION', 'local');
  }
  // Store the data in session storage to clear on close.
  else {
    //console.log(token);
    // Set the token in sessionStorage.
    sessionStorage.setItem('DML_JWT_AUTH', token);
    // Set the username in sessionStorage.
    sessionStorage.setItem('DML_USER', name);
    // Set a flag (always in localStorage) to show our storage method.
    localStorage.setItem('DML_AUTH_LOCATION', 'session');
  }

  const currentDate = new Date();
  const currentTimestamp = currentDate.getTime();
  // Set a flag (always in localStorage) to show our last active time.
  localStorage.setItem('DML_LAST_LOGIN', currentTimestamp.toString());
  localStorage.setItem('DML_LAST_ACTIVE', currentTimestamp.toString());

  // Reload the App to clear all stored data.
  window.location.reload();
};

const getTokenData = (): tokenData => {
  const token = getStorageValue('DML_JWT_AUTH');
  
  return jwt_decode(token);
};

export const getPermissions = (variable): boolean => {
  if (isAuthenticated()) {
    const decodedToken = getTokenData();
    
    let userPermissions = decodedToken.permissions;
    for(let i = 0;i < userPermissions.length; i++){
      
         if(userPermissions[i]['child'] == variable){
           return true;
         }
        
    }
    return false;
    //return userPermissions.includes(variable);

  }
  return false;
}

export const isRole = (role): boolean => {
  if (isAuthenticated()) {
    const decodedToken = getTokenData();
    if(decodedToken.role == role){
    //console.log(decodedToken);
     return true;
    }
  }
  return false;
};

export const getRole = (): boolean => {
  if (isAuthenticated()) {
    const decodedToken = getTokenData();
  
    //console.log(decodedToken);
     return decodedToken.role;
    
  }
  return false;
};


export const isUserManager = (): boolean => {
  if (isAuthenticated()) {
    const decodedToken = getTokenData();
   // console.log(decodedToken);
    return decodedToken.is_manager;
  }
  return false;
};


export const isPracticeEmails = (): boolean => {
  if (isAuthenticated()) {
    const decodedToken = getTokenData();
   // console.log(decodedToken);
    return decodedToken.practice_emails;
  }
  return false;
};

export const doUsernamesMatch = (name: string): boolean => {
  if (isAuthenticated()) {
    const decodedToken = getTokenData();
    return decodedToken.username === name;
  }
  return false;
};

export const doIDsMatch = (id: string): boolean => {
  if (isAuthenticated()) {
    const decodedToken = getTokenData();
    return decodedToken.id === id;
  }
  return false;
};

export const getAuthenticatedUserId = (): string => {
  if (isAuthenticated()) {
    const decodedToken = getTokenData();
    return decodedToken.id;
  }
};
