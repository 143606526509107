
import * as React from 'react';
import {Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
class ModalConfirm extends React.Component {

  constructor(props) {
      super(props);
      this.state = {
        isOpen: this.props.isOpen,
      };

  }

  render() {
      return (
          <>
            <Modal isOpen={this.state.isOpen} toggle={this.props.onClose} centered>
              
              <ModalHeader toggle={this.props.onClose}><h2>Confirm Service Line delete</h2></ModalHeader>
                  <ModalBody>
                      <h4 className="mb-0">Are you sure you want to delete this? This will delete all associated variants.</h4>
                  </ModalBody>
              <ModalFooter>
                      <Button
                            className="delete-item-button"
                            outline
                            size="lg"
                            color="danger"
                            title="Delete"
                            onClick={this.props.confirmAction}
                          >
                            <i className="fal fa-trash-alt" />
                            <span>Yes, I'm sure</span>
                      </Button>
              </ModalFooter>
           </Modal>
           </>
      )
  }
}

export default ModalConfirm;