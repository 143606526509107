/* eslint no-unused-vars: "off" */
import React, { useContext } from 'react';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { Badge, Button, Label } from 'reactstrap';

import { Close } from 'shared/icons';
import { GET_PRIMARY_SYNONYM, DEMOTE_PRIMARY_SYNONYM } from 'synonym/queries';
import { demoteSynonym } from 'synonym/actions';
import { Synonyms } from 'synonym/container';

const DisplayName = ({ cftTerm }) => {
  const { refetch } = useContext(Synonyms);
  const [demotionMutation] = useMutation(DEMOTE_PRIMARY_SYNONYM);
  if (cftTerm) {
    const { preferredName, primarySynonym } = cftTerm;
    return (
      <Label>
        Display Name:
        {primarySynonym && (
          <Badge className="ml-1 linked-reference-item badge" color="primary">
            {primarySynonym.name}
            <Button
              className="ml-1"
              color="danger"
              onClick={demoteSynonym(
                {
                  variables: {
                    termID: cftTerm.id
                  }
                },
                demotionMutation,
                refetch
              )}
            >
              <Close />
            </Button>
          </Badge>
        )}
        {!primarySynonym && (
          <Badge color="tertiary" className="ml-1">
            {preferredName}
          </Badge>
        )}
      </Label>
    );
  } else {
    return null;
  }
};

export default DisplayName;
