/* @flow */
import * as React from 'react';

import ContentSection from 'elements/ContentSection';
import { Query } from 'react-apollo';
import type {

  AuditTableRowProps,
  AuditQueryProps,
  AuditContentProps,
  
} from 'audit/types';
import type { SectionDataType, SectionProps,SectionListProps,SharedSectionCallbacks, } from 'shared/types';
import ReactPaginate from "react-paginate";
import SectionTable from 'elements/SectionTable';
import SectionError from 'elements/SectionError';
import { auditListQuery } from './queries';
import { Link, Redirect, Route } from 'react-router-dom';
import { Alert, Badge } from 'reactstrap';
import SectionActionButtons from 'elements/SectionActionButtons';
import SectionSearchField from 'elements/SectionSearchField';
import { doUsernamesMatch, getAuthenticatedUserId, isAuthenticated,getPermissions,isUserManager } from 'functions/AuthFunctions';
import {createBrowserHistory} from "history";
import qs from "qs";

const history = createBrowserHistory();

let currentUrlParams = new URLSearchParams(window.location.search);
class Audit extends React.Component<SectionProps> {
  callbacks: SharedSectionCallbacks;
  constructor(props: SectionListProps) {
    super(props);
    this.state = {
      filter: '',
      afterCurser: '',
      order: {
        sortField: 'FULLNAME_FIRST_LAST',
        sortDirection: 'ASC'
      },
      limit: 25,
      offset: 0,
      pageNumber: 0
    };
    this.callbacks = {
      handleChange: this.handleChange,
      handleKeyDown: this.handleKeyDown,
      clearFilter: this.clearFilter,
      updateSort: this.updateSort,
      updateFirst: this.updateFirst,
      handlePage:this.handlePage
      
      

    };
    this.pagehandler={
      handlePage:this.handlePage
    }
  };

  UNSAFE_componentWillMount() {
    this.timer = null;
  }

  componentDidMount(){
    const filterParams = history.location.search.substr(1);
    const filtersFromParams = qs.parse(filterParams);
    if(filtersFromParams.search){
     
      this.setState(() => ({
        filter: filtersFromParams.search
      }));
    }
    if(filtersFromParams.page){
     
      this.setState(() => ({
        pageNumber: filtersFromParams.page
      }));
    }
    if(filtersFromParams.curser){
     
      this.setState(() => ({
        afterCurser: filtersFromParams.curser
      }));
    }
  }

  reFocus = (target: HTMLInputElement) => {
    //console.log(target);
    const elemId = target.getAttribute('id');
    const element = document && elemId && document.getElementById(elemId);
    //console.log(element instanceof HTMLInputElement);
    if (typeof element !== 'undefined' && element !== null && element instanceof HTMLInputElement) {
      element.focus();
    }
  };

  updateSort = (field: string, direction: string) => {
    const sortField = field ? field : this.state.order.sortField;
    const sortDirection = direction ? direction : this.state.order.sortDirection;

    this.setState(() => ({
      order: {
        sortField: sortField,
        sortDirection: sortDirection
      }
    }));
  };

  clearFilter = () => {
    this.setState(() => ({
      filter: ''
    }));
    history.replace(``);
  };

 

  updateFirst = (n: number) => {
    this.setState(() => ({
      limit: n
    }));
  };

  

  handleChange = ({ target }: { target: HTMLInputElement }) => {
  
    clearTimeout(this.timer);
    this.timer = setTimeout(
      function doChange() {
        this.triggerChange(target);
      }.bind(this),
      this.waitInterval
    );
  };

  handleKeyDown = (e: KeyboardEvent) => {
    if (e.key === this.enterKey) {
      const target = e.target;
      if (target instanceof HTMLInputElement) {
        const attribute: string | null | void = target.getAttribute('id');
        const elemId: string = attribute && typeof attribute === 'string' ? attribute : '';
        const element: HTMLElement | null = document.getElementById(elemId);
        if (typeof element !== 'undefined' && element !== null && element instanceof HTMLInputElement) {
          this.triggerChange(target);
        }
      }
    }
  };

  triggerChange = (target: HTMLInputElement) => {
    this.setState(() => ({
      filter: target.value ? target.value : '',
      pageNumber:0
    }));
    history.push(`?search=${target.value}`);
    if (target.value) {
      this.reFocus(target);
    }
  };
  handlePage = (id,page) => {
    
    
    this.setState(() => ({
      afterCurser: id,
      pageNumber:page
    }));

    history.push(`?page=${page}&curser=${id}`);
    //window.location = window.location.pathname +"?page="+ page
    //this.props.propcallbacks.handleChange();
  };

  
  render() {
     const match: MatchType = this.props.match;
  //   const SectionContent = () => {
  //     return (
  //       <Alert color="warning">
  //         <div className="alert--with-icon">
  //           <i className="alert-icon fal fa-info-circle" />
  //           <p className="mb-0">This section is currently unavailable. It is scheduled for future development.</p>
  //         </div>
  //       </Alert>
  //     );
  //   };


  // This action (Adding a user) is ONLY available to users with is_admin set to true.
  // const buttonLinks: Array<ActionButtonLink> | boolean = isUserAdmin()
  //   ? [
  //       {
  //         uri: '/user/add',
  //         id: 'addUserLink',
  //         text: 'Create User',
  //         icon: 'fas fa-plus'
  //       }
  //     ]
  //   : false;

  const sectionData: SectionDataType = {
    heading: 'Audit Logs',
    content: <AuditListContent 
    callbacks={this.callbacks}
    page={this.pagehandler}
    pageNumber={this.state.pageNumber}
    data={{
      filter: this.state.filter,
      order: {
        sortField: this.state.order.sortField,
        sortDirection: this.state.order.sortDirection
      },
      offset: this.state.afterCurser,
      limit: this.state.limit
    }}
    location={this.props.location} 
    
    />,
    actions: false,
    modals: false
  };
  return (
    <React.Fragment>
     <ContentSection data={sectionData} history={this.props.history} />
     
   </React.Fragment>
  );
  // }
  
}
}
class AuditsQuery extends React.Component<AuditQueryProps> {
  render() {
    
    const FILTER = this.props.data.filter ? this.props.data.filter : '';
    // Default offset.
    const OFFSET = this.props.data.offset ? this.props.data.offset : '';
    const LIMIT = this.props.data.limit ? this.props.data.limit : 25;

  
    return (
      <Query 
          query={auditListQuery} 
          pollInterval={0} 
          notifyOnNetworkStatusChange 
          fetchPolicy="cache-and-network"
          variables={{
            filter: FILTER,
            afterCurser: OFFSET,
            first: LIMIT,
            
          }}
          fetchPolicy="cache-and-network"
        
          >
        {({ variables, loading, data, fetchMore, networkStatus }) => {
          // Skip loading icon/flash when we are polling for new results.
          if (loading && networkStatus !== 6 && networkStatus !== 3) {
            // Handle loading screen.
            const row = {
              classes: 'loading',
              content: <i className="fal fa-spinner" />
            };
            return (
              <SectionTable
                render={() => (
                  <tbody>
                    <tr>
                      <td className={row.classes}>{row.content}</td>
                    </tr>
                  </tbody>
                )}
              />
            );
          }

          const audits: [AuditData] | boolean = data && data.auditLogs? data.auditLogs : false;
         
          if (audits && audits.length === 0 && !loading) {
            // Handle a zero result set.
            const errorContent = (
              <div className="text-center">
                <h4 className="text-warning">No Results Found.</h4>
                <h2 className="text-warning">¯\_(ツ)_/¯</h2>
              </div>
            );
            return <SectionError color="warning" classes="" content={errorContent} />;
          }
        
          //console.log(data);

          if (!data && !loading) {
            // We've encountered a error connecting to GraphQL server.
            // We should on this account, disable the Create Specialty button.
            // @todo: Should be an easier way to detect an epic failure to connect.
            const button = document.getElementById('addUserLink');
            if (typeof button !== 'undefined' && button !== null && button instanceof HTMLButtonElement) {
              button.classList.add('disabled');
              button.disabled = true;
            }
            return (
              <Alert color="danger" className={`error--graphql-connection`}>
                <div className={`text-center`}>
                  <h4 className="text-error">GraphQL connection failure.</h4>
                  <h2 className="text-error">¯\_(ツ)_/¯</h2>
                </div>
              </Alert>
            );
          }

          //console.log(audits);
        
          // Pagination issues.
          // @see https://github.com/apollographql/apollo-client/issues/2499
          return <AuditFeed pageNumber={this.props.pageNumber} page={this.props.page} users={audits.edges || [] } pages={audits.aggregate || []} limit={this.props.data.limit || []} />;
        }}
      </Query>
    );
  }
}

class AuditListContent extends React.Component<LocationOnlyProps, AuditContentProps> {
  render() {
    return (
      <div className="group-landing--wrapper">
           <SectionSearchField
          filter={this.props.data.filter}
          callbacks={this.props.callbacks}
          element={{
            id: 'groupList',
            placeholder: 'Search Logs'
          }}
        />
        <div className="table-responsive">
          <AuditsQuery pageNumber={this.props.pageNumber} page={this.props.callbacks} data={this.props.data} location={this.props.location} />
        </div>
       
      </div>
    );
  }
}

class AuditFeed extends React.Component<UserFeedProps> {

  handlePageClick = (data) => {
    
   // currentUrlParams.set("page", JSON.stringify(data.slected+1));
   
    let page = data.selected;
   
  
    let last = 25 * page;
    let total = last - 1;
 
 
    
    this.props.page.handlePage(total,page);
    //window.location = window.location.pathname +"?page="+ currentPage
  };
  render() {
  
    if (this.props.users && this.props.users.length) {

      let last = this.props.users.length;
      // Friendly method to display the text "Showing X-Y of Z total results.
      let fromItems = 1;
      let toItems = 25;

      if(this.props.pages.count < 25 ){
        fromItems = 1;
        toItems = this.props.pages.count
      }

      else if(this.props.pageNumber != 0){
        fromItems = (this.props.pageNumber*25);
        toItems = (this.props.pageNumber*25)+24
      }
     
      
      const total = Math.ceil(this.props.pages.count/this.props.limit);
 
      // Compile all the table rows together.
      const FeedRows = () => {
        return this.props.users.map((user, id) => <AuditTableRow row={user.node} key={id} />);
      };
      //console.log('here');

      // Primary return without errors or oddities.
      return (
        <React.Fragment>
          <h4>
            Showing{' '}
            <strong>
              {fromItems} - {toItems}
            </strong>{' '}
            of <strong>{this.props.pages.count}</strong> rows.
          </h4>
          <SectionTable
            render={() => (
              <React.Fragment>
                <thead className="thead-light">
                  <AuditTableHeaderRow />
                </thead>
                <tbody className="result-set">
                  <FeedRows />
                </tbody>
               
              </React.Fragment>
            )}
          />
           <ReactPaginate
       previousLabel={"previous"}
       nextLabel={"next"}
       breakLabel={"..."}
       breakClassName={"break-me"}
       pageCount={total}
       forcePage={this.props.pageNumber}
       // marginPagesDisplayed={2}
       //pageRangeDisplayed={5}
       //callbacks={this.props.callbacks}
       onPageChange={this.handlePageClick}
       containerClassName={"pagination"}
       subContainerClassName={"pages pagination"}
       activeClassName={"active"}
     />
          
        </React.Fragment>
      );
    }

    // Last case error that something went terribly wrong.
    const errorContent = (
      <div>
        <h4 className="text-danger">Unknown error occurred.</h4>
        <h2 className="text-danger">¯\_(ツ)_/¯</h2>
      </div>
    );
    return <SectionError color="danger" classes="" content={errorContent} />;
  }
}

class AuditTableRow extends React.Component<UserTableRowProps> {
  render() {
    const user = this.props.row;
    const username = user.user != null ? user.user.username : '';

    // Admin or current user sees a link, otherise, plain text.
    //console.log(user);
    const milliseconds = user.timestamp * 1000 // 1575909015000

    const dateObject = new Date(milliseconds)

    const humanDateFormat = dateObject.toLocaleString()
    if (getPermissions('logs') || doUsernamesMatch(user.username)) {
     
      return (
        <tr id={user.id} className={`result-set--row`}>
          <td className="main-cell term" scope="row">
            {username}
          </td>
          <td className="status d-none d-lg-table-cell text-right">{humanDateFormat}</td>
          <td className="account-type d-none d-lg-table-cell text-right">{user.message}</td>
          <td className="account-type d-none d-lg-table-cell text-right">{user.action}</td>
          <td >{user.args}</td>
          {/* <td className="actions">
            {EditLink}
            {DeleteLink}
          </td> */}
        </tr>
      );
    } else {
      return null;
    }
  }
}

/**
 * Component to handle table header for CFT results.
 */
class AuditTableHeaderRow extends React.Component<{}> {
  render() {
    return (
      <tr>
        <th scope="col">
          <div className="sorting-cell">
            <span className="col-label">Username</span>
          </div>
        </th>
        <th className="actions text-right" scope="col">
          <span className="col-label">Timestamp</span>
        </th>
        <th className="actions text-right" scope="col">
          <span className="col-label">Message</span>
        </th>
        <th className="actions" scope="col">
          <span className="col-label">Action</span>
        </th>
        <th  scope="col">
          <span className="col-label">Change</span>
        </th>
      </tr>
    );
  }
}



export default Audit;
