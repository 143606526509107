/* @flow */
import * as React from 'react';
import ReactDOM from 'react-dom';
import { Alert } from 'reactstrap';
import type { AlertProps, AlertState } from 'shared/types';

/**
 * Function to handle displaying a system alert at any point in time.
 *
 * @todo Figure out a potentially better way to handle SystemAlert(s).
 *
 * @code
 * <SystemAlert
 *   autoClose
 *   color="success"
 *   icon="alert-icon fal fa-check-circle"
 *   messageData={{
 *     action: 'Updated',                  // Action name.
 *     item: 'item called SUCH and SUCH',  // Primary item name.
 *     id: 'with an id of',                // Appears in parentheses after the item.
 *     tail: 'from the SO and SO place.'   // Appended message portion.
 *   }}
 * />
 */
export class SystemAlert extends React.Component<AlertProps, AlertState> {
  alertAutoCloseTimer: number;

  constructor(props: AlertProps) {
    super(props);
    this.alertAutoCloseTimer = 30000; // 30 seconds.

    this.state = {
      visible: true
    };

    this.purgeAlert = this.purgeAlert.bind(this);
    this.timedAlert = this.timedAlert.bind(this);
  }

  /**
   * @todo: Need the ability to properly clear the timer here.
   * When first item is deleted, timer starts at 10 seconds.
   * If another is deleted at say, 8 seconds, then the second alert
   * closes in 2 seconds.
   */
  timedAlert = (autoClose: boolean) => {
    if (autoClose) {
      setTimeout(() => {
        const alerts = document.getElementById('system-alert-wrapper');
        ReactDOM.unmountComponentAtNode(alerts);
      }, this.alertAutoCloseTimer);
    }
  };

  purgeAlert = () => {
    this.setState({ visible: false });
    // Get the alert container.
    const alerts = document.getElementById('system-alert-wrapper');
    setTimeout(() => {
      ReactDOM.unmountComponentAtNode(alerts);
    }, 100);
  };

  render() {
    return (
      <Alert
        onLoad={this.timedAlert(this.props.autoClose)}
        color={this.props.color}
        isOpen={this.state.visible}
        toggle={this.purgeAlert}
        className = {this.props.className}
      >
        <div className="alert--with-icon">
          {this.props.icon ? <i className={this.props.icon} /> : false}
          <span>
            {this.props.messageData.action} <strong>{this.props.messageData.item}</strong>{' '}
            {this.props.messageData.id ? <em>{this.props.messageData.id}</em> : false} {this.props.messageData.tail}
          </span>
        </div>
      </Alert>
    );
  }
}
