/* @flow */
import * as React from 'react';

import { Button, Input, FormText, InputGroupAddon, InputGroup } from 'reactstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { TextFieldOverrideProps } from 'shared/types';
import { isRole } from 'functions/AuthFunctions';
export class TextFieldWithOverride extends React.Component<TextFieldOverrideProps, {}> {
  render() {
    return (
      <React.Fragment>
        <InputGroup>
          <Input
            type={this.props.type}
            rows={this.props.rows ? this.props.rows : 1}
            autoComplete="off"
            name={this.props.name}
            id={this.props.id}
            defaultValue={this.props.default}
            disabled={this.props.match && !this.props.override}
            className={`provider-text-input ${!!this.props.match ? 'border-success' : 'border-danger'}`}
            onChange={e => {
              this.props.callbacks.change(e);
              if (this.props.changeExtra) {
                this.props.changeExtra(e);
              }
            }}
          />
          {isRole('trulyAwardUser') == true ?

          ''
          :
          
          <InputGroupAddon addonType="append">
            <Button
              id={`overrideRevert${this.props.id}`}
              data-target={this.props.id}
              data-field={this.props.name}
              color={`${this.props.match ? 'success' : 'danger'}`}
              className={`override-toggle-button`}
              title={`${this.props.match ? 'Override this field.' : 'Remove this override.'}`}
              onClick={e => {
                if (this.props.match) {
                  this.props.callbacks.edit(e);
                } else {
                  this.props.callbacks.revert(e, this.props.original);
                  if (this.props.revertExtra) {
                    this.props.revertExtra(e);
                  }
                }
              }}
            >
              <FontAwesomeIcon icon={this.props.match ? ['fas', 'edit'] : ['fas', 'times']} />
            </Button>
          </InputGroupAddon>
        }
        </InputGroup>
        <FormText color="muted" className={`mt-2 ${this.props.match ? 'd-none' : 'd-block'}`}>
          <strong>Original Value:</strong> <em>{this.props.original}</em>
        </FormText>
      </React.Fragment>
    );
  }
}
