/* @flow */
/* eslint-disable no-unused-vars */
import * as React from 'react';
//import { umlsInternalSearch, umlsSearch } from 'unifiedMedicalLanguageSystem/queries';
import { topspecialtyInfoQuery } from 'topspecialties/queries';
import { Query } from 'react-apollo';
import { clearSearch } from 'functions/SectionFunctions';
import { animateScroll } from 'functions/Functions';
import { Button } from 'reactstrap';
import type { UmlsSearchProps, UmlsSearchState, UmlsTerm, UmlsFeedProps } from 'clinicalFocusTerm/types';
import { client } from 'functions/Connect';
import { Link } from 'react-router-dom';
import { specialtyConnectionQuery } from 'specialty/queries';
/**
 * Component to handle search field.
 */
class SpecialtySearch extends React.Component<UmlsSearchProps, UmlsSearchState> {
  waitInterval: number;
  enterKey: number;
  timer: any;

  constructor(props: UmlsSearchProps) {
    super(props);
    this.state = {
        filter: '',
      afterCurser: '',
      order: {
        sortField: 'NAME',
        sortDirection: 'ASC'
      },
      limit: 25,
      offset: 0,
      pageNumber: 0
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.triggerChange = this.triggerChange.bind(this);
    this.reFocus = this.reFocus.bind(this);
    this.clearFilter = this.clearFilter.bind(this);

    this.waitInterval = 1000;
    this.enterKey = 13;
  }

  reFocus = (target: HTMLInputElement) => {
    const elemId = target.getAttribute('id');
    const element = document && elemId && document.getElementById(elemId);
    if (typeof element !== 'undefined' && element !== null && element instanceof HTMLInputElement) {
      element.focus();
    }
  };

  clearFilter = () => {
    this.setState(() => ({
      filter: ''
    }));
  };

  handleChange = ({ target }: { target: HTMLInputElement }) => {
    clearTimeout(this.timer);
    this.timer = setTimeout(
      function doChange() {
        this.triggerChange(target);
      }.bind(this),
      this.waitInterval
    );
  };

  handleKeyDown = (e: KeyboardEvent) => {
    if (e.key === this.enterKey) {
      const target = e.target;
      if (target instanceof HTMLInputElement) {
        const attribute: string | null | void = target.getAttribute('id');
        const elemId: string = attribute && typeof attribute === 'string' ? attribute : '';
        const element: HTMLElement | null = document.getElementById(elemId);
        if (typeof element !== 'undefined' && element !== null && element instanceof HTMLInputElement) {
          this.triggerChange(target);
        }
      }
    }
  };

  triggerChange = (target: HTMLInputElement) => {
    this.setState(() => ({
      filter: target.value ? target.value : ''
    }));
    if (target.value) {
      this.reFocus(target);
    }
  };

  render() {
    const QueryResults = () => {
      if (this.state.filter !== '') {
         // This is the filter sent from the parent form(s).
    const FILTER = this.state.filter ? this.state.filter : '';
    // Default offset.
    const OFFSET = this.state.offset ? this.state.offset : 0;
    // Default item to sort on.
    const ORDER = this.state.order
      ? {
          sort: this.state.order.sortField,
          direction: this.state.order.sortDirection
        }
      : {
          sort: 'NAME',
          direction: 'ASC'
        };
    // Default items per page to display.
    const LIMIT = this.state.limit ? this.state.limit : 25;
        return (
          <Query
            query={specialtyConnectionQuery}
            pollInterval={0}
            notifyOnNetworkStatusChange
            variables={{
              filter: FILTER,
              afterCurser: OFFSET,
              first: LIMIT,
              orderBy: ORDER
            }}
            fetchPolicy="no-cache"
          >
            {({ loading, data, fetchMore, networkStatus }) => {
              // Skip loading icon/flash when we are polling for new results
              // or getting more results via fetchMore.
              if (loading && networkStatus !== 6 && networkStatus !== 3) {
                // Handle loading screen.

                return (
                  <div className={`text-center loading mt-4`}>
                    <i className="fal fa-spinner" />
                  </div>
                );
              }

              const specialties =
                data && data.specialties && data.specialties.edges.length >= 1
                  ? data.specialties.edges
                  : [];
              const page = data && data.umlsSearch ? data.umlsSearch.pageNumber : 1;

              if (specialties.length >= 1) {
                const umlsResults = specialties.map((term: UmlsTerm, id: number) => {
                  console.log(term.node.id);
                  return (
                    <Query
                      key={id}
                      query={topspecialtyInfoQuery}
                      pollInterval={0}
                      notifyOnNetworkStatusChange
                      variables={{
                        specialty_id: term.node.id
                      }}
                      fetchPolicy="no-cache"
                    >
                      {({ data, loading, error }) => {
                        if (loading || error) return null;

                        const result = data && data.topSpecialty && data.topSpecialty.id ? data.topSpecialty : {};
                        const cft = Object.assign({}, result, {
                          exists: !!(result && result.id)
                        });
                        console.log(result);

                        const ActionButton = () => {
                          if (cft.exists) {
                            return (
                              <Link
                                to={`/top_specialties/delete/${result.specialty[0].id}`}
                                title={`delete ${term.node.name}, which already exists in the Most Searched Specialty Database database.`}
                              >
                               <i className="fas fa-times" />
                              </Link>
                            );
                          } else {
                            return (
                              <Button
                                onClick={(e: Event) => {
                                  e.preventDefault();
                                  this.props.termSelected(term.node);
                                }}
                                title={`Add ${term.node.name} to the Most Searched Specialty database.`}
                              >
                                <i className="fas fa-plus" />
                              </Button>
                            );
                          }
                        };

                        // console.log(cft.exists);

                        return (
                          <tr id={term.node.id} className={`umls-term${cft.exists ? ' text-success term-exists' : ''}`}>
                            <td className={`umls-term--name`}>{term.node.name}</td>
                            {/* <td className={`umls-term--cuid`}>{term.cuid}</td> */}
                            <td className={`umls-term--actions text-center`}>
                              <ActionButton />
                            </td>
                          </tr>
                        );
                      }}
                    </Query>
                  );
                });

                return (
                  <UmlsFeed
                    results={umlsResults}
                    onLoadMore={() =>
                      fetchMore({
                        variables: {
                          pageNumber: page + 1
                        },
                        updateQuery: (prevResult, { fetchMoreResult }) => {
                          if (!fetchMoreResult) return prevResult;
                          if (prevResult === fetchMoreResult) return prevResult;

                          const updatedResultSet = Object.assign({}, prevResult, {
                            umlsSearch: Object.assign({}, prevResult.umlsSearch, {
                              pageNumber: fetchMoreResult.umlsSearch.pageNumber,
                              result: Object.assign({}, prevResult.umlsSearch.result, {
                                results: [
                                  ...prevResult.umlsSearch.result.results,
                                  ...fetchMoreResult.umlsSearch.result.results
                                ]
                              })
                            })
                          });

                          return updatedResultSet;
                        }
                      })
                        .then(response => {
                          // A 75px offset leaves the last previous result partially
                          // in view. A 100px offset puts the first new row exactly
                          // at the top of the viewport.
                          animateScroll(
                            response.data.umlsSearch.result.results[0].cuid, // The primary element we are scrolling to.
                            'dml-modal', // Scrolling wrapper.
                            75, // Offset Adjustment.
                            350 // Duration of scroll.
                          );
                        })
                        .catch(err => {
                          console.warn('FATAL ERROR IN UmlsSearch.');
                          console.error(err);
                        })
                    }
                  />
                );
              }
              return null;
            }}
          </Query>
        );
      }

      return null;
      
    };

    const visibilityClass = this.state.filter && this.state.filter.length >= 1 ? 'active-search ' : '';
    return (
      <React.Fragment>
        <div id="umls-search-field" className={`${visibilityClass} umls-search-form--wrapper`}>
          <input
            id="umlsSearch"
            defaultValue=""
            className="search umls-search umls-search--field"
            type="text"
            placeholder="Search Specialties..."
            onChange={this.handleChange}
            onKeyDown={this.handleKeyDown}
          />
          <button className="btn search-icon">
            <i className="fal fa-search" />
          </button>
          <button
            className="btn close-icon"
            onClick={() => {
              clearSearch('umlsSearch', this.clearFilter);
            }}
          >
            <i className="fas fa-times" />
          </button>
        </div>

        <QueryResults />
      </React.Fragment>
    );
  }
}

class UmlsFeed extends React.Component<UmlsFeedProps> {
  render() {
    const HeaderRows = () => {
      return (
        <tr>
          <th scope="col">Specialty Name</th>
          {/* <th scope="col">CUID</th> */}
          <th scope="col" className={`text-center`}>
            Actions
          </th>
        </tr>
      );
    };

    // console.log(this.props.results);

    return (
      <React.Fragment>
        <div className={``}>
          <div className={`text-success`} />
        </div>

        <table className="table table-hover mt-4">
          <tbody>
            <HeaderRows />
            {this.props.results}
          </tbody>
        </table>

        <div className="text-center">
          <Button color="primary" className={`btn btn-large button--load-more mt-3`} onClick={this.props.onLoadMore}>
            Load more
          </Button>
        </div>
      </React.Fragment>
    );
  }
}

export default SpecialtySearch;
