import React from 'react';
import { Alert as ReactstrapAlert } from 'reactstrap';

const Alert = ({ children, icon, ...props }) => {
  let className = icon ? 'alert--with-icon' : undefined;
  const AlertIcon = icon;
  return (
    <ReactstrapAlert className={className} {...props}>
      <AlertIcon className="alert-icon" />
      {children}
    </ReactstrapAlert>
  );
};

export default Alert;
