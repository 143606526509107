/* @flow */
/**
 * Function to handle open/close of the Association searches like Specialty/Physician.
 * @param div
 * @param button
 * @param input
 */
export const toggleAssociationSearch = ({ div, button, input }: { div: string, button: string, input: string }) => {
  const targetElement = document && document.getElementById(div);
  const buttonElement = document && document.getElementById(button);
  const inputElement = document && document.getElementById(input);

  // Find target div and open it or close it.
  if (typeof targetElement !== 'undefined' && targetElement !== null && targetElement instanceof HTMLElement) {
    targetElement.classList.toggle('d-none');
    targetElement.classList.toggle('d-block');

    // If the input is defined, focus on it if we're opening the div.
    if (
      typeof inputElement !== 'undefined' &&
      inputElement !== null &&
      inputElement instanceof HTMLInputElement &&
      targetElement.classList.contains('d-block')
    ) {
      inputElement.focus();
    }
  }

  if (typeof buttonElement !== 'undefined' && buttonElement !== null) {
    buttonElement.classList.toggle('association-closed');
    buttonElement.classList.toggle('association-open');
  }
};

/**
 * This function will handle clearing the value out of (any) top level search field.
 * @param targetId
 * @param stateCallback
 */
export const clearSearch = (targetId: string, stateCallback: () => void): void => {
  const target = document && document.getElementById(targetId);
  if (typeof target !== 'undefined' && target !== null && target instanceof HTMLInputElement) {
    target.value = '';
    stateCallback();
    const wrapper = target.closest('.section-search-form--wrapper');
    if (typeof wrapper !== 'undefined' && wrapper !== null) {
      wrapper.classList.remove('active-search');
    }

    target.focus();
  }
};

/**
 * This function will handle toggling buttons and links as well as form fields
 * when an error connecting to GraphQL service happens, or is recovered from.
 * @todo: Revisit handleGraphqlStatusChange() function(ality).
 *
 * @param failure
 * @param buttons
 * @param fields
 */
type DisableButtons = string[];
type DisableFields = string[];
export const handleGraphqlStatusChange = (failure: boolean, buttons: DisableButtons, fields: DisableFields) => {
  buttons.map((item, index) => {
    const button = document.getElementById(item);
    if (typeof button !== 'undefined' && button !== null && button instanceof HTMLButtonElement) {
      failure ? button.classList.add('disabled') : button.classList.remove('disabled');
      failure ? (button.disabled = true) : (button.disabled = false);
    }
    return null;
  });

  fields.map((item, index) => {
    const field = document.getElementById(item);
    if (typeof field !== 'undefined' && field !== null && field instanceof HTMLInputElement) {
      failure ? (field.disabled = true) : (field.disabled = false);
    }
    return null;
  });
};
