import gql from 'graphql-tag';

/* Query to Get Terms: */

export const GET_PROVIDER_SPECIALTY_TERMS = gql`
  query TermsForProvider($providerID: ID!) {
    provider(id: $providerID) {
      id
      fullName
      disableSpecialtyTermsAssociation{
        disable_specialty_terms_association
      }
      cftTerms {
        id
        name
        preferredName
      }
      specialties {
        id
        name
      }
      specialtyAssociatedTerms {
        edges {
          isExcluded
          cursor
          node {
            id
            name
            preferredName
            isExcluded
            specialties {
              id
            }
          }
        }
      }
    }
  }
`;

/* Mutate Specialty Term:*/

export const MUTATE_SPECIALTY_TERM = gql`
  mutation ExcludeTermsForProvider($providerID: ID!, $termIDs: [ID!]!, $action: ProviderSpecialtyTermAction!) {
    updateProviderSpecialtyTerms(input: { providerId: $providerID, termIds: $termIDs, action: $action }) {
      success
      provider {
        id
        specialtyAssociatedTerms(first: 200) {
          edges {
            isExcluded
            node {
              id
              isExcluded
              preferredName
            }
          }
        }
      }
    }
  }
`;

export const MUTATE_SPECIALTY_TERMS_ASSOCIATION = gql`
  mutation updateProviderOverrides($providerID: ID!, $disabled: Boolean) {
    updateProviderOverrides(
      input: { providerId: $providerID, update: { disable_specialty_terms_association: $disabled } }
    ) {
      success
    }
  }
`;
