import gql from 'graphql-tag';

/**
 * Query to handle Synonyms from UMLS
 */
export const GET_UMLS_SYNONYMS = gql`
  query GetPossibleSynonymsFromUmls($cui: String!) {
    umlsConcept(cui: $cui) {
      atoms(language: ENG,pageSize: 1000) {
        result {
          umls_auid: ui
          name
          rootSource
        }
      }
    }
  }
`;



/**
 * Query to fetch synonyms from CFT
 **/
export const GET_CFT_SYNONYMS = gql`
  query GetSynonymsForTerm($termID: ID) {
    cftTerm(id: $termID) {
      id
      preferredName
      primarySynonym {
        id
        name
      }
    }
    synonyms(filter: { termId: $termID }) {
      id
      auid
      name
    }
  }
`;

/**
 * Query to get the primary synonym
 **/
export const GET_PRIMARY_SYNONYM = gql`
  query GetSynonymsForTerm($termID: ID!) {
    cftTerm(id: $termID) {
      preferredName
      primarySynonym {
        id
        name
      }
    }
  }
`;

export const GET_POSSIBLE_DML_SYNONYMS = gql`
  query GetPossibleSynonymsFromDML($name: String) {
    synonyms(filter: { search: $name }) {
      id
      auid
      name
    }
  }
`;

export const GET_CUSTOM_DML_SYNONYMS = gql`
  query GetPossibleCustomSynonymsFromDML($name: String) {
    synonyms(filter: { search: $name, sabs: [DML_CUSTOM] }) {
      id
      auid
      name
      source
    }
  }
`;

export const synonymUniqueSlugQuery = gql`
  query synonymUniqueSlugQuery($slug: String!) {
    synonym(slug: $slug) {
      id
      slug
      name
      status
      terms{
        id
        name
        slug
      }
    }
  }
`;

export const PROMOTE_SYNONYM_TO_PRIMARY = gql`
  mutation promotePrimarySynonym($termID: ID!, $synonymID: ID!) {
    updateTerm(input: { termId: $termID, update: { primary_synonym_id: $synonymID } }) {
      success
      term {
        id
        name
        preferredName
        primarySynonym {
          id
          name
        }
      }
    }
  }
`;

export const DEMOTE_PRIMARY_SYNONYM = gql`
  mutation demotePrimarySynonym($termID: ID!) {
    updateTerm(input: { termId: $termID, update: { unsetFields: ["primary_synonym_id"] } }) {
      success
      term {
        id
        name
        preferredName
        primarySynonym {
          id
          name
        }
      }
    }
  }
`;

export const GET_SYNONYMS = gql`
  query GetPossibleSynonymsFromDML($search: String) {
    synonyms(filter: { search: $search }) {
      id
      auid
      name
    }
  }
`;

export const REMOVE_SYNONYM_TERM_ASSOCIATION = gql`
  mutation AddPrimarySynonymToTerm($input: RemoveTermSynonymAssociationInput!) {
    removeTermSynonymAssociation(input: $input) {
      success
      clientMutationId
      synonym {
        name
        id
        auid
      }
    }
  }
`;

export const CREATE_SYNONYM = gql`
  mutation CreateSynonym($name: String!, $termID: ID!) {
    createSynonym(input: { name: $name, termId: $termID }) {
      success
      synonym {
        slug
        name
        auid
      }
    }
  }
`;

export const updateSynonymMutation = gql`
  mutation UpdateSynonym($input: UpdateSynonymInput!) {
    updateSynonym(input: $input) {
      success
      synonym {
        id
        slug
        name
      }
    }
  }
`;

export const removeSynonym = gql`
mutation RemoveSynonym($input: RemoveSynonymInput!) {
  removeSynonym(input: $input) {
    success
    synonym {
      id
      slug
      name
    }
  }
}
`;

export const CREATE_SYNONYM_ONLY = gql`
  mutation CreateOnlySynonym($input: CreateOnlySynonymInput!) {
    createOnlySynonym( input: $input) {
      success
      synonym {
        slug
        name
        
      }
    }
  }
`;



export const CREATE_TERM_SYNONYM_ASSOCIATION = gql`
  mutation CreateTermSynonymAssociation($input: CreateTermSynonymAssociationInput!) {
    createTermSynonymAssociation(input: $input) {
      success
      synonym {
        id
        name
      }
      term {
        id
      }
    }
  }
`;
