/* @flow */
/**
 * Class to add/remove classes based on responsive breakpoints.
 *
 * @todo: Make this awesome enough to share.
 * @todo: Comment like crazy.
 * @todo: Implement a max property (that works) on the breakpoint values.
 * @todo: Make setting body classes optional.
 * @todo: Better Flow type checking.
 *
 * @example
 *
 * const options = {
 *   mode: 'single', // Either 'single' or 'multi'.
 *   debug: false,
 * };
 * new ResponsiveClasses(options);
 *
 * // Listen for changes to breakpoint (Pure Javascript).
 * window.addEventListener('breakpointChange', (e) => {
 *   // console.log(e.detail);
 * });
 *
 * // Listen for changes to breakpoint (jQuery).
 * $(window).on('breakpointChange', (e) => {
 *   // console.log(e.detail);
 * });
 *
 * @class ResponsiveClasses
 */

// For some reason OptionProps shows up as unused and gives warning.
/* eslint-disable no-unused-vars */
type OptionProps = {
  options: {
    breakpoints: [
      {
        key: string,
        min: number
      }
    ],
    mode: string,
    debug: boolean,
    element: HTMLElement
  }
};

type Breakpoint = {
  key: string,
  min: number
};

export class ResponsiveClasses<OptionProps> {
  options: {
    breakpoints: [
      {
        key: string,
        min: number
      }
    ],
    mode: string,
    debug: boolean,
    element: HTMLElement
  };
  activeBreakpoints: {};
  activeBreakpoint: boolean | string;
  /**
   * Constructs ResponsiveClasses configurations.
   *
   * @constructor
   *
   * @param {Object} options
   *  Options for the ResponsiveClasses implementation.
   */
  constructor(options: OptionProps) {
    const defaults = {
      breakpoints: [
        { key: 'xs', min: 0 },
        { key: 'sm', min: 576 },
        { key: 'md', min: 768 },
        { key: 'lg', min: 992 },
        { key: 'xl', min: 1200 },
        { key: 'xxl', min: 1900 }
      ],
      mode: 'single', // Either 'single' or 'multi'.
      debug: false,
      element: document.getElementsByTagName('BODY')[0]
    };
    this.options = Object.assign({}, defaults, options);
    // All active breakpoints (uses only min-width to match).
    this.activeBreakpoints = {};
    // Specific active breakpoint (uses min/max-width to match).
    this.activeBreakpoint = false;

    window.addEventListener('resize', () => {
      this.checkBreakpoints();
    });

    window.addEventListener('load', () => {
      this.checkBreakpoints();
    });
  }

  /**
   * Function to handle debugging positioning variables.
   */
  // eslint-disable-next-line no-unused-vars
  debugBreakpoints = () => {
    /* eslint-disable no-console */
    if (typeof this.activeBreakpoint === 'string') {
      console.log(`Active Breakpoint: ${this.activeBreakpoint}`);
      console.log('----------------------------------');
    }
    /* eslint-enable no-console */
  };

  /**
   * Function to calculate and assign the appropriate breakpoint..
   *
   * @return {String}
   *   The key (string) value of the active breakpoint.
   */
  getBreakpoint = () => {
    return this.activeBreakpoint;
  };

  /**
   *  Iterate over this.options.breakpoints and test each one to see
   *  if it is an/the active breakpoint.
   */
  checkBreakpoints = () => {
    const breakpoints = this.options.breakpoints;
    const prevActiveBreakpoint = this.getBreakpoint();
    for (let i = 0, len = breakpoints.length; i < len; i++) {
      this.checkBreakpointMulti(breakpoints[i]);
      this.checkBreakpointOne(breakpoints[i], i);
    }
    const currActiveBreakpoint = this.getBreakpoint();
    if (prevActiveBreakpoint !== currActiveBreakpoint) {
      const breakpointEvent = new CustomEvent('breakpointChange', {
        detail: {
          prev: prevActiveBreakpoint,
          curr: currActiveBreakpoint
        }
      });
      this.updateClasses();
      window.dispatchEvent(breakpointEvent);
    }

    if (this.options.debug) {
      this.debugBreakpoints();
    }
  };

  /**
   * Check to see if a certain breakpoint is active.
   *
   * @param {Object} breakpoint
   *   The text name of the breakpoint, which is the key in this.options.breakpoints
   */
  checkBreakpointMulti = (breakpoint: Breakpoint) => {
    this.activeBreakpoints[breakpoint.key] = window.matchMedia(`(min-width: ${breakpoint.min}px)`).matches;
  };

  /**
   * Check to see if a certain breakpoint is active.
   *
   * @param {Object} breakpoint
   *   The text name of the breakpoint, which is the key in this.options.breakpoints
   * @param {Number} i
   *   The key in the array belonging to the breakpoint. Allows seeing if there's a next one or not.
   */
  checkBreakpointOne = (breakpoint: Breakpoint, i: number) => {
    const nextIndex = i + 1;
    let match;

    if (this.options.breakpoints[nextIndex]) {
      const maxVal = this.options.breakpoints[nextIndex].min - 1;
      match = window.matchMedia(`(min-width: ${breakpoint.min}px) and (max-width: ${maxVal}px)`).matches;
    } else {
      match = window.matchMedia(`(min-width: ${breakpoint.min}px)`).matches;
    }

    if (match) {
      this.activeBreakpoint = breakpoint.key;
    }
  };

  /**
   * Updates the body classes to match the appropriate breakpoints.
   */
  updateClasses = () => {
    const breakpoints = this.options.breakpoints;
    const mode = this.options.mode;

    switch (mode) {
      case 'multi':
        for (let i = 0, len = breakpoints.length; i < len; i++) {
          const breakpoint = breakpoints[i].key;
          if (this.activeBreakpoints[breakpoint]) {
            this.options.element.classList.add(breakpoint);
          } else {
            this.options.element.classList.remove(breakpoint);
          }
        }
        break;
      case 'single':
      default:
        for (let i = 0, len = breakpoints.length; i < len; i++) {
          this.options.element.classList.remove(breakpoints[i].key);

          if (this.activeBreakpoint === breakpoints[i].key) {
            this.options.element.classList.add(breakpoints[i].key);
          }
        }
        break;
    }
  };
}

/**
 * @return {boolean}
 *   Return true if we consider this a mobile breakpoint.
 */
export const isMobile = () => {
  const body = document.getElementsByTagName('BODY')[0];
  return body.classList.contains('xs') || body.classList.contains('sm');
};
