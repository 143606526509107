/* @flow */
import React from 'react';
import { Button, Badge } from 'reactstrap';
import { isRole } from 'functions/AuthFunctions';
import type { ProviderAssociationPackage } from 'provider/types';

type Props = {
  action: (element: ProviderAssociationPackage, slug: string) => void,
  association: Object,
  children: string,
  colors: {
    badge: string,
    button: string
  },
  icon: string,
  slug: string
};

const Association = (props: Props) => {
  console.log(props);
  const { action, children, colors, icon, association, slug, source } = props;
  const buttonProps = {
    color: colors.button,
    onClick: action(association, slug)
  };
  if (association && association.id) {
    buttonProps['data-item-id'] = `${association.id}`;
  }

  return (
    
    props.slug == 'cftTerms' && isRole('trulyAwardUser') !== true   ? <Badge color={colors.badge} className="linked-reference-item mr-1 mb-1">
     <a style = {{color: 'black'}} href={"/cft/edit/"+props.association.slug}>{children}</a>
   <span>{source && source}</span> 
   {isRole('trulyAwardUser') == true ? '':
   <Button className="btn btn-sm specialty-remove-button" {...buttonProps}>
     <i className={`fas ${icon}`} />
   </Button>
   }
    </Badge>:

    <Badge color={colors.badge} className="linked-reference-item mr-1 mb-1">
       {children}
      <span>{source && source}</span> 
      {isRole('trulyAwardUser') == true ? '':
      <Button className="btn btn-sm specialty-remove-button" {...buttonProps}>
        <i className={`fas ${icon}`} />
      </Button>
      }
    </Badge>
    
  );
};

Association.defaultProps = {
  colors: {
    badge: 'tertiary',
    button: 'danger'
  },
  icon: 'fa-times'
};

export default Association;
