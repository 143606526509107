/* @flow */
/* eslint-disable no-unused-vars */
import React from 'react';
import { Alert, Button } from 'reactstrap';

import { cftInfoQuery, deleteCftMutation } from 'clinicalFocusTerm/queries';
import { topspecialtyInfoQuery,RemoveTopSpecialty } from 'topspecialties/queries';
import { Query, Mutation } from 'react-apollo';

import { Link, Redirect } from 'react-router-dom';
import type { DeleteCftProps } from 'clinicalFocusTerm/types';
import { modalClose, modalHide } from 'elements/ModalSection';

export class TopSpecialtyDeleteForm extends React.Component<DeleteCftProps> {
  render() {
    console.log(this.props)
    return (
      <React.Fragment>
        <Query
          query={topspecialtyInfoQuery}
          pollInterval={0}
          notifyOnNetworkStatusChange={true}
          variables={{
            specialty_id: this.props.cft && this.props.cft.slug ? this.props.cft.slug : 'nothingtoseehere'
          }}
          fetchPolicy="cache-and-network"
        >
          {({ data, loading }) => {
            if (loading) return null;
            const cft =
              data && data.topSpecialty
                ? data.topSpecialty
                : {
                    // Set the default form values.
                    id: null,
                    type: '',
                    name: '',
                    status: false,
                    taxonomy: '',
                    specialties: [],
                    providers: [],
                    umls_cuid: ''
                  };
console.log(cft);
            const ConfirmationDetails = () => {
              if (data && data.topSpecialty) {
                return (
                  <React.Fragment>
                    <Alert color="danger" className="mb-3 delete-confirmation">
                      <div className="alert--with-icon">
                        <i className="alert-icon fal fa-trash-alt" />
                        <h4 className="mb-0">Are you sure you want to delete following Most Selected Specialty?</h4>
                      </div>
                    </Alert>
                    <hr />
                    <div className="delete-confirmation--details">
                      <p>
                        <span className="delete-confirmation-label">Specialty ID: </span>
                        <strong>{cft && cft.id ? cft.id : ''}</strong>
                      </p>
                      <p>
                        <span className="delete-confirmation-label">Specialty Name: </span>
                        <strong>{cft && cft.specialty[0].name ? cft.specialty[0].name : ''}</strong>
                      </p>
                     
                    </div>
                    <hr />
                  </React.Fragment>
                );
              }
              return null;
            };

            return (
              <React.Fragment>
                <Mutation
                  mutation={RemoveTopSpecialty}
                  variables={{
                    input: {
                      specialty_id: cft && cft.specialty[0].id ? cft.specialty[0].id : null
                    }
                  }}
                >
                  {(deleteCFT, { data, loading }) => {
                    if (loading) {
                      return null;
                    }
                    const success = data && data.deleteTopSpecialty && data.deleteTopSpecialty.success;

                    if (success) {
                      const status = {
                        success: success,
                        action: success ? 'Deleted' : 'Failed to Delete',
                        item: {
                          id: data && data.deleteTopSpecialty && data.deleteTopSpecialty.success ? cft.id : cft.id,
                          name: data && data.deleteTopSpecialty && data.deleteTopSpecialty.success ? cft.specialty[0].name : cft.specialty[0].name
                        },
                        tail: 'from the CFT database...'
                      };

                      return (
                        <React.Fragment>
                          <Alert color="success">
                            <div className="alert--with-icon">
                              <i className="alert-icon fal fa-check-circle" />
                              <p className="mb-0">
                                Successfully {status.action} <strong>{status.item.name}</strong> (
                                <em>{status.item.id})</em> {status.tail}
                              </p>
                            </div>
                          </Alert>
                          <hr />
                          <div className={`text-center`}>
                            <Button
                              outline
                              className="delete-item-button--success"
                              size="lg"
                              color="success"
                              title="Done"
                              onClick={() => {
                                modalClose(this.props.history);
                              }}
                            >
                              <i className="fal fa-check-circle" />
                              <span>Done</span>
                            </Button>
                          </div>
                        </React.Fragment>
                      );
                    }

                    if (cft && cft.id !== null) {
                      return (
                        <React.Fragment>
                          <ConfirmationDetails />
                          <div className="text-center">
                            <Button
                              className="delete-item-button"
                              outline
                              size="lg"
                              color="danger"
                              title="Delete"
                              onClick={() => {
                                deleteCFT();
                              }}
                            >
                              <i className="fal fa-trash-alt" />
                              <span>Yes, I'm sure</span>
                            </Button>
                          </div>
                        </React.Fragment>
                      );
                    } else {
                      return (
                        <Alert color="danger">
                          <div className="alert--with-icon">
                            <i className="alert-icon fal fa-info-circle" />
                            <p className="mb-0">That CFT could not be found...</p>
                          </div>
                        </Alert>
                      );
                    }
                  }}
                </Mutation>
              </React.Fragment>
            );
          }}
        </Query>
      </React.Fragment>
    );
  }
}
