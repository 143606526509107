import gql from 'graphql-tag';

export const GET_SYNONYMS = gql`
  query synonyms($filter: String, $orderBy: SynonymOrdering,  $first: Int, $afterCurser: String) {
    synonymsList(filter: $filter, orderBy: $orderBy, first: $first,afterCurser: $afterCurser) {
      aggregate {
        count
            }
     edges {
      node {
      id
      auid
      name
      slug
      updated_at
      
    }
    cursor
  }
}
  }
`;