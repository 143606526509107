import gql from 'graphql-tag';

/**
 * Query for getting a list of Providers.
 */
// Version that supported pagination via mariadb
// export const providerConnectionQuery = gql`
//   query providers($before: String, $after: String, $first: Int!, $filter: String, $orderBy: ProviderOrdering) {
//     providers(before: $before, after: $after, first: $first, filter: $filter, orderBy: $orderBy) {
//       pageInfo {
//         hasNextPage
//         hasPreviousPage
//         startCursor
//         endCursor
//       }
//       aggregate {
//         count
//       }
//       edges {
//         node {
//           id
//           fullName
//           firstName
//           lastName
//           middleName
//           image
//           isOnFad
//           isStaffPhysician
//           isPcp
//           isAcceptingNewPatients
//           specialties {
//             id
//           }
//         }
//       }
//     }
//   }
// `;
export const searchProviderQuery = gql`
  query providers($filter: String, $orderBy: ProviderOrdering, $first: Int, $afterCurser: String) {
    providers(filter: $filter, orderBy: $orderBy,  first: $first,afterCurser: $afterCurser) {
      edges {
        node {
      id
      fullName
        }
      }
    }
 
  }
`;

export const providerConnectionQuery = gql`
  query providers($filter: String, $orderBy: ProviderOrdering, $first: Int, $afterCurser: String) {
    providers(filter: $filter, orderBy: $orderBy, first: $first,afterCurser: $afterCurser) {
    aggregate {
       count       
      }
      edges {
         node {
      id
      fullName
      firstName
      suffix
      lastName
      middleName
      imageOriginal {
        url
      }
      imageNew {
        url
      }
      isFad
      isStaff
      isPcp
      isBookable
      isOnlineScheduling
      isAcceptingNewPatients
      override{
       disableClinicalFocusTerms
      }
      specialties {
        id
      }
      video {
        youtubeVideoId
      }
    }
    cursor
  }
    }
 
  }
`;

/**
 * Query for getting a single Provider. This is the MERGED data, merging the RAW and OVERRIDDEN data.
 */
export const providerInfoQuery = gql`
  query provider($id: ID!) {
    provider(id: $id) {
      id
      firstName
      lastName
      middleName
      suffix
      fullName
      gender
      email
      website

      npi
      bioNew {
        bio
      }
      bioOriginal {
        bio
      }
      yearBeganPractice
      imageNew {
        url
      }
      imageOriginal {
        url
      }
      video {
        youtubeVideoId
      }
      isFad
      isStaff
      isPcp
      isAcceptingNewPatients
      isBookable
      isOnlineScheduling
      override{
       disableClinicalFocusTerms
      }
    }
  }
`;

/**
 * Query for getting a single Provider. This is the MERGED data, merging the RAW and OVERRIDDEN data.
 */
export const providerTermQuery = gql`
  query provider($id: ID!) {
    provider(id: $id) {
      id
      fullName
      cftTerms {
        id
        name
        slug
      }
    }
  }
`;

/**
 * Query for getting a single Provider. This is the MERGED data, merging the RAW and OVERRIDDEN data.
 */
export const providerGroupQuery = gql`
  query provider($id: ID!) {
    provider(id: $id) {
      id
      fullName
      groups {
        id
        name
        slug
      }
    }
  }
`;


export const providerAwardQuery = gql`
  query provider($id: ID!) {
    provider(id: $id) {
      id
      fullName
      awards{
       year,
       status,
       award_name{
        award_name
        id
       }
      }
     
    }
    awards{
      award_name
      award_type
      id
   }

  }
`;

/**
 * Query for getting a single Provider. This is the MERGED data, merging the RAW and OVERRIDDEN data.
 */
export const providerSpecialtyQuery = gql`
  query provider($id: ID!) {
    provider(id: $id) {
      id
      fullName
      specialties {
        id
        name
      }
      specialtiesOriginal {
        id
        name
      }
      specialtiesAdd {
        id
        name
        is_visible
        enable_inherited_list
      }
      specialtiesRemove {
        id
        name
      }
    }
  }
`;

/**
 * Query for getting a single Provider and the ORIGINAL, not overridden data.
 */
export const rawProviderInfoQuery = gql`
  query providerRaw($id: ID!) {
    providerRaw(id: $id) {
      id
      firstName
      lastName
      middleName
      fullName
      suffix
      gender
      email
      website

      npi
      bioNew {
        bio
      }
      bioOriginal {
        bio
      }
      yearBeganPractice
      imageNew {
        url
      }
      imageOriginal {
        url
      }
      video {
        youtubeVideoId
      }
      isFad
      isStaff
      isPcp
      isBookable
      isOnlineScheduling
      isAcceptingNewPatients
      override{
       disableClinicalFocusTerms
      }
    }
  }
`;

/**
 * Query for getting a single Provider's OVERRIDES.
 */
export const overrideProviderInfoQuery = gql`
  query providerOverrides($id: ID!) {
    providerOverrides(id: $id) {
      id
      firstName
      lastName
      middleName
      suffix
      gender
      yearBeganPractice
      bioNew {
        bio
      }

      imageNew {
        url
      }

      isFad
      isStaff
      isPcp
      isAcceptingNewPatients
      isBookable
      isOnlineScheduling
      override{
       disableClinicalFocusTerms
      }
    }
  }
`;

/**
 * Mutation for updating a provider.
 */
export const updateProviderOverrides = gql`
  mutation updateProviderOverrides($input: UpdateProviderInput!) {
    updateProviderOverrides(input: $input) {
      success
    }
  }
`;

export const RemoveOverrides = gql`
   mutation removeOverrides($id: ID!, $log:LOG!){
    removeOverrides(id:$id,log:$log){
      success
    }
   }
   `;

  export const updateSpecialtyInheritedListStatusMutation = gql`
   mutation updateSpecialtyInheritedListStatus($input:updateSpecialtyInheritedListStatusInput!){
    updateSpecialtyInheritedListStatus(input:$input){
      success
    }
   }
   `;

   export const updateSpecialtyHeaderVisibiltyMutation = gql`
   mutation updateSpecialtyHeaderVisibilty($input:updateSpecialtyHeaderVisibiltyInput!){
    updateSpecialtyHeaderVisibilty(input:$input){
      success
    }
   }
   `;
