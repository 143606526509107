/* @flow */
import * as React from 'react';
import { withRouter, Switch, Route, Redirect } from 'react-router-dom';
import Dashboard from 'dashboard/Dashboard';
import Groups from 'group/Groups';
import Audit from 'audit/Audit';
import Emails from 'emails/Emails';
import Banners from 'promotedSearch/Banners';
import Users from 'user/Users';
import Faq from './Faq';
import TopSpecialties from './topspecialties/TopSpecialties';
import CftSection from 'clinicalFocusTerm/CftList';
import synonymSection from 'synonymTerms/SynonymList';
import Specialties from 'specialty/Specialties';
import { isAuthenticated, getPermissions } from 'functions/AuthFunctions';
import type { routeProps } from 'shared/types';
import ProviderSection from 'provider/Providers';

class AuthenticatedRoutes extends React.Component<routeProps> {
  componentDidUpdate() {
    if (isAuthenticated()) {
      // Perform operations to see if a user's login has expired.
      this.props.authentication.check();
    }
  }
  render() {
    return (
      <Switch>
       
        <Route exact path={'/'} render={() => <Redirect to="/dashboard" />} />
        <Route exact path={'/dashboard'} component={Dashboard} />
        {(getPermissions('terms')== true)?
        <Route path={'/cft'} component={CftSection} />
        :
        ''
        }
        {(getPermissions('synonyms')== true)?
        <Route path={'/synonyms'} component={synonymSection} />
        :
        ''
        }
        {(getPermissions('groups')== true)?
        <Route path={'/group'} component={Groups} />
        :
        ''
        }
         {(getPermissions('promoted_search')== true)?
        <Route path={'/promoted_search'} component={Banners} />
        :
        ''
        }
        {(getPermissions('specialties')== true)?
        <Route path={'/specialty'} component={Specialties} />
        :
        ''
        }
        {(getPermissions('top_specialties')== true)?
        <Route path={'/top_specialties'} component={TopSpecialties} />
        :
        ''
        }
        {(getPermissions('provider_override')== true)?
        <Route path={'/provider'} component={ProviderSection} />
        :
        ''
        }
        {(getPermissions('logs')== true)?
        <Route exact path={'/audit'} component={Audit} />
        :
        ''
        }
        {(getPermissions('emails')== true)?
        <Route path={'/email'} component={Emails} />
        :
        ''
        }
         {(getPermissions('users')== true)?
        <Route path={'/user'} component={Users} />
        :
        ''
        }
        <Route exact path={'/faq'} component={Faq} />
      </Switch>
    );
    
  }
}

export default withRouter(AuthenticatedRoutes);
