import gql from 'graphql-tag';

export const auditListQuery = gql`
  query auditLogs($first: Int, $afterCurser: String,$filter: String,) {
    auditLogs(first: $first,afterCurser: $afterCurser,filter: $filter) {
        aggregate {
                     count
                   }
        edges {
           node {
      id
      user {
        username
      }
      timestamp
      message
      action
      args
     
    }
    cursor
}
      
        
      
   
    
      
     
    },
   
    
  }
`;

export const auditInfoQuery = gql`
  query auditLog($id: ID!) {
    auditLog(id: $id) {
      id
      username
      is_admin
      status
    }
  }
`;
