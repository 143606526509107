import React, { Component } from 'react';
import TimePicker from 'rc-time-picker';
import moment from 'moment';
import 'rc-time-picker/assets/index.css';
import { FormGroup, Label, Input } from 'reactstrap';
import { FormControl, InputLabel } from '@material-ui/core';

class TimePickerInput extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedTime: this.props.selectedTime,
    };
  }

  handleTimeChange = (value) => {
    this.setState({ selectedTime: value });
    if (this.props.onTimeChange) {
      this.props.onTimeChange(value);
    }
  };

  handleClick = (e) => {
    e.stopPropagation(); // Prevent event propagation to parent modal
  };

  render() {
    return (
      <FormControl className="ml-2">
        <Label for="timePicker">{this.props.label}</Label>
        <TimePicker
          showSecond={false}
          value={this.state.selectedTime}
          onChange={this.handleTimeChange}
          className=""
          id="timePicker"
          onClick={this.handleClick}
          
        />
      </FormControl>
    );
  }
}

export default TimePickerInput;
