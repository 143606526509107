/* @flow */
/* eslint-disable no-unused-vars */
import * as React from 'react';
import { Alert, Form, FormGroup, Label, Input, FormText, Button, Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap';
import Select from 'react-select';

import { searchSpecialtyQuery, specialtyInfoQuery, createChildParentSpecialtyMutation, removeChildParentSpecialtyMutation, insertSpecialtyMutation, updateSpecialtyMutation, deleteSpecialtyMutation} from 'specialty/queries';
import {searchServiceLineQuery, createServiceLineSpecialtyMutation, removeServiceLineSpecialtyMutation, changeVariantsSearchStatusMutation} from 'serviceLines/queries';
import { Query, Mutation } from 'react-apollo';
import ModalSection, { modalClose }  from 'elements/ModalSection';
import { SystemAlert } from 'elements/SystemAlert';
import ToggleButton from '../elements/ToggleButton';
import ReactDOM from 'react-dom';
import AssociationForm from 'elements/AssociationForm';
import {
  searchCftsQuery,
  associateSpecialtyWithTermMutation,
  associateSpecialtyWithTermMasterMutation,
  associateSpecialtyWithInheritedTermMutation,
  cftInfoQuery,
  removeSpecialtyFromTermMutation,
  removeSpecialtyFromTermMasterMutation,
  removeInheritedTermSpecialtyMutation,
  copySpecialtyDefaultVariantTermsMutation
} from 'clinicalFocusTerm/queries';

export class EditSpecialtyModal extends React.Component {

  constructor(props: SectionListProps) {
    super(props);
  }

  componentDidMount() {
      setTimeout(() => {
        ModalSection.openModal();
      }, 100);
  }

  render() {
   
    return (
      <ModalSection
        heading={`Edit Specialty`}
        content={
          <React.Fragment>
            <SpecialtyDataQuery
              history={this.props.history}
              match={this.props.match}
              slug={this.props.slug}
              search={this.props.search}
              updateModalVisibilty = {this.props.updateModalVisibilty}
            />
          </React.Fragment>
        }
        actions={false}
        history={this.props.history}
      />
    );
  }
}

export class DeleteSpecialtyModal extends React.Component {
  componentDidMount() {
    setTimeout(() => {
      ModalSection.openModal();
    }, 100);
  }

  render() {
    return (
      <ModalSection
        heading="Delete Specialty"
        content={
          <SpecialtyDeleteForm
          history={this.props.history}
          slug={this.props.slug}
          search={this.props.search}
          callbacks={this.props.callbacks}
        />
        }
        actions={false}
        history={this.props.history}
      />
    );
  }
}

class SpecialtyDeleteForm extends React.Component {
  render() {
    return (
      <React.Fragment>
        <Query
          query={specialtyInfoQuery}
          pollInterval={0}
          notifyOnNetworkStatusChange={true}
          variables= {{
            slug: this.props.slug
          }}
          fetchPolicy="no-cache"
        >
          {({ data, loading }) => {
            if (loading) return null;
            const specialty =
            data && data.specialty
              ? data.specialty
              :  {
                id:'',
                name:'',
                source: 'custom',
                display_name: '',
                slug:'',
                status: 1,
                is_visible: 1,
                is_board_certified: 1,
                is_clean: 1,
                is_clean_timestamp: null,
              };

            const ConfirmationDetails = ({specialty}) => {
             
              if (specialty) {
                return (
                  <React.Fragment>
                    <Alert color="danger" className="mb-3 delete-confirmation">
                      <div className="alert--with-icon">
                        <i className="alert-icon fal fa-trash-alt" />
                        <h4 className="mb-0">Are you sure you want to delete following Specialty?</h4>
                      </div>
                    </Alert>
                    <hr />
                    <div className="delete-confirmation--details">
                      <p>
                        <span className="delete-confirmation-label w-200px">Northwell Specialty Name: </span>
                        <strong>{specialty.name ? specialty.name : ''}</strong>
                      </p>
                      <p>
                        <span className="delete-confirmation-label w-200px">Find a Doctor Display Name: </span>
                        <strong>{specialty.name ? specialty.display_name : ''}</strong>
                      </p>
                      <p>
                        <span className="delete-confirmation-label w-200px">Slug: </span>
                        <strong>{specialty.slug ? specialty.slug : ''}</strong>
                      </p>
                      <p>
                        <span className="delete-confirmation-label w-200px">Source: </span>
                         <strong>{specialty.source ? specialty.source : ''}</strong>
                      </p>
                      <p>
                        <span className="delete-confirmation-label w-200px">Is Visible: </span>
                         <strong>{!!specialty.is_visible ? 'Yes' : 'No'}</strong>
                      </p>
                      <p>
                        <span className="delete-confirmation-label w-200px">Is Board Certified: </span>
                        <strong>{!!specialty.is_board_certified ? 'Yes' : 'No'}</strong>
                      </p>
                      <p>
                        <span className="delete-confirmation-label w-200px">Is Clean: </span>
                        <strong>{!!specialty.is_clean ? 'Yes' : 'No'}</strong>
                      </p>
                      <p>
                        <span className="delete-confirmation-label w-200px">Is Inherited List Clean: </span>
                        <strong>{!!specialty.is_clean_timestamp ? 'Yes' : 'No'}</strong>
                      </p>
                    </div>
                    <hr />
                  </React.Fragment>
                );
              }
              return null;
            };

          

            return (
              <React.Fragment>
                <Mutation
                  mutation={deleteSpecialtyMutation}
                  variables={{
                    input: {
                      id: specialty && specialty.id ? specialty.id : null
                    }
                  }}
                >
                  {(deleteSpecialty, { data, loading }) => {
                    if (loading) {
                      return null;
                    }
                    const success = data && data.deleteSpecialty && data.deleteSpecialty.success;

                    if (success) {
                      
                      const colorVar = (success === true) ? 'success' : 'danger';
                      const actionVar = (success === true) ? 'Specialty deleted successfully' : 'Error deleting specialty, please try again';
                      
                      const NotifyUpserted = () => {
              
                        return (
                          <SystemAlert
                            autoClose
                            color={colorVar}
                            icon="alert-icon fal fa-check-circle"
                            messageData={{
                              action: actionVar,
                              item: "",
                              id: '',
                              tail: '...'
                            }}
                          />
                        );
                      }
                      modalClose(this.props.history);
                      const alert = document && document.getElementById('system-alert-wrapper');
                      
                      ReactDOM.render(<NotifyUpserted />, alert);
                    }

                    if (specialty && specialty.id !== null) {
                      return (
                        <React.Fragment>
                          <ConfirmationDetails  specialty={specialty}/>
                          <div className="text-center">
                            <Button
                              className="delete-item-button"
                              outline
                              size="lg"
                              color="danger"
                              title="Delete"
                              onClick={() => {
                                deleteSpecialty();
                              }}
                            >
                              <i className="fal fa-trash-alt" />
                              <span>Yes, I'm sure</span>
                            </Button>
                          </div>
                        </React.Fragment>
                      );
                    } else {
                      return (
                        <Alert color="danger">
                          <div className="alert--with-icon">
                            <i className="alert-icon fal fa-info-circle" />
                            <p className="mb-0">That Specialty could not be found...</p>
                          </div>
                        </Alert>
                      );
                    }
                  }}
                </Mutation>
              </React.Fragment>
            );
          }}
        </Query>
      </React.Fragment>
    );
  }
}

class VariantPopup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      copyDefaultList: false,
      selectedServiceId:'',
      isOpen: this.props.isOpen,
    };
  }

  togglePopup = () => {
    this.setState(prevState => ({
      isOpen: !prevState.isOpen,
    }));
  }

  getServiceLineVariants = () =>{
      let variantOptions =[];
      const serviceLines = this.props.service_lines;
      const serviceLineVariants = this.props.allSpecialtyServiceLinesVariants;
      serviceLines.map(item => {
          if(!serviceLineVariants[item.slug].length > 0){
            variantOptions.push({value:item.id,label:item.name,slug:item.slug});
          }
      });
      return variantOptions;
  }

  render() {
    const handleVariantListChange = (target) =>{
      this.setState({selectedServiceId:target.value});
      this.props.handleVariantChange(target);
    }
    const hanldeCopyChange = (e) =>{
      this.setState(prevState => ({
        copyDefaultList: !prevState.copyDefaultList,
      }));
    } 

    const submitMutation = (createDefaulInheritedtList)=>{
      this.togglePopup();
      if(this.state.copyDefaultList){
        createDefaulInheritedtList();
      }
      this.props.addDefaultList(this.state.copyDefaultList);
      
      
    }
    const inputVars = {specialtyId: this.props.specialty.id, servicelineId:this.state.selectedServiceId};
    return(
      <Mutation
      mutation={copySpecialtyDefaultVariantTermsMutation}
      variables={{
        input: inputVars
      }}
      refetchQueries={[
        {
          // Clear the cache for the parent query.
          query: specialtyInfoQuery,
          variables: {
            slug: this.props.specialty.slug
          }
        }
      ]}
    >
      {(createDefaultList, { loading, error }) => {
        if (loading) {
          return null;
        }
        if (error) {
          console.log(error);
        }
        if (loading) return null;
            const variantOptions = this.getServiceLineVariants();
            const serviceLinesCount = this.props.service_lines?this.props.service_lines.length:0;
            const buttonDisabled = (this.props.variantVisibleCount == serviceLinesCount)?true:false;
            const alertText = (buttonDisabled) ?  (
            <Alert color="warning" className={`mb-3 d-block`}>
            <div className="alert--with-icon">
              <i className="alert-icon fal fa-info-circle" /> Please associate another Service Line in order to add another Variant Inherited Term List
              </div>
            </Alert>):"";
            return (
              <React.Fragment>
                {alertText}
                <FormGroup>
                  <div className="add-variant">
                  <Button
                      id="saveGroupButton"
                      type="button"
                      size="md"
                      color="primary"
                      title="Save"
                      className="btn-large"
                      disabled={buttonDisabled}
                      onClick={()=>this.togglePopup()}
                    >
                      <span className='fas fa-plus'></span> Add variant List 
                      </Button>
                      </div>
                </FormGroup>
                <Modal isOpen={this.state.isOpen} toggle={this.togglePopup} centered>
                <ModalHeader toggle={this.togglePopup}>Add Variant List</ModalHeader>
                <ModalBody>
                <h4>Specialty or Service Line</h4>
                <Select
                    id="variant"
                    name="variant"
                    options={variantOptions}
                    onChange={(e)=>handleVariantListChange(e)} 
                    defaultValue={''}
                    placeholder={"Select"}
                  />
                  <hr/>
                   <input
                    type="checkbox"
                    name="defaultList"
                    checked={this.state.copyDefaultList}
                    onClick={(e)=>hanldeCopyChange(e)}
                    disabled={(this.state.selectedServiceId != "")?false:true}
                />
                <label for="defaultList"> Copy Default List</label>
                  </ModalBody>
                <ModalFooter>
                  <Button color="primary" onClick={(e)=> submitMutation(createDefaultList)}>Done</Button>
                </ModalFooter>
              </Modal>
            </React.Fragment>
        );
      }}
      </Mutation>
    );
     
  }
}

export class AddSpecialtyModal extends React.Component {
  componentDidMount() {
    setTimeout(() => {
      ModalSection.openModal();
    }, 100);
  }

  render() {
    return (
      <ModalSection
        heading={`Add Specialty`}
        content={
          <React.Fragment>
            <SpecialtyDataQuery
              history={this.props.history}
              match={this.props.match}
              slug={this.props.slug}
              search={this.props.search}
              updateModalVisibilty = {this.props.updateModalVisibilty}
            />
          </React.Fragment>
        }
        actions={false}
        history={this.props.history}
      />
    );
  }
}

class SpecialtyDataQuery extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      updateSpecialty: false
    }
  }

  toggleSpecialtyQuery = () => {
    this.setState(prevState => ({
      updateSpecialty: !prevState.updateSpecialty,
    }));
  }

  render(){
    return(
    <Query
    query={specialtyInfoQuery}
    pollInterval={0}
    notifyOnNetworkStatusChange={true}
    variables={{
      slug: this.props.slug && this.props.slug ? this.props.slug : ''
    }}
    fetchPolicy="no-cache"
  >
    {({ data, loading }) => {
      if (loading) return null;
          const specialty = (data && data.specialty)? data.specialty: null; 
          return (<SpecialtyForm  toggleSpecialtyQuery = {this.toggleSpecialtyQuery} history={this.props.history} updateModalVisibilty={this.props.updateModalVisibilty} slug={this.props.slug} specialty = {specialty} />);
    }
    }
     </Query>
    );
  }

}

class SpecialtyForm extends React.Component {
  variantVisibleCount = 0;
  constructor(props) {
    super(props);
    this.state = {
      showConfirmDialog :false,
      specialtyData: this.props.specialty !== null? this.props.specialty:
      {
        id:'',
        name:'',
        display_name: '',
        source: 'custom',
        slug:'',
        status: 1,
        is_visible: 1,
        is_board_certified: 1,
        is_clean: 1,
        is_clean_timestamp: null,
      },
      variant:{
        id:'',
        slug:'',
        label:'Service Line'
      },
      isOpen:false
    };
    this.setSpecialtyAndSlug = this.setSpecialtyAndSlug.bind(this);
    this.setFieldState = this.setFieldState.bind(this);
    this.togglePopup = this.togglePopup.bind(this);
    this.handleVariantChange = this.handleVariantChange.bind(this);
    
  }

  togglePopup = () => {
    this.setState(prevState => ({
      isOpen: !prevState.isOpen,
    }));
  }

  setSpecialtyAndSlug = (fieldVal) => {
    let slugVal = fieldVal.toLowerCase().trimEnd();
    if(slugVal.includes(" ")) {
       slugVal = slugVal.replace(/\s+/g, '-');
    }
    this.setState(prevState => ({
      specialtyData: {
        ...prevState.specialtyData,
        name : fieldVal,
        slug : slugVal
      }
    }));
  }

  setFieldState = (e) =>{
    const fieldName = e.target.name;
    const fieldVal = e.target.value;
    this.setState(prevState => ({
      specialtyData: {
        ...prevState.specialtyData,
        [fieldName] : JSON.parse(fieldVal)
      }
    }));
  }

  setDisplayState = (e) =>{
    const fieldName = e.target.name;
    const fieldVal = e.target.value;
    this.setState(prevState => ({
      specialtyData: {
        ...prevState.specialtyData,
        [fieldName] : fieldVal
      }
    }));
  }

  handleVariantChange = (target) => {
    this.setState({ variant: { id: target.value, label: target.label, slug: target.slug}});
  }

  async updateServiceLines(element, action) {
    let service_lines_state = this.state.specialtyData.service_lines;
    if(action === "add"){
      let elementFound;
      if(service_lines_state.length > 0 ){
        elementFound = service_lines_state.find(item =>(item.slug === element.slug));
      }
      if(!elementFound){
        service_lines_state.push(element);
        this.setState(prevState => ({
          specialtyData : { ...prevState.specialtyData,
            service_lines: service_lines_state
          }
        }));
      }
     
    }else if(action === "delete"){
      let service_lines = service_lines_state.filter(item => (element.slug !== item.slug));
      if(element.slug === this.state.variant.slug){
        this.setState(prevState => ({
          variant:{
            id:0,
            slug:'',
            label:'Service Line'
          },
          specialtyData : { ...prevState.specialtyData,
          service_lines: service_lines
          }
        }));
      }else{
        this.setState(prevState => ({
          specialtyData : { ...prevState.specialtyData,
          service_lines: service_lines
          }
        }));
      }
    }
  }

  resetDefaultList() {
     const slug = this.state.variant.slug;
    let varianttTerms = this.state.specialtyData.cftVariantTerms;
     let cftTerms = varianttTerms.filter((term) =>term.service_line_slug == slug);
    this.setState(prevState =>({
      specialtyData : { ...prevState.specialtyData,
        cftVariantTerms: cftTerms
      }
    }));
  }

  addDefaultList = (copyDefault) => {
    const slug = this.state.variant.slug;
    let defaultTerms = this.state.specialtyData.cftDefaultTerms;
    let variantTerms = this.state.specialtyData.cftVariantTerms;
    if(copyDefault){
      defaultTerms.forEach((term) =>{
        variantTerms.push({id:term.id, name:term.name, slug:term.slug, service_line_slug: slug});
      });
    }else{
      variantTerms.push({});
    }
   
    this.setState(prevState =>({
      specialtyData : { ...prevState.specialtyData,
        cftVariantTerms: variantTerms
      }
    }));
  }
  
  /*Display vairant blocks for each service lines*/
  showVariantBlocks = ({service_lines, cftVariantTerms}) =>{
      { if(service_lines.length > 0 ){
        this.variantVisibleCount = 0;
       return service_lines.map((sl) => {
        if(cftVariantTerms.length > 0){
        const variants = cftVariantTerms.filter(t=>(t.service_line_slug === sl.slug));
        if(variants.length > 0 || this.state.variant.slug === sl.slug) {
           this.variantVisibleCount++;
           let visibiltyCheck = cftVariantTerms ? (cftVariantTerms.filter(t=>(t.service_line_slug === sl.slug)).length >0 ) :false;
           let serviceLineSearch = this.state.specialtyData.serviceLineSearchStatus.filter(vs=>vs.slug == sl.slug);
           let isToggled = serviceLineSearch[0]?serviceLineSearch[0].is_variant_term_list_enabled:1;
           
            return (
             <React.Fragment>
            <FormGroup  key={sl.id}>
             <AssociationForm
              id={`inherited_terms_variant${sl.id}`}
              searchQuery={searchCftsQuery}
              variantSlug={sl.slug}
              name= {"variants"}
              deleteMutation={removeInheritedTermSpecialtyMutation}
              associateMutation={associateSpecialtyWithInheritedTermMutation}
              mutationVariables={{
                specialtyId: (this.props.specialty)?this.props.specialty.id:null,
                servicelineId: sl.id
              }}
              refetch={specialtyInfoQuery}
              refetchVars={{ slug: this.props.specialty.slug , servicelineId:sl.id}}
              label={`Variant Inherited Term List for ${sl.name}`} //variant or for which service_line_id ! = 0
              variantName = {sl.name}
              slug={`cftTerms`}
              gqlFilterVar={`term`}
              gqlFirstVar={100}
              elementField={`name`}
              elements={variants ?variants :[]}
              callbacks={{
                handleFormChange: (element, action, slug) => {  
                   this.setVariantList(element, action, slug);
                },
                handleFormSaved: () => {}
              }}
            />
            { (visibiltyCheck && 
            <div className="toggle-container">
            <FormText color="muted" className={`row small`}>
                <i className="fal fa-info-circle" /> This list only applies to the Service Line: {sl.name}
            </FormText>
              <ToggleButton isOpen={isToggled} mutationName = {changeVariantsSearchStatusMutation} mutationInput={{specialtyId:this.props.specialty.id,service_lineId:sl.id}} disabled={sl.slug?false:true} label="Enable Inherited Term List for Search" />
            </div>
            )}
            <hr />
          </FormGroup>
          </React.Fragment>
        );
          }
         }
        }
        ) }
        return null;
    };
  }

  

  setVariantList (element, action="", slug="") {
     let cftTerms = this.state.specialtyData.cftVariantTerms;
     cftTerms = cftTerms.filter(obj => obj !== null && Object.keys(obj).length > 0);
     if (action === "add"){
      let elementFound;
      if(cftTerms.length > 0 ){
        elementFound = cftTerms.find(item =>(item.slug == element.slug && item.service_line_slug == slug));
      }
      if(!elementFound){
        element.service_line_slug = slug;
        cftTerms.push(element);
        this.setState(prevState => ({
          specialtyData : { ...prevState.specialtyData,
          cftVariantTerms: cftTerms
          }
        }));
      }
    }else if (action === "delete"){
      this.setState({ variant: {
        id:'',
        slug:'',
        label:'Service Line'
      }
    });
      cftTerms = cftTerms.filter(t => (element !== t));
        this.setState(prevState => ({
          specialtyData : { ...prevState.specialtyData,
          cftVariantTerms: cftTerms
          }
        }));
    }
   }

   setDefaultTermsList(element, action=""){
    let cftTerms = this.state.specialtyData.cftDefaultTerms;
    if (action === "add"){
     let elementFound;
     if(cftTerms.length > 0 ){
       elementFound = cftTerms.find(item =>(item.name === element.name));
     }
     if(!elementFound){
       cftTerms.push(element);
       this.setState(prevState => ({
         specialtyData : { ...prevState.specialtyData,
          cftDefaultTerms: cftTerms
         }
       }));
     }
   }else if (action === "delete"){
      const newTerms = cftTerms.filter(t => (element.slug != t.slug));
       this.setState(prevState => ({
         specialtyData : { ...prevState.specialtyData,
          cftDefaultTerms: newTerms
         }
       }));
   }
   }

   updateSpecialtyTermsMaster(element, action=""){
    let cftTerms = this.state.specialtyData.cftTermsMaster;
    if (action === "add"){
     let elementFound;
     if(cftTerms.length > 0 ){
       elementFound = cftTerms.find(item =>(item.name === element.name));
     }
     if(!elementFound){
       cftTerms.push(element);
       this.setState(prevState => ({
         specialtyData : { ...prevState.specialtyData,
          cftTermsMaster: cftTerms
         }
       }));
     }
   }else if (action === "delete"){
      const newTerms = cftTerms.filter(t => (element.slug != t.slug));
       this.setState(prevState => ({
         specialtyData : { ...prevState.specialtyData,
          cftTermsMaster: newTerms
         }
       }));
   }
   }


   allSpecialtyServiceLinesVariants = ()=>{
    let variants = {};
    const { cftVariantTerms, service_lines } = this.state.specialtyData;
    if(service_lines.length > 0) {
      service_lines.forEach(s=>{
        let s_slug = s.slug;
        variants[s_slug] = [];
        if(cftVariantTerms.length > 0) {
          cftVariantTerms.forEach(v=>{
          if(v.service_line_slug === s_slug){
            if(variants[s_slug]){
              if(!(variants[s_slug].find(item =>(item === v))) ){
                variants[s_slug].push(v);
              }
            }
          }
        });
        }
      });
      return variants;
   }
  }
  
  render() {
   const specialty = this.props.specialty;
   const upsertMutation = specialty ? updateSpecialtyMutation : insertSpecialtyMutation;
   const closeSpecialtyForm = ()=>{ 
    this.props.updateModalVisibilty(false);
    modalClose();
   }
   const submitSpecialtyForm = (e,upsertGroup) =>{
    const  submittedData = {
      input: {
        name: this.state.specialtyData.name,
        slug: this.state.specialtyData.slug,
        source: this.state.specialtyData.source,
        display_name: this.state.specialtyData.display_name,
        is_board_certified: this.state.specialtyData.is_board_certified,
        is_clean: this.state.specialtyData.is_clean,
        is_clean_timestamp: this.state.specialtyData.is_clean_timestamp,
        status: this.state.specialtyData.status,
        is_visible: this.state.specialtyData.is_visible
    }
  }
  console.log("submitted data: ", submittedData);
  if(this.state.specialtyData.id){
    submittedData.input.id = this.state.specialtyData.id;
  }
    e.preventDefault();
    upsertGroup({
      variables: submittedData
    }).then((result) => {
      if (result.data) {
        const mutationName = (this.state.specialtyData.id)?'updateSpecialty':'createSpecialty';
        const success = result.data[mutationName].success;
        const colorVar = (success === true) ? 'success' : 'danger';
        const actionVar = (success === true) ? 'Specialty saved successfully' : 'Error saving specialty, please try again';
     
      const NotifyUpserted = () => {
        
        return (
          <SystemAlert
            autoClose
            color={colorVar}
            icon="alert-icon fal fa-check-circle"
            messageData={{
              action: actionVar,
              item: "",
              id: '',
              tail: '...'
            }}
          />
        );
      }
          this.setState({
            submitSearchQuery: false
          });
            
            if(mutationName === 'createSpecialty'){
              this.props.history.push(`edit/${this.state.specialtyData.slug}`);
             return ( <SpecialtyDataQuery
              history={this.props.history}
              match={this.props.match}
              slug={this.props.slug}
              search={this.props.search}
              updateModalVisibilty = {this.props.updateModalVisibilty}
            />);
            }else{
            const alert = document && document.getElementById('specialty-save-alert');
            if(alert){
              ReactDOM.render(<NotifyUpserted />, alert);
            }
            }
            
      }
    });
  }
    
    return (
      
          <React.Fragment>
            <div id="specialty-save-alert"></div>
            <Alert color={(this.state.specialtyData.id)?`warning`:`info`} className={`mb-3`}>
              <div className="alert--with-icon">
                <i className="alert-icon fal fa-info-circle" />
                  { (this.state.specialtyData.id) ?
                     (<span>This form allows you to edit Specialty and relationship(s) between the Specialty 
                    <strong> ({this.state.specialtyData.name})</strong> and <em>Clinical focus terms.</em>
                    </span>
                    ):(<span>This form allows you to add new Specialty</span>)
                  }
              </div>
            </Alert>
            <Mutation
                mutation={upsertMutation}
              >
            {(upsertGroup, { loading, error }) => {
            if (loading) {
              return null;
            }
            if (error) {
              console.log(`Error in saving specialty...`);
            }
            return(
            <Form
              onSubmit={(e) => submitSpecialtyForm(e, upsertGroup)}
            >
                <FormGroup>
                <Input
                  type="hidden"
                  name="id"
                  id="specialtyId"
                  defaultValue={this.state.specialtyData.id}
                />

                <Label for="specialtyName">Northwell Specialty Name</Label>
                <Input
                  type="text"
                  autoComplete="on"
                  name="name"
                  id="name"
                  //value={this.state.specialtyData.name}
                  defaultValue = {this.state.specialtyData.name}
                  onChange={(e) => this.setSpecialtyAndSlug(e.target.value)}
                />
                </FormGroup>
                
              <FormGroup>
                <FormText color="muted">
                  Specialty slug: <strong><span className="" id="slugName" >{this.state.specialtyData.slug}</span></strong>
                </FormText>
                <Input
                  type="hidden"
                  name="slug"
                  id="slug"
                  value={this.state.specialtyData.slug}
                />
              <hr />
            </FormGroup>
            <FormGroup>
            <Label for="specialtyName">Find a Doctor Display Name</Label>
                <Input
                  type="text"
                  autoComplete="off"
                  name="display_name"
                  id="display_name"
                  defaultValue={this.state.specialtyData.display_name}
                  onChange = {(e)=>this.setDisplayState(e)}
                  />
            </FormGroup>
            <FormGroup>
            <Label for="source" className="my-n1">Source</Label>
            <FormText color="muted" className={`mb-2 justify-content-between align-items-center`}>
              <i className="fal fa-info-circle" /> What system is this specialty sourced from?
            </FormText>
            <Input
                  type="text"
                  autoComplete="off"
                  name="source"
                  id="source"
                  //value= {specialty?this.state.specialtyData.source:'custom'}
                  defaultValue = {this.state.specialtyData.source}
                  disabled = {true}
                />
                 <hr />
            </FormGroup>
            <div className="d-flex">
            <div className="field-container">
            <legend className={`mb-0`}>
              <div className={`d-flex justify-content-between align-items-center`}>
                <span>Is Active</span>
              </div>
            </legend>
            <FormText color="muted" className={`mb-2`}>
              <i className="fal fa-info-circle" /> Allows the ability to disable this specialty for all providers
            </FormText>
            <div className="form-radio-group">
              <FormGroup check>
                <Label check>
                  <Input
                    onClick={e => {
                    this.setFieldState(e, true);
                  }}
                    type="radio"
                    name="status"
                    value={true}
                    disabled = {false}
                    defaultChecked={this.state.specialtyData.status ? 'checked' : false}
                  />{' '}
                  <span className="text-success">Yes</span>
                </Label>
              </FormGroup>
              <FormGroup check >
                <Label check>
                  <Input
                    onClick={e => {
                    this.setFieldState(e, false);
                  }}
                    type="radio"
                    name="status"
                    value={false}
                    disabled = {false}
                    defaultChecked={this.state.specialtyData.status ? false : 'checked'}
                  />{' '}
                  <span className="text-warning">No</span>
                </Label>
              </FormGroup>
            </div>
            <hr />
          </div>
          <div className="field-container">
            <legend className={`mb-0`}>
              <div className={`d-flex justify-content-between align-items-center`}>
                <span>Is Visible</span>
              </div>
            </legend>
            <FormText color="muted" className={`mb-2`}>
              <i className="fal fa-info-circle" /> Controls whether this specialty is visible in typeahead and provider profiles
            </FormText>
            <div className="form-radio-group">
              <FormGroup check>
                <Label check>
                  <Input
                    onClick={e => {
                    this.setFieldState(e, true);
                  }}
                    type="radio"
                    name="is_visible"
                    value={true}
                    disabled = {false}
                    defaultChecked={this.state.specialtyData.is_visible ? 'checked' : false}
                  />{' '}
                  <span className="text-success">Yes</span>
                </Label>
              </FormGroup>
              <FormGroup check>
                <Label check>
                  <Input
                    onClick={e => {
                    this.setFieldState(e, false);
                  }}
                    type="radio"
                    name="is_visible"
                    value={false}
                    disabled = {false}
                    defaultChecked={this.state.specialtyData.is_visible ? false : 'checked'}
                  />{' '}
                  <span className="text-warning">No</span>
                </Label>
              </FormGroup>
            </div>
            <hr />
          </div>
          </div>
          <FormGroup className={`my-0`}>
            <legend className={`mb-0`}>
              <div className={`d-flex justify-content-between align-items-center`}>
                <span>Is Board Certified</span>
              </div>
            </legend>
            <FormText color="muted" className={`mb-2`}>
              <i className="fal fa-info-circle" /> Indicates whether this specialty is board certified or not board certified
            </FormText>
            <div className="form-radio-group">
              <FormGroup check>
                <Label check>
                  <Input
                    onClick={e => {
                    this.setFieldState(e, true);
                  }}
                    type="radio"
                    name="is_board_certified"
                    value={true}
                    disabled = {false}
                    defaultChecked={this.state.specialtyData.is_board_certified ? 'checked' : false}
                  />{' '}
                  <span className="text-success">Yes</span>
                </Label>
              </FormGroup>
              <FormGroup check>
                <Label check>
                  <Input
                    onClick={e => {
                    this.setFieldState(e, false);
                  }}  
                    type="radio"
                    name="is_board_certified"
                    value={false}
                    disabled = {false}
                    defaultChecked={this.state.specialtyData.is_board_certified ? false : 'checked'}
                  />{' '}
                  <span className="text-warning">No</span>
                </Label>
              </FormGroup>
            </div>
            <hr />
          </FormGroup>
          <div className="d-flex">
            <div className="field-container">
            <legend className={`mb-0`}>
              <div className={`d-flex justify-content-between align-items-center`}>
                <span>Is Specialty List Clean?</span>
              </div>
            </legend>
            <FormText color="muted" className={`mb-2`}>
              <i className="fal fa-info-circle" /> This lets you know if Specialty lists have been fixed
            </FormText>
            <div className="form-radio-group">
              <FormGroup check>
                <Label check>
                  <Input
                    onClick={e => {
                      this.setFieldState(e, true);
                    }}
                    type="radio"
                    name="is_clean"
                    value= {true}
                    disabled = {false}
                    defaultChecked={this.state.specialtyData.is_clean ? 'checked' : false}
                  />{' '}
                  <span className="text-success">Yes</span>
                </Label>
              </FormGroup>
              <FormGroup check>
                <Label check>
                  <Input
                    onClick={e => {
                      this.setFieldState(e, false);
                    }}
                    type="radio"
                    name="is_clean"
                    value= {false}
                    disabled = {false}
                    defaultChecked={this.state.specialtyData.is_clean ? false : 'checked'}
                  />{' '}
                  <span className="text-warning">No</span>
                </Label>
              </FormGroup>
            </div>
            <hr />
          </div>
          <div className="field-container">
            <legend className={`mb-0`}>
              <div className={`d-flex justify-content-between align-items-center`}>
                <span>Are Inherited Lists Clean?</span>
          
              </div>
            </legend>
            <FormText color="muted" className={`mb-2`}>
              <i className="fal fa-info-circle" /> This lets you know if Specialty Inherited lists is clean or not
            </FormText>
            <div className="form-radio-group">
              <FormGroup check>
                <Label check>
                  <Input
                    onClick={e => {
                      this.setFieldState(e, true);
                    }}
                    type="radio"
                    name="is_clean_timestamp"
                    value= {true}
                    disabled = {false}
                    defaultChecked={(this.state.specialtyData.is_clean_timestamp !== null)?(this.state.specialtyData.is_clean_timestamp !== 0 ? 'checked' : false):"checked"}
                  />{' '}
                  <span className="text-success">Yes</span>
                </Label>
              </FormGroup>
              <FormGroup check>
                <Label check>
                  <Input
                    onClick={e => {
                      this.setFieldState(e, false);
                    }}
                    type="radio"
                    name="is_clean_timestamp"
                    value= {false}
                    disabled = {false}
                    defaultChecked={(this.state.specialtyData.is_clean_timestamp !== null)?(this.state.specialtyData.is_clean_timestamp === 0 ? 'checked' : false):false}
                  />{' '}
                  <span className="text-warning">No</span>
                </Label>
              </FormGroup>
            </div>
            <hr />
          </div>
          </div>
          {/* All association form should be visible in edit mode once record is inserted */
            (specialty !== null && specialty !== undefined) && 
            (
              <React.Fragment>
                {/**DIsabling the parent child specialties for now, to be used in future */}

              {/* <FormGroup>
              <AssociationForm
                id="parent_specialty"
                skip_id = {(specialty)?specialty.id:''}
                searchQuery={searchSpecialtyQuery}
                deleteMutation={removeChildParentSpecialtyMutation}
                associateMutation={createChildParentSpecialtyMutation}
                mutationVariables={{
                  childParentId: (specialty)?specialty.id:null,
                  specialtyType:"parent"
                }}
                refetch={specialtyInfoQuery}
                refetchVars={{ id: specialty.id }}
                label={`Parent Specialties`}
                slug={`specialties`}
                gqlFilterVar={`specialty`}
                gqlFirstVar={100}
                elementField={`name`} // field name of the result items
                elements={(specialty.parentSpecialties)?specialty.parentSpecialties:[]}
                callbacks={{
                  handleFormChange: () => {},
                  handleFormSaved: () => {}
                }}
              />
              <hr />
            </FormGroup> */}
            {/* <FormGroup>
              <AssociationForm
                id="child_specialty"
                skip_id = {(specialty)?specialty.id:''}
                searchQuery={searchSpecialtyQuery}
                deleteMutation={removeChildParentSpecialtyMutation}
                associateMutation={createChildParentSpecialtyMutation}
                mutationVariables={{
                  childParentId: (specialty)?specialty.id:null,
                  specialtyType:"child"
                }}
                refetch={specialtyInfoQuery}
                refetchVars={{ id: specialty.id }}
                label={`Child Specialties`}
                slug={`specialties`} //payload of search query result
                gqlFilterVar={`specialty`}
                gqlFirstVar={100}
                elementField={`name`} // field name of the result items or display column
                elements={(specialty.childSpecialties)?specialty.childSpecialties:[]}
                callbacks={{
                  handleFormChange: () => {},
                  handleFormSaved: () => {}
                }}
              />
              <hr />
            </FormGroup> */}
            <FormGroup>
            {/* <div>
            {this.state.showConfirmDialog && (
              <ModalConfirm isOpen={true} confirmAction={this.confirmAction} onClose = {this.toggleServiceLineConfirm}  />
            )}</div> */}
              <AssociationForm
                id="service_lines"
                name="service_lines"
                searchQuery={searchServiceLineQuery}
                deleteMutation={removeServiceLineSpecialtyMutation}
                associateMutation={createServiceLineSpecialtyMutation}
                mutationVariables={{
                  specialtyId: (specialty)?specialty.id:null,
                }}
                refetch={specialtyInfoQuery}
                refetchVars={{ id: specialty.id }}
                label={`Service Lines`}
                slug={`serviceLines`} //payload of search query result
                gqlFilterVar={`service_line`} //filter var with Id suffix gets added for search query
                gqlFirstVar={100}
                elementField={`name`} // field name of the result items or display column
                elements={(this.state.specialtyData.service_lines)?this.state.specialtyData.service_lines:[]} //final display of selected items badge with delete option
                callbacks={{
                  handleFormChange: async (element, action) => {  
                     await this.updateServiceLines(element, action);
                  },
                  handleFormSaved: () => {}
                }}
              />
              <hr />
            </FormGroup>
            <FormGroup>
              <AssociationForm
                id="specialty_terms"
                name="specialty_terms"
                searchQuery={searchCftsQuery}
                deleteMutation={removeSpecialtyFromTermMasterMutation}
                associateMutation={associateSpecialtyWithTermMasterMutation}
                mutationVariables={{
                  specialtyId: (this.state.specialtyData)?this.state.specialtyData.id:null
                }}
                refetch={specialtyInfoQuery}
                refetchVars={{ slug: specialty.slug }}
                label={`Specialty Term List`}
                slug={`cftTerms`}
                gqlFilterVar={`term`}
                gqlFirstVar={100}
                elementField={`name`}
                elements={(this.state.specialtyData.cftTermsMaster)?this.state.specialtyData.cftTermsMaster:[]}
                callbacks={{
                  handleFormChange: async (element, action) => {  
                    await this.updateSpecialtyTermsMaster(element, action);
                 },
                  handleFormSaved: () => {}
                }}
              />
            </FormGroup>
            <FormGroup>
              <AssociationForm
                id="inherited_terms_default"
                name="inherited_terms_default"
                searchQuery={searchCftsQuery}
                deleteMutation={removeSpecialtyFromTermMutation}
                associateMutation={associateSpecialtyWithTermMutation}
                mutationVariables={{
                  specialtyId: (specialty)?specialty.id:null
                }}
                refetch={specialtyInfoQuery}
                refetchVars={{ slug: specialty.slug }}
                label={`Default Inherited Term List`} //for which service_line_id=0
                slug={`cftTerms`}
                gqlFilterVar={`term`}
                gqlFirstVar={100}
                elementField={`name`}
                elements={(this.state.specialtyData.cftDefaultTerms)?this.state.specialtyData.cftDefaultTerms:[]}
                callbacks={{
                  handleFormChange: (element, action) => {
                    this.setDefaultTermsList(element, action);
                  },
                  handleFormSaved: () => {}
                }}
              />
              <hr />
            </FormGroup>
            {this.showVariantBlocks(this.state.specialtyData)}
            <div className='justify-content-between align-items-center'>
            <VariantPopup addDefaultList={this.addDefaultList} specialty={specialty} variantVisibleCount={this.variantVisibleCount} allSpecialtyServiceLinesVariants={this.allSpecialtyServiceLinesVariants()} isOpen={false} handleVariantChange = {this.handleVariantChange} service_lines={this.state.specialtyData.service_lines}/>
            </div>
             </React.Fragment>
            )
          }
          <FormGroup>
          <Button color="primary" onClick={()=>closeSpecialtyForm()} className="px-y pr-2 mr-2" >Cancel</Button>
          <Button color="primary" className="px-y "  disabled = {this.state.specialtyData.name !== '' ?false:true} type="submit">Save</Button>
          </FormGroup>
              </Form>
              )
                 }}
              </Mutation>
            </React.Fragment>
          );
           
  }
}


