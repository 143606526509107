import * as React from 'react';
import ContentSection from 'elements/ContentSection';
import {createBrowserHistory} from "history";
import SectionSearchField from 'elements/SectionSearchField';
import SectionActionButtons from 'elements/SectionActionButtons';
import { NewBannerForm, DeleteBannerModal,  EditBannerForm} from './BannerForms';
import { Link, Route } from 'react-router-dom';
import { programConnectionQuery } from 'promotedSearch/queries';
import { Query } from 'react-apollo';
import SectionTable from 'elements/SectionTable';
import { Alert } from 'reactstrap';
import SectionError from 'elements/SectionError';
import SectionDropdownField from 'elements/StandardSectionDropdownField';
import TableColumnSort from 'layout/TableColumnSort';
import ReactPaginate from "react-paginate";
import InlineDatePicker from './InlineDatePicker';
import TimePickerInput from './TimePickerInput';

const history = createBrowserHistory();
const ModalFormContext = React.createContext();
class Banners extends React.Component{

    constructor(props) {
      super(props);
      this.state = {
        showModalForm: true,
        status:'',
        filter: '',
        day: '',
        startDate: '',
        endDate: '',
        startTime:'',
        endTime: '',
        default: {
          filter:'',
          status:'',
          day:'',
          startDate:'',
          endDate:'',
          startTime:'',
          endTime:''
        },
        afterCurser: '',
        order: {
          sortField: 'NAME',
          sortDirection: 'ASC'
        },
        limit: 25,
        offset: 0,
        pageNumber: 0,
        togglePage: false
      }

      this.callbacks = {
        handleChange: this.handleChange,
        handleKeyDown: this.handleKeyDown,
        handleFilterChange: this.handleFilterChange,
        clearFilter: this.clearFilter,
        resetFilter:this.resetFilter,
        updateSort: this.updateSort,
        updateFirst: this.updateFirst
      };

      this.handleChange = this.handleChange.bind(this);
      this.handleKeyDown = this.handleKeyDown.bind(this);
      this.triggerChange = this.triggerChange.bind(this);
      this.reFocus = this.reFocus.bind(this);
      this.clearFilter = this.clearFilter.bind(this);
      this.updateSort = this.updateSort.bind(this);
      this.handleFilterChange = this.handleFilterChange.bind(this);
      this.handlePage = this.handlePage.bind(this);
      this.resetFilter = this.resetFilter.bind(this);

      this.pagehandler = {
        handlePage: this.handlePage
      };
    }

    clearFilter = () => {
      this.setState(() => ({
        filter: ''
      }));
      history.replace(``);
    };

    refreshListing = ()=>{
      this.setState(prevState => ({
        togglePage: !prevState.togglePage,
      }));
    }

    updateModalVisibilty = (data) => {
      this.setState({showModalForm:data});
    }

    updateFirst = (n: number) => {
      this.setState(() => ({
        limit: n
      }));
    };

    handleChange = ({ target }: { target: HTMLInputElement }) => {
      clearTimeout(this.timer);
      this.timer = setTimeout(
        function doChange() {
          this.triggerChange(target);
        }.bind(this),
        this.waitInterval
      );
    };
  
    handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === this.enterKey) {
        const target = e.target;
        if (target instanceof HTMLInputElement) {
          const attribute: string | null | void = target.getAttribute('id');
          const elemId: string = attribute && typeof attribute === 'string' ? attribute : '';
          const element: HTMLElement | null = document.getElementById(elemId);
          if (typeof element !== 'undefined' && element !== null && element instanceof HTMLInputElement) {
            this.triggerChange(target);
          }
        }
      }
    };
  
    triggerChange = (target: HTMLInputElement) => {
      this.setState(() => ({
        filter: target.value ? target.value : ''
      }));
      if (target.value) {
        this.reFocus(target);
      }
  
      history.push(`?search=${target.value}`);
    };
  
    handlePage = (id,page) => {
       this.setState(() => ({
         afterCurser: id,
         pageNumber:page
       }));
  
       history.push(`?page=${page}&curser=${id}`);
       //window.location = window.location.pathname +"?page="+ page
       //this.props.propcallbacks.handleChange();
     };

     reFocus = (target: HTMLInputElement) => {
      const elemId = target.getAttribute('id');
      const element = document && elemId && document.getElementById(elemId);
      if (typeof element !== 'undefined' && element !== null && element instanceof HTMLInputElement) {
        element.focus();
      }
    };
  
    updateSort = (field: string, direction: string) => {
      const sortField = field ? field : this.state.order.sortField;
      const sortDirection = direction ? direction : this.state.order.sortDirection;
  
      this.setState(() => ({
        order: {
          sortField: sortField,
          sortDirection: sortDirection
        }
      }));
    };

    handleFilterChange = (target: HTMLInputElement, id="") => {
      if(target){
        if(target.id === 'status'){
          this.setState(() => ({
            status:target.value,
            default:{
              status:target
            }
          }));
         }
        if(target.id === 'day'){
          this.setState(() => ({
            day:target.value,
            default:{
              day:target
            }
          }));
         }
      }
      
      if(id === 'start_date'){
        this.setState(() => ({
          startDate:target,
          default:{
            startDate:target
          }
        }));
       }
       if(id === 'end_date'){
        this.setState(() => ({
          endDate:target,
          default:{
            endDate:target
          }
        }));
       }
       if(id === 'start_time'){
        this.setState(() => ({
          startTime:target,
          default:{
            startTime:target
          }
        }));
       }
       if(id === 'end_time'){
        this.setState(() => ({
          endTime:target,
          default:{
            endTime:target
          }
        }));
       }
    
    };
  
    clearFilter = () => {
      this.setState(() => ({
        filter:'',
        status:'',
        day:'',
        startDate:'',
        endDate:'',
        startTime:'',
        endTime:'',
      }));
  
      history.replace(``);
    };
  
    resetFilter = (id) => {
      if(id === 'status'){
        this.setState(() => ({
          status:'',
          defaut:{
            status: '',
          }
        }));
       }
      if(id === 'day'){
        this.setState(() => ({
          day:'',
          defaut:{
            day: '',
          }
        }));
       }
      if(id === 'start_date'){
        this.setState(() => ({
          startDate:'',
          defaut:{
            startDate: '',
          }
        }));
       }
       if(id === 'end_date'){
        this.setState(() => ({
          endDate:'',
          defaut:{
            endDate: '',
          }
        }));
       }
       if(id === 'start_time'){
        this.setState(() => ({
          startTime:'',
          defaut:{
            startTime: '',
          }
        }));
       }
       if(id === 'end_time'){
        this.setState(() => ({
          endTime:'',
          defaut:{
            endTime: '',
          }
        }));
       }
    }

    render () {
      const match = this.props.match;

      const sharedSearchData = {
        filter: this.state.filter,
        order: {
          sortField: this.state.order.sortField,
          sortDirection: this.state.order.sortDirection
        },
        limit: this.state.limit,
        offset: this.state.offset
      };

      const ModalRoutes = () => {
        return (
          <React.Fragment>
            <Route
              exact
              path={`${match && match.path ? match.path : ''}/add`}
              render={(props) => 
              <NewBannerForm {...props} search={sharedSearchData} refreshListing = {this.refreshListing} />
            }
            />
  
            <Route
              exact
              path={`${match && match.path ? match.path : ''}/edit/:slug`}
              render={(props) => (
                <EditBannerForm
                  {...props}
                  search={sharedSearchData}
                  slug={ props.match.params && typeof props.match.params.slug === 'string' ? props.match.params.slug : ''}
                  newBanner={false}
                  showModalForm = {this.state.showModalForm}
                  refreshListing = {this.refreshListing}
                 />
              )}
              refreshListing = {this.refreshListing}
            />
  
            <Route
              exact
              path={`${match.path}/delete/:slug`}
              render={(props) => (
                <DeleteBannerModal
                {...props}
                search={sharedSearchData}
                slug={ props.match.params && typeof props.match.params.slug === 'string' ? props.match.params.slug : ''}
                callbacks={this.callbacks}
              />
              )}
            />
          </React.Fragment>
        );
      };

      const buttonLinks = [
        {
          uri: '/promoted_search/add',
          id: 'addBannerLink',
          text: 'Add Program',
          icon: 'fas fa-plus'
        }
      ];

      const sectionData = {
        heading: 'Promoted Search',
        content: (
          <BannerContent
            callbacks={this.callbacks}
            data={{
              filter: this.state.filter,
              status: this.state.status,
              day: this.state.day,
              startDate: this.state.startDate,
              endDate: this.state.endDate,
              startTime: this.state.startTime,
              endTime: this.state.endTime,
              default: this.state.default,
              order: {
                sortField: this.state.order.sortField,
                sortDirection: this.state.order.sortDirection
              },
              offset: this.state.afterCurser,
              limit: this.state.limit,
              page: this.pagehandler,
              pageNumber: this.state.pageNumber
            }}
           
            location={this.props.location}
          />
        ),
        actions: <SectionActionButtons links={buttonLinks} />,
        modals: <ModalRoutes />
      };

      return (
        <React.Fragment>
         <ModalFormContext.Provider value={{updateModalVisibilty:this.updateModalVisibilty}} >
          <ContentSection data={sectionData} history={this.props.history} />
        </ModalFormContext.Provider>
        </React.Fragment>
      );
    }
}

class BannerContent extends React.Component {
  render() {
    return (
      <div className="group-landing--wrapper">
        <SectionSearchField
          filter={this.props.data.filter}
          callbacks={this.props.callbacks}
          element={{
            id: 'bannerList',
            placeholder: 'Search Program...'
          }}
        />
        <div className='filter-btn-div'><button className='btn btn-secondary' onClick={this.props.callbacks.clearFilter}>Reset Filter</button></div>
         
        <div className="table-responsive">
          {/* <GroupQuery data={this.props.data} callbacks={this.props.callbacks} location={this.props.location} /> */}
          <BannerQuery data={this.props.data} callbacks={this.props.callbacks} location={this.props.location} />
        </div>
      </div>
    );
  }
}

class BannerQuery extends React.Component {
  render() {
    // @todo: This may need to have the offset & limit sent back to the parent state.
    // This is the filter sent from the parent form(s).
    const filter = this.props.data.filter ? this.props.data.filter : '';
    const status = this.props.data.status ? this.props.data.status : '';
    const day = this.props.data.day ? this.props.data.day : '';
    const startDate = this.props.data.startDate ? this.props.data.startDate : '';
    const endDate = this.props.data.endDate ? this.props.data.endDate : '';
    const startTime = this.props.data.startTime ? this.props.data.startTime : '';
    const endTime = this.props.data.endTime ? this.props.data.endTime : '';
    // Default offset.
    const OFFSET = this.props.data.offset ? this.props.data.offset : 0;
    // Default item to sort on.
    const ORDER = this.props.data.order
      ? {
          sort: this.props.data.order.sortField,
          direction: this.props.data.order.sortDirection
        }
      : {
          sort: 'NAME',
          direction: 'ASC'
        };
    // Default items per page to display.
    const LIMIT = this.props.data.limit ? this.props.data.limit : 25;

    return (
      <Query
        query={programConnectionQuery}
        pollInterval={0}
        notifyOnNetworkStatusChange
        variables={{
          filter: filter,
          status: status,
          day: day,
          startDate: startDate,
          endDate: endDate,
          startTime: startTime,
          endTime: endTime,
          afterCurser: OFFSET,
          first: LIMIT,
          orderBy: ORDER
        }}
        fetchPolicy="no-cache"
      >
        {({ variables, loading, data, fetchMore, networkStatus }) => {
          // Skip loading icon/flash when we are polling for new results.
          if (loading && networkStatus !== 6 && networkStatus !== 3) {
            // Handle loading screen.
            const row = {
              classes: 'loading',
              content: <i className="fal fa-spinner" />
            };
            return (
              <SectionTable
                render={() => (
                  <tbody>
                    <tr>
                      <td className={row.classes}>{row.content}</td>
                    </tr>
                  </tbody>
                )}
              />
            );
          }

          const query: [] = data && data.promotedPrograms ? data.promotedPrograms : [];
          if (query && query.length === 0 && !loading) {
            // Handle a zero result set.
            const errorContent = (
              <div className="text-center">
                <h4 className="text-warning">No Results Found.</h4>
                <h2 className="text-warning">¯\_(ツ)_/¯</h2>
              </div>
            );
            return <SectionError color="warning" classes="" content={errorContent} />;
          }

          if (!data && !loading) {
            // We've encountered a error connecting to GraphQL server.
            const button = document.getElementById('addBannerLink');
            if (typeof button !== 'undefined' && button !== null && button instanceof HTMLButtonElement) {
              button.classList.add('disabled');
              button.disabled = true;
            }
            const search = document.getElementById('groupList');
            if (typeof search !== 'undefined' && search !== null && search instanceof HTMLInputElement) {
              search.disabled = true;
            }
            return (
              <Alert color="danger" className={`error--graphql-connection`}>
                <div className={`text-center`}>
                  <h4 className="text-error">GraphQL connection failure</h4>
                  <h2 className="text-error">¯\_(ツ)_/¯</h2>
                </div>
              </Alert>
            );
          }

          // Pagination issues.
          // @see https://github.com/apollographql/apollo-client/issues/2499
          return (
            <React.Fragment>
              <ProgramFeed
                programs={query.edges || []}
                callbacks={this.props.callbacks}
                pages={query.aggregate}
                data={{
                  total: query && query.length >= 1 ? query.length : 0,
                  status: status,
                  filter: filter,
                  day: day,
                  startDate: startDate,
                  endDate: endDate,
                  startTime: startTime,
                  endTime: endTime,
                  default: this.props.data.default,
                  order: {
                    sortField: ORDER.sort,
                    sortDirection: ORDER.direction
                  },
                  offset: OFFSET,
                  limit: LIMIT,
                  page:this.props.data.page,
                  pageNumber:this.props.data.pageNumber
                }}
              />
              <Alert color="info" className={`my-0 ${query.length >= 100 ? 'd-block' : 'd-none'}`}>
                <div className="alert--with-icon">
                  <i className="alert-icon fal fa-info-circle" />
                  <div>
                    <span>
                      Not all promoted programs can be listed at once. Only the first 100 results are shown. Please use the
                      search form to narrow down the results.
                    </span>
                  </div>
                </div>
              </Alert>
            </React.Fragment>
          );
        }}
      </Query>
    );
  }
}

class ProgramFeed extends React.Component {
  handlePageClick = (data) => {
    let page = data.selected;
  
    let last = 25 * page;
    let total = last - 1;
    
    this.props.data.page.handlePage(total,page);
  };
  handleTimeChange = (time, stateVar) => {
    this.setState({ [stateVar]: time });
  };

  handleDateChange = (date, stateVar) => {
    this.setState({ [stateVar]: date });
  };

  render() {
    const data = this.props.data;
    let fromItems = 1;
    let toItems = 25;

    if (this.props.pages.count < 25) {
      fromItems = 1;
      toItems = this.props.pages.count
    }

    else if (this.props.data.pageNumber != 0) {
      fromItems = (this.props.data.pageNumber * 25);
      toItems = (this.props.data.pageNumber * 25) + 24
    }
    if (this.props.programs ) {
      const total = Math.ceil(this.props.pages.count/this.props.data.limit);
      // Friendly method to display the text "Showing X-Y of Z total results.
      // const fromItems = 1;
      // const toItems = this.props.programs.length;
      const filterData = {
        filter: this.props.data.filter,
        status: this.props.data.status,
        day: this.props.data.day,
        startDate: this.props.data.startDate,
        endDate: this.props.data.endDate,
        startTime: this.props.data.startTime,
        endTime: this.props.data.endTime,
        order: this.props.data.order,
        limit: this.props.data.limit,
        offset: this.props.data.offset
      };

      // Compile all the table rows together.
      const FeedRows = () => {
        return this.props.programs.map((group, id) => (
          
          <ProgramTableRow row={group.node} key={id} search={filterData} />
        ));
      };

      let dayOptions = [
        {value:'',label:"Select",id:'day'},
        {value:'sunday',label:"Sunday",id:'day'},
        {value:'monday',label:"Monday",id:'day'},
        {value:'tuesday',label:"Tuesday",id:'day'},
        {value:'wednesday',label:"Wednesday",id:'day'},
        {value:'thursday',label:"Thursday",id:'day'},
        {value:'friday',label:"Friday",id:'day'},
        {value:'saturday',label:"Saturday",id:'day'},
      ];
      let activeOptions = [{value:'',label:"Select",id:'status'},{value:1,label:"Yes",id:'status'},{value:'0',label:"No",id:'status'}];

      
      const selectedDayObj = dayOptions.find(item => item.value === this.props.data.day);
      const selectedStatusObj = activeOptions.find(item => item.value === this.props.data.status);

      return (
        <React.Fragment>
          <div className="row pb-3 m-auto">
            <div className="col-sm">
            <label for="day">Day</label>
           <SectionDropdownField
                          id='day'
                          placeholder='Select'
                          default= {this.props.data.day != ''?(selectedDayObj):this.props.data.day}
                          //filter={this.props.data.filter}
                          onChange={this.props.callbacks.handleFilterChange}
                          element={{
                            id: 'day',
                            
                          }}
                          options= {
                            dayOptions
                          }
            />
             </div>
             <div className='mt-36'>
            { (this.props.data.day !== '' &&
             <button className='btn btn-reset' onClick={()=>this.props.callbacks.resetFilter('day')} ><i className="fas fa-times" /></button>
            )}</div>
            
            <div className='d-flex flex-column'>
            <div className=' d-flex flex-row'>
            <InlineDatePicker id="start_date" name="start_date" label="Start Date" selectedDate={this.props.data.startDate}  onDateChange={(value)=>this.props.callbacks.handleFilterChange(value,'start_date')} />
            <div className='mt-36'>
            { (this.props.data.startDate !== '' &&
             <button className='btn btn-reset' onClick={()=>this.props.callbacks.resetFilter('start_date')} ><i className="fas fa-times" /></button>
            )}</div>
            <InlineDatePicker id="end_date"  name="end_date" label="End Date" selectedDate={this.props.data.endDate}  onDateChange={(value)=>this.props.callbacks.handleFilterChange(value,'end_date')} />
            <div className='mt-36'>
            { (this.props.data.endDate !== '' &&
             <button className='btn btn-reset' onClick={()=>this.props.callbacks.resetFilter('end_date')} ><i className="fas fa-times" /></button>
            )}</div>
            <TimePickerInput id="start_time" label="Start Time" selectedTime={this.props.data.startTime}  onTimeChange={(value)=>this.props.callbacks.handleFilterChange(value,'start_time')} />
            {/* <div className='mt-36'>
            { (this.props.data.startTime !== '' &&
             <button className='btn btn-reset' onClick={()=>this.props.callbacks.resetFilter('start_time')} ><i className="fas fa-times" /></button>
            )}</div> */}
            <TimePickerInput id="end_time" label="End Time" selectedTime={this.props.data.endTime} onTimeChange={(value) => this.props.callbacks.handleFilterChange(value, 'end_time')} />
            {/* <div className='mt-36'>
            { (this.props.data.endTime !== '' &&
             <button className='btn btn-reset' onClick={()=>this.props.callbacks.resetFilter('end_time')} ><i className="fas fa-times" /></button>
            )}</div> */}
            </div>
            </div>

            <div className="col-sm">
            <label for="source">Is Active</label>
                <SectionDropdownField
                            id='active'
                            placeholder= 'Select'
                            default= {this.props.data.status != ''?(selectedStatusObj):this.props.data.status}
                            //filter={this.props.data.filter}
                            onChange={this.props.callbacks.handleFilterChange}
                            element={{
                              id: 'status',
                              
                            }}
                            options= {
                              activeOptions
                            }
            />
            </div>
            <div className='mt-36'>
            { (this.props.data.status !== '' &&
             <button className='btn btn-reset' onClick={()=>this.props.callbacks.resetFilter('status')} ><i className="fas fa-times" /></button>
            )}</div>
            
          </div> 
          { (this.props.pages.count >0 && <h4>
            Showing{' '}
            <strong>
              {fromItems} - {toItems}
            </strong>{' '}
            of <strong>{this.props.pages.count}</strong> rows.
          </h4>)
          }
          <SectionTable
            render={() => (
              <React.Fragment>
                <thead className="thead-light">
                  <SpecialtyTableHeaderRow order={this.props.data.order} callbacks={this.props.callbacks} />
                </thead>
                <tbody className="result-set">
                  <FeedRows />
                  {(this.props.pages.count <= 0 && (
                    <tr><td colspan="8" className=''><strong>No records found</strong></td></tr>
                  ))}
                </tbody>
              </React.Fragment>
            )}
          />
            { (this.props.pages.count >0 && <ReactPaginate
       previousLabel={"previous"}
       nextLabel={"next"}
       breakLabel={"..."}
       breakClassName={"break-me"}
       pageCount={total}
       forcePage={this.props.data.pageNumber}
       // marginPagesDisplayed={2}
       //pageRangeDisplayed={5}
       //callbacks={this.props.callbacks}
       onPageChange={this.handlePageClick}
       containerClassName={"pagination"}
       subContainerClassName={"pages pagination"}
       activeClassName={"active"}
     />
            )}
           
        </React.Fragment>
      );
    }

    // Last case error that something went terribly wrong.
    const errorContent = (
      <div>
        <h4 className="text-danger">Unknown error occurred.</h4>
        <h2 className="text-danger">¯\_(ツ)_/¯</h2>
      </div>
    );
    return <SectionError color="danger" classes="" content={errorContent} />;
  }
}

/**
 * Component to handle single table row of results.
 */

class ProgramTableRow extends React.Component<SpecialtyTableRowProps> {

  render() {
    const program = this.props.row;
    const status = program.status ? (
      <span className="text-success">enabled</span>
    ) : (
      <span className="text-warning">disabled</span>
    );

    return (
      <ModalFormContext.Consumer>
        {(context) => 
        {
          return (
            <tr id={program.id} className={`result-set--row`}>
            <th className="main-cell term" scope="row">
              <Link to={`/promoted_search/edit/${program.slug}`} title={`Edit ${program.name}`} onClick={()=>context.updateModalVisibilty(true)}  >
                {program.name}
              </Link>
            </th>
            
            <td className="d-none d-xl-table-cell text-center">{program.priority}</td>
           
            
            <td className="d-none d-xl-table-cell text-center">{status}</td>
             <td className="actions">
              <Link to={`/promoted_search/edit/${program.slug}`} title={`Edit ${program.name}`} className="btn btn-edit">
                <i className="fas fa-edit" />
              </Link>
              <Link to={`/promoted_search/delete/${program.slug}`} title={`Delete ${program.name}`} className="btn btn-delete">
                <i className="fas fa-times" />
              </Link>
            </td>
          </tr>
          );
        }
      }
       </ModalFormContext.Consumer>
    
    );
  }
}

/**
 * Component to handle table header for CFT results.
 */
class SpecialtyTableHeaderRow extends React.Component {
  render() {
    return (
      <tr>
        <th scope="col">
          <div className="">
            <span className="col-label">Name</span>
          </div>
        </th>
        <th className="actions" scope="col">
          <span className="col-label">Priority</span>
        </th>
        <th className="actions" scope="col">
          <span className="col-label">Is Active</span>
        </th>
        <th className="actions" scope="col" colSpan={2}>
          <span className="col-label">Actions</span>
        </th>
      </tr>
    );
  }
}

export default Banners;