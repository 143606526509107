/* eslint no-unused-vars: "off" */
import React, { Fragment, useEffect, useState } from 'react';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { Badge, Button, Card, CardHeader, CardBody, InputGroup, InputGroupAddon, Label, FormGroup } from 'reactstrap';

import { SynonymsSearch, SynonymBadge } from 'synonym';
import { Plus, Close } from 'shared/icons';
import DisplayName from 'synonym/inputs/displayName';
import { connectSynonyms } from 'synonym/container';

const SynonymInput = props => {
  const { cftTerm, term, synonyms } = props;
  if (term) {
 
    const [searchVisible, setSearchVisible] = useState(false);
    return (
      <Fragment>
        <FormGroup>
          <DisplayName cftTerm={cftTerm} />
        </FormGroup>
        <FormGroup className="mb-3">
          <Label>
            Synonyms
            {synonyms && (
              <Badge className="label-badge" color="primary">
                {Object.keys(synonyms).length}
              </Badge>
            )}
          </Label>
          <InputGroup className="current-synonyms relation-list linked-reference-item form-control">
            {synonyms &&
              Object.values(synonyms).map(synonym => {
                return <SynonymBadge key={synonym.id} synonym={synonym} term={cftTerm} />;
              })}
            <InputGroupAddon addonType="append">
              {!searchVisible && (
                <Button className="association-button association-closed" color="primary" onClick={() => setSearchVisible(true)}>
                  <Plus />
                </Button>
              )}
              {searchVisible && (
                <Button className="association-button association-closed" color="primary" onClick={() => setSearchVisible(false)}>
                  <Close />
                </Button>
              )}
            </InputGroupAddon>
          </InputGroup>
          <SynonymsSearch searchVisible={searchVisible} {...props} />
        </FormGroup>
        <hr />
      </Fragment>
    );
  } else {
    return null;
  }
};

export default connectSynonyms(SynonymInput);
