/* @flow */

import React from 'react';
import { Icon } from './';

const Plus = props => {
  return <Icon fontType="fas" icon="fa-plus" {...props} />;
};

export default Plus;
