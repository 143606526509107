/* @flow */
import * as React from 'react';
import { Link } from 'react-router-dom';
import { toggleMobileMenu } from '../functions/Mobile';
import { isMobile } from 'functions/ResponsiveClasses';

// Import images for Header.
import icon from '../../images/icon.png';
import logo from '../../images/logo.png';

/* eslint-disable no-console */
const links = {
  main: {
    href: '/',
    title: 'Home'
  }
};

class Header extends React.Component<{}> {
  render() {
    return <Navbar />;
  }
}

class Navbar extends React.Component<{}> {
  static toggleSidebarMenu() {
    const sidebar = document.getElementById('sidebar');
    const button = document.getElementById('sidebarToggle');

    if (typeof sidebar !== 'undefined' && sidebar !== null) {
      if (sidebar.classList.contains('nav-hidden')) {
        sidebar.classList.remove('nav-hidden');
        sidebar.classList.add('nav-visible');
        if (typeof button !== 'undefined' && button !== null) {
          button.setAttribute('aria-expanded', 'true');
        }
      } else {
        sidebar.classList.remove('nav-visible');
        sidebar.classList.add('nav-hidden');
        if (typeof button !== 'undefined' && button !== null) {
          button.setAttribute('aria-expanded', 'false');
        }
      }
    }
  }

  render() {
    const expanded = isMobile() ? 'false' : 'true';
    return (
      <nav className="navbar navbar-light navbar-fixed bg-light">
        <div className="navbar-main">
          <NavbarLogo />
        </div>
        <button
          id="sidebarToggle"
          className="d-md-none navbar-toggler"
          type=" button"
          aria-controls="sidebar"
          aria-expanded={expanded}
          aria-label="Toggle sidebar navigation"
          onClick={Navbar.toggleSidebarMenu}
        >
          <i className="fas fa-bars" />
          <i className="fas fa-times" />
        </button>
      </nav>
    );
  }
}

class NavbarLogo extends React.Component<{}> {
  render() {
    return (
      <Link to={links.main.href} className="navbar-brand" title={links.main.title} onClick={toggleMobileMenu}>
        <span className="navbar-logo">
          <img className="dml-logo d-none d-sm-inline" height="30" src={logo} alt="Northwell DML Tool " />
          <img className="dml-logo d-inline d-sm-none" height="30" width="30" src={icon} alt="Northwell DML Tool " />
        </span>
        <NavbarSiteName />
      </Link>
    );
  }
}

class NavbarSiteName extends React.Component<{}> {
  render() {
    return (
      <span className="site-name">
        <span className="d-none d-lg-inline">Northwell Health: </span>
        Data Management Layer
      </span>
    );
  }
}

export default Header;
