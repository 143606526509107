/* @flow */
import React, { Component, Suspense } from 'react';
import ReactDOM from 'react-dom';
import { Alert, Button, Form, FormGroup, Label, Input, FormText, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import ModalSection, { modalClose } from 'elements/ModalSection';
import Select from 'react-select';
import AssociationForm from 'elements/AssociationForm';
import { createProgramTermMutation, removeProgramTermMutation, createPromotedProgramSpecialtyMutation, removeProgramSpecialtyMutation, promotedProgramInfoQuery,  updatePromotedProgramMutation, deletePromotedProgramMutation,
  insertPromotedProgramMutation, createPromotedProgramSchedule, changeProgramSpecialtyIncludeTermsMutation, changeProgramScheduleStatusMutation, removeProgramScheduleMutation, programIconListQuery} from 'promotedSearch/queries';
import { searchSpecialtyQuery} from 'specialty/queries';
import { cftTermsQuery} from 'clinicalFocusTerm/queries';
// import MammoIcon from './MammoIcon';
import { Query, Mutation } from 'react-apollo';
import { SystemAlert } from 'elements/SystemAlert';
import InlineDatePicker from './InlineDatePicker';
import TimePickerInput from './TimePickerInput';
import SectionTable from 'elements/SectionTable';
import SectionError from 'elements/SectionError';
import { convertUnixTimestampToDate, convertUnixTimestampToDateAmPm, slugify } from 'helper';
import ToggleButton from '../elements/ToggleButton';
import DeleteButtonMutation from '../elements/DeleteButtonMutation';

export class NewBannerForm extends React.Component {
  componentDidMount() {
    setTimeout(() => {
      ModalSection.openModal();
    }, 100);
  }

  render() {
    
    return (
      <ModalSection
        heading="Create Promoted Program"
        content={
          <BannerForm
            history={this.props.history}
            match={this.props.match}
            slug={''}
            search={this.props.search}
            refreshListing = {this.props.refreshListing}
            upsertMutation = {insertPromotedProgramMutation}
            newBanner
          />
        }
        actions={false}
        history={this.props.history}
      />
    );
  }
}

export class EditBannerForm extends React.Component {
  componentDidMount() {
    setTimeout(() => {
      ModalSection.openModal();
    }, 100);
  }

  render() {
    return (
      <ModalSection
        heading="Edit Promoted Program"
        content={
          <BannerQuery
            history={this.props.history}
            match={this.props.match}
            slug= {this.props.slug} //this.props.slug
            search={this.props.search}
            refreshListing = {this.props.refreshListing}
            newBanner={false}
          />
        }
        actions={false}
        history={this.props.history}
      />
    );
  }
}

class IconsComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = { Icon: null, isHovered: false, isClicked: false};
  }

  componentDidMount() {
    this.loadIcon();
  }

  loadIcon = async () => {
    const { iconName } = this.props;
    const icons = await import(`react-icons/fa`);
    const iconVal = icons[iconName];
    this.setState({ Icon: iconVal });
  };

  handleIconClick = (event,iconId) => {
    this.props.setIconId(iconId, this.props.iconName, this.props.giIconId);
    this.setState((prevState)=> ({ isHovered: false, isClicked : !prevState.isClicked }));
    
  }

  render () {
   const { Icon } = this.state;
    const bannerIconsClass = { color: '#33322f', fill: '#343634', width:'40px',height:'40px', padding:'2px'};
   
    if (!Icon) return null;
    let borderClass = '';
    borderClass =  this.state.isClicked ? 'border-dark' : this.state.isHovered === true ? 'border-dark' : 'border-medium';
    return (
         <div className={'p-1 mr-2 '} id={'iconDiv'+this.props.iconId}><Icon style={bannerIconsClass} 
          onClick={(event) =>this.handleIconClick(event,this.props.iconId)}/></div>
    );
  }
}

export class DeleteBannerModal extends React.Component {
  componentDidMount() {
    setTimeout(() => {
      ModalSection.openModal();
    }, 100);
  }

  render() {
    return (
      <ModalSection
        heading="Delete Promoted Program"
        content={
          <BannerDeleteForm
          history={this.props.history}
          slug={this.props.slug}
          search={this.props.search}
          callbacks={this.props.callbacks}
        />
        }
        actions={false}
        history={this.props.history}
      />
    );
  }
}

class BannerDeleteForm extends React.Component {
  render() {
    return (
      <React.Fragment>
        <Query
          query={promotedProgramInfoQuery}
          pollInterval={0}
          notifyOnNetworkStatusChange={true}
          variables= {{
            slug: this.props.slug
          }}
          fetchPolicy="no-cache"
        >
          {({ data, loading }) => {
            if (loading) return null;
            const program =
            data && data.program
              ? data.program
              :  {
                id:'',
                name:'',
                title:'',
                source: 'custom',
                slug:'',
                status: 1,
                is_board_certified: 1,
                is_clean: 1,
                is_clean_timestamp: null,
              };

            const ConfirmationDetails = ({program}) => {
             
              if (program) {
                return (
                  <React.Fragment>
                    <Alert color="danger" className="mb-3 delete-confirmation">
                      <div className="alert--with-icon">
                        <i className="alert-icon fal fa-trash-alt" />
                        <h4 className="mb-0">Are you sure you want to delete following Promoted Program? All the associated schedules, terms and specialties will also be deleted.</h4>
                      </div>
                    </Alert>
                    <hr />
                    <div className="delete-confirmation--details">
                      <p>
                        <span className="delete-confirmation-label w-200px">Program Name: </span>
                        <strong>{program.name ? program.name : ''}</strong>
                      </p>
                      <p>
                        <span className="delete-confirmation-label w-200px">Slug: </span>
                        <strong>{program.slug ? program.slug : ''}</strong>
                      </p>
                      <p>
                        <span className="delete-confirmation-label w-200px">Title: </span>
                        <strong>{program.title ? program.title : ''}</strong>
                      </p>
                      <p>
                        <span className="delete-confirmation-label w-200px">Button Text: </span>
                         <strong>{program.button_text ? program.button_text : ''}</strong>
                      </p>
                      <p>
                        <span className="delete-confirmation-label w-200px">Url: </span>
                        <strong>{program.url}</strong>
                      </p>
                      <p>
                        <span className="delete-confirmation-label w-200px">Priority: </span>
                        <strong>{program.priority}</strong>
                      </p>
                      <p>
                        <span className="delete-confirmation-label w-200px">Is Active: </span>
                        <strong>{program.status ? 'Yes' : 'No'}</strong>
                      </p>
                    </div>
                    <hr />
                  </React.Fragment>
                );
              }
              return null;
            };

          

            return (
              <React.Fragment>
                <Mutation
                  mutation={deletePromotedProgramMutation}
                  variables={{
                    input: {
                      id: program && program.id ? program.id : null
                    }
                  }}
                >
                  {(deleteProgram, { data, loading }) => {
                    if (loading) {
                      return null;
                    }
                    const success = data && data.deletePromotedProgram && data.deletePromotedProgram.success;

                    if (success) {
                      const colorVar = (success === true) ? 'success' : 'danger';
                      const actionVar = (success === true) ? 'Promoted Program deleted successfully' : 'Error deleting Promoted Program, please try again';
                      
                      const NotifyUpserted = () => {
              
                        return (
                          <SystemAlert
                            autoClose
                            color={colorVar}
                            icon="alert-icon fal fa-check-circle"
                            messageData={{
                              action: actionVar,
                              item: "",
                              id: '',
                              tail: '...'
                            }}
                          />
                        );
                      }
                      modalClose(this.props.history);
                      const alert = document && document.getElementById('system-alert-wrapper');
                      
                      ReactDOM.render(<NotifyUpserted />, alert);
                    }

                    if (program && program.id !== null) {
                      return (
                        <React.Fragment>
                          <ConfirmationDetails  program={program}/>
                          <div className="text-center">
                            <Button
                              className="delete-item-button"
                              outline
                              size="lg"
                              color="danger"
                              title="Delete"
                              onClick={() => {
                                deleteProgram();
                              }}
                            >
                              <i className="fal fa-trash-alt" />
                              <span>Yes, I'm sure</span>
                            </Button>
                          </div>
                        </React.Fragment>
                      );
                    } else {
                      return (
                        <Alert color="danger">
                          <div className="alert--with-icon">
                            <i className="alert-icon fal fa-info-circle" />
                            <p className="mb-0">That Program could not be found...</p>
                          </div>
                        </Alert>
                      );
                    }
                  }}
                </Mutation>
              </React.Fragment>
            );
          }}
        </Query>
      </React.Fragment>
    );
  }
}
class BannerQuery extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <Query
      query={promotedProgramInfoQuery}
      pollInterval={0}
      notifyOnNetworkStatusChange={true}
      variables={{
        slug: this.props.slug ? this.props.slug : ''
      }}
      fetchPolicy="no-cache"
    >
       {({ data, loading, refetch }) => {
          if (loading) return null;
          const program =
            data && data.program                                          
              ? data.program
              : {
                  // Set the default form values.
                  id: '',
                  slug: '',
                  name: '',
                  title: '',
                  copy: '',
                  button_text: '',
                  icon: '',
                  priority: 0,
                  status: false,
                  specialties: [],
                  terms: [],
                  schedules: []
                };

        const upsertMutation = this.props.slug ? updatePromotedProgramMutation : insertPromotedProgramMutation;
    
      return(
      <BannerForm upsertMutation={upsertMutation} program={program} slug={this.props.slug}  />
      )
    }}
      </Query>
    );
  }
}

class BannerForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      iconName: '',
      id: this.props.id?this.props.id:null,
      giIconId : null,
      program: this.props.program,
      formChanged: false,
      formSubmitted: false,
      submitReady: false,
      scheduleData: null,
    };

    this.handleFormChange = this.handleFormChange.bind(this);
    this.handleFormSaved = this.handleFormSaved.bind(this);
    this.readySubmit = this.readySubmit.bind(this);
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
  }

  setIconId = (iconId, name, giIconId) => {
    this.setState((prevState) => ({
      iconName: name,
      program: {
        ...prevState.program,
        icon_id:iconId
      } ,
      giIconId,
    }));
  }

  unsetIconId = (iconId, name) => {
    this.setState((prevState) => ({
      program: {
        ...prevState.program,
        icon_id:null
      } ,
      iconName: name
    }));
  }

  hanldeToggle = () => {
    this.setState((prevState) => ({
      toggle: !prevState.toggle
    }));
  }

  readySubmit = () => {
    const name = document && document.getElementById('name');
    let readyToSubmit =
      typeof name !== 'undefined' && name !== null && name instanceof HTMLInputElement && name.value.length >= 1;
      this.setState(() => ({
        submitReady: readyToSubmit
      }));
  };

  handleFormSubmit = () => {
    const submittedData = {
      name: this.state.program.name,
      title: this.state.program.title,
      slug: this.state.program.slug,  //programSlug ? programSlug : '',
      status:this.state.program?this.state.program.status:1,
      copy:this.state.program.copy,
      icon_id: this.state.giIconId?this.state.giIconId:this.state.program.icon_id,
      priority: this.state.program.priority,
      button_text: this.state.program.button_text,
      url: this.state.program.url,
    };
    return submittedData;
  }

  setSlug = (programName)=>{
    let slugVal = programName.toLowerCase().trimEnd();
       slugVal = slugify(slugVal);
    this.setState((prevState) => ({
      program: {
        ...prevState.program,
        slug: slugVal
      }
    }));
  }

  setProgramData = (fieldName, val) =>{
    this.setState(prevState => ({
      program: {
        ...prevState.program,
        [fieldName] : val
      }
    }));
  }

  handleToggle = (val) => {
    this.setState(prevState => ({
      toggle: val
    }));
  };

  handleFormChange = () => {
    this.setState(() => ({
      formChanged: true
    }));

    setTimeout(() => {
      this.readySubmit();
    }, 250);
  };

  handleFormSaved = () => {
    this.setState(() => ({
      formChanged: false
    }));
  };

  handleScheduleAdd =  (newData) => {
    const data = this.state.program.schedules ? this.state.program.schedules : [];
    if(data){
      data.push(newData);
    }
    this.setState((prevState)=>({
      program : {
        ...prevState.program,
        schedules: data
      } 
    }));
  };

  handleScheduleDelete = (scheduleId) =>{
    const scheduleDataObj = this.state.program.schedules ? this.state.program.schedules : [];
    if(scheduleDataObj){
      const list = scheduleDataObj;
      const newList = list.filter(s => s.id !== scheduleId);
      this.setState((prevState)=>({
        program : {
          ...prevState.program,
          schedules: newList
        } 
      }));
    } 
    const Notifydelete = () => {
      return (
        <SystemAlert
          autoClose
          color="success"
          icon="alert-icon fal fa-check-circle"
          messageData={{
            action: 'Promoted Program schedule deleted',
            tail: '...'
          }}
        />
      );
    };
    const alert = document && document.getElementById('system-alert-program-schedule');
    if(alert){
      ReactDOM.render(<Notifydelete />, alert);
    }
  }

  handleSpecialtyAdd =  (newData) => {
    const data = this.state.program.specialties ? this.state.program.specialties : [];
    if(data){
      data.push(newData);
    }
    
    this.setState((prevState)=>({
      program : {
        ...prevState.program,
        specialties: data
      } 
    }));
  };

  handleSpecialtyToggle = (xrefId) => {
    const List = this.state.program.specialties.length > 0 ? this.state.program.specialties : [];
    const newList = List.map(s => {
      if(s.id === xrefId){
        return {...s, use_inherited_terms: s.use_inherited_terms === 1?0:1};
      }else{
        return s;
      }
    });
    this.setState((prevState)=>({
      program : {
        ...prevState.program,
        specialties: newList
      } 
    }));
  }

  handleScheduleToggle = (scheduleId) => {
    const List = this.state.program.schedules ? this.state.program.schedules : [];
    const newList = List.map(s => {
      if(s.id === scheduleId){
        return {...s, status: s.status === 1?0:1};
      } else {
        return s;
      }
    });
    this.setState((prevState)=>({
      program : {
        ...prevState.program,
        schedules: newList
      } 
    }));
  }

  handleSpecialtyDelete = (specialtyXrefId) => {
    const DataObj = this.state.program.specialties ? this.state.program.specialties : [];
    if(DataObj){
      const list = DataObj;
      const newList = list.filter(s => s.id !== specialtyXrefId);
      this.setState((prevState)=>({
        program : {
          ...prevState.program,
          specialties: newList
        } 
      }));
    } 
    const Notifydelete = () => {
      return (
        <SystemAlert
          autoClose
          color="success"
          icon="alert-icon fal fa-check-circle"
          messageData={{
            action: 'Promoted Program specialty deleted',
            tail: '...'
          }}
        />
      );
    };
    const alert = document && document.getElementById('system-alert-program-specialty');
    if(alert){
      ReactDOM.render(<Notifydelete />, alert);
    }
  }

  setTermsData(element, action=""){
    let cftTerms = this.state.program.terms;
    if (action === "add"){
     let elementFound;
     if(cftTerms.length > 0 ){
       elementFound = cftTerms.find(item =>(item.name === element.name));
     }
     if(!elementFound){
       cftTerms.push(element);
       this.setState(prevState => ({
         program : { ...prevState.program,
          terms: cftTerms
         }
       }));
     }
   }else if (action === "delete"){
      const newTerms = cftTerms.filter(t => (element.slug != t.slug));
       this.setState(prevState => ({
        program : { ...prevState.program,
          terms: newTerms
         }
       }));
   }
   }

  render() {
    const iconStateVal = this.state.giIconId?this.state.giIconId:this.state.program?this.state.program.icon_id:null;
    return (
      <React.Fragment>
      <div id="system-alert-program"></div>
       <Alert color="warning" className={`mb-3 ${this.state.formChanged ? 'd-block' : 'd-none'}`}>
            <div className="alert--with-icon">
              <i className="alert-icon fal fa-info-circle" />
              <span>
                The form has been updated. Use the <strong>Save Promoted Program</strong> button to avoid losing any changes.
              </span>
            </div>
          </Alert>

          <Mutation mutation={this.props.upsertMutation}>
            {(upsertGroup, { loading, error }) => {
              if (loading) {
                return null;
              }
              if (error) {
                console.log(`ERROR UPSERTING PROMOTED PROGRAM...`);
              }
      return(
        <Form
        onLoad={() => {
          setTimeout(() => {
            this.readySubmit();
          }, 250);
        }}
        onChange={() => {
          this.handleFormChange();
        }}
      onSubmit={(e: Event) => {
        e.preventDefault();
       const programData = this.handleFormSubmit();
        if (this.props.slug) {
          upsertGroup({
            variables: {
              input: {
                programId: this.state.program.id,
                update: programData
              }
            }
          });
        } else {
          upsertGroup({
            variables: {
              input: programData
            }
          });
        }

        //Send a message to the screen.
        const NotifyUpserted = () => {
          return (
            <SystemAlert
              autoClose
              color="success"
              icon="alert-icon fal fa-check-circle"
              messageData={{
                action: this.state.program .slug !== '' ? 'Promoted Program Updated' : 'Promoted Program Created',
                item: this.state.program.name,
                tail: '...'
              }}
            />
          );
        };
        if(this.props.upsertMutation === insertPromotedProgramMutation){
          setTimeout(() => {
            this.props.history.push(`edit/${this.state.program.slug}`);
          }, 500);
          return (   <BannerQuery
            history={this.props.history}
            match={this.props.match}
            slug= {this.props.slug} //this.props.slug
            search={this.props.search}
            refreshListing = {this.props.refreshListing}
            newBanner={false}
          />);
        
        }else{
          modalClose(this.props.history);
          const alert = document && document.getElementById('system-alert-wrapper');
          if(alert){
            ReactDOM.render(<NotifyUpserted />, alert);
          }
          
        }
      }}
    >
      <FormGroup>
        <React.Fragment>
          <Input
            type="hidden"
            name="id"
            id="programId"
            defaultValue={this.props.slug?this.state.program.id:null}

          />
        </React.Fragment>
        <hr />
      </FormGroup>
      <FormGroup>
        <Label for={`groupURL`}>Promoted Program Name</Label>
        <Input
          type="text"
          autoComplete="off"
          name={`name`}
          id={`name`}
          defaultValue={this.props.slug?this.state.program.name:''}
          placeholder={`Name of the program`}
          invalid={false}
          valid={false}
          onChange={(e) => {
            this.setSlug(e.target.value);
            this.setProgramData('name',e.target.value);
          }}
        />
        <hr />
      </FormGroup>
      <FormGroup>
            <FormText color="muted">
              Program slug: <strong><span className="" id="slugName" >{this.state.program? this.state.program.slug:''}</span></strong>
            </FormText>
            <Input
              type="hidden"
              name="slug"
              id="slug"
              value={this.state.program? this.state.program.slug:''}
            />
          <hr />
        </FormGroup>
        <FormGroup>
        <Label for={`groupURL`}>Promoted Program Title</Label>
        <Input
          type="text"
          autoComplete="off"
          name={`title`}
          id={`title`}
          defaultValue={this.props.slug?this.state.program.title:''}
          placeholder={`Title of the program`}
          invalid={false}
          valid={false}
          onChange={(e) => {
            this.setProgramData('title',e.target.value);
          }}
        />
        <hr />
      </FormGroup>
      <FormGroup>
        <Label for={`copy`}>Copy</Label>
        <Input
          type="textarea"
          autoComplete="off"
          rows="10"
          cols="10"
          name={`copy`}
          id={`copy`}
          defaultValue={this.props.slug?this.state.program.copy:''}
          onChange={(e)=>this.setProgramData('copy',e.target.value)}
          placeholder={``}
          invalid={false}
          valid={false}
        />
        <hr />
      </FormGroup>
      <FormGroup>
          <Label for={`button_text`}>Button Text</Label>
          <Input
            type="text"
            autoComplete="off"
            name={`button_text`}
            id={`button_text`}
            defaultValue={this.props.slug?this.state.program.button_text:''}
            placeholder={`Click Here`}
            onChange={(e)=>this.setProgramData('button_text',e.target.value)}
          />
          <hr />
        </FormGroup>
      <FormGroup>
        <Label for={`icon`}>Banner URL</Label>
        <Input
          type="text"
          autoComplete="off"
          name={`url`}
          id={`url`}
          defaultValue={this.props.slug?this.state.program.url:''}
          placeholder={`https://www.northwell.edu/`}
          invalid={false}
          valid={false}
          onChange={(e)=>this.setProgramData('url',e.target.value)}
        />
        <hr />
      </FormGroup>
      <FormGroup>
      <Label for={`icon`}>Icon</Label>
      <div className='card-deck mt-2 ml-1'>
      <Query
              query={programIconListQuery}
              pollInterval={0}
              notifyOnNetworkStatusChange={true}
              variables={{
                slug: this.props.slug ? this.props.slug : '',
                allRows: 1,
              }}
              fetchPolicy="no-cache"
            >
               {({ data, loading, refetch }) => {
                  if (loading) return null;
                  const IconsList =
                    data && data.programIconsList                                          
                      ? data.programIconsList
                      : {
                          // Set the default form values.
                          id: '',
                          slug: '',
                          name: '',
                          title: '',
                          copy: '',
                          button_text: '',
                          icon: '',
                          priority: 0,
                          status: false,
                          specialties: [],
                          terms: [],
                        };
            const IconsListOptions = IconsList.edges ? IconsList.edges.map(s => {
              return { id: s.cursor,  name: s.node.name, iconId:s.node.id}
            }):[];     
             return ( 
             <React.Fragment>
        <SelectBox options={IconsListOptions} multiSelect={false} setIconId={this.setIconId}  selectedIconId={this.props.slug?this.state.program.icon_id:0}  />
        </React.Fragment>
             );
            }
          }
          </Query>
      </div>
      </FormGroup>
      <FormGroup>
          <Label for={`button_text`}>Priority</Label>
          <Input
            type="text"
            autoComplete="off"
            name={`priority`}
            id={`priority`}
            defaultValue={this.props.slug?this.state.program.priority:''}
            placeholder={0}
            invalid={false}
            valid={false}
            onChange={(e)=>this.setProgramData('priority',e.target.value)}
          />
          <hr />
        </FormGroup>
        { this.props.slug && this.props.slug !== undefined && (
          <div>
            <div id="system-alert-program-specialty"></div>
          <FormGroup>
            <legend>Specialty List</legend>
            <SpecialtyFeed handleToggle = {this.handleSpecialtyToggle} pageNumber={this.state.pageNumber} handleDelete={this.handleSpecialtyDelete}   page={this.pagehandler} data={ this.state.program.specialties}  limit={this.state.limit} />
            <SpecialtyPopup programId={this.state.program.id} handleAdd={this.handleSpecialtyAdd}  />
        </FormGroup>
        </div>
        )}
         { this.props.slug && this.props.slug !== undefined && (
         <FormGroup>
          <AssociationForm
            name="terms"
            id="terms"
            searchQuery={cftTermsQuery}
            deleteMutation={removeProgramTermMutation}
            associateMutation={createProgramTermMutation}
            mutationVariables={{
              programId: this.state.program.id
            }}
            refetch={promotedProgramInfoQuery}
            refetchVars={{ slug: this.props.slug}} //programId as state.id
            label={`Terms`}
            slug={`cftTerms`}
            gqlFilterVar={`term`}
            gqlFirstVar={100}
            elementField={`name`} // field name of the result items
            elements={this.state.program.terms}
            callbacks={{
              handleFormChange: (element, action, slug) => {  
                 this.setTermsData(element, action, slug);
              },
              handleFormSaved: () => {}
            }}
          />
          <hr />
        </FormGroup>
        )}
        
        { this.props.slug && this.props.slug !== undefined && (
          <div>
          <div id="system-alert-program-schedule"></div>
          <FormGroup>  
                <legend>Schedule List</legend> 
                  <React.Fragment>
                    <AuditFeed handleToggle = {this.handleScheduleToggle} pageNumber={this.state.pageNumber} handleAdd={this.handleScheduleAdd} handleDelete={this.handleScheduleDelete}  page={this.pagehandler} schedules={ this.state.program.schedules}  limit={this.state.limit} />
                    <SchedulePopup history={this.props.history}  program={this.state.program} handleAdd={this.handleScheduleAdd}   />
                 </React.Fragment>
          </FormGroup>
          </div>
        )}
       
      <FormGroup>
        <legend>Is Active</legend>
        <div className="form-radio-group">
          <FormGroup check>
            <Label check>
              <Input
                type="radio"
                name="status"
                value={1}
                defaultChecked={this.props.slug?this.state.program.status ? 'checked' : false:true}
                onClick={(e)=>this.setProgramData('status',1)}
              />{' '}
              <span className="text-success">Yes</span>
            </Label>
          </FormGroup>
          <FormGroup check>
            <Label check>
              <Input
                type="radio"
                name="status"
                value={0}
                defaultChecked={this.props.slug?this.state.program.status ? false : 'checked':false}
                onClick={(e)=>this.setProgramData('status',0)}
              />{' '}
              <span className="text-warning">No</span>
            </Label>
          </FormGroup>
        </div>
        <FormText color="muted">
          <i className="fal fa-info-circle" /> By default the status of a new Banner is set to{' '}
          <strong>
            <span className="text-warning">Disabled</span>
          </strong>
          . Set the status to{' '}
          <strong>
            <span className="text-success">Enabled</span>
          </strong>{' '}
          to activate this banner.
        </FormText>
        <hr />
      </FormGroup>

      <FormGroup className="mt-2 form-actions d-flex justify-content-between align-items-end">
        <Button
          id="saveGroupButton"
          type="submit"
          size="md"
          color="primary"
          title="Save"
          disabled={!this.submitReady && !iconStateVal}
        >
          <i className="fal fa-save" />
          <span>Save Promoted Program</span>
        </Button>
      </FormGroup>
    </Form>
      );
    }}
          </Mutation>
   
  </React.Fragment>
    );
  }
}

class SpecialtyFeed extends React.Component {
  constructor(props) {
    super(props);
  }
  
  handlePageClick = (data) => {
    let page = data.selected;
    let last = 25 * page;
    let total = last - 1;
    this.props.page.handlePage(total, page);
    //this.handlePage(total, page);
    //window.location = window.location.pathname +"?page="+ currentPage
  }

  render() {
    if (this.props.data && this.props.data.length > 0) {
    
      const FeedRows = () => {
        return this.props.data.map((s, id) => <SpecialtyTableRow handleToggle={this.props.handleToggle}  handleDelete={this.props.handleDelete} row={s} key={id} />);
      };
      // Primary return without errors or oddities.
      return (
        <React.Fragment>
          <SectionTable
            render={() => (
              <React.Fragment>
                <thead className="thead-light">
                  <SpecialtyTableHeaderRow />
                </thead>
                <tbody className="result-set">
                  <FeedRows />
                </tbody>

              </React.Fragment>
            )}
          />
        </React.Fragment>
      )
    }
    
    // Last case error that something went terribly wrong.
    const errorContent = (
      <div>
        <h4 className="text-danger">No specialty found</h4>
        <h2 className="text-danger">¯\_(ツ)_/¯</h2>
      </div>
    );
    return <SectionError color="warning" classes="text-center" content={errorContent} />
  }
}

/**
 * Component to handle table header for Specialty results.
 * 
 */
class SpecialtyTableHeaderRow extends React.Component {
  render() {
    return (
      <tr>
        <th className="actions text-left" scope="col">
          <span className="col-label">Specialty</span>
        </th>
        <th className="actions text-left" scope="col">
          <span
           className="col-label">Include Inherited Terms?</span>
        </th>
        <th className="actions text-left" scope="col">
          <span
           className="col-label">Actions</span>
        </th>
      </tr>
    );
  }
}

class SpecialtyTableRow extends React.Component {
  
  constructor(props) {
    super(props);
    this.state = {
      isOpen: this.props.row ? this.props.row.use_inherited_terms === 1 ? true : false : false,
      schedule: this.props.row,
    };
  }

  handleIsOpen = () => {
    this.setState((prevState) => ({
      isOpen: !prevState.isOpen
    }));
  };
  

  render() {
          return(
            <tr id={this.props.row?this.props.row.id:''} className={`text-md`} key={this.props.row?this.props.row.id:''} >
              <td className="account-type d-none d-lg-table-cell text-left">
               {this.props.row?this.props.row.name:''}
              </td>
              <td className="account-type d-none d-lg-table-cell text-left"> 
                <ToggleButton key={this.props.row.id} callbackToggle={this.props.handleToggle} id={this.props.row.id}  type="schedule" isOpen={this.state.isOpen}  mutationName = {changeProgramSpecialtyIncludeTermsMutation} mutationInput={{id:this.props.row?this.props.row.id:'', status: this.props.row?!this.props.row.use_inherited_terms:false}}  disabled={false} label="" />
              </td>
              <td className="account-type d-none d-md-table-cell text-left">
              <DeleteButtonMutation handleDelete={this.props.handleDelete} id={this.props.row?this.props.row.id:''} mutationInput={{id:this.props.row?this.props.row.id:''}} mutationName={removeProgramSpecialtyMutation} />
              </td>
            </tr>
          );
  } 
}

class AuditFeed extends React.Component {
  constructor(props) {
    super(props);
  }
  
  handlePageClick = (data) => {
    let page = data.selected;
    let last = 25 * page;
    let total = last - 1;
    this.props.page.handlePage(total, page);
    //this.handlePage(total, page);
    //window.location = window.location.pathname +"?page="+ currentPage
  }

  render() {
    if (this.props.schedules && this.props.schedules.length > 0) {
    
      const FeedRows = () => {
        return this.props.schedules.map((s, id) => <AuditTableRow handleToggle={this.props.handleToggle}  handleDelete={this.props.handleDelete}  row={s} key={id} />);
      };
      // Primary return without errors or oddities.
      return (
        <React.Fragment>
          <SectionTable
            render={() => (
              <React.Fragment>
                <thead className="thead-light">
                  <AuditTableHeaderRow />
                </thead>
                <tbody className="result-set">
                  <FeedRows />
                </tbody>

              </React.Fragment>
            )}
          />
        </React.Fragment>
      )
    }
    
    // Last case error that something went terribly wrong.
    const errorContent = (
      <div>
        <h4 className="text-danger">No schedule found</h4>
        <h2 className="text-danger">¯\_(ツ)_/¯</h2>
      </div>
    );
    return <SectionError color="warning" classes="text-center" content={errorContent} />
  }
}

class AuditTableRow extends React.Component {
  
  constructor(props) {
    super(props);
    this.state = {
      isOpen:  this.props.row ? this.props.row.status === 1 ? true : false : false,
      schedule: this.props.row,
    };
  }

  handleIsOpen = (stateVal) => {
    this.setState((prevState) => ({
      isOpen: stateVal
    }));
  };
  

  render() {
      return (
       
            <tr id={this.props.row.id} className={`text-md`} key={this.props.row.id} >
              <td className="account-type d-none d-lg-table-cell text-left">
                <span className={`${Number(this.props.row.sunday) === 1 ?'text-black':'text-muted'} pr-1`}>Sun</span>
                <span className={`${Number(this.props.row.monday) === 1 ?'text-black':'text-muted'} pr-1`}>Mon</span>
                <span className={`${Number(this.props.row.tuesday) === 1 ?'text-black':'text-muted'} pr-1`}>Tue</span>
                <span className={`${Number(this.props.row.wednesday) === 1 ?'text-black':'text-muted'} pr-1`}> Wed</span>
                <span className={`${Number(this.props.row.thursday) === 1 ?'text-black':'text-muted'} pr-1`}>Thu</span>
                <span className={`${Number(this.props.row.friday) === 1 ? 'text-black':'text-muted'} pr-1`}>Fri</span>
                <span className={`${Number(this.props.row.saturday) === 1 ?'text-black':'text-muted'} pr-1`}>Sat</span>
              </td>
              <td className="account-type d-none d-lg-table-cell text-left">{this.props.row.start_date? convertUnixTimestampToDate(this.props.row.start_date): '' } - {this.props.row.end_date ? convertUnixTimestampToDate(this.props.row.end_date): ''}</td>
              <td className="account-type d-none d-lg-table-cell text-left">{this.props.row.start_time ? convertUnixTimestampToDateAmPm(this.props.row.start_time): '' } - {this.props.row.end_time ? convertUnixTimestampToDateAmPm(this.props.row.end_time): ''}</td>
              <td className="account-type d-none d-lg-table-cell text-left"> 
                <ToggleButton callbackToggle={this.props.handleToggle} id={this.props.row.id}  type="schedule" isOpen={this.state.isOpen}  mutationName = {changeProgramScheduleStatusMutation} mutationInput={{programScheduleId:this.props.row.id, status: !this.props.row.status}}  disabled={false} label="" />
              </td>
              <td className="account-type d-none d-md-table-cell text-left">
              <DeleteButtonMutation handleDelete={this.props.handleDelete} id={this.props.row.id} mutationInput={{id:this.props.row.id}} mutationName={removeProgramScheduleMutation} />
              </td>
            </tr>
          );
  } 
}

/**
 * Component to handle table header for Schedule results.
 * 
 */
class AuditTableHeaderRow extends React.Component {
  render() {
    return (
      <tr>
        <th className="actions text-left" scope="col">
          <span className="col-label">Day of the Week</span>
        </th>
        <th className="actions text-left" scope="col">
          <span className="col-label">Date Range</span>
        </th>
        <th className="actions text-left" scope="col">
          <span
           className="col-label">Time Range</span>
        </th>
        <th className="actions text-left" scope="col">
          <span
           className="col-label">Status</span>
        </th>
        <th className="actions text-left" scope="col">
          <span
           className="col-label">Actions</span>
        </th>
      </tr>
    );
  }
}


class SchedulePopup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedDays: [],
      isOpen: this.props.isOpen,
      selectedStartDate: null,
      selectedEndDate: null,
      selectedStartTime: null,
      selectedEndTime: null,
    };
  }

  togglePopup = () => {
    this.setState(prevState => ({
      isOpen: !prevState.isOpen,
    }));
  }

  handleTimeChange = (time, stateVar) => {
    this.setState({ [stateVar]: time });
  };

  handleDateChange = (date, stateVar) => {
    this.setState({ [stateVar]: date });
  };

  render() {

    const submitMutation = (event,createProgramSchedule)=>{
      event.preventDefault();
      createProgramSchedule();
      const NotifyUpserted = () => {
        return (
          <SystemAlert
            autoClose
            color="success"
            icon="alert-icon fal fa-check-circle"
            messageData={{
              action: 'Promoted program scheduled successfully.',
              item: '',
              id: '',
              tail: '...'
            }}
          />
        );
      };
      const alert = document && document.getElementById('system-alert-program-schedule');
      if (alert) {
      this.togglePopup();
        ReactDOM.render(<NotifyUpserted />, alert);
      }
    }
    const inputVars = {programId: this.props.program.id, days: this.state.selectedDays, start_date:this.state.selectedStartDate, end_date:  this.state.selectedEndDate, start_time:this.state.selectedStartTime, end_time: this.state.selectedEndTime}; //{programId: this.props.program.id};
    
    const options = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    
    const setDays = (data) => {
      this.setState({ selectedDays: data });
    }

    return(
     
      <Mutation
      mutation={createPromotedProgramSchedule}
      variables={{
        input: inputVars
      }}
      onCompleted={(data) => {
        const newData = data.createPromotedProgramSchedule.schedule;
        this.props.handleAdd(newData);
      }}
      
    >
      {(createSchedule, { loading, error }) => {
        if (loading) {
          return null;
        }
        if (error) {
          console.log(error);
        }
            const buttonDisabled = false;
            const alertText = (buttonDisabled) ?  (
            <Alert color="warning" className={`mb-3 d-block`}>
            <div className="alert--with-icon">
              <i className="alert-icon fal fa-info-circle" /> validation error
              </div>
            </Alert>):"";
            return (
              <React.Fragment>
                {alertText}
                <FormGroup>
                  <div className="add-variant">
                  <Button
                      id="saveGroupButton"
                      type="button"
                      size="md"
                      color="primary"
                      title="Save"
                      className="btn-large"
                      disabled={buttonDisabled}
                      onClick={()=>this.togglePopup()}
                    >
                      <span className='fas fa-plus'></span> Add schedule 
                      </Button>
                      </div>
                </FormGroup>
                <Modal isOpen={this.state.isOpen} toggle={this.togglePopup} centered className="modal-lg" backdrop="static">
                <ModalHeader toggle={this.togglePopup}>Add Schedule</ModalHeader>
                <ModalBody>
                  <div className='schedule-box'>
                    <SelectBox options={options} multiSelect={true} setDays = {(v)=>setDays(v)} />
                  </div>
                  <div className='d-flex flex-column'>
                  <div className='ml-2 d-flex flex-row'>
                  <InlineDatePicker name="start_date" label="Start Date"  onDateChange={(value)=>this.handleDateChange(value,'selectedStartDate')} />
                  <TimePickerInput label="Start Time"  onTimeChange={(value)=>this.handleTimeChange(value,'selectedStartTime')} />
                  </div>
                  <div className='ml-2 d-flex flex-row'>
                  <InlineDatePicker name="End_date" label="End Date"  onDateChange={(value)=>this.handleDateChange(value,'selectedEndDate')} />
                  <TimePickerInput label="End Time" onTimeChange={(value) => this.handleTimeChange(value, 'selectedEndTime')} />
                  </div>
                  </div>
                  </ModalBody>
                <ModalFooter>
                  <Button color="primary" onClick={(e)=> this.togglePopup() }>Cancel</Button>
                  <Button color="primary" type="submit" onClick={(e)=> submitMutation(e,createSchedule)}>Add</Button>
                </ModalFooter>
              </Modal>
            </React.Fragment>
        );
      }}
      </Mutation>
    );
  }
}

class SpecialtyPopup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedSpecialtyId: null,
    };
  }

  togglePopup = () => {
    this.setState(prevState => ({
      isOpen: !prevState.isOpen,
    }));
  }
  
  handleSpecialtyChange = (targetObj) => {
    this.setState({ selectedSpecialtyId :targetObj.value});
  }

  render() {
    const submitMutation = (event,AddSpecialty)=>{
      event.preventDefault();
      AddSpecialty();
      const NotifyUpserted = () => {
        return (
          <SystemAlert
            autoClose
            color="success"
            icon="alert-icon fal fa-check-circle"
            messageData={{
              action: 'Promoted program specialty added successfully.',
              item: '',
              id: '',
              tail: '...'
            }}
          />
        );
      };
      const alert = document && document.getElementById('system-alert-program-specialty');
      if (alert) {
      this.togglePopup();
        ReactDOM.render(<NotifyUpserted />, alert);
      }
    }
    const inputVars = {programId: this.props.programId, specialtyId: this.state.selectedSpecialtyId }; //{programId: this.props.program.id};
    return(
      <Mutation
      mutation={createPromotedProgramSpecialtyMutation}
      variables={{
        input: inputVars
      }}
      onCompleted={(data) => {
        const newData = data.createPromotedProgramSpecialty.specialty;
        this.props.handleAdd(newData);
      }}
    >
      {(createSchedule, { loading, error }) => {
        if (loading) {
          return null;
        }
        if (error) {
          console.log(error);
        }
            const buttonDisabled = false;
            const alertText = (buttonDisabled) ?  (
            <Alert color="warning" className={`mb-3 d-block`}>
            <div className="alert--with-icon">
              <i className="alert-icon fal fa-info-circle" /> validation error
              </div>
            </Alert>):"";
            return (
              <Query
              query={searchSpecialtyQuery}
              pollInterval={0}
              notifyOnNetworkStatusChange={true}
              variables={{
                slug: this.props.slug ? this.props.slug : '',
                allRows: 1,
              }}
              fetchPolicy="no-cache"
            >
               {({ data, loading, refetch }) => {
                  if (loading) return null;
                  const specialties =
                    data && data.specialties                                          
                      ? data.specialties
                      : {
                          // Set the default form values.
                          id: '',
                          slug: '',
                          name: '',
                          title: '',
                          copy: '',
                          button_text: '',
                          icon: '',
                          priority: 0,
                          status: false,
                          specialties: [],
                          terms: [],
                        };
            const specialtyOptions = specialties.allRecords ? specialties.allRecords.map(s => {
              return { value: s.id,  label: s.name}
            }):[];     
             return ( 
             <React.Fragment>
                {alertText}
                <FormGroup>
                  <div className="add-variant">
                  <Button
                      id="saveGroupButton"
                      type="button"
                      size="md"
                      color="primary"
                      title="Save"
                      className="btn-large"
                      disabled={buttonDisabled}
                      onClick={()=>this.togglePopup()}
                    >
                      <span className='fas fa-plus'></span> Add Specialty 
                      </Button>
                      </div>
                </FormGroup>
                <Modal isOpen={this.state.isOpen} toggle={this.togglePopup} centered className="modal-lg" backdrop="static">
                <ModalHeader toggle={this.togglePopup}>Add Specialty</ModalHeader>
                <ModalBody>
                <h4>Specialties List</h4>
                <Select
                    id="variant"
                    name="variant"
                    options={specialtyOptions}
                    onChange={(e)=>this.handleSpecialtyChange(e)} 
                    defaultValue={''}
                    placeholder={"Select"}
                  />
                  <hr/>
                  </ModalBody>
                <ModalFooter>
                  <Button color="primary" onClick={(e)=> this.togglePopup() }>Cancel</Button>
                  <Button color="primary" type="submit" onClick={(e)=> submitMutation(e,createSchedule)}>Add Specialty</Button>
                </ModalFooter>
              </Modal>
            </React.Fragment>
             );
            }}
            </Query>
            );
      }}
      </Mutation>
    );
  }
}

class SelectBox extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Icon: null,
      selectedOptions: this.props.multiSelect ? [] : this.props.selectedIconId, // Adjust based on single or multi-select
    };
  }

  numVal = (id) => {
    return (typeof id === "string")? Number(id):id;
  }

  handleSelect = (option) => {
    var selectedArr = [];
    if (this.props.multiSelect) {
      this.setState((prevState) => {
        const { selectedOptions } = prevState;
        if (selectedOptions.includes(option)) {
          this.props.setDays(selectedOptions.filter(opt => opt !== option));
          return  { selectedOptions: selectedOptions.filter(opt => opt !== option) };
        } else {
          this.props.setDays([...selectedOptions, option] );
          return  { selectedOptions: [...selectedOptions, option] };
        }
      });
    } else {
      this.setState({ selectedOptions: this.numVal(option.id) });
    }
  }

  render() {
    const { options, multiSelect } = this.props;
    const { selectedOptions } = this.state;
    const boxContainer = (option) => {
      return this.props.multiSelect ? option : <IconsComponent iconId={option.id} giIconId={option.iconId} iconName={option.name} setIconId={this.props.setIconId} /> ;
    }
    return (
      <div className={this.props.multiSelect ? 'select-box':'select-box-single'}>
        {options.map(option => (
          <div
            key={this.props.multiSelect ? option : option.id}
            className={`option ${multiSelect ? 
              (selectedOptions.includes(option) ? 'selected' : '') : 
              (selectedOptions ===  this.numVal(option.id) ? 'selected' :'')
            }`}
            onClick={() => this.handleSelect(option)}
          >
        {boxContainer(option)}
          </div>
        ))}
      </div>
    );
  }
}


export default SelectBox;


