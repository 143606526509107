/* @flow */
import * as React from 'react';
import type { SectionTableProps } from 'shared/types';

/**
 * Component to handle entire table.
 */
class SectionTable extends React.Component<SectionTableProps> {
  render() {
    return <table className="table table-hover">{this.props.render()}</table>;
  }
}

export default SectionTable;
