/* @flow */
import * as React from 'react';
import { withRouter, Switch, Route } from 'react-router-dom';
import LoginForm from 'user/LoginForm';
import type { routeProps } from 'shared/types';

class UnauthenticatedRoutes extends React.Component<routeProps> {
  render() {
    return (
      <Switch>
        <Route path="/" component={() => <LoginForm {...this.props} />} />
      </Switch>
    );
  }
}

export default withRouter(UnauthenticatedRoutes);
