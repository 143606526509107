/* eslint no-unused-vars: "off" */
import React from 'react';
import { Label } from 'reactstrap';
const Toggle = ({ input, label }) => {
  return (
    <label className="toggle">
      <span className="mr-2">{label}</span>
      <div className="switch">
        <input type="checkbox" {...input} />
        <span className="slider" />
      </div>
    </label>
  );
};

export default Toggle;
