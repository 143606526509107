/* @flow */
import React, { Component } from 'react';
import { Query } from 'react-apollo';
import { Alert, Card, CardBody, CardFooter, CardTitle, Input } from 'reactstrap';

import { AssociationAddable } from 'shared/inputs/association';
import  CreateGroupAdds  from 'elements/CreateGroupAdds';
export default class Search extends Component {
  state = {
    filter: ''
  };

  handleChange = ({ target: { value } }: { target: HTMLInputElement }) => {
    this.setState({ filter: value });
  };

  clearSearch = () => {
    this.setState({ filter: '' });
  };

  render() {
    const { added, elements, gqlFirstVar, label, searchQuery, slug, visible } = this.props;
    const { filter } = this.state;
    const hasSearchValue = filter.length >= 1;
    const visibilityClass = hasSearchValue ? 'active-search ' : '';
    if (visible) {
      return (
        <Card inverse color="primary">
          <CardBody>
            <CardTitle className=""> {label}</CardTitle>
            <div id={`${slug}-search-field`} className={visibilityClass + 'section-search-form--wrapper association-search'}>
              <Input
                id={`${slug}AssociationList`}
                value={filter}
                className="search section-search section-search--field"
                autoComplete="off"
                type="text"
                placeholder={`Search ${label}...`}
                onChange={this.handleChange}
              />
              <React.Fragment>
                {!hasSearchValue && (
                  <button className="btn search-icon">
                    <i className="fal fa-search" />
                  </button>
                )}
                {hasSearchValue && (
                  <button
                    className="btn btn-small close-icon"
                    onClick={(e: Event) => {
                      this.clearSearch(e);
                    }}
                  >
                    <i className="fas fa-times" />
                  </button>
                )}
              </React.Fragment>
            </div>
          </CardBody>
         
          {this.props.slug == 'cftTerms' ?
          <CreateGroupAdds group_id={this.props.mutationVariables}  refetch={this.props.refetch} mutation={this.props.associateMutation} setcreatedTerm={''} slug = {this.props.slug} />
          :''}
          {hasSearchValue && (
            <CardFooter className="bg-light">
              <div id={`${slug}Results`}>
                <Query
                  query={searchQuery}
                  variables={{
                    filter: filter,
                    first: gqlFirstVar
                  }}
                  fetchPolicy="cache-and-network"
                >
                  {({ variables, loading, data, error }) => {
                    if (loading) return null;
                    if (error) return `Error!: ${error}`;
                    const reducedElements = [];
                    data[slug].edges.map((element, id) => {
                      if (
                        typeof elements !== 'undefined' &&
                        !elements.find(x => x.id === element.id) &&
                        (typeof added !== 'undefined' && !added.find(x => x.id === element.id))
                      ) {
                        reducedElements.push(element.node);
                      }
                      return null;
                    });

                    if (reducedElements.length === 0) {
                      return (
                        <Alert color="warning" className="mb-0">
                          <div className="alert--with-icon">
                            <i className="alert-icon fal fa-info-circle" />
                            <p className="mb-0">
                              No <em>{label}</em> results found for search term of <strong>{filter}</strong>
                              ...
                            </p>
                          </div>
                        </Alert>
                      );
                    }

                    if (reducedElements.length > 0 && hasSearchValue) {
                      return reducedElements.map((element, id) => {
                        return (
                          <AssociationAddable
                            {...this.props}
                            key={element.id}
                            action={this.props.callbacks.associate}
                            association={element}
                          >
                            {element.name}
                          </AssociationAddable>
                        );
                      });
                    }
                    return null;
                  }}
                </Query>
              </div>
            </CardFooter>
          )}
        </Card>
      );
    } else {
      return null;
    }
  }
}

Search.defaultProps = {
  visible: true,
  gqlFirstVar: 100
};
