/* @flow */
import * as React from 'react';
import type { ActionButtonLink, SectionActionButtonsProps } from 'shared/types';
import { Link } from 'react-router-dom';
import { Button } from 'reactstrap';

/**
 * Component to action buttons for the listing table.
 */
class SectionActionButtons extends React.Component<SectionActionButtonsProps> {
  render() {
    let buttons = [];
    if (typeof this.props.links !== 'boolean') {
        buttons = this.props.links.map((link: ActionButtonLink, index: number) => {
          if(link.visible === false) {
            return null;
          }
          return(
          (link.uri === "" || link.uri === "#")?
             (<>
             <Button
              key={index}
              id={link.id}
              color="primary"
              className="btn btn-primary mr-1"
              onClick={link.onClick}
            >
            <i className={link.icon} />
            <span className="d-none d-lg-inline-block">{link.text}</span>
            </Button>
            </>)
          :
             (<><Link to={link.uri} className="btn btn-primary mr-1" id={link.id} key={index}>
            <i className={link.icon} />
            <span className="d-none d-lg-inline-block">{link.text}</span>
          </Link></>)
          )
        }
        
      );

      
    }
    if(typeof this.props.submits !== "undefined" && typeof this.props.submits !== 'boolean'){
       buttons.push( this.props.submits.map((link: ActionButtonLink, index: number) => (
     
        <Button
        key={index}
        id={link.id}
        color="primary"
        className="btn btn-primary mr-1"
        onClick={link.onclick}
      >
      <i className={link.icon} />
      <span className="d-none d-lg-inline-block">{link.text}</span>
      </Button>
      )))
    }
    return <div className="section-actions">{buttons}</div>;
  }
}

export default SectionActionButtons;
