import gql from 'graphql-tag';

/**
 * Query to handle a paginated list of CFTs, including aggregation and page information.
 */
// Version that supported pagination via mariadb
// export const groupConnectionQuery = gql`
//   query groups($filter: String, $orderBy: GroupOrdering, $first: Int!, $before: String, $after: String) {
//     groups(filter: $filter, orderBy: $orderBy, first: $first, after: $after, before: $before) {
//       pageInfo {
//         hasNextPage
//         hasPreviousPage
//         startCursor
//         endCursor
//       }
//       aggregate {
//         count
//       }
//       edges {
//         node {
//           id
//           slug
//           name
//           url
//           providers {
//             id
//           }
//           updated_at
//           status
//         }
//       }
//     }
//   }
// `;

export const searchGroupsQuery = gql`
  query groups($filter: String, $orderBy: GroupOrdering, $first: Int, $afterCurser: String) {
    groups(filter: $filter, orderBy: $orderBy, first: $first,afterCurser: $afterCurser) {
      edges {
        node {
      id
      name
        }
      }
    }
  }
`;

export const groupConnectionQuery = gql`
  query groups($filter: String, $orderBy: GroupOrdering, $first: Int, $afterCurser: String) {
    groups(filter: $filter, orderBy: $orderBy, first: $first,afterCurser: $afterCurser) {

      aggregate {
        count
      }
      edges {
        node {
      id
      slug
      name
      url
      providers {
        id
      }
      updated_at
      status
    }
    cursor
  }
    }
  }
`;

/**
 * Query for getting a single CFT.
 */
export const groupInfoQuery = gql`
  query group($id: ID, $slug: String) {
    group(id: $id, slug: $slug) {
      id
      slug
      name
      url
      display_on_profile
      status
      created_at
      updated_at
      providers {
        id
        fullName
        lastName
      }
    }
  }
`;

/**
 * Query too see if a group exists based on the slug match.
 */
export const groupSlugQuery = gql`
  query group($slug: String!) {
    group(slug: $slug) {
      id
      slug
      name
    }
  }
`;

/**
 * Mutation to handle removing a Group completely.
 */
export const deleteGroupMutation = gql`
  mutation removeGroup($input: RemoveGroupInput!) {
    removeGroup(input: $input) {
      success
      group {
        id
        name
      }
    }
  }
`;

/**
 * Mutation to handle creating a new CFT.
 */
export const insertGroupMutation = gql`
  mutation createGroup($input: CreateGroupInput!) {
    createGroup(input: $input) {
      success
      group {
        id
        slug
        name
      }
    }
  }
`;

/**
 * Mutation to handle updating an existing CFT.
 */
export const updateGroupMutation = gql`
  mutation updateGroup($input: UpdateGroupInput!) {
    updateGroup(input: $input) {
      success
      group {
        id
        slug
        name
      }
    }
  }
`;

/**
 * Mutation to handle removing the association between a Physician and a CFT.
 */
export const removePhysicianFromGroupMutation = gql`
  mutation removeGroupProviderAssociation($input: RemoveGroupProviderAssociationInput!) {
    removeGroupProviderAssociation(input: $input) {
      success
    }
  }
`;

/**
 * Mutation to handle adding an association between a Physician and a CFT.
 */
export const associatePhysicianWithGroupMutation = gql`
  mutation createGroupProviderAssociation($input: CreateGroupProviderAssociationInput!) {
    createGroupProviderAssociation(input: $input) {
      success
    }
  }
`;

export const associatePhysiciansWithGroupMutation = gql`
  mutation createGroupProvidersAssociation($input: CreateGroupProvidersAssociationInput!) {
    createGroupProvidersAssociation(input: $input) {
      success
    }
  }
`;
