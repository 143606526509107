/* @flow */
import * as React from 'react';
import VisualError from './VisualError';
import type { ErrorProps, ErrorState } from 'shared/types';

export class ErrorBoundary extends React.Component<ErrorProps, ErrorState> {
  constructor(props: ErrorProps) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch = () => {
    // Display fallback UI
    this.setState({ hasError: true });
  };

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <VisualError />;
    }
    return this.props.children;
  }
}
