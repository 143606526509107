/* eslint no-unused-vars: "off" */
import React, { Fragment, useContext,useState } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { Warning } from 'shared/messaging';
import { Info as InfoIcon, Plus } from 'shared/icons';
import {associatePhysicianWithTermsMutation} from 'clinicalFocusTerm/queries'
import { associatePhysiciansWithGroupMutation } from 'group/queries';
import { Synonyms } from 'synonym/container';
import { createSynonym } from 'synonym/actions';
import { Container, Modal,Button,ModalHeader, ModalBody, ModalFooter,Input,TextArea,Alert} from 'reactstrap';

const CreateGroupAdds = (group_id,refetch1,mutation,setcreatedTerm,slug) => {
//  const { refetch } = useContext(refetch1);

  const [modal, setModal] = useState(false);
  const [providerId, setProviders] = useState({provider_id: {}});
  const [warning, setWarning] = useState({warning: false});
 
  const toggle = () => setModal(!modal);

  const handleClose = () => setModal(false);
  const handleShow = () => setModal(true);
  let associatemutation = '';
  let termId = '';
  if(group_id.slug == 'cftTerms' ){
    termId = group_id.group_id.providerId
    associatemutation = associatePhysicianWithTermsMutation
    //const [creationMutation] = useMutation( );
  }
  else{
    associatemutation = associatePhysiciansWithGroupMutation
     
  }
  const [creationMutation] = useMutation(associatemutation);
  const createGroup = (variables, creationMutation, refetch1, setcreatedTerm,setModal) => async () => {
   let response = '';
    if(group_id.slug == 'cftTerms' ){

      const {
        data: {
          createTermsProviderAssociation: { success }
        }
       
      }  = await creationMutation({ ...variables });
      console.log(success);
      if (success) {
       // setcreatedTerm('');
        if(setModal){
         setModal(false);
        }
        
        window.location.reload(); 
        //refetch();
       
        
        
      }
      else{
        setWarning(true);
      }

      
    }

    else{
      const {
        data: {
          createGroupProvidersAssociation: { success }
        }
      }  = await creationMutation({ ...variables });
      console.log(success);
      if (success) {

       // setcreatedTerm('');
        if(setModal){
         setModal(false);
        }
        //console.log(success);
        window.location.reload(); 
        //refetch();
       
        
        
      }



    }
      
   
  };

  
  return (
 
    <Warning icon={InfoIcon}>
      {/* It appears that the synonym you are searching for doesn't exist. */}
      <a
        className="ml-2 custom-cft-button text-primary"
        onClick={handleShow}
        // onClick={createSynonym({ variables: { name: name, termID: term.id } }, creationMutation, refetch, setSearch)}
      >
      
        <Plus className="mr-1 " />
       
        {'providerId' in group_id.group_id ?
           "Add Cfts By Id" + "Example (id,id)"
        :
       
        "Add A Group of Physicians By IDs." + "Example (id,id)"
        }
        
      </a>
  
      <Modal isOpen={modal} toggle={toggle} centered>
     
      <Alert  color="danger" className={`error--graphql-connection ${warning === true ? 'd-block' : 'd-none'}`}>
                <div className={`text-center`}>
                  <h4 className="text-error">One Or More of the terms you tried to add are associated to this provider already.</h4>
                  
                </div>
      </Alert>
      {'providerId' in group_id.group_id ?   
      <ModalHeader toggle={toggle}>Add A Group of CFTs By IDs</ModalHeader>
      :
      <ModalHeader toggle={toggle}>Add A Group of Physicians By IDs</ModalHeader>
      }
    
    <ModalBody> 
    {'providerId' in group_id.group_id ?   
     <textarea id="providers"
     className="search section-search section-search--field form-control"
     placeholder="CFT IDs"
     //value={''}
     onChange={({ target: { value } }) => setProviders(value)}
   />
   :
       <textarea id="providers"
                className="search section-search section-search--field form-control"
                placeholder="Physicians IDs"
                //value={''}
                onChange={({ target: { value } }) => setProviders(value)}
              />
      }
    </ModalBody>
    <ModalFooter>
    {group_id.slug == 'cftTerms' ?
       <Button variant="primary" onClick={createGroup({ variables:{ input: Object.assign({}, {termId},{providerId}) }}, creationMutation, refetch1, setcreatedTerm,setModal)} >
            Save Changes
       </Button>
    :
      <Button variant="primary" onClick={createGroup({ variables:{ input: Object.assign({}, group_id.group_id,{providerId}) }}, creationMutation, refetch1, setcreatedTerm,setModal)} >
           Save Changes
      </Button>
    }
 
   </ModalFooter>
   </Modal>
    </Warning>
  
 
  );
};

export default CreateGroupAdds;