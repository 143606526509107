/* @flow */

import React from 'react';
import { Icon } from './';

const AngleDoubleUp = ({ direction, ...props }) => {
  return <Icon fontType="fas" icon="fa-angle-double-up" {...props} />;
};

export default AngleDoubleUp;
