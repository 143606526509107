/* @flow */
import { clearSearch } from 'functions/SectionFunctions';
import * as React from 'react';
import type { SectionSearchProps } from 'shared/types';

/**
 * Component to handle search field.
 */
class SectionSearchField extends React.Component<SectionSearchProps> {
  render() {
    const visibilityClass = this.props.filter && this.props.filter.length >= 1 ? 'active-search ' : '';
    return (
      <div id="section-search-field" className={visibilityClass + 'd-block section-search-form--wrapper mb-4'}>
        <input
          id={this.props.element.id}
          defaultValue={this.props.filter}
          className="search section-search section-search--field"
          type="text"
          placeholder={this.props.element.placeholder}
          onChange={this.props.callbacks.handleChange}
          onKeyDown={this.props.callbacks.handleKeyDown}
        />
        <button className="btn search-icon">
          <i className="fal fa-search" />
        </button>
        <button
          className="btn close-icon"
          onClick={() => {
            clearSearch(this.props.element.id, this.props.callbacks.clearFilter);
          }}
        >
          <i className="fas fa-times" />
        </button>
      </div>
    );
  }
}

export default SectionSearchField;
