import gql from 'graphql-tag';

// Version that supported pagination via mariadb
// export const specialtyConnectionQuery = gql`
//   query specialties($before: String, $after: String, $first: Int!, $filter: String, $orderBy: SpecialtyOrdering) {
//     specialties(before: $before, after: $after, first: $first, filter: $filter, orderBy: $orderBy) {
//       pageInfo {
//         hasNextPage
//         hasPreviousPage
//         startCursor
//         endCursor
//       }
//       aggregate {
//         count
//       }
//       edges {
//         node {
//           id
//           slug
//           name
//           status
//           cftTerms {
//             id
//           }
//         }
//       }
//     }
//   }
// `;

export const searchSpecialtyQuery = gql`
  query specialties($filter: String,$skip_id:String, $orderBy: SpecialtyOrdering,  $first: Int, $afterCurser: String, $allRows: Int) {
    specialties(filter: $filter,skip_id:$skip_id, orderBy: $orderBy, first: $first,afterCurser: $afterCurser, allRows: $allRows) {
     allRecords{
        id
        name
      }
      edges {
        node {
      id
      name
      
        }
      }
    }
  }
`;

export const specialtyConnectionQuery = gql`
  query specialties($filter: String,$filterStatus: Int, $filterSource: String,$filterCertified: Int,$filterClean: Int,$filterService: String, $orderBy: SpecialtyOrdering, $first: Int, $afterCurser: String) {
    specialties(filter: $filter,filterStatus: $filterStatus, filterSource: $filterSource,filterCertified: $filterCertified,filterClean: $filterClean,filterService: $filterService, orderBy: $orderBy, first: $first,afterCurser: $afterCurser) {
      aggregate {
            count
                }
      edges {
        node {
      id
      slug
      name
      display_name
      is_board_certified
      source
      status
      is_clean
      is_clean_timestamp
      cftTermsMaster {
        id
      }
      service_lines{
        id,
        name,
        slug
      }

   
    }
    cursor
  }
    }
  }
`;

/**
 * Query for getting a single CFT.
 */
export const specialtyInfoQuery = gql`
  query specialty($id: ID, $slug: String) {
    specialty(id: $id, slug: $slug) {
      id
      slug
      name
      display_name
      source
      status
      is_clean
      is_visible
      is_board_certified
      is_clean_timestamp
      cftDefaultTerms {
        id
        name
        slug
      }
      cftVariantTerms{
        id
        name
        slug
        service_line_slug
      }
      cftTermsMaster {
        id
        name
        slug
      }
      parentSpecialties{
        id
        name 
        slug
      }
      childSpecialties{
        id
        name
        slug
      }
      service_lines{
        id
        name
        slug  
      }
      serviceLineSearchStatus{
        id
        name
        slug
        is_variant_term_list_enabled
      }
    }
  }
`;



// export const specialtyChildParentList = gql`
// query specialty($id: ID) {
//   specialty(specialtyChildId: $id, ) {
   
//     parentSpecialtyList{
//       id
//       name
//       slug
//     }
//   }
// }
// `;



/**
 * Mutation to handle creating the association between a Specialty and its association with specialty as child or parent
 */
export const createChildParentSpecialtyMutation = gql`
  mutation createChildParentSpecialtyAssociation($input: createChildParentSpecialtyAssociationInput!) {
    createChildParentSpecialtyAssociation(input: $input) {
      success
    }
  }
`;


/**
 * Mutation to handle removing the association between a Specialty and its association with specialty as child or parent
 */
export const removeChildParentSpecialtyMutation = gql`
  mutation removeChildParentSpecialtyAssociation($input: removeChildParentSpecialtyAssociationInput!) {
    removeChildParentSpecialtyAssociation(input: $input) {
      success
    }
  }
`;

/**
 * Mutation to handle creating a new CFT.
 */
export const insertSpecialtyMutation = gql`
 mutation createSpecialty($input: createSpecialtyInput!) {
   createSpecialty(input: $input) {
     success
   }
 }
`;

export const updateSpecialtyMutation = gql`
  mutation updateSpecialty($input: updateSpecialtyInput!) {
    updateSpecialty(input: $input) {
      success
    }
  }
`;

/**
 * Mutation to handle removing a Group completely.
 */
export const deleteSpecialtyMutation = gql`
  mutation deleteSpecialty($input: deleteSepecialtyInput!) {
    deleteSpecialty(input: $input) {
      success
    }
  }
`;
