/* @flow */
import * as React from 'react';
import { withRouter, BrowserRouter } from 'react-router-dom';
import { ApolloProvider } from 'react-apollo';
import { client } from './functions/Connect'; // eslint-disable-line no-unused-vars
import Header from './layout/Header';
import Sidebar from './layout/Sidebar';
import AuthenticatedRoutes from './AuthenticatedRoutes';

import { ErrorBoundary } from './elements/ErrorBoundary';
// Import Font Awesome icons.
import './elements/Icons';
// Import compiled stylesheet.
import '../styles/main.css';
// Add responsive body classes to <body> element (custom).
import { ResponsiveClasses } from './functions/ResponsiveClasses';
import { toggleMobileMenu } from './functions/Mobile';
import UnauthenticatedRoutes from './UnauthenticatedRoutes';
import { checkActivity, isAuthenticated, loginUser, logoutUser } from './functions/AuthFunctions';

class App extends React.Component<
  {},
  {
    isAuthenticated: boolean
  }
> {
  authentication: {
    auth: boolean,
    login: (token: string, name: string) => void,
    logout: () => void
  };

  constructor(props: {}) {
    super(props);
    this.state = {
      isAuthenticated: isAuthenticated()
    };

    this.authentication = {
      auth: this.state.isAuthenticated,
      login: loginUser,
      logout: logoutUser,
      check: checkActivity
    };
  }

  componentDidMount() {
    // Add responsive class behaviors.
    new ResponsiveClasses();
    window.addEventListener('breakpointChange', App.toggleSidebar);

    if (isAuthenticated()) {
      // Perform operations to see if a user's login has expired.
      checkActivity();
    }
  }

  componentWillUnmount() {
    window.removeEventListener('breakpointChange', App.toggleSidebar);
  }

  static toggleSidebar() {
    toggleMobileMenu();
  }

  render() {
    // If we are NOT authenticated, load up the login form.
    const Routes = this.state.isAuthenticated ? (
      <AuthenticatedRoutes authentication={this.authentication} />
    ) : (
      <UnauthenticatedRoutes authentication={this.authentication} />
    );

    const SidebarRouter = withRouter(Sidebar);
    const HeaderRouter = withRouter(Header);

    return (
      <ErrorBoundary>
        <BrowserRouter>
          <ApolloProvider client={client}>
            <div className="page-wrapper">
              <HeaderRouter />
              <div className="page-main">
                <SidebarRouter authentication={this.authentication} />
                {Routes}
              </div>
            </div>
          </ApolloProvider>
        </BrowserRouter>
      </ErrorBoundary>
    );
  }
}

export default App;
