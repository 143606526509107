/* @flow */
import * as React from 'react';
import ReactDOM from 'react-dom';
import { Alert, Button, Form, FormGroup, Label, Input, FormText, Card, CardHeader, CardBody } from 'reactstrap';

import {
  groupInfoQuery,
  deleteGroupMutation,
  removePhysicianFromGroupMutation,
  associatePhysicianWithGroupMutation,
  groupSlugQuery,
  updateGroupMutation,
  insertGroupMutation
} from 'group/queries';
import { Query, Mutation } from 'react-apollo';
import ModalSection, { modalClose, modalHide } from 'elements/ModalSection';
import { SystemAlert } from 'elements/SystemAlert';

import { Redirect } from 'react-router-dom';
import type { AddGroupProps, EditGroupProps, EditGroupState, DeleteGroupProps } from 'group/types';

import { searchProviderQuery } from 'provider/queries';
import AssociationForm from 'elements/AssociationForm';
import MachineName, { uniqueSlugCheck } from 'shared/inputs/MachineName';
// import { provider } from '../../../../api/src/components/graphql/rootQueries/provider';

export class NewGroupModal extends React.Component<AddGroupProps> {
  componentDidMount() {
    setTimeout(() => {
      ModalSection.openModal();
    }, 100);
  }

  render() {
    const emptyGroup = {
      slug: '0'
    };
    return (
      <ModalSection
        heading="Add New Group"
        content={
          <GroupEditForm
            history={this.props.history}
            match={this.props.match}
            group={emptyGroup}
            search={this.props.search}
            newGroup
          />
        }
        actions={false}
        history={this.props.history}
      />
    );
  }
}

export class EditGroupModal extends React.Component<EditGroupProps> {
  componentDidMount() {
    setTimeout(() => {
      ModalSection.openModal();
    }, 100);
  }

  render() {
    return (
      <ModalSection
        heading={`Edit Group`}
        content={
          <React.Fragment>
            <GroupEditForm
              history={this.props.history}
              match={this.props.match}
              group={this.props.group}
              search={this.props.search}
              newGroup={false}
            />
          </React.Fragment>
        }
        actions={false}
        history={this.props.history}
      />
    );
  }
}

class GroupEditForm extends React.Component<EditGroupProps, EditGroupState> {
  constructor(props: EditGroupProps) {
    super(props);
    this.state = {
      formChanged: false,
      formSubmitted: false,
      deleteRequest: false,
      deleteGroupSlug: false,
      newGroup: typeof this.props.newGroup !== 'undefined' && this.props.newGroup === true,
      custom: false,
      prevSearch: {
        filter: this.props.search && this.props.search.filter ? this.props.search.filter : '',
        after: this.props.search && this.props.search.after ? this.props.search.after : '',
        first: this.props.search && this.props.search.first ? this.props.search.first : 25,
        limit: this.props.search && this.props.search.limit ? this.props.search.limit : 25,
        order:
          this.props.search && this.props.search.order
            ? this.props.search.order
            : {
                sortField: '',
                sortDirection: ''
              }
      },
      submitReady: false,
      specialties: [],
      providers: []
    };

    this.handleFormChange = this.handleFormChange.bind(this);
    this.handleFormSaved = this.handleFormSaved.bind(this);
    this.readySubmit = this.readySubmit.bind(this);
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.handleDeleteSubmit = this.handleDeleteSubmit.bind(this);
    
  }
  


  handleFormChange = () => {
    //console.log('blue');
    this.setState(() => ({
      formChanged: true
    }));

    this.readySubmit();
  };

  handleFormSaved = () => {
    this.setState(() => ({
      formChanged: false
    }));
  };

  handleDeleteSubmit = slug => {
    modalHide();

    // Wait a tick and change the state, which will open the delete modal.
    setTimeout(() => {
      this.setState(() => ({
        deleteRequest: true,
        deleteGroupSlug: slug
      }));
    }, 500);
  };

  



  /**
   * @todo: Handle sanitizing bogus input. #TRUSTNOONE
   */
  handleFormSubmit = () => {
    
    const status = document && document.querySelector('input[name="groupPublishedStatus"]:checked');
    const profile = document && document.querySelector('input[name="groupProfileVisibility"]:checked');
    const name = document && document.getElementById('groupName');
    const slug = document && document.getElementById('groupName');
    const url = document && document.getElementById('groupURL');
    let groupName, groupSlug, groupUrl;
    let groupStatus,
      groupDisplay = false;

    if (typeof status !== 'undefined' && status !== null && status instanceof HTMLInputElement) {
      groupStatus = status.value === 'enabled'; // Returns true or false;
    }

    if (typeof profile !== 'undefined' && profile !== null && profile instanceof HTMLInputElement) {
      groupDisplay = profile.value === 'enabled'; // Returns true or false;
    }

    if (typeof name !== 'undefined' && name !== null && name instanceof HTMLInputElement) {
      groupName = name.value;
    }

    if (typeof url !== 'undefined' && url !== null && url instanceof HTMLInputElement) {
      groupUrl = url.value;
    }

    if (typeof slug !== 'undefined' && slug !== null && slug instanceof HTMLInputElement) {
      groupSlug = slug.value.toLowerCase()
      .replace("'","")
      .replace(/\W/g, '-')
      .replace(/-{2,}/g, '-')
      .replace(/^-+|-+$/g, '');
    }

    const submittedData = {
      name: groupName ? groupName : '',
      slug: groupSlug ? groupSlug : '',
      status: groupStatus,
      display_on_profile: groupDisplay,
      url: groupUrl
    };
    return submittedData;
  };

  /**
   * Function handles determining if the form is ready to submit.
   */
  readySubmit = () => {
    const name = document && document.getElementById('groupName');

    const readyToSubmit =
      typeof name !== 'undefined' && name !== null && name instanceof HTMLInputElement && name.value.length >= 1;

    this.setState(() => ({
      submitReady: readyToSubmit
    }));
  };

  render() {
    if (this.state.deleteRequest === true && typeof this.state.deleteGroupSlug === 'string') {
      return <Redirect to={`/group/delete/${this.state.deleteGroupSlug}`} />;
    }

    return (
      <Query
        query={groupInfoQuery}
        pollInterval={0}
        notifyOnNetworkStatusChange={true}
        variables={{
          slug: this.props.group && this.props.group.slug ? this.props.group.slug : ''
        }}
        fetchPolicy="cache-and-network"
      >
        {({ data, loading }) => {
          if (loading) return null;
          const group =
            data && data.group
              ? data.group
              : {
                  // Set the default form values.
                  id: '',
                  slug: '',
                  name: '',
                  url: '',
                  display_on_profile: false,
                  status: false,
                  providers: []
                };

          const upsertMutation = group.id ? updateGroupMutation : insertGroupMutation;

          return (
            <React.Fragment>
              <Alert color="warning" className={`mb-3 ${this.state.formChanged ? 'd-block' : 'd-none'}`}>
                <div className="alert--with-icon">
                  <i className="alert-icon fal fa-info-circle" />
                  <span>
                    The form has been updated. Use the <strong>Save Group</strong> button to avoid losing any changes.
                  </span>
                </div>
              </Alert>

              <Mutation mutation={upsertMutation}>
                {(upsertGroup, { loading, error }) => {
                  if (loading) {
                    return null;
                  }
                  if (error) {
                    console.log(`ERROR UPSERTING GROUP...`);
                  }

                  return (
                    <Form
                      onLoad={() => {}}
                      onChange={() => {
                        this.handleFormChange();
                      }}
                      onSubmit={(e: Event) => {
                        e.preventDefault();
                        const groupData = this.handleFormSubmit();

                        if (group && group.id) {
                          upsertGroup({
                            variables: {
                              input: {
                                groupId: group.id,
                                update: groupData
                              }
                            }
                          });
                        } else {
                          upsertGroup({
                            variables: {
                              input: groupData
                            }
                          });
                        }

                        // Send a message to the screen.
                        const NotifyUpserted = () => {
                          return (
                            <SystemAlert
                              autoClose
                              color="success"
                              icon="alert-icon fal fa-check-circle"
                              messageData={{
                                action: group.id ? 'Updated' : 'Created',
                                item: groupData.name,
                                id: group.id,
                                tail: '...'
                              }}
                            />
                          );
                        };

                        const alert = document && document.getElementById('system-alert-wrapper');
                        if (alert) {
                          ReactDOM.render(<NotifyUpserted />, alert);
                          modalClose(this.props.history);
                        }
                      }}
                    >
                      <FormGroup>
                        <React.Fragment>
                          <Input
                            type="hidden"
                            name="id"
                            id="groupId"
                            defaultValue={group && group.id ? group.id : ''}
                          />

                          <MachineName
                            isUniqueCallback={(slug: string) => {
                              return uniqueSlugCheck(slug, groupSlugQuery, 'group', 'saveGroupButton');
                            }}
                            // The field that is used to store the final version before submission.
                            // The field that also allows you to customize the slug.
                            target={{
                              // What this type of field is called: Machine Name/System Name/Slug
                              label: 'Machine Name',
                              id: 'groupSlug',
                              name: 'custom',
                              defaultValue: group && group.slug ? group.slug : ''
                            }}
                            // The source field for the generated slug.
                            source={{
                              label: 'Group Name',
                              id: 'groupName',
                              name: 'name',
                              placeholder: 'Enter a descriptive group name...',
                              defaultValue: group && group.name ? group.name : ''
                            }}
                            // If existing elements (based on source.defaultValue) should be
                            // automatically updated. Otherwise, a saved/preexisting value would not
                            // be updated immediately and would require manual (thoughtful) editing.
                            autoChangeOnExisting={false}
                            // Callback (from parent(s)) to be fired
                            changeCallback={() => {
                              setTimeout(() => {
                                this.handleFormChange();
                              }, 1000);
                            }}
                            existing={!!(group && group.id)}
                          />
                        </React.Fragment>
                        <hr />
                      </FormGroup>

                      <FormGroup className={group.id ? '' : 'd-none'}>
                        {console.log(group.providers)}
                        <AssociationForm
                          searchQuery={searchProviderQuery}
                          deleteMutation={removePhysicianFromGroupMutation}
                          associateMutation={associatePhysicianWithGroupMutation}
                          mutationVariables={{
                            groupId: group.id
                          }}
                          refetch={groupInfoQuery}
                          refetchVars={{ slug: group.slug }}
                          label={`Physicians`}
                          slug={`providers`}
                          gqlFilterVar={`provider`}
                          gqlFirstVar={200}
                          elementField={`fullName`}
                          elements={group.providers}
                          callbacks={{
                           
                            handleFormChange: () => {},
                            handleFormSaved: () => {}
                          }}
                        />
                        <hr />
                      </FormGroup>

                      <Card className={`my-3`}>
                        <CardHeader className={`bg-gray-300`}>
                          <legend className={`mb-0`}>FAD Profile Options</legend>
                          <FormText color="muted">
                            <i className="fal fa-info-circle" /> The following options control settings for how (or if)
                            the <strong>Group</strong> is displayed on <em>Find a Doctor</em> Physician profile pages.
                          </FormText>
                        </CardHeader>
                        <CardBody className={`p-4`}>
                          <FormGroup className={`fad-profile-options mb-0`}>
                            <legend>Group Profile Visibility</legend>
                            <div className="form-radio-group">
                              <FormGroup check>
                                <Label check>
                                  <Input
                                    type="radio"
                                    name="groupProfileVisibility"
                                    value="enabled"
                                    defaultChecked={group.display_on_profile ? 'checked' : false}
                                  />{' '}
                                  <span className="text-success">Visible</span>
                                </Label>
                              </FormGroup>
                              <FormGroup check>
                                <Label check>
                                  <Input
                                    type="radio"
                                    name="groupProfileVisibility"
                                    value="disabled"
                                    defaultChecked={group.display_on_profile ? false : 'checked'}
                                  />{' '}
                                  <span className="text-danger">Hidden</span>
                                </Label>
                              </FormGroup>
                            </div>
                            <FormText color="muted">
                              <i className="fal fa-info-circle" /> <strong>Groups</strong> with the{' '}
                              <strong>
                                <span className="text-success">Visible</span>
                              </strong>{' '}
                              flag set will appear on <em>Find a Doctor</em> Physician profile pages, while{' '}
                              <strong>
                                <span className="text-danger">Hidden</span>
                              </strong>{' '}
                              groups will not.
                            </FormText>
                            <hr />
                            <Label for={`groupURL`}>Group URL</Label>
                            <Input
                              type="text"
                              autoComplete="off"
                              name={`url`}
                              id={`groupURL`}
                              defaultValue={group.url}
                              placeholder={`https://www.northwell.edu/something/`}
                              invalid={false}
                              valid={false}
                            />
                            <FormText color="muted">
                              <i className="fal fa-info-circle" /> The <strong>Group URL</strong> allows a custom URL to
                              be provided/used to link to a page for this group. Please enter a fully qualified URL
                              including <em>http://</em> or <em>https://</em>.
                            </FormText>
                          </FormGroup>
                        </CardBody>
                      </Card>

                      <FormGroup>
                        <legend>Publishing Status</legend>
                        <div className="form-radio-group">
                          <FormGroup check>
                            <Label check>
                              <Input
                                type="radio"
                                name="groupPublishedStatus"
                                value="enabled"
                                defaultChecked={group.status ? 'checked' : false}
                              />{' '}
                              <span className="text-success">Enabled</span>
                            </Label>
                          </FormGroup>
                          <FormGroup check>
                            <Label check>
                              <Input
                                type="radio"
                                name="groupPublishedStatus"
                                value="disabled"
                                defaultChecked={group.status ? false : 'checked'}
                              />{' '}
                              <span className="text-warning">Disabled</span>
                            </Label>
                          </FormGroup>
                        </div>
                        <FormText color="muted">
                          <i className="fal fa-info-circle" /> By default the status of a new Group is set to{' '}
                          <strong>
                            <span className="text-warning">Disabled</span>
                          </strong>
                          . Set the status to{' '}
                          <strong>
                            <span className="text-success">Enabled</span>
                          </strong>{' '}
                          to activate this group.
                        </FormText>
                        <hr />
                      </FormGroup>

                      <FormGroup className="mt-2 form-actions d-flex justify-content-between align-items-end">
                        <Button
                          id="saveGroupButton"
                          type="submit"
                          size="md"
                          color="primary"
                          title="Save"
                          disabled={!this.state.submitReady}
                        >
                          <i className="fal fa-save" />
                          <span>Save Group</span>
                        </Button>
                        <span className={group.id ? 'd-inline-block' : 'd-none'}>
                          <Button
                            outline
                            size="md"
                            color="danger"
                            title={`Delete ${group.name}`}
                            onClick={() => {
                              this.handleDeleteSubmit(group.id);
                            }}
                          >
                            <i className="fal fa-trash-alt" />
                            <span>Delete Group</span>
                          </Button>
                        </span>
                      </FormGroup>
                    </Form>
                  );
                }}
              </Mutation>
            </React.Fragment>
          );
        }}
      </Query>
    );
  }
}

/**
 * Component to handle a delete confirmation message.
 */
export class DeleteGroupModal extends React.Component<DeleteGroupProps> {
  componentDidMount() {
    setTimeout(() => {
      ModalSection.openModal();
    }, 100);
  }

  render() {
    return (
      <ModalSection
        heading="Delete Group"
        content={
          <GroupDeleteForm
            history={this.props.history}
            group={this.props.group}
            search={this.props.search}
            callbacks={this.props.callbacks}
          />
        }
        actions={false}
        history={this.props.history}
      />
    );
  }
}

class GroupDeleteForm extends React.Component<DeleteGroupProps> {
  render() {
    return (
      <React.Fragment>
        <Query
          query={groupInfoQuery}
          pollInterval={0}
          notifyOnNetworkStatusChange={true}
          variables={{
            slug: this.props.group && this.props.group.slug ? this.props.group.slug : ''
          }}
          fetchPolicy="no-cache"
        >
          {({ data, loading }) => {
            if (loading) return null;
            const group =
              data && data.group
                ? data.group
                : {
                    // Set the default form values.
                    id: null,
                    name: '',
                    slug: '',
                    status: false,
                    providers: []
                  };

            const ConfirmationDetails = () => {
              if (data && data.group) {
                return (
                  <React.Fragment>
                    <Alert color="danger" className="mb-3 delete-confirmation">
                      <div className="alert--with-icon">
                        <i className="alert-icon fal fa-trash-alt" />
                        <h4 className="mb-0">Are you sure you want to delete following Group?</h4>
                      </div>
                    </Alert>
                    <hr />
                    <div className="delete-confirmation--details">
                      <p>
                        <span className="delete-confirmation-label">Group ID: </span>
                        <strong>{group && group.id ? group.id : ''}</strong>
                      </p>
                      <p>
                        <span className="delete-confirmation-label">Group Name: </span>
                        <strong>{group && group.name ? group.name : ''}</strong>
                      </p>
                      <p>
                        <span className="delete-confirmation-label">Slug: </span>
                        <strong>{group && group.slug ? group.slug : ''}</strong>
                      </p>
                      <p>
                        <span className="delete-confirmation-label">Physicians: </span>
                        <strong>{group && typeof group.providers === 'object' ? group.providers.length : ''}</strong>
                      </p>
                    </div>
                    <hr />
                  </React.Fragment>
                );
              }
              return null;
            };

            return (
              <React.Fragment>
                <Mutation
                  mutation={deleteGroupMutation}
                  variables={{
                    input: {
                      id: group && group.id ? group.id : null
                    }
                  }}
                >
                  {(deleteGroup, { data, loading }) => {
                    if (loading) {
                      return null;
                    }
                    const success = data && data.removeGroup && data.removeGroup.success;

                    if (success) {
                      const status = {
                        success: success,
                        action: success ? 'Deleted' : 'Failed to Delete',
                        item: {
                          id: data && data.removeGroup && data.removeGroup.group ? data.removeGroup.group.id : group.id,
                          name:
                            data && data.removeGroup && data.removeGroup.group
                              ? data.removeGroup.group.name
                              : group.name
                        },
                        tail: 'from the Group database...'
                      };

                      return (
                        <React.Fragment>
                          <Alert color="success">
                            <div className="alert--with-icon">
                              <i className="alert-icon fal fa-check-circle" />
                              <p className="mb-0">
                                Successfully {status.action} <strong>{status.item.name}</strong> (
                                <em>{status.item.id})</em> {status.tail}
                              </p>
                            </div>
                          </Alert>
                          <hr />
                          <div className={`text-center`}>
                            <Button
                              outline
                              className="delete-item-button--success"
                              size="lg"
                              color="success"
                              title="Done"
                              onClick={() => {
                                modalClose(this.props.history);
                              }}
                            >
                              <i className="fal fa-check-circle" />
                              <span>Done</span>
                            </Button>
                          </div>
                        </React.Fragment>
                      );
                    }

                    if (group && group.id !== null) {
                      return (
                        <React.Fragment>
                          <ConfirmationDetails />
                          <div className="text-center">
                            <Button
                              className="delete-item-button"
                              outline
                              size="lg"
                              color="danger"
                              title="Delete"
                              onClick={() => {
                                deleteGroup();
                              }}
                            >
                              <i className="fal fa-trash-alt" />
                              <span>Yes, I'm sure</span>
                            </Button>
                          </div>
                        </React.Fragment>
                      );
                    } else {
                      return (
                        <Alert color="danger">
                          <div className="alert--with-icon">
                            <i className="alert-icon fal fa-info-circle" />
                            <p className="mb-0">That Group could not be found...</p>
                          </div>
                        </Alert>
                      );
                    }
                  }}
                </Mutation>
              </React.Fragment>
            );
          }}
        </Query>
      </React.Fragment>
    );
  }
}
