/* eslint no-unused-vars: "off" */
import React, { Fragment, useContext,useState } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { Warning } from 'shared/messaging';
import { Info as InfoIcon, Plus } from 'shared/icons';

import { CREATE_SYNONYM } from 'synonym/queries';
import { Synonyms } from 'synonym/container';
import { createSynonym } from 'synonym/actions';
import { Container, Modal,Button,ModalHeader, ModalBody, ModalFooter,Input} from 'reactstrap';

const CreateSynonym = ({ name, term, setSearch,setcreatedTerm }) => {
  const { refetch } = useContext(Synonyms);
  const [creationMutation] = useMutation(CREATE_SYNONYM);
  const [modal, setModal] = useState(false);
 
  const toggle = () => setModal(!modal);

  const handleClose = () => setModal(false);
  const handleShow = () => setModal(true);
  return (
 
    <Warning icon={InfoIcon}>
      {/* It appears that the synonym you are searching for doesn't exist. */}
      <a
        className="ml-2 custom-cft-button text-primary"
        onClick={handleShow}
        // onClick={createSynonym({ variables: { name: name, termID: term.id } }, creationMutation, refetch, setSearch)}
      >
        <Plus className="mr-1 " />
        Create a Custom Synonym
      </a>
      <Modal isOpen={modal} toggle={toggle} centered>
      <ModalHeader toggle={toggle}>Add Custom Synonym</ModalHeader>
    
    <ModalBody>    
       <Input
                className="search section-search section-search--field form-control"
                placeholder="Custom Synonyms"
               // value={createdTerm}
                onChange={({ target: { value } }) => setcreatedTerm(value)}
              />
    </ModalBody>
    <ModalFooter>
   
   <Button variant="primary" onClick={createSynonym({ variables: { name: name, termID: term.id } }, creationMutation, refetch, setcreatedTerm,setModal)}>
    Save Changes
   </Button>
   </ModalFooter>
   </Modal>
    </Warning>
  
 
  );
};

export default CreateSynonym;
