import gql from 'graphql-tag';

/**
 * Query to handle a paginated list of CFTs, including aggregation and page information.
 */
export const umlsSearch = gql`
  query umlsSearch($filter: String!, $pageSize: Int, $pageNumber: Int) {
    umlsSearch(filter: $filter, pageSize: $pageSize, pageNumber: $pageNumber) {
      pageNumber
      result {
        results {
          cuid
          name
        }
      }
    }
  }
`;

export const umlsInternalSearch = gql`
  query cftTerm($id: ID, $slug: String, $umls_cuid: String) {
    cftTerm(id: $id, slug: $slug, umls_cuid: $umls_cuid) {
      id
      umls_cuid
      name
      slug
    }
  }
`;
